import { Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { SvgDollarIcon } from '../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../components/FormWrap';
import RowWrap from '../../../../../components/RowWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import KeyboardWrapper, { KeyboardDirection } from '../../keyboard';
import { FormSelect } from '../../../../../components/Form/FormSelect';
import { SvgArrowSelectIcon } from '../../../../../components/@svg/SvgArrowSelectIcon';
import { DiscountTypeEnum } from '../../checkoutConstants';
import { SvgPercentIcon } from '../../../../../components/@svg/SvgPercentIcon';
import { Customer } from '../../../../../apis/client-axios';

export interface Props {
  open: boolean;
  onCancelModal: () => void;
  onOK: (redeem: number, forOff: number, type: DiscountTypeEnum) => void;
  customer?: Customer;
}

interface FormData {
  type: DiscountTypeEnum;
  redeem: number;
  forOff: number;
}

function CustomRedeem<T extends object>(props: Props) {
  const { open, onCancelModal, onOK, customer } = props;

  const intl = useIntl();
  const keyboardRef = useRef<T>();
  const n = (key: keyof FormData) => key;
  const [focusedInputName, setFocusedInputName] = useState<keyof FormData>();
  const [form] = Form.useForm();
  const type = Form.useWatch(n('type'), form);
  const forOff = Form.useWatch(n('forOff'), form);
  const redeem = Form.useWatch(n('redeem'), form);
  const forOffRef = useRef<HTMLInputElement>(null);
  const redeemRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (forOff && forOffRef.current) {
      const [integer] = forOff.replace(/,/g, '').split('.');
      forOffRef.current.selectionStart = forOffRef.current.selectionEnd = forOff.length + Math.floor(integer / 3);
    }

    if (redeem && redeemRef.current) {
      const [integer] = redeem.replace(/,/g, '').split('.');
      redeemRef.current.selectionStart = redeemRef.current.selectionEnd = redeem.length + Math.floor(integer / 3);
    }
  }, [forOff, redeem]);

  const onFinish = (data: FormData) => {
    const redeem = Math.floor(Number(data.redeem?.toString().replace(/[,\s]/g, '')));
    const forOff = Number(data.forOff?.toString().replace(/[,\s]/g, ''));
    onOK(redeem, forOff, data.type);
  };
  const renderIconForInput = () => {
    if (type === DiscountTypeEnum.Dollar) return { prefix: <SvgDollarIcon /> };
    else return { suffix: <SvgPercentIcon /> };
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-custom-discout-modal-heading">
            {intl.formatMessage({ id: 'checkout.discountAndRewards.customRedeem' })}
          </div>
        ),
        className: 'salon__checkout-custom-discout-modal salon__checkout-discount-rewards-modal',
        width: 700,
        footer: null,
      }}
    >
      <FormWrap
        form={form}
        name="customRedeem"
        layout="vertical"
        className="w-100 salon__checkout-custom-discount-modal-form p-t-20 p-b-40"
        onFinish={onFinish}
      >
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <FormSelect
              name={'type'}
              formItemProps={{
                className: 'salon__checkout-select-redeem width-266',
                initialValue: DiscountTypeEnum.Dollar,
              }}
              selectProps={{
                className: 'font-weight-500',
                placeholder: intl.formatMessage({ id: 'employee.placeholder.selectGender' }),
                options: [
                  {
                    label: intl.formatMessage({ id: 'checkout.redeem.anAmount' }),
                    value: DiscountTypeEnum.Dollar,
                  },
                  {
                    label: intl.formatMessage({ id: 'checkout.redeem.aPercent' }),
                    value: DiscountTypeEnum.Percent,
                  },
                ],
                allowClear: false,
                suffixIcon: <SvgArrowSelectIcon />,
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12 }}>
            <FormInputNumber
              name={n('redeem')}
              label={intl.formatMessage({ id: 'checkout.discountAndRewards.customRedeem.title' })}
              numericFormatProps={{
                className: 'm-b-0',
                placeholder: '0',
                onFocus: () => setFocusedInputName(n('redeem')),
                decimalScale: 0,
                getInputRef: redeemRef,
                onKeyDown: (e) => {
                  return e.preventDefault();
                },
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
                rules: [
                  { required: true, message: '' },
                  {
                    validator: (_, value: string | number) => {
                      const v = Number(value?.toString().trim()?.replace(/,/g, '').replace(/ /g, ''));
                      const reward =
                        customer?.customerReward?.reduce((currentValue, item) => currentValue + item.reward, 0) ?? 0;
                      if (v && (!reward || (reward && v > reward))) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12 }}>
            <FormInputNumber
              name={n('forOff')}
              label={intl.formatMessage({ id: 'checkout.discountAndRewards.customRedeem.title.for' })}
              {...renderIconForInput()}
              numericFormatProps={{
                className: 'm-b-0',
                placeholder: intl.formatMessage({ id: 'checkout.discountAndRewards.customRedeem.placeholder' }),
                onFocus: () => setFocusedInputName(n('forOff')),
                getInputRef: forOffRef,
                onKeyDown: (e) => {
                  return e.preventDefault();
                },
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
                rules: [{ required: true, message: '' }],
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 19 }}>
            <KeyboardWrapper
              inputName={focusedInputName}
              form={form}
              keyboardRef={keyboardRef}
              maxLength={9}
              keyboardProps={{
                inputPattern: /^\d+$/,
              }}
              direction={focusedInputName === n('forOff') ? KeyboardDirection.rtl : KeyboardDirection.ltr}
              isNotFormat={focusedInputName === n('redeem') ? true : false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 5 }}>
            <ButtonStyled
              content={
                <span className="salon__checkout-custom-discout-modal-keyboard-button-text">
                  {intl.formatMessage({ id: 'checkout.ok' })}
                </span>
              }
              buttonProps={{
                className: 'width-122 height-328',
                htmlType: 'submit',
              }}
              isPrimary={true}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
}

export default CustomRedeem;
