import axios from 'axios';
import {
  AuthApi,
  CustomersApi,
  Configuration,
  RolesApi,
  PermissionsApi,
  ServicesApi,
  SalonsApi,
  EmployeesApi,
  AssetsApi,
  AppointmentApi,
  PromotionsApi,
  TicketApi,
  SettingsApi,
  CompensationApi,
} from './client-axios';

const config = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
  accessToken: localStorage.getItem('token') || sessionStorage.getItem('token') || undefined,
});
export const axiosInstance = axios.create();

axiosInstance.defaults.headers.salonId = localStorage.getItem('salonId') || null;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export type ErrorResponse = { response: { data: { message: string }; status: number } };

const authApi = new AuthApi(config, undefined, axiosInstance);
const customerApi = new CustomersApi(config, undefined, axiosInstance);
const roleApi = new RolesApi(config, undefined, axiosInstance);
const permissionApi = new PermissionsApi(config, undefined, axiosInstance);
const employeeApi = new EmployeesApi(config, undefined, axiosInstance);
const salonApi = new SalonsApi(config, undefined, axiosInstance);
const servicesApi = new ServicesApi(config, undefined, axiosInstance);
const assetsApi = new AssetsApi(config, undefined, axiosInstance);
const appointmentApi = new AppointmentApi(config, undefined, axiosInstance);
const ticketApi = new TicketApi(config, undefined, axiosInstance);
const promotionApi = new PromotionsApi(config, undefined, axiosInstance);
const settingApi = new SettingsApi(config, undefined, axiosInstance);
const compensationApi = new CompensationApi(config, undefined, axiosInstance);

export {
  authApi,
  customerApi,
  roleApi,
  permissionApi,
  servicesApi,
  salonApi,
  employeeApi,
  assetsApi,
  appointmentApi,
  promotionApi,
  ticketApi,
  settingApi,
  compensationApi,
};
