import { useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { customerApi } from '../../../../../apis';
// import { Customer } from '../../../../../apis/client-axios';
import { FormInputSearch } from '../../../../../components/Form/FormInputSearch';
import FormWrap from '../../../../../components/FormWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { QUERY_CUSTOMER } from '../../../../../utils/constant';
import { IFromStateInput } from '../index';
import { CustomerInformation } from './CustomerInformation';

export interface Props {
  open: boolean;
  setOpenModal: (state: boolean) => void;
  setTabData: (data: IFromStateInput) => void;
}

const CustomerModal: FC<Props> = ({ open, setOpenModal, setTabData }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  // const [filterCustomers, setFilterCustomers] = useState<Customer[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const { data: listDataCustomers } = useQuery({
    queryKey: [QUERY_CUSTOMER, searchText], //when search text change api will refetching
    queryFn: () =>
      customerApi.customerControllerGet(0, undefined, undefined, searchText, localStorage.getItem('salonId')),
  });

  // const {
  //   data: listDataCustomers,
  //   isFetching: isFetchingCustomers,
  //   refetch: handleRefetchCustomers,
  // } = useQuery([QUERY_CUSTOMER], () =>
  //   customerApi.customerControllerGet(0, undefined, undefined, undefined, localStorage.getItem('salonId'))
  // );

  // useEffect(() => {
  //   if (searchText) {
  //     handleRefetchCustomers();
  //     setFilterCustomers(listDataCustomers?.data.content || []);
  //     //   setFilterCustomers((prev) => {
  //     //     const copyCustomers = [...prev];
  //     //     return copyCustomers.filter((customer) => customer.name === searchText || customer.phoneNumber === searchText);
  //     //   });
  //   } else {
  //     setFilterCustomers(listDataCustomers?.data.content || []);
  //     // console.log(listDataCustomers?.data.content);
  //     // console.log(filterCustomers);
  //   }
  // }, [searchText, open]);

  useEffect(() => {
    setSearchText('');
    form.resetFields();
  }, [open]);

  const onFinish = (formValues: { fullTextSearch?: string }) => {
    setSearchText(formValues.fullTextSearch);
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpenModal(false)}
      modalProps={{
        title: (
          <div className="salon__appointment-modal-heading m-l-r-10-n">
            <span>{intl.formatMessage({ id: 'appointment.modal.customer.search.heading' })}</span>
          </div>
        ),
        className: 'salon__appointment-modal-create salon__appointment-modal-create-customer',
        width: 400,
        footer: null,
        centered: true,
        destroyOnClose: true,
      }}
    >
      <FormWrap
        className="salon__appointment-customer-picker m-l-r-10-n"
        form={form}
        onFinish={onFinish}
        layout="inline"
        id="customerModal"
      >
        <FormInputSearch
          name="fullTextSearch"
          inputProps={{
            className: 'salon__appointment-customer-picker-search-input',
            placeholder: intl.formatMessage({ id: 'appointment.modal.customer.search.placeholder' }),
          }}
        />

        <div className="salon__appointment-customer-picker-list">
          {listDataCustomers &&
            listDataCustomers.data.content?.map((item, index) => (
              <div
                key={`customer_${index}`}
                className="cursor-pointer"
                onClick={() => {
                  setTabData({ phone: item.phoneNumber, name: item.name });
                  setOpenModal(false);
                }}
              >
                <CustomerInformation name={item.name} phoneNumber={item.phoneNumber} rating={5} key={item.id} />
              </div>
            ))}
        </div>
      </FormWrap>
    </StyledModal>
  );
};

export default CustomerModal;
