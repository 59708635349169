import React from 'react';
import { useIntl } from 'react-intl';
import { Space } from 'antd';
import { SvgMiniStarIconActive } from '../../../../../../components/@svg/SvgMiniStarIconActive';

type Props = {
  name: string;
  phoneNumber: string;
  rating: number;
};
export const CustomerInformation: React.FC<Props> = ({ name, phoneNumber, rating }) => {
  const intl = useIntl();
  return (
    <div className="salon__appointment-modal-customer-information">
      <div className="d-flex salon__appointment-modal-customer-information-content">
        <div className="w-80 justify-content-start">
          <Space direction="vertical" size={0}>
            <span
              className="font-size-16 font-weight-700 w-100 overflow-hidden"
              style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {name.length <= 10 ? name : name.slice(0, 10) + '...'}
            </span>
            <span className="font-size-16 font-weight-500 w-100">
              {`${phoneNumber.slice(0, 3)} - ${phoneNumber.slice(3, 6)} - ${phoneNumber.slice(6, 10)}`}
            </span>
          </Space>
        </div>
        <div className="w-20 justify-content-end">
          <div className="font-size-12 font-weight-700 salon__appointment-modal-customer-information-rating">
            <span className="p-t-3">{rating}</span>
            <SvgMiniStarIconActive />
          </div>
        </div>
      </div>
    </div>
  );
};
