import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  AppointmentStatusEnum,
  Service,
  ServiceItems,
  TicketDTOStatusEnum,
} from '../../../../../apis/client-axios/api';
import { SvgAppointmentDeleteCheckoutIcon } from '../../../../../components/@svg/SvgAppointmentDeleteCheckoutIcon';
import { SvgAppointmentPullDownIcon } from '../../../../../components/@svg/SvgAppointmentPullDownIcon';
import { SvgAppointmentPullUpIcon } from '../../../../../components/@svg/SvgAppointmentPullUpIcon';
import NotificationError from '../../../../../components/HandleShowNotiError';
import { ButtonIcon } from '../../../../../components/buttons/ButtonIcon';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { formatTimeHHMMa } from '../../../../../utils';
import { IAppointmentBill, IMerchandise, IServicesItem, ITechnicianItem } from '../../../checkOut';
import { MAIN_ROUTES } from '../../../routes';
import { STATUSES } from '../../index';
import { AppointmentEvent, ID_CHECKOUT_VIRTUAL } from '../../models';

interface CheckoutProps {
  draggedEvent?: AppointmentEvent;
  onChangeDraggedEvent: (draggedEvent?: AppointmentEvent) => void;
  onChangeEvents: (events: AppointmentEvent[]) => void;
  events: AppointmentEvent[];
  isCheckoutMobile: boolean;
  checkoutList: AppointmentEvent[];
  onChangeCheckoutList: (checkoutList: AppointmentEvent[]) => void;
}
interface IServiceAppointmentCheckout extends IServicesItem {
  technicianId?: number;
}
const Checkout: FC<CheckoutProps> = (props) => {
  const {
    draggedEvent,
    onChangeDraggedEvent,
    events,
    onChangeEvents,
    isCheckoutMobile,
    checkoutList,
    onChangeCheckoutList,
  } = props;

  const navigate = useNavigate();

  const intl = useIntl();
  const [isExpand, setIsExpand] = useState<boolean>(true);
  const [messageError, setMessageError] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (checkoutList.length === 2 && ref.current) {
      ref.current.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [checkoutList]);

  useEffect(() => {
    if (messageError) {
      NotificationError({ contentNoti: messageError });
      setMessageError('');
    }
  }, [messageError]);

  useEffect(() => {
    if (isCheckoutMobile) {
      handleMouseUp();
    }
  }, [isCheckoutMobile]);

  const handleMouseUp = () => {
    if (!draggedEvent || (draggedEvent && draggedEvent.isBlockTime)) {
      if (draggedEvent) {
        onChangeDraggedEvent(undefined);
      }

      return;
    }

    if (!isExpand) {
      setIsExpand(true);
    }

    if (
      draggedEvent.resource?.status !== AppointmentStatusEnum.CheckedIn &&
      draggedEvent.resource?.status !== AppointmentStatusEnum.BeingServed
    ) {
      onChangeDraggedEvent(undefined);

      if (!messageError) {
        setMessageError(intl.formatMessage({ id: 'appointment.calendar.checkout.error' }));
      }
      return;
    }

    if (checkoutList.findIndex((item) => item.id === draggedEvent.id) === -1) {
      const copyCheckoutList = [...checkoutList];
      copyCheckoutList.push(draggedEvent);

      onChangeCheckoutList(copyCheckoutList);
      onChangeDraggedEvent(undefined);

      const copyEvents = [...events];
      const findIndex = copyEvents.findIndex((item) => item.id === draggedEvent.id);
      if (findIndex > -1) {
        copyEvents.splice(findIndex, 1);
        onChangeEvents(copyEvents);
      }
    }
  };

  const handleDeleteCheckout = (event: AppointmentEvent) => {
    const copyEvents = [...events];
    const copyCheckoutList = [...checkoutList];
    const findIndex = copyCheckoutList.findIndex((item) => item.id === event.id);

    if (findIndex > -1) {
      copyCheckoutList.splice(findIndex, 1);
      onChangeCheckoutList(copyCheckoutList);
      copyEvents.push(event);
      onChangeEvents(copyEvents);
    }
  };

  const handleCheckout = () => {
    const technician: ITechnicianItem[] = [];
    const appointmentId: number[] = [];
    const ticketMerchandise: IMerchandise[] = [];
    const services: IServicesItem[] = [];
    const technicianUnassignService: IServiceAppointmentCheckout[] = [];

    const checkoutServices = checkoutList.flatMap((checkout) => checkout.resource?.services);
    console.log({ checkoutList });
    console.log({ checkoutServices });

    checkoutServices.forEach((service) => {
      const ticketMerchandiseItem: IMerchandise = {
        name: service?.name,
        merchandisePrice: service?.price,
        merchandiseId: service?.id,
        isTaxable: service?.isTaxable ?? false,
        idDelete: parseFloat(Math.random().toFixed(3)) * 10,
      } as IMerchandise;

      const serviceItem: IServicesItem = {
        description: service?.description,
        id: service?.id,
        name: service?.name,
        price: service?.price,
        supply: service?.supply,
        isMerchandise: service?.isMerchandise,
        isGeneralService: !service?.isMerchandise,
        isTaxable: service?.isTaxable,
      } as IServicesItem;

      const findIndexService = services.findIndex((service) => service.id === serviceItem.id);

      if (findIndexService === -1) {
        if (serviceItem.isMerchandise) {
          ticketMerchandise.push(ticketMerchandiseItem);
        }
      }
    });

    const grpServices: { [key: number]: IServiceAppointmentCheckout[] } = {};
    checkoutList.forEach((item: AppointmentEvent) => {
      const technicianId = item?.resource?.technicianId ?? 0;
      const services = item?.resource?.services;
      if (!!services) {
        if (!grpServices[technicianId]) {
          grpServices[technicianId] = [];
        }
        grpServices[technicianId].push(
          ...services.map((item) => {
            return {
              ...item,
              isGeneralService: false,
              description: item.description ?? '',
              supply: item.supply ?? 0,
            };
          })
        );
      }
    });
    const listServiceGrp: IServiceAppointmentCheckout[] = Object.entries(grpServices).reduce(
      (services, [technicianId, servicesArray]: any) => {
        const servicesWithTechnicianId = servicesArray?.map((service: IServiceAppointmentCheckout) => ({
          ...service,
          technicianId: parseInt(technicianId),
        }));
        return services.concat(servicesWithTechnicianId);
      },
      []
    );

    checkoutList.forEach((checkout) => {
      if (!checkout.resource?.technician) {
        checkout.resource?.services.forEach((service) => {
          technicianUnassignService.push({
            ...service,
            isGeneralService: false,
            description: service.description ?? '',
            supply: service.supply ?? 0,
            technicianId: -1,
          });
        });
      }
    });
    console.log({ technicianUnassignService });

    const handleSortListService = (technicianId: number, service: ServiceItems[]) => {
      const technicianService: IServicesItem[] = [];
      if (technicianId !== 0) {
        listServiceGrp.forEach((item) => {
          if (technicianId === item.technicianId && item && !item.isMerchandise) {
            technicianService.push({
              description: item?.description ?? '',
              id: item?.id,
              name: item?.name,
              price: item?.price,
              supply: item?.supply ?? 0,
              isMerchandise: item?.isMerchandise,
              isGeneralService: false,
              isTaxable: item.isTaxable,
            });
          }
        });
      } else {
        service.forEach((item) => {
          if (item && !item.isMerchandise) {
            technicianService.push({
              description: item?.description ?? '',
              id: item?.id,
              name: item?.name,
              price: item?.price,
              supply: item?.supply ?? 0,
              isMerchandise: item?.isMerchandise,
              isGeneralService: false,
              isTaxable: item.isTaxable,
            });
          }
        });
      }
      return technicianService;
    };
    checkoutList.forEach((checkout) => {
      const technicianItem: ITechnicianItem = {
        id: checkout.resource?.technician?.id ? +checkout.resource?.technician?.id : -1,
        defaultAvatar: checkout.resource?.technician?.defaultAvatar,
        name: checkout.resource?.technician?.name,
        phoneNumber: checkout.resource?.technician?.phoneNumber,
        title: checkout.resource?.technician?.title,
        services: !checkout.resource?.technician?.id
          ? technicianUnassignService
          : handleSortListService(
              checkout?.resource?.technician?.id ? +checkout.resource?.technician?.id : 0,
              checkout.resource?.services ?? []
            ),
        skills: checkout.resource?.technician?.skills.map((e) => e.id),
      };

      const findIndex = technician.findIndex((tech) => tech.id === technicianItem.id);

      if (technicianItem.id && findIndex === -1) {
        technician.push(technicianItem);
      }

      appointmentId.push(Number(checkout.id));
    });

    const deposit =
      checkoutList
        .flatMap((checkout) => checkout.resource?.appointmentDeposits)
        .reduce((prev, curr) => {
          return prev + Number(curr?.money);
        }, 0) || 0;

    const appointmentBill: IAppointmentBill = {
      technician,
      appointmentId,
      customerId: Number(checkoutList[0].resource?.customerId),
      ticketMerchandise,
      status: TicketDTOStatusEnum.Hold,
      giftCards: [],
      deposit,
      customer: checkoutList[0].resource?.customer,
    };

    sessionStorage.setItem('appointmentBill', JSON.stringify(appointmentBill));
    navigate(MAIN_ROUTES.CHECK_OUT);
    console.log({ appointmentBill });
  };

  return (
    <>
      <div
        id={ID_CHECKOUT_VIRTUAL}
        className="salon__appointment-calendar-checkout-virtual"
        onMouseUp={handleMouseUp}
        style={{
          zIndex: draggedEvent ? 7 : 3,
        }}
      ></div>
      <div
        className={`salon__appointment-calendar-checkout ${
          isExpand ? 'salon__appointment-calendar-checkout-expand' : ''
        }`}
        style={{
          boxShadow: draggedEvent ? '-1px -1px 20px 0px rgba(0, 0, 0, 0.3)' : 'none',
        }}
      >
        <div className="d-flex justify-content-center w-100">
          <ButtonStyled
            isPrimary
            content={intl.formatMessage({ id: 'appointment.button.checkout' })}
            buttonProps={{
              disabled: !Boolean(checkoutList.length),
              className: `salon__appointment-calendar-checkout-btn ${
                checkoutList.length > 0 ? 'salon__appointment-calendar-checkout-btn-active' : ''
              }`,
              onClick: handleCheckout,
            }}
          />
          <ButtonIcon
            icon={isExpand ? <SvgAppointmentPullDownIcon /> : <SvgAppointmentPullUpIcon />}
            className="salon__appointment-calendar-checkout-btn-expand"
            buttonProps={{
              onClick: () => setIsExpand((prev) => !prev),
            }}
          />
        </div>
        <div
          className={`salon__appointment-calendar-checkout-list-container ${
            checkoutList.length > 2 ? 'salon__appointment-calendar-checkout-list-container-scroll' : ''
          }`}
          ref={ref}
        >
          {isExpand &&
            (checkoutList.length === 0 ? (
              <p className="font-size-12 font-weight-700 salon__appointment-calendar-checkout-empty">
                {intl.formatMessage({ id: 'appointment.calendar.checkout.empty' })}
              </p>
            ) : (
              <div className="salon__appointment-calendar-checkout-list">
                {checkoutList.map((item) => (
                  <div
                    className="salon__appointment-calendar-checkout-item"
                    key={item.id}
                    style={{ background: STATUSES.find((status) => status.key === item.resource?.status)?.color }}
                  >
                    <ButtonIcon
                      icon={<SvgAppointmentDeleteCheckoutIcon />}
                      buttonProps={{
                        onClick: () => handleDeleteCheckout(item),
                      }}
                    />
                    <p className="salon__appointment-calendar-checkout-item-text">
                      <span className="font-size-12 font-weight-700">{formatTimeHHMMa(item?.start)}</span>
                      <span className="font-size-12 font-weight-700">-</span>
                      <span className="font-size-12 font-weight-700">{formatTimeHHMMa(item?.end)}</span>
                    </p>
                    <p className="font-size-12 font-weight-500 salon__appointment-calendar-checkout-item-text">
                      {item.resource?.customer.name}
                    </p>
                    <p className="font-size-12 font-weight-500 salon__appointment-calendar-checkout-item-text">
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Checkout;
