import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Promotion } from '../../../../apis/client-axios';
import { StyledModal } from '../../../../components/StyledModal';
import {
  IHandleSubmitCheckoutProps,
  IListIdTotalPrice,
  ITechnicianItem,
  ITipInformation,
  PaymentInformation,
} from '../index';
import { Payment } from './Payment';
import { TipsChargeCard } from './Payment/ChargeCard/TipsChargeCard';
import './payAndCompleteStyle.scss';

export enum PayAndCompleteEnum {
  Payment = 'payment',
  Tips = 'tips',
}

export interface Props {
  open: boolean;
  onChangeOpenPayAndComplete: (type: PayAndCompleteEnum, isOpen: boolean) => void;
  handleSubmitCheckout: (submitParams?: IHandleSubmitCheckoutProps) => void;
  listTotal: IListIdTotalPrice;
  setListTotal: (listTotal: IListIdTotalPrice) => void;
  onChangePaymentInformation: React.Dispatch<React.SetStateAction<PaymentInformation>>;
  paymentInformation?: PaymentInformation;
  technicians: ITechnicianItem[];
  listPromotion: AxiosResponse<Promotion, any> | undefined;
  resetBill: () => void;
  tipInformation: ITipInformation | undefined;
  setTipInformation: React.Dispatch<React.SetStateAction<ITipInformation | undefined>>;
  isLoading: boolean;
}

function PayAndComplete<T extends object>(props: Props) {
  const {
    open,
    onChangeOpenPayAndComplete,
    handleSubmitCheckout,
    listTotal,
    setListTotal,
    paymentInformation,
    onChangePaymentInformation,
    technicians,
    listPromotion,
    resetBill,
    tipInformation,
    setTipInformation,
    isLoading,
  } = props;
  const intl = useIntl();
  const [currentScreen, setCurrentScreen] = useState<PayAndCompleteEnum>(PayAndCompleteEnum.Payment);

  const handleCloseModal = (isOpen: boolean) => {
    onChangeOpenPayAndComplete(currentScreen, isOpen);

    if (currentScreen === PayAndCompleteEnum.Tips && !isOpen) {
      setTipInformation(undefined);
    }
  };

  const saveTipAndTechnician = (totalTip: number) => {
    setTipInformation((prev) => ({
      ...prev,
      technicians,
      tip: totalTip,
    }));
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case PayAndCompleteEnum.Payment:
        return (
          <Payment
            setOpenModal={handleCloseModal}
            handleSubmitCheckout={handleSubmitCheckout}
            listTotal={listTotal}
            setListTotal={setListTotal}
            paymentInformation={paymentInformation}
            onChangePaymentInformation={onChangePaymentInformation}
            onChangeCurrentScreen={setCurrentScreen}
            listPromotion={listPromotion}
            saveTipAndTechnician={saveTipAndTechnician}
            resetBill={resetBill}
            tipInformation={tipInformation}
            isLoading={isLoading}
          />
        );
      case PayAndCompleteEnum.Tips:
        return <TipsChargeCard setOpenModal={handleCloseModal} tipInformation={tipInformation} />;
      default:
        return <></>;
    }
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => handleCloseModal(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: `checkout.payAndComplete.title.${currentScreen}` })}
          </div>
        ),
        className: `salon__checkout__pay-n-complete-modal ${
          currentScreen === PayAndCompleteEnum.Tips ? 'salon__checkout__pay-n-complete-modal__tip-screen' : ''
        }`,
        width: 700,
        footer: null,
      }}
    >
      {renderScreen()}
    </StyledModal>
  );
}

export default PayAndComplete;
