import dayjs, { Dayjs } from 'dayjs';
import { DateObject } from 'react-multi-date-picker';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_FULL_DATE = 'MM/DD/YYYY HH:mm:ss';
export const DATE_FORMAT_WO_TIME = 'MM/DD/YYYY HH:mm';
export const DATE_FORMAT_UTC = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const FULL_TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT = 'HH:mm';
export const APPOINTMENT_TIME_FORMAT = 'hh:mma';
export const APPOINTMENT_FULL_TIME_FORMAT = 'h:mmA MMM D, YYYY';
export const CHECKOUT_FULL_TIME_FORMAT = 'hh:mm A, MM/DD/YYYY';
export const PROMOTION_FULL_TIME_FORMAT = 'h:mm A, MM/DD/YYYY';
export const DATE_TIME_WITH_MERIDIEM_FORMAT = 'MM/DD/YYYY, hh:mm A';

export const DATE_TIME_WITH_AT_ON = 'hh:mm A, on MM/DD/YYYY';

export const HHMMA_TIME_FORMAT = (splitter?: string) => 'hh:mm' + (splitter ?? '') + 'a';

const toDayjs = (date: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null): Dayjs | DateObject => {
  if (dayjs.isDayjs(date)) {
    return date;
  } else if (date instanceof Date) {
    return dayjs(date);
  } else if (typeof date === 'string') {
    return dayjs(new Date(date));
  } else if (date instanceof DateObject) {
    return date;
  } else {
    return dayjs();
  }
};
export const formatDate = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT_WO_TIME) : '';

export const formatDateWithMer = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_TIME_WITH_MERIDIEM_FORMAT) : '';

export const formatDateWithAtOn = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_TIME_WITH_AT_ON) : '';

export const formatOnlyDate = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT) : '';

export const formatTime = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(FULL_TIME_FORMAT) : '';

export const formatTimeHHMM = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(TIME_FORMAT) : '';

export const formatTimeHHMMa = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(APPOINTMENT_TIME_FORMAT) : '';

export const formatTimeHHMMaWithSplitter = (
  date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null,
  splitter?: string
) => (date ? toDayjs(date).format(HHMMA_TIME_FORMAT(splitter)) : '');

export const formatTimeHHMMaFulltime = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(APPOINTMENT_FULL_TIME_FORMAT).replace(/AM/g, 'am').replace(/PM/g, 'pm') : '';

export const formatFulltimeHmaMMDDYYYY = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(PROMOTION_FULL_TIME_FORMAT).replace(/AM/g, 'am').replace(/PM/g, 'pm') : '';

export const formatDateTimeUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT_UTC) : '';

export const formatDateTimeJsonUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()) : '';

export const _fmUtcToLocalFullDateUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return date ? dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()).format(DATE_FORMAT_FULL_DATE) : '';
};

export const _fmUtcToLocalOnlyDateUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return date ? dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()).format(DATE_FORMAT) : '';
};

export const _fmUtcToLocalFullDateTimeUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return date ? dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()).format(DATE_FORMAT_FULL_DATE) : '';
};

export const _fmFullDateTimeUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()).format();
};

export const formatDateTimeWithMeridiem = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return date ? dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()).format(DATE_TIME_WITH_MERIDIEM_FORMAT) : '';
};

export const emailChecker = (email: string) => {
  const regex =
    /^(([^<>()[\]\\~!#$%^&()<>.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email);
};

export const phoneChecker = (phone: string) => {
  // const regex =
  //   /^(0)[1-9]((\s|\s?\-\s?)?[0-9])([0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]?[0-9]?[0-9]$/;
  const regex = /^\d{10,12}$/;
  return regex.test(phone);
};

export const passwordChecker = (password: string) => {
  const regex = /^((?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,32})$/;
  return regex.test(password);
};

export const noSpecialCharactersChecker = (phone: string) => {
  const regex = /^[a-zA-Z0-9]$/;
  return regex.test(phone);
};

export const isValidUrl = (urlString: string) => {
  const re = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(urlString);
};

export function setStorageByName<T>(key: string, value: T) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getStorageByName<T>(key: string): T | null {
  const item: string | null = window.localStorage.getItem(key);
  if (item === null) {
    return null;
  }
  return JSON.parse(item) as T;
}
export function getSessionStorageByName<T>(key: string): T | null {
  const item: string | null = window.sessionStorage.getItem(key);
  if (item === null) {
    return null;
  }
  return JSON.parse(item) as T;
}

export const removeStorageByName = (key: string) => {
  window.localStorage.removeItem(key);
};
export const removeSessionStorageByName = (key: string) => {
  window.sessionStorage.removeItem(key);
};

export const clearAllStorage = () => {
  window.localStorage.clear();
};
export const clearAllSessionStorage = () => {
  window.sessionStorage.clear();
};

export const formatPhoneNumber = (phoneNumber?: string, spliter?: string) => {
  const x = phoneNumber && phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !!x?.length
    ? !x[2]
      ? x[1]
      : `${x[1]} ${spliter || '-'} ${x[2]}${x[3] ? ` ${spliter || '-'} ${x[3]}` : ''}`
    : '';
};

export const regexPasswordMin8LettersMin1Number = (password: string) => {
  const regex = /^(?=.*\d).{8,}$/;
  return regex.test(password);
};

export const regexCheckUrl = (url: string) => {
  const regexUrl = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g;
  return regexUrl.test(url);
};

export const formatNumberThousand = (numberThousand: string | number, spliter?: string) => {
  return numberThousand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, spliter || ',');
};

export const formatNumberThousandWithDecimal = (number: string | number) => {
  return Number(number)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNumberCashID = (number: string | number) => {
  return Number(number)
    .toString()
    .replace(/\B(?=(\d{4})+(?!\d))/g, ' ') !== '0'
    ? Number(number)
        .toString()
        .replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
    : '';
};

export const formatNumberThousandWithDecimalZero = (number: string | number) => {
  return Number(Number(number).toFixed(2))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertFormatNumberToNumber = (number: string | number) => {
  return Number(Number(number?.toString().replace(/,/g, '').replace(/ /g, '') || '').toFixed(2));
};

export const sortDayJS = (days: dayjs.Dayjs[] | Date[] | string[] | DateObject[]) =>
  days.sort((a, b) => {
    const dayA = dayjs(a.toString());
    const dayB = dayjs(b.toString());
    if (dayjs(dayA).isAfter(dayjs(dayB))) return 1;
    else if (dayjs(dayA).isBefore(dayjs(dayB))) return -1;
    else return 0;
  });

export const handleTruncateToTwoDecimal = (value: number): number => {
  if (value > 0) {
    const valueStr = value.toFixed(3);
    const truncatedStr = valueStr.slice(0, -1);
    return parseFloat(truncatedStr);
  }
  return 0;
};

export const reverseArray = (arr: Array<any>) => {
  var reversed = [];
  for (var i = arr.length - 1; i >= 0; i--) {
    reversed.push(arr[i]);
  }
  return reversed;
};

export const regexCheckIpV4 = (ip: string) => {
  const regexUrl = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;
  return regexUrl.test(ip);
};
