import React from 'react';

export const SvgNextCarouselIcon = () => (
  <span>
    <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.502187 0.352542C0.595603 0.258915 0.722429 0.206299 0.854687 0.206299C0.986946 0.206299 1.11377 0.258915 1.20719 0.352542L3.50219 2.64754C3.69719 2.84254 3.69719 3.15754 3.50219 3.35254L1.20719 5.64754C1.01219 5.84254 0.697187 5.84254 0.502187 5.64754C0.307188 5.45254 0.307188 5.13754 0.502187 4.94254L2.44219 2.99754L0.502187 1.05754C0.307188 0.862542 0.312187 0.542542 0.502187 0.352542Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
