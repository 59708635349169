import React from 'react';

export const SvgCheckInQrCodeIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M16 24H13.3333V21.3333H16V24ZM13.3333 14.6667H10.6667V21.3333H13.3333V14.6667ZM24 12H21.3333V17.3333H24V12ZM21.3333 9.33333H18.6667V12H21.3333V9.33333ZM5.33333 12H2.66667V14.6667H5.33333V12ZM2.66667 9.33333H0V12H2.66667V9.33333ZM12 2.66667H14.6667V0H12V2.66667ZM2 2V6H6V2H2ZM6.66667 8H1.33333C0.6 8 0 7.4 0 6.66667V1.33333C0 0.6 0.6 0 1.33333 0H6.66667C7.4 0 8 0.6 8 1.33333V6.66667C8 7.4 7.4 8 6.66667 8ZM2 18V22H6V18H2ZM6.66667 24H1.33333C0.6 24 0 23.4 0 22.6667V17.3333C0 16.6 0.6 16 1.33333 16H6.66667C7.4 16 8 16.6 8 17.3333V22.6667C8 23.4 7.4 24 6.66667 24ZM18 2V6H22V2H18ZM22.6667 8H17.3333C16.6 8 16 7.4 16 6.66667V1.33333C16 0.6 16.6 0 17.3333 0H22.6667C23.4 0 24 0.6 24 1.33333V6.66667C24 7.4 23.4 8 22.6667 8ZM21.3333 21.3333V17.3333H16V20H18.6667V24H24V21.3333H21.3333ZM18.6667 12H13.3333V14.6667H18.6667V12ZM13.3333 9.33333H5.33333V12H8V14.6667H10.6667V12H13.3333V9.33333ZM14.6667 8V5.33333H12V2.66667H9.33333V8H14.6667ZM5 3H3V5H5V3ZM5 19H3V21H5V19ZM21 3H19V5H21V3Z"
        fill="#00297A"
      />
    </svg>
  </span>
);
