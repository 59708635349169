import { Form, FormItemProps, Input, InputProps } from 'antd';
import React, { ReactNode } from 'react';
import './formInput.scss';
import { PasswordProps } from 'antd/es/input';
import { NamePath } from 'antd/es/form/interface';
type Props = {
  name: string | NamePath<any>;
  label?: string | ReactNode;
  formItemProps?: FormItemProps;
  inputProps?: InputProps | PasswordProps;
  isPassword?: boolean;
};

export const FormInput: React.FC<Props> = ({ inputProps, formItemProps, isPassword, ...rest }) => {
  return (
    <Form.Item
      {...rest}
      {...formItemProps}
      className={`salon__form-input w-100 ${formItemProps?.className ?? ''} ${
        inputProps?.readOnly || inputProps?.disabled ? 'salon__form-input-read-only' : ''
      }`}
    >
      {!isPassword ? (
        <Input autoComplete="off" maxLength={255} {...inputProps} />
      ) : (
        <Input.Password autoComplete="off" maxLength={255} {...inputProps} />
      )}
    </Form.Item>
  );
};
