import { Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Employee } from '../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../components/@svg/SvgDollarIcon';
import SvgReloadTechnician from '../../../../components/@svg/SvgReloadTechnician';
import ColWrap from '../../../../components/ColWrap';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../components/FormWrap';
import NotificationError from '../../../../components/HandleShowNotiError';
import RowWrap from '../../../../components/RowWrap';
import { StyledModal } from '../../../../components/StyledModal';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { handleTruncateToTwoDecimal } from '../../../../utils';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../checkoutConstants';
import { IAppointmentBill, IListIdDisable, IServicesItem, ITechnicianItem } from '../index';
import KeyboardWrapper, { KeyboardDirection } from '../keyboard';
import ModalSelectTechnician from './ModalSelectTechnician';
import './style.scss';
export interface Props {
  open: boolean;
  appointmentBill: IAppointmentBill;
  idTechnician: string | number;
  isEdit: boolean;
  idService: string | number;
  serviceItem?: IServicesItem;
  listIdDisable: IListIdDisable;
  discountsAndRewardsApplied: IDiscountsAndRewardsApplied | undefined;
  setIsEditServicePrice: (isEditServicePrice: boolean) => void;
  setIdTechnician: (idTechnician: string | number) => void;
  setListSkill: (listSkill: number[]) => void;
  onCancelModal: () => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  handleGetTotal: (updateBill: IAppointmentBill) => void;
  setListIdDisable: ({}: IListIdDisable) => void;
  indexServiceEdit: number;
}
enum InputNameEnum {
  price = 'price',
  supply = 'supply',
}
interface FormData {
  price?: number;
  supply?: number;
}
function GeneralService<T extends object>(props: Props) {
  const {
    open,
    idTechnician,
    idService,
    appointmentBill,
    isEdit,
    serviceItem,
    listIdDisable,
    discountsAndRewardsApplied,
    setIdTechnician,
    setListSkill,
    onCancelModal,
    setAppointmentBill,
    handleGetTotal,
    setIsEditServicePrice,
    setListIdDisable,
    indexServiceEdit,
  } = props;
  const intl = useIntl();
  const keyboardRef = useRef<T>();
  const [openModalSelect, setOpenModalSelect] = useState(false);
  const [technician, setTechnician] = useState<Employee | any>();
  const [oldTechnicianId, setOldTechnicianId] = useState<string | number>();
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>(InputNameEnum.price);
  const n = (key: keyof FormData) => key;
  const [form] = Form.useForm();
  const priceRef = useRef<HTMLInputElement>(null);
  const supplyRef = useRef<HTMLInputElement>(null);
  const price = Form.useWatch(n('price'), form);
  const supply = Form.useWatch(n('supply'), form);

  useEffect(() => {
    setOldTechnicianId(idTechnician);
  }, []);

  useEffect(() => {
    if (price && priceRef.current) {
      const [integer] = price.replace(/,/g, '').split('.');
      priceRef.current.selectionStart = priceRef.current.selectionEnd = price.length + Math.floor(integer / 3);
    }

    if (supply && supplyRef.current) {
      const [integer] = supply.replace(/,/g, '').split('.');
      supplyRef.current.selectionStart = supplyRef.current.selectionEnd = supply.length + Math.floor(integer / 3);
    }
  }, [price, supply]);

  useEffect(() => {
    if (isEdit) {
      appointmentBill.technician.some((item) => {
        if (item.id === idTechnician) {
          const found = item.services?.find((serviceItem) => serviceItem.id === idService);
          if (found) {
            if (found.price) {
              form.setFieldValue(n('price'), Number.parseFloat(found.price.toString()).toFixed(2));
            }
            if (found.supply) {
              form.setFieldValue(n('supply'), Number.parseFloat(found.supply.toString()).toFixed(2));
            }
          }
        }
        return false;
      });
    }
  }, [open]);
  const onSubmit = () => {
    const data = form.getFieldsValue();

    if (data?.price === undefined || data?.price.trim() === '') {
      NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.generalService.validate.price' }) });
    } else if (Number(data?.price) < Number(data?.supply)) {
      NotificationError({
        contentNoti: intl.formatMessage({ id: 'checkout.generalService.validate.price.sub' }),
      });
    } else {
      console.log({ data });

      if (isEdit) {
        const updateService = appointmentBill;

        for (let index = 0; index < appointmentBill.technician.length; index++) {
          const technician = appointmentBill.technician[index];
          if (technician.id === idTechnician) {
            if (idTechnician === oldTechnicianId) {
              updateService.technician[index].services = technician.services?.map((service, indexService) =>
                service.id === idService && indexService === indexServiceEdit
                  ? {
                      ...service,
                      price: data?.price ? handleConvertPrice(data?.price) : 0,
                      supply: data?.supply ? handleConvertPrice(data.supply) : 0,
                      discount: {
                        occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
                          service.id
                        )
                          ? {
                              discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                              type: discountsAndRewardsApplied?.occasionDiscount?.type,
                              moneyDiscount:
                                discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                                  ? discountsAndRewardsApplied?.occasionDiscount?.amount
                                  : handleTruncateToTwoDecimal(
                                      (data.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 1)) / 100
                                    ),
                            }
                          : undefined,
                        weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(service.id)
                          ? {
                              discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                              type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                              moneyDiscount:
                                discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                                  ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                                  : handleTruncateToTwoDecimal(
                                      (data.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 1)) / 100
                                    ),
                            }
                          : undefined,
                        birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
                          service.id
                        )
                          ? {
                              discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                              type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                              moneyDiscount:
                                discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                                  ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                                  : handleTruncateToTwoDecimal(
                                      (data.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 1)) / 100
                                    ),
                            }
                          : undefined,
                      },
                    }
                  : service
              );
            } else if (serviceItem) {
              updateService.technician[index].services = [
                ...(technician.services || []),
                {
                  ...serviceItem,
                  price: data?.price ? handleConvertPrice(data?.price) : 0,
                  supply: data?.supply ? handleConvertPrice(data.supply) : 0,
                  discount: {
                    occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
                      serviceItem?.id
                    )
                      ? {
                          discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                          type: discountsAndRewardsApplied?.occasionDiscount?.type,
                          moneyDiscount:
                            discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                              ? discountsAndRewardsApplied?.occasionDiscount?.amount
                              : handleTruncateToTwoDecimal(
                                  (data.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 1)) / 100
                                ),
                        }
                      : undefined,
                    weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(serviceItem.id)
                      ? {
                          discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                          type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                          moneyDiscount:
                            discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                              ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                              : handleTruncateToTwoDecimal(
                                  (data.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 1)) / 100
                                ),
                        }
                      : undefined,
                    birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
                      serviceItem.id
                    )
                      ? {
                          discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                          type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                          moneyDiscount:
                            discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                              ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                              : handleTruncateToTwoDecimal(
                                  (data.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 1)) / 100
                                ),
                        }
                      : undefined,
                  },
                },
              ];
            }
          }

          if (technician.id === oldTechnicianId && oldTechnicianId !== idTechnician) {
            updateService.technician[index].services = technician.services?.filter(
              (e, indexService) => indexService !== indexServiceEdit
            );
          }
        }

        setAppointmentBill(updateService);
        handleGetTotal(updateService);
      } else if (technician) {
        handleCreateNewGeneralService(data, false, idService);
      }
      form.resetFields();
      setIsEditServicePrice(false);
      onCancelModal();
    }
  };

  const handleCreateNewGeneralService = (data: any, isHaveRemove: boolean, idService: string | number) => {
    appointmentBill.technician.some((item) => {
      const listIdServiceDisable: number[] = [];
      const idGeneralService = parseFloat(Math.random().toFixed(3)) * 10;
      const dataGeneralServices = [
        {
          id: idGeneralService,
          isMerchandise: false,
          name: intl.formatMessage({ id: 'checkout.generalService' }),
          price: handleConvertPrice(data?.price),
          supply: handleConvertPrice(data?.supply),
          description: '',
          isGeneralService: true,
          isTaxable: true,
        },
      ];
      if (item.id === idTechnician) {
        const updateService = {
          ...appointmentBill,
          technician: appointmentBill.technician.map((item) => {
            return {
              ...item,
              services:
                idTechnician === item.id
                  ? item.services
                    ? item.services.concat(dataGeneralServices)
                    : dataGeneralServices
                  : item.services,
            };
          }),
        };
        updateService.technician.map((item) =>
          item.services?.map((itemServices) => {
            return listIdServiceDisable.push(itemServices.id as number);
          })
        );
        setListIdDisable({
          ...listIdDisable,
          listIdServices: listIdServiceDisable,
        });
        if (isHaveRemove) {
          updateService.technician.forEach((technician) => {
            technician.services = technician?.services?.filter((itemService) => itemService.id !== idService);
          });
        }

        setAppointmentBill(updateService);
        handleGetTotal(updateService);
        setIdTechnician(idTechnician);
        setListSkill(item.skills ?? []);
        return true;
      }
      return false;
    });
  };

  const handleConvertPrice = (price: string) => {
    return price ? Number(price.toString().replace(/,/g, '')) : 0;
  };

  const handleChangeTechnician = (newTechnician: ITechnicianItem | Employee) => {
    setTechnician(newTechnician);
    // if (newTechnician.id !== technician?.id) form.resetFields();
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-general-modal-heading">
            {isEdit
              ? intl.formatMessage({ id: 'checkout.editServicePrice' })
              : intl.formatMessage({ id: 'checkout.generalService' })}
          </div>
        ),
        className: 'salon__checkout-general-modal',
        width: 700,
        footer: null,
      }}
    >
      <FormWrap
        form={form}
        name="generalService"
        layout="vertical"
        className="w-100 salon__checkout-general-modal-form"
      >
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[20, 20]}
          isWrap={true}
          styleFill="between"
          rowClassName="align-items-center"
        >
          <ColWrap colProps={{ span: 16 }}>
            <span className="salon__checkout-general-modal-title">
              {isEdit && serviceItem
                ? intl.formatMessage({ id: 'checkout.enterAmountNameOfService' }, { name: serviceItem.name })
                : intl.formatMessage({ id: 'checkout.generalService.title' })}
            </span>
          </ColWrap>
          <ColWrap colProps={{ span: 8 }}>
            <div className="d-flex gap-8 align-items-center justify-content-end">
              <div className="salon__checkout-general-modal-card-main gap-4 justify-content-start">
                <img width={20} height={20} src={technician?.avatar?.source || 'assets/qr/avatar.png'} alt="" />
                <p className="salon__checkout-general-modal-card-name m-b-0">
                  {technician?.name ?? intl.formatMessage({ id: 'checkout.unassigned' })}
                </p>
              </div>
              {openModalSelect ? (
                <SvgReloadTechnician />
              ) : (
                <p
                  className="salon__checkout-general-modal-card-button m-b-0"
                  onClick={() => (appointmentBill.technician.length > 1 ? setOpenModalSelect(true) : false)}
                >
                  {intl.formatMessage({ id: 'checkout.generalService.card.button' })}
                </p>
              )}
            </div>
          </ColWrap>

          <ColWrap colProps={{ span: 12 }}>
            <div className="salon__checkout-input-checkout-readonly">
              <FormInputNumber
                name={n('price')}
                prefix={<SvgDollarIcon />}
                label={intl.formatMessage({ id: 'checkout.generalService.label.price' })}
                numericFormatProps={{
                  inputMode: 'none',
                  placeholder: '0.00',
                  className: 'm-b-0',
                  max: 999_999_999,
                  onFocus: () => setFocusedInputName(InputNameEnum.price),
                  onChange: (e) => {
                    const currentValue = Number.parseFloat(e.target.value.replace(/,/g, '')).toFixed(2);
                    form.setFieldValue([n('price')], currentValue);
                  },
                  onKeyDown: (e) => {
                    return e.preventDefault();
                  },
                  getInputRef: priceRef,
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                }}
              />
            </div>
          </ColWrap>
          <ColWrap colProps={{ span: 12 }}>
            <div className="salon__checkout-input-checkout-readonly">
              <FormInputNumber
                name={n('supply')}
                prefix={<SvgDollarIcon />}
                label={intl.formatMessage({ id: 'checkout.generalService.label.supply' })}
                numericFormatProps={{
                  inputMode: 'none',
                  placeholder: '0.00',
                  className: 'm-b-0',
                  max: 999_999_999,
                  onFocus: () => setFocusedInputName(InputNameEnum.supply),
                  onChange: (e) => {
                    const currentValue = Number.parseFloat(e.target.value.replace(/,/g, '')).toFixed(2);
                    form.setFieldValue([n('supply')], currentValue);
                  },
                  onKeyDown: (e) => {
                    return e.preventDefault();
                  },
                  getInputRef: supplyRef,
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                }}
              />
            </div>
          </ColWrap>
        </RowWrap>
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 19 }}>
            <KeyboardWrapper
              inputName={focusedInputName}
              form={form}
              keyboardRef={keyboardRef}
              maxLength={9}
              keyboardProps={{
                inputPattern: /^\d+$/,
              }}
              direction={KeyboardDirection.rtl}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 5 }}>
            <ButtonStyled
              content={
                <span className="salon__checkout-giftcard-modal-keyboard-button-text ">
                  {intl.formatMessage({ id: 'checkout.ok' })}
                </span>
              }
              buttonProps={{
                className: 'width-122 height-328',
                onClick: () => {
                  onSubmit();
                },
              }}
              isPrimary={true}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>

      <ModalSelectTechnician
        openModalSelect={openModalSelect}
        setOpenModalSelect={setOpenModalSelect}
        setIdTechnician={setIdTechnician}
        setListSkill={setListSkill}
        setTechnician={handleChangeTechnician}
        idTechnician={idTechnician}
        appointmentBill={appointmentBill}
        idService={idService}
      />
    </StyledModal>
  );
}

export default GeneralService;
