import { useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { appointmentApi } from '../../../../../apis';
import { Appointment, TicketDTOStatusEnum } from '../../../../../apis/client-axios';
import { SvgAddMoreDataIconSmall } from '../../../../../components/@svg/SvgAddMoreDataIconSmall';
import CheckInCard from '../../../../../components/CheckinCard';
import { FormInputSearch } from '../../../../../components/Form/FormInputSearch';
import FormWrap from '../../../../../components/FormWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { QUERY_APPOINTMENTS_CHECKOUT_POPUP } from '../../../../../utils/constant';
import { APPOINTMENT_SORT } from '../../../appointment/models';
import { DesiredStatusesItem, IAppointmentBill, IFilter, IListIdDisable, IServicesItem } from '../../index';
import '../style.scss';

export interface Props {
  open: boolean;
  listIdDisable: IListIdDisable;
  appointmentBill: IAppointmentBill;
  onCancelModal: () => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  setIdTechnician: (idTechnician: number | string) => void;
  setListSkill: (listSkill: number[]) => void;
  setListIdDisable: (listIdDisable: IListIdDisable) => void;
  setIdAppointment: (idTechnician: number[]) => void;
  setServiceApply: (serviceApply: number[]) => void;
}
const initialFilters: IFilter = {
  page: 1,
  sort: APPOINTMENT_SORT,
  fullTextSearch: '',
  salonId: '',
  date: new Date(),
};
function ModalCheckIn(props: Props) {
  const {
    open,
    listIdDisable,
    appointmentBill,
    onCancelModal,
    setAppointmentBill,
    setIdTechnician,
    setListSkill,
    setListIdDisable,
    setIdAppointment,
    setServiceApply,
  } = props;
  const [filters, setFilters] = useState(initialFilters);
  const intl = useIntl();
  const [form] = Form.useForm();
  const { data: listAppointments } = useQuery({
    queryKey: [QUERY_APPOINTMENTS_CHECKOUT_POPUP, filters.fullTextSearch],
    staleTime: 1000,
    enabled: true,
    queryFn: () => appointmentApi.appointmentControllerGet(1, '', 0, APPOINTMENT_SORT, filters.fullTextSearch),
  });
  const handleSaveValue = (record: Appointment) => {
    console.log(record);
    const dataBill: IAppointmentBill = {
      ...appointmentBill,
      appointmentId: [record.id],
      customerId: record.customer.id,
      status: TicketDTOStatusEnum.Completed,
      // customer: {
      //   id: record.customer.id,
      //   name: record.customer.name,
      //   phoneNumber: record.customer.phoneNumber,
      //   salonId: record.customer.salonId,
      // },
      customer: record.customer,
      technician: [
        {
          id: record.technician ? record.technician.id : 0,
          name: record.technician?.name,
          defaultAvatar: record.technician?.defaultAvatar,
          phoneNumber: record.technician?.phoneNumber,
          title: record.technician?.title,
          services: record.services.map((item) => {
            return {
              id: item.id,
              name: item.name,
              price: item.price,
              description: item.description,
            };
          }) as IServicesItem[],
        },
      ],
    };
    setIdTechnician(record.technicianId ?? 0);
    setListSkill(record.technician?.skills.map((item) => item.id) ?? []);
    setIdAppointment([record.id]);
    setListIdDisable({
      ...listIdDisable,
      listIdServices: record.services.map((item) => item.id),
      listIdTechnician: [record.technician ? record.technician.id : 0],
    });
    setServiceApply(record.services.map((item) => item.id));
    setAppointmentBill(dataBill);

    setFilters({ ...filters, fullTextSearch: '' });
    form.resetFields();
    onCancelModal();
  };
  const onFinish = (values: any) => {
    setFilters({ ...filters, fullTextSearch: values.fullTextSearch });
  };
  return (
    <StyledModal
      isOpen={open}
      onCancel={() => {
        setFilters({ ...filters, fullTextSearch: '' });
        form.resetFields();
        onCancelModal();
      }}
      modalProps={{
        title: (
          <div className="salon__checkout-option-modal-heading">
            {intl.formatMessage({ id: 'checkout.option.checkin' })}
          </div>
        ),
        className: 'salon__checkout-option-modal salon__checkout-modal-checkin',
        width: 862,
        footer: null,
      }}
    >
      <FormWrap
        form={form}
        name="checkInForm"
        layout="vertical"
        className="w-100 salon__checkout-option-modal-form p-b-20"
        onFinish={onFinish}
      >
        <FormInputSearch
          name={'fullTextSearch'}
          inputProps={{
            placeholder: intl.formatMessage({ id: 'customer.placeholder.search' }),
            className: 'width-780 ',
          }}
          formItemProps={{
            rules: [
              {
                transform: (value) => {
                  return value?.trim();
                },
              },
            ],
            className: 'm-0 p-b-20',
          }}
        />
        <div className="salon__checkout-list-checkin-container">
          {!!listAppointments?.data.content?.length ? (
            listAppointments?.data.content.filter(
              (appointment) =>
                appointment.status === DesiredStatusesItem.CheckIn ||
                appointment.status === DesiredStatusesItem.BeingServed
            ).length ? (
              listAppointments?.data.content
                .filter(
                  (appointment) =>
                    appointment.status === DesiredStatusesItem.CheckIn ||
                    appointment.status === DesiredStatusesItem.BeingServed
                )
                .map((data: Appointment, index: number) => (
                  <div
                    className="cursor-pointer width-175"
                    onClick={() => {
                      handleSaveValue(data);
                    }}
                  >
                    <CheckInCard
                      customerName={data.customer.name}
                      estimate={data.estimate}
                      rate={5}
                      serviceName={data.services.map((item) => item.name).join(', ')}
                      status={data.status}
                      timeStart={data?.timeStart}
                      technicianName={data.technician?.name}
                    />
                  </div>
                ))
            ) : (
              <div className="d-flex align-items-center justify-content-center w-100">
                <SvgAddMoreDataIconSmall />
              </div>
            )
          ) : (
            <div className="d-flex align-items-center justify-content-center w-100">
              <SvgAddMoreDataIconSmall />
            </div>
          )}
        </div>
      </FormWrap>
    </StyledModal>
  );
}

export default ModalCheckIn;
