import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SuspenseWrapper } from '../../../components/LoadingComponent';
import { SETTING_ROUTES } from '../routes';
import { PROMOTIONS_ROUTES } from './promotionsConstants';

const PromotionsTenant = React.lazy(() => import('./index'));

const routes = [
  { path: PROMOTIONS_ROUTES.FEE_N_CASH_DISCOUNTS, component: React.lazy(() => import('./FeeNCashDiscounts/index')) },
  { path: PROMOTIONS_ROUTES.CHECKIN_REWARD, component: React.lazy(() => import('./CheckinReward/index')) },
  { path: PROMOTIONS_ROUTES.LOYALTY_REWARD, component: React.lazy(() => import('./LoyaltyReward/index')) },
  { path: PROMOTIONS_ROUTES.BIRTHDAY_REWARD, component: React.lazy(() => import('./BirthdayReward/index')) },
  { path: PROMOTIONS_ROUTES.OCCASION_REWARD, component: React.lazy(() => import('./OccasionReward/index')) },
  { path: PROMOTIONS_ROUTES.REFERRAL_REWARD, component: React.lazy(() => import('./ReferralReward/index')) },
  { path: PROMOTIONS_ROUTES.WEEKLY_REWARD, component: React.lazy(() => import('./WeeklyReward/index')) },
  { path: PROMOTIONS_ROUTES.GENERAL_REWARD, component: React.lazy(() => import('./GeneralReward/index')) },
  {
    path: PROMOTIONS_ROUTES.ANNOUNCEMENTS,
    component: React.lazy(() => import('./Annoucements/ListAnnouncements/index')),
  },
  {
    path: PROMOTIONS_ROUTES.ANNOUNCEMENTS_CREATE,
    component: React.lazy(() => import('./Annoucements/DetailAnnouncements/index')),
  },
  {
    path: PROMOTIONS_ROUTES.ANNOUNCEMENTS_DETAIL + ':id',
    component: React.lazy(() => import('./Annoucements/DetailAnnouncements/index')),
  },
];

const defaultRoutePath = PROMOTIONS_ROUTES.FEE_N_CASH_DISCOUNTS;

const PromotionsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<SuspenseWrapper component={<PromotionsTenant />} />}>
        <Route path="/" element={<Navigate to={defaultRoutePath} />} />
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={<SuspenseWrapper component={<route.component />} />} />
        ))}
      </Route>
    </Routes>
  );
};

export default PromotionsRoutes;
