import { QRCode } from 'antd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SvgLogoutCheckinIcon } from '../../../../components/@svg/SvgLogoutCheckinIcon';
import { ButtonIcon } from '../../../../components/buttons/ButtonIcon';
import { RootState, useAppDispatch } from '../../../../store';
import { logout } from '../../../../store/authSlice';
import { MAIN_ROUTES } from '../../routes';
import { BOOKING_ONLINE_ROUTES } from '../bookingOnlineConstants';
import './checkInQR.scss';
import { SettingCheckin } from '../../../../apis/client-axios';

interface IProps {
  settingCheckin: SettingCheckin | undefined;
}

const CheckInQR: React.FC<IProps> = ({ ...props }) => {
  const { settingCheckin } = props;
  const intl = useIntl();
  const salonInformation = useSelector((state: RootState) => state.auth.salonActive);
  const dispatch = useAppDispatch();
  return (
    <section className="salon__qr">
      <div className="salon__qr-img top">
        <img src="/assets/qr/img-qr-top.png" alt="qr" />
      </div>

      <div className="salon__qr-main">
        <div>
          <p className="salon__qr-title-salon">{settingCheckin?.flashTitle}</p>
          <p className="salon__qr-sub-title-salon">{settingCheckin?.checkinPrompt}</p>
        </div>
        <h3 className="salon__qr-main-title">
          {settingCheckin?.flashMessage ?? intl.formatMessage({ id: 'bookingOnline.checkin.qr.title' })}
        </h3>
        <a
          className="salon__qr-main-button"
          href={'/' + MAIN_ROUTES.BOOKING_ONLINE + BOOKING_ONLINE_ROUTES.INFORMATION}
        >
          <span className="salon__qr-main-button-text">
            {intl.formatMessage({ id: 'bookingOnline.checkin.qr.button' })}
          </span>
        </a>
        {settingCheckin?.allowCustomersCheckinFromPhone ? (
          <div className="salon__qr-main__image">
            <div className="salon__qr-main-container">
              <QRCode
                value={`${process.env.REACT_APP_URL}/${MAIN_ROUTES.BOOKING_ONLINE}${BOOKING_ONLINE_ROUTES.INFORMATION}/${salonInformation?.id}`}
                color={'#2076A8'}
                className="salon__qr-main-qr-scan"
              />
            </div>
            <p className="salon__qr-main__image-des"> {intl.formatMessage({ id: 'bookingOnline.checkin.qr.des' })}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="salon__qr-img bottom">
        <img src="/assets/qr/img-qr-bottom.png" alt="qr" />
      </div>
      <div className="position-absolute bottom-14 right-18">
        <ButtonIcon
          icon={
            <div className="d-flex gap-10">
              <SvgLogoutCheckinIcon />
              <span className="salon__qr-logout-content">{intl.formatMessage({ id: 'common.logout' })}</span>
            </div>
          }
          className="salon__qr-logout"
          buttonProps={{
            onClick: () => {
              dispatch(logout());
            },
          }}
        />
      </div>
    </section>
  );
};

export default CheckInQR;
