import { Image } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatNumberThousandWithDecimal } from '../../../../../utils';
import { AppointmentResource, FilterProps, RESOURCE_UNASIGNED_KEY } from '../../models';

interface TechnicianProps extends FilterProps {
  technician: AppointmentResource;
  onOpenDetailDay?: (technician: AppointmentResource) => void;
}

const Technician: FC<TechnicianProps> = (props) => {
  const { technician, onOpenDetailDay } = props;

  const intl = useIntl();
  const divRef = useRef<HTMLDivElement>(null);
  const [, forceRender] = useState({});

  useEffect(() => {
    forceRender({});
  }, []);

  const handleOpenDetailDay = () => {
    if (!onOpenDetailDay || technician.resourceId === RESOURCE_UNASIGNED_KEY) return;

    onOpenDetailDay(technician);
  };

  return (
    <div
      className="cursor-pointer salon__appointment-filter-technician-item"
      onClick={handleOpenDetailDay}
      ref={divRef}
    >
      <div className="salon__appointment-filter-technician-item-detail">
        {technician.resourceId !== RESOURCE_UNASIGNED_KEY && (
          <div className="salon__appointment-filter-technician-avatar">
            <Image alt={technician.resourceTitle} src={technician.avatar} preview={false} width={48} height={48} />
          </div>
        )}
        <div
          className={`salon__appointment-filter-technician-infor ${
            technician.resourceId === RESOURCE_UNASIGNED_KEY
              ? 'salon__appointment-filter-technician-infor-unasigned'
              : ''
          }`}
        >
          <p className="font-weight-600 salon__appointment-filter-technician-name">
            {technician.resourceTitle.length > 11 && divRef.current?.clientWidth === 176
              ? technician.resourceTitle.substring(0, 10) + '...'
              : technician.resourceTitle}
          </p>
          <div className="salon__appointment-filter-technician-detail">
            {technician.resourceId !== RESOURCE_UNASIGNED_KEY && (
              <p className="font-weight-500 salon__appointment-filter-technician-revenue">
                $
                {formatNumberThousandWithDecimal(technician.revenue).length > 4 && divRef.current?.clientWidth === 176
                  ? formatNumberThousandWithDecimal(technician.revenue).substring(0, 3) + '...'
                  : formatNumberThousandWithDecimal(technician.revenue)}
              </p>
            )}
            <div className="font-weight-400 salon__appointment-filter-technician-tasks-wrapper">
              <span className="font-weight-400 salon__appointment-filter-technician-tasks">
                {technician.tasks > 9 ? technician.tasks : `0${technician.tasks}`}|
                {formatNumberThousandWithDecimal(technician.hours).length > 4 &&
                technician.resourceId !== RESOURCE_UNASIGNED_KEY &&
                divRef.current?.clientWidth === 176
                  ? formatNumberThousandWithDecimal(technician.hours).substring(0, 3) + '...'
                  : formatNumberThousandWithDecimal(technician.hours)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="salon__appointment-filter-technician-item-display">
        <span className="font-size-16 font-weight-600 salon__appointment-filter-technician-item-display-tasks">
          {technician.tasks > 9 ? technician.tasks : `0${technician.tasks}`}
          <span>{intl.formatMessage({ id: 'appointment.technician.tasks' })}</span>
        </span>
        <span className="font-size-14 font-weight-400 salon__appointment-filter-technician-item-display-hours">
          {formatNumberThousandWithDecimal(technician.hours)}
          <span>{intl.formatMessage({ id: 'appointment.technician.hours' })}</span>
        </span>
      </div>
    </div>
  );
};

export default Technician;
