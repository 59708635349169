import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SuspenseWrapper } from '../../../components/LoadingComponent';
import { SETTING_ROUTES } from '../routes';

const SettingTenant = React.lazy(() => import('./index'));
const routes = [
  { path: SETTING_ROUTES.BUSINESS_SETTING, component: React.lazy(() => import('./businessSetting/index')) },
  { path: SETTING_ROUTES.BILLING_PAYMENT, component: React.lazy(() => import('./billingPayment/index')) },
  { path: SETTING_ROUTES.CUSTOMER_RATE_SETTING, component: React.lazy(() => import('./customerRateSetting/index')) },

  { path: SETTING_ROUTES.CHECK_IN_SETTING, component: React.lazy(() => import('./checkInSetting/index')) },
  { path: SETTING_ROUTES.CHECK_OUT_SETTING, component: React.lazy(() => import('./checkOutSetting/index')) },
  { path: SETTING_ROUTES.DATA_SETTING, component: React.lazy(() => import('./dataSetting/index')) },
  { path: SETTING_ROUTES.EXPENSE, component: React.lazy(() => import('./expense/index')) },
  { path: SETTING_ROUTES.PRINTER_SETTING, component: React.lazy(() => import('./printerSetting/index')) },

  {
    path: SETTING_ROUTES.TERMINAL_DEVICE_SETTING,
    component: React.lazy(() => import('./terminalDeviceSetting/index')),
  },
  { path: SETTING_ROUTES.TEXT_MESSAGE_SETTING, component: React.lazy(() => import('./textMessageSetting/index')) },
  { path: SETTING_ROUTES.TIP_SETTING, component: React.lazy(() => import('./tipSetting/index')) },
  { path: SETTING_ROUTES.SERVICE, component: React.lazy(() => import('./servicesManagement/index')) },
];

const defaultRoutePath = SETTING_ROUTES.BUSINESS_SETTING;

const PromotionsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<SettingTenant />}>
        <Route path="/" element={<Navigate to={defaultRoutePath} />} />
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={<SuspenseWrapper component={<route.component />} />} />
        ))}
      </Route>
    </Routes>
  );
};

export default PromotionsRoutes;
