import { useQuery } from '@tanstack/react-query';
import { Card } from 'antd';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { employeeApi } from '../../../../apis';
import { Employee } from '../../../../apis/client-axios';
import { StyledModal } from '../../../../components/StyledModal';
import { QUERY_LIST_TECHNICIAN } from '../../../../utils/constant';
import './style.scss';
import { IAppointmentBill, ITechnicianItem } from '../index';

interface Props {
  openModalSelect: boolean;
  idTechnician: string | number;
  idService: string | number;
  appointmentBill: IAppointmentBill;
  setOpenModalSelect: (state: boolean) => void;
  setTechnician: (technician: ITechnicianItem | Employee) => void;
  setIdTechnician: (idTechnician: string | number) => void;
  setListSkill: (listSkill: number[]) => void;
}

const ModalSelectTechnician: FC<Props> = ({ ...props }) => {
  const {
    openModalSelect,
    idTechnician,
    appointmentBill,
    setOpenModalSelect,
    setTechnician,
    setIdTechnician,
    setListSkill,
    idService,
  } = props;

  const intl = useIntl();

  const addTechnician = (item: ITechnicianItem) => {
    setTechnician(item);
    setOpenModalSelect(false);
    setIdTechnician(item.id);
    setListSkill(item.skills ?? []);
  };
  useEffect(() => {
    if (idTechnician && appointmentBill.technician) {
      const findTechnician = appointmentBill.technician.find((item) => item.id === idTechnician);
      if (findTechnician) {
        setTechnician(findTechnician);
      }
    }
  }, [idTechnician, appointmentBill.technician]);

  return (
    <StyledModal
      isOpen={openModalSelect}
      onCancel={() => setOpenModalSelect(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-general-modal-heading">{intl.formatMessage({ id: 'checkout.select' })}</div>
        ),
        className: 'salon__checkout-general-select-technician-modal',
        width: 700,
        footer: null,

        centered: true,
      }}
    >
      <div className="salon__checkout-general-select-technician-modal-card">
        {appointmentBill.technician && appointmentBill.technician?.length > 1
          ? appointmentBill.technician
              .filter((e) => e.skills?.includes(Number(idService)) || idService.toString().includes('.'))
              .map((item) => (
                <Card
                  className="salon__checkout-general-select-technician-modal-card-main"
                  onClick={() => addTechnician(item)}
                >
                  <img src="/assets/qr/avatar.png" alt="" />
                  <p className="salon__checkout-general-select-technician-modal-card-name">
                    {item?.name ?? intl.formatMessage({ id: 'checkout.unassigned' })}
                  </p>
                </Card>
              ))
          : ''}
      </div>
    </StyledModal>
  );
};

export default ModalSelectTechnician;
