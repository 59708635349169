import { useIntl } from 'react-intl';
import { StyledPopup } from '../../../../components/StyledPopup';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { IListIdTotalPrice } from '../index';
import './style.scss';
import { formatNumberThousandWithDecimal } from '../../../../utils';

interface Props {
  open: boolean;
  listTotal: IListIdTotalPrice;
  price: number;
  onCancelModal: () => void;
  onDeleteTicket: () => void;
}

function DeleteTicket(props: Props) {
  const { open, price, onCancelModal, onDeleteTicket } = props;
  const intl = useIntl();

  return (
    <StyledPopup
      isOpen={open}
      content={
        <div className="text-align-left line-height-19">
          <div className="salon__checkout-delete-ticket-modal-popup-title">
            {intl.formatMessage({ id: 'checkout.tax.ticket.delete' })}
          </div>
          <p className="salon__checkout-delete-ticket-modal-popup-content">
            {intl.formatMessage({ id: 'checkout.tax.ticket.delete.content' })}
            <span className="salon__checkout-delete-ticket-modal-popup-content-strong">{`$${formatNumberThousandWithDecimal(
              price ?? 0
            )}`}</span>
            {intl.formatMessage({ id: 'checkout.tax.ticket.delete.content.last' })}
          </p>
        </div>
      }
      onCancel={() => {
        onCancelModal();
      }}
      onOk={() => {
        onCancelModal();
        onDeleteTicket();
      }}
      isHiddenCancel={false}
      customFooter={
        <div className="d-flex gap-12 justify-content-center">
          <ButtonStyled
            content={intl.formatMessage({ id: 'common.no' })}
            buttonProps={{
              className: 'width-100',
              onClick: () => {
                onCancelModal();
              },
            }}
            isPrimary={true}
          />
          <ButtonStyled
            content={intl.formatMessage({ id: 'common.yes' })}
            buttonProps={{
              className: 'width-100 m-0',
              onClick: async () => {
                onCancelModal();
                onDeleteTicket();
              },
            }}
            isButtonCancel={true}
            isCancelPopup={false}
            isPrimary={false}
          />
        </div>
      }
    />
  );
}

export default DeleteTicket;
