import React from 'react';

export const SvgReloadTechnician = () => (
  <span>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0025 10C12.6925 10 10.0025 12.69 10.0025 16H11.7925C12.2425 16 12.4625 16.54 12.1525 16.85L9.36247 19.64C9.16247 19.84 8.85247 19.84 8.65247 19.64L5.86247 16.85C5.54247 16.54 5.76247 16 6.21247 16H8.00247C8.00247 11.58 11.5825 8 16.0025 8C17.0425 8 18.0425 8.2 18.9525 8.57C19.6225 8.84 19.8025 9.7 19.2925 10.21C19.0225 10.48 18.6125 10.59 18.2525 10.44C17.5625 10.15 16.7925 10 16.0025 10ZM19.8625 15.14L22.6525 12.35C22.8425 12.16 23.1625 12.16 23.3525 12.36L26.1425 15.15C26.4625 15.46 26.2425 16 25.7925 16H24.0025C24.0025 20.42 20.4225 24 16.0025 24C14.9625 24 13.9625 23.8 13.0525 23.43C12.3825 23.16 12.2025 22.3 12.7125 21.79C12.9825 21.52 13.3925 21.41 13.7525 21.56C14.4425 21.85 15.2125 22 16.0025 22C19.3125 22 22.0025 19.31 22.0025 16H20.2125C19.7625 16 19.5425 15.46 19.8625 15.14Z"
        fill="#0090FF"
      />
    </svg>
  </span>
);

export default SvgReloadTechnician;
