import React, { useRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import NotificationSuccess from '../../../../../../../components/HandleShowNotiSuccess';
import FormWrap from '../../../../../../../components/FormWrap';
import RowWrap from '../../../../../../../components/RowWrap';
import ColWrap from '../../../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../../../components/Form/FormInputNumber';
import { SvgDollarIcon } from '../../../../../../../components/@svg/SvgDollarIcon';
import { formatNumberCashID, formatNumberThousandWithDecimal } from '../../../../../../../utils';
import { CustomButton } from '../../../../../../../components/buttons/CustomButton';
import KeyboardWrapper from '../../../../keyboard';
import { FormInput } from '../../../../../../../components/Form/FormInput';
import { NamePath } from 'antd/es/form/interface';
import { CardNumberEnum } from '..';
import NotificationError from '../../../../../../../components/HandleShowNotiError';
import { IListIdTotalPrice } from '../../../..';

interface IProps {
  setCurrentScreen: React.Dispatch<React.SetStateAction<CardNumberEnum>>;
  setOpenModal: (open: boolean) => void;
  setIsOpenPayAndComplete: (open: boolean) => void;
  listTotal: IListIdTotalPrice;
}

interface TechnicianTip {
  id: number;
  tip?: number;
}

interface FormData {
  cashID?: string;
  securityCode?: number;
}

enum InputNameEnum {
  CashID = 'cashID',
  SecurityCode = 'securityCode',
}

export const SecurityCodeCardNumber: React.FC<IProps> = (props) => {
  const { setCurrentScreen, setOpenModal, setIsOpenPayAndComplete, listTotal } = props;
  const [form] = Form.useForm();
  const intl = useIntl();
  const keyboardRef = useRef<any>();
  const n = (key: keyof FormData) => key;
  const nTip = (key: keyof TechnicianTip) => key;
  const [focusedInputName, setFocusedInputName] = useState<string | NamePath<any>>();

  const onClickOK = (value: FormData) => {
    console.log('value', value);
    setCurrentScreen(CardNumberEnum.CardPayment);
  };

  const onFinishFailed = () => {
    NotificationError({
      contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.noti.incorrectCardInformation' }),
    });
  };

  useEffect(() => {}, []);

  return (
    <FormWrap
      form={form}
      onFinish={onClickOK}
      onFinishFailed={onFinishFailed}
      className="salon__checkout__pay-n-complete-modal__cash-payment"
      layout="vertical"
    >
      <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
        <ColWrap colProps={{ span: 24 }}>
          <p className="font-size-16 font-weight-500 m-0">
            {`${intl.formatMessage({ id: 'checkout.payAndComplete.balanceToPay' })}: `}
            <span className="font-weight-700">
              ${formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0)}
            </span>
          </p>
        </ColWrap>
        <ColWrap colProps={{ span: 24 }}>
          <FormInput
            name={n('cashID')}
            label={intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.cashID.label' })}
            inputProps={{
              placeholder: intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.cashID.placeHolder' }),
              className: 'm-b-0',
              onFocus: () => setFocusedInputName(InputNameEnum.CashID),
            }}
            formItemProps={{
              className: 'm-b-0 w-full',
              rules: [
                {
                  validator: (_, value: string | number) => {
                    const v = value?.toString().trim()?.replace(/,/g, '').replace(/ /g, '');
                    if (!v || v.length !== 16) {
                      return Promise.reject('');
                    }
                    return Promise.resolve();
                  },
                },
              ],
            }}
          />
        </ColWrap>

        <ColWrap colProps={{ span: 24 }}>
          <FormInput
            name={n('securityCode')}
            label={intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.securityCode.label' })}
            inputProps={{
              placeholder: intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.securityCode.placeHolder' }),
              className: 'm-b-0',
              onFocus: () => setFocusedInputName(InputNameEnum.SecurityCode),
            }}
            formItemProps={{
              className: 'm-b-0 w-full',
              rules: [
                {
                  validator: (_, value: string | number) => {
                    const v = value?.toString().trim()?.replace(/,/g, '').replace(/ /g, '');
                    if (!v || v.length < 3 || v.length > 4) {
                      return Promise.reject('');
                    }
                    return Promise.resolve();
                  },
                },
              ],
            }}
          />
        </ColWrap>

        <ColWrap colProps={{ span: 24 }}>
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
            {/* numeric keyboard */}
            <ColWrap colProps={{ span: 18 }}>
              <KeyboardWrapper
                inputName={focusedInputName}
                form={form}
                keyboardRef={keyboardRef}
                maxLength={focusedInputName === n('cashID') ? 16 : 4}
                keyboardProps={{
                  inputPattern: /^\d+$/,
                }}
                formatFunction={formatNumberCashID}
              />
            </ColWrap>

            {/* ok button */}
            <ColWrap colProps={{ span: 6 }}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                  htmlType: 'submit',
                }}
                content={intl.formatMessage({ id: 'common.OK' })}
              />
            </ColWrap>
          </RowWrap>
        </ColWrap>
      </RowWrap>
    </FormWrap>
  );
};
