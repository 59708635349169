import { Form, FormItemProps } from 'antd';
import React, { ReactNode } from 'react';
import '../FormInput/formInput.scss';
import { TextAreaProps } from 'antd/lib/input';
import TextArea from 'antd/lib/input/TextArea';
import './formTextArea.scss';

type Props = {
  name: string;
  label?: string | ReactNode;
  formItemProps?: FormItemProps;
  inputProps?: TextAreaProps;
};

export const FormTextArea: React.FC<Props> = ({ inputProps, formItemProps, ...rest }) => {
  return (
    <Form.Item
      {...rest}
      {...formItemProps}
      className={`salon__form-input salon__form-input-textarea w-100 ${formItemProps?.className ?? ''}`}
    >
      <TextArea autoComplete="off" {...inputProps} />
    </Form.Item>
  );
};
