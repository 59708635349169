import React, { useEffect } from 'react';
import { ChargeCardEnum } from '..';
import { useIntl } from 'react-intl';

interface IProps {
  setCurrentScreen: React.Dispatch<React.SetStateAction<ChargeCardEnum>>;
}

export const PaymentChargeCard: React.FC<IProps> = (props) => {
  const { setCurrentScreen } = props;
  const intl = useIntl();

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setCurrentScreen(ChargeCardEnum.Print);
    }, 3000);
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  return (
    <div className="height-130 w-full d-flex justify-content-center align-items-center gap-12">
      <img src="/assets/qr/loading.gif" alt="loading" width={48} height={48} />
      <span className="font-weight-400 font-size-16">
        {intl.formatMessage({ id: 'checkout.payAndComplete.pleaseWait' })}
      </span>
    </div>
  );
};
