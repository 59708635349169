import { useIntl } from 'react-intl';
import './checkInConfirm.scss';
import { Carousel } from 'antd';
import { useEffect, useState } from 'react';
import ScreenSaver from '../screenSaver';
import { useParams } from 'react-router-dom';
import { MAIN_ROUTES } from '../../routes';
import { BOOKING_ONLINE_ROUTES } from '../bookingOnlineConstants';

const CheckInConfirm = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [status, setStatus] = useState<boolean>(false);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (!status) setStatus(true);
    }, 10000);

    const onMouseDown = () => {
      clearInterval(myInterval);
      myInterval = setInterval(() => {
        if (!status) setStatus(true);
      }, 10000);
    };

    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, []);
  return (
    <div className="salon__home">
      {!status ? (
        <section className="salon_confirm_qr">
          <div className="salon_confirm_qr-img top">
            <picture>
              <source media="(max-width: 767px)" srcSet="/assets/qr/img-qr-top-sp.png" />
              <img src="/assets/qr/img-qr-top.png" alt="qr" />
            </picture>
          </div>
          <div className="salon_confirm_qr-main">
            <div className="salon_confirm_qr-main__image">
              <img src="/assets/qr/confirm.png" alt="" />
              <p className="salon_confirm_qr-main__image-des">
                {intl.formatMessage({ id: 'bookingOnline.checkin.confirm.des' })}
              </p>
            </div>
            <h3 className="salon_confirm_qr-main-title">
              {intl.formatMessage({ id: 'bookingOnline.checkin.confirm.title' })}
            </h3>
            <a
              className="salon_confirm_qr-main-button"
              href={id ? `/${MAIN_ROUTES.BOOKING_ONLINE}${BOOKING_ONLINE_ROUTES.INFORMATION}/${id}` : '/'}
            >
              <span className="salon_confirm_qr-main-button-text">
                {intl.formatMessage({ id: 'bookingOnline.checkin.confirm.button' })}
              </span>
            </a>
          </div>
          <div className="salon_confirm_qr-img bottom">
            <picture>
              <source media="(max-width: 767px)" srcSet="/assets/qr/img-qr-bottom-sp.png" />
              <img src="/assets/qr/qr-bottom-confirm.png" alt="qr" />
            </picture>
          </div>
        </section>
      ) : (
        <ScreenSaver status={status} setStatus={setStatus} />
      )}
    </div>
  );
};

export default CheckInConfirm;
