import React, { useEffect } from 'react';
import { Image, Modal } from 'antd';
import { useIntl } from 'react-intl';
import './discountsAndRewards.scss';
import { StyledModal } from '../../../../components/StyledModal';
import RowWrap from '../../../../components/RowWrap';
import ColWrap from '../../../../components/ColWrap';
import { Discounts } from './Discounts';
import { RewardPoints } from './RewardPoints';
import { useState } from 'react';
import { IListIdDisable, IOpenModalCheckOut } from '../index';
import StyledButtonTab from '../../../../components/StyledButtonTab';
import { CustomButton } from '../../../../components/buttons/CustomButton';
import { SvgCloseIcon } from '../../../../components/@svg/SvgCloseIcon';
import avatarDefaultSrc from '../../../../assets/images/appointment/avatar-default.png';
import { formatNumberThousand, formatPhoneNumber, getStorageByName } from '../../../../utils';
import { SvgPointRewardIcon } from '../../../../components/@svg/SvgPointRewardIcon';
import { useQuery } from '@tanstack/react-query';
import { QUERY_PROMOTION } from '../../../../utils/constant';
import { promotionApi } from '../../../../apis';
import { Customer, Promotion, ServiceControllerGet200Response } from '../../../../apis/client-axios';
import { IDiscountsAndRewardsApplied } from '../checkoutConstants';
import { AxiosResponse } from 'axios';

export interface Props {
  open: IOpenModalCheckOut;
  setOpenModal: React.Dispatch<React.SetStateAction<IOpenModalCheckOut>>;
  customer?: Customer;
  listIdDisable: IListIdDisable;
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
  handleRemoveCustomer: () => void;
  listDataServices: AxiosResponse<ServiceControllerGet200Response, any> | undefined;
  promotionData: AxiosResponse<Promotion, any> | undefined;
}

enum DiscountsAndRewardsTabsEnum {
  Discounts = 'checkout.discountAndRewards.discounts',
  Rewards = 'checkout.discountAndRewards.rewardPoints',
}

const listDiscountsNRewardsTabs: DiscountsAndRewardsTabsEnum[] = [
  DiscountsAndRewardsTabsEnum.Discounts,
  DiscountsAndRewardsTabsEnum.Rewards,
];

const { confirm } = Modal;

function DiscountsAndRewards<T extends object>(props: Props) {
  const {
    open,
    setOpenModal,
    customer,
    discountsAndRewardsApplied,
    setDiscountsAndRewardsApplied,
    listIdDisable,
    handleRemoveCustomer,
    listDataServices,
    promotionData,
  } = props;
  const intl = useIntl();
  const salonId = getStorageByName('salonId') as string;
  console.log('listDataServices', listDataServices);
  const [promotion, setPromotion] = useState<Promotion>();
  const [currentTab, setCurrentTab] = useState<DiscountsAndRewardsTabsEnum>(DiscountsAndRewardsTabsEnum.Discounts);

  // const { data: promotionData } = useQuery({
  //   queryKey: [QUERY_PROMOTION],
  //   queryFn: () => promotionApi.promotionControllerGet(salonId),
  // });

  const onCancelModal = () => {
    setOpenModal((prev) => ({
      ...prev,
      openDiscountsAndRewards: false,
    }));
  };

  const confirmAddDiscountsAndRewards = (
    onOKButton?: () => void,
    onCancelButton?: () => void,
    isMerchandise?: boolean,
    isGeneralDiscount?: boolean
  ) => {
    if (isMerchandise && (!listIdDisable?.listIdMerchandise || !listIdDisable.listIdMerchandise.length)) {
      confirm({
        width: 480,
        centered: true,
        className: 'salon__modal-styled salon__modal__confirmation',
        content: (
          <div className="text-center font-size-20 font-weight-400 line-height-28 white-space-pre-line">
            {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.addServices.pleaseAdd' })}
            &nbsp;
            <span className="font-weight-600">
              {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.addServices.merchandise' })}
            </span>
            &nbsp;
            {intl.formatMessage({
              id: 'checkout.discountAndRewards.confirmModal.addServices.toTheTicketThenYouCanAdd',
            })}
            &nbsp;
            <span className="font-weight-600">
              {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.addServices.discount' })}
            </span>
            &nbsp;
            {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.addServices.orPromotion' })}
          </div>
        ),
        onOk() {},
        cancelButtonProps: {
          className: 'd-none',
        },
        okButtonProps: {
          className: 'm-0',
        },
        okText: intl.formatMessage({ id: 'common.OK' }),
      });
    } else if (
      (!listIdDisable?.listIdServices || !listIdDisable?.listIdServices.length) &&
      (!listIdDisable?.listIdMerchandise || !listIdDisable?.listIdMerchandise.length) &&
      !isGeneralDiscount
    ) {
      confirm({
        width: 480,
        centered: true,
        className: 'salon__modal-styled salon__modal__confirmation',
        content: (
          <div className="text-center font-size-20 font-weight-400 line-height-28 white-space-pre-line">
            {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.addServices.pleaseAdd' })}
            &nbsp;
            <span className="font-weight-600">
              {intl.formatMessage({
                id: 'checkout.discountAndRewards.confirmModal.addServices.servicesAndMerchandise',
              })}
            </span>
            &nbsp;
            {intl.formatMessage({
              id: 'checkout.discountAndRewards.confirmModal.addServices.toTheTicketThenYouCanAdd',
            })}
            &nbsp;
            <span className="font-weight-600">
              {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.addServices.discount' })}
            </span>
            &nbsp;
            {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.addServices.orPromotion' })}
          </div>
        ),
        onOk() {},
        cancelButtonProps: {
          className: 'd-none',
        },
        okButtonProps: {
          className: 'm-0',
        },
        okText: intl.formatMessage({ id: 'common.OK' }),
      });
    } else if (
      discountsAndRewardsApplied &&
      !!Object.values(discountsAndRewardsApplied).filter((item) => item).length
    ) {
      confirm({
        width: 480,
        centered: true,
        className: 'salon__modal-styled salon__modal__confirmation',
        content: (
          <div className="text-center font-size-20 font-weight-400 line-height-28 white-space-pre-line">
            {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.gotDiscount.youHaveGotA' })}
            &nbsp;
            <span className="font-weight-600">
              {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.gotDiscount.discount' })}
            </span>
            &nbsp;
            {intl.formatMessage({
              id: 'checkout.discountAndRewards.confirmModal.gotDiscount.onYourTicketSaleAreYouSureTo',
            })}
            &nbsp;
            <span className="font-weight-600">
              {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.gotDiscount.apply' })}
            </span>
            &nbsp;
            {intl.formatMessage({ id: 'checkout.discountAndRewards.confirmModal.gotDiscount.anotherOne' })}
          </div>
        ),
        onOk() {
          if (onOKButton) onOKButton();
        },
        onCancel() {
          if (onCancelButton) onCancelButton();
        },
        okText: intl.formatMessage({ id: 'common.yes' }),
        cancelText: intl.formatMessage({ id: 'common.no' }),
      });
    } else {
      if (onOKButton) onOKButton();
    }
  };

  const renderTab = () => {
    switch (currentTab) {
      case DiscountsAndRewardsTabsEnum.Discounts:
        return (
          <Discounts
            promotion={promotion}
            setOpenModal={setOpenModal}
            customer={customer}
            discountsAndRewardsApplied={discountsAndRewardsApplied}
            setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
            confirmAddDiscountsAndRewards={confirmAddDiscountsAndRewards}
            onCancelModal={onCancelModal}
            listDataServices={listDataServices}
          />
        );
      case DiscountsAndRewardsTabsEnum.Rewards:
        return (
          <RewardPoints
            promotion={promotion}
            setOpenModal={setOpenModal}
            customer={customer}
            discountsAndRewardsApplied={discountsAndRewardsApplied}
            setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
            confirmAddDiscountsAndRewards={confirmAddDiscountsAndRewards}
            onCancelModal={onCancelModal}
          />
        );
    }
  };

  useEffect(() => {
    if (promotionData?.data) {
      setPromotion(promotionData.data);
    }
  }, [promotionData]);

  return (
    <StyledModal
      isOpen={open.openDiscountsAndRewards}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-discount-rewards-modal-heading">
            {intl.formatMessage({ id: 'checkout.discountAndRewards' })}
          </div>
        ),
        className: 'salon__checkout-discount-rewards-modal',
        width: 700,
        footer: null,
      }}
    >
      <div className="w-100 salon__checkout-discount-rewards-modal-form ">
        {customer && (
          <RowWrap
            isAutoFillRow={true}
            isGutter={false}
            isWrap={true}
            styleFill="between"
            className="m-l-r-40-n background-color-F6F9FB salon__checkout-discount-rewards-modal__customer"
          >
            <ColWrap colProps={{ span: 12 }}>
              <div className="d-flex align-items-center gap-8 p-l-20">
                <CustomButton
                  type="link"
                  buttonProps={{
                    icon: <SvgCloseIcon />,
                    className: 'salon__checkout-discount-rewards-modal__customer-close',
                    onClick: () => {
                      handleRemoveCustomer();
                    },
                  }}
                />
                <Image preview={false} src={avatarDefaultSrc} className="border-radius-8px width-32 height-32" />
                <div className="d-flex flex-column">
                  <p className="salon__checkout-discount-rewards-modal-customer-name">{customer.name}</p>
                  {customer.phoneNumber && (
                    <div className="font-size-12 font-weight-400 line-height-17">
                      {formatPhoneNumber(customer.phoneNumber)}
                    </div>
                  )}
                </div>
              </div>
            </ColWrap>
            <ColWrap colProps={{ span: 12 }}>
              <div className="d-flex flex-column align-items-end p-r-20">
                <p className="font-size-12 font-weight-400 line-height-17 m-0">
                  {intl.formatMessage({ id: 'checkout.discountAndRewards.pointsAvailable' })}
                </p>
                <p className={`salon__checkout-customer-subtitle cursor-pointer m-0 d-flex align-items-center gap-4`}>
                  <span>
                    {formatNumberThousand(
                      customer?.customerReward?.reduce((currentValue, item) => currentValue + item.reward, 0) || 0
                    )}
                  </span>{' '}
                  <SvgPointRewardIcon className="d-flex" />
                </p>
              </div>
            </ColWrap>
          </RowWrap>
        )}
        <StyledButtonTab
          dataArr={listDiscountsNRewardsTabs}
          setValueActive={setCurrentTab as (value: string) => void}
          valueActive={currentTab}
          className="p-t-b-12 salon__checkout-discount-rewards-modal__tabs-list"
        />
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[20, 8]}
          isWrap={true}
          styleFill="between"
          className="p-b-20"
        >
          <ColWrap colProps={{ span: 24 }}>{renderTab()}</ColWrap>
        </RowWrap>
      </div>
    </StyledModal>
  );
}

export default DiscountsAndRewards;
