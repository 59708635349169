import { Form } from 'antd';
import { useRef, useEffect, useState } from 'react';
import FormWrap from '../../../../components/FormWrap';
import { FormInput } from '../../../../components/Form/FormInput';
import NotificationError from '../../../../components/HandleShowNotiError';
import { useIntl } from 'react-intl';
import { customerApi } from '../../../../apis';
import { useMutation } from '@tanstack/react-query';
import { Customer } from '../../../../apis/client-axios';
import { useDebounce } from './useDebounce';
import KeyboardWrapper from '../../../../components/KeyboardWrapper';
import { DATE_FORMAT, formatPhoneNumber, phoneChecker } from '../../../../utils';
import { FormDatePicker } from '../../../../components/Form/FormDatePicker';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { useParams } from 'react-router-dom';

interface ITabOneProps {
  dataDetail: Customer | undefined;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  dataCustomer: Customer | undefined;
  setDataCustomer: (customer: Customer) => void;
  setCheckedTechnician: (checkedTechnician: boolean) => void;
  setSelectedTechnician: (selectedTechnician: number | undefined) => void;
  setListSelectedService: (services: number[]) => void;
  setReceiveMessagesByPhone: (receiveMessagesByPhone: boolean) => void;
  setReceivePromotionViaEmail: (receivePromotionViaEmail: boolean) => void;
  setDataDetail: (customer: Customer) => void;
  showKeyboard: boolean;
  setListMerchandise: (merchandise: number[]) => void;
  setAppointmentId: (appoinmentId: number) => void;
}
function StepOne<T extends object>(props: ITabOneProps) {
  const {
    dataDetail,
    setStep,
    dataCustomer,
    setDataCustomer,
    setCheckedTechnician,
    setSelectedTechnician,
    setListSelectedService,
    setDataDetail,
    showKeyboard,
    setListMerchandise,
    setAppointmentId,
  } = props;
  const intl = useIntl();
  const keyboard = useRef<any>();
  const [form] = Form.useForm();
  const [inputName, setInputName] = useState<string | undefined>('phoneNumber');
  const { id } = useParams();
  const dataPhone = Form.useWatch('phoneNumber', form)?.replace(/[-\s]/g, '');

  const debouncedValue = useDebounce<string>(dataPhone, 1000);

  useEffect(() => {
    document.getElementById('customer_birthDay')?.setAttribute('inputmode', 'none');
    document.getElementById('phoneNumber')?.focus();
  }, []);

  const createCustomerCheckinMutation = useMutation(
    (dataPhone: string) => customerApi.customerControllerGetCustomerByPhoneNumber(dataPhone),
    {
      onSuccess: (data) => {
        setDataDetail(data?.data);
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
    }
  );
  useEffect(() => {
    if (dataDetail) {
      if (dataDetail?.appointments && dataDetail?.appointments.length > 0) {
        setSelectedTechnician(dataDetail.appointments[0]?.technician?.id ?? undefined);
        setListSelectedService(
          dataDetail.appointments[0]?.services?.filter((item) => item.isMerchandise === false).map((item) => item.id) ??
            []
        );
        setListMerchandise(
          dataDetail.appointments[0]?.services?.filter((item) => item.isMerchandise === true).map((item) => item.id) ??
            []
        );
        setCheckedTechnician(dataDetail.appointments[0]?.useAnyTechnician);
        setAppointmentId(dataDetail.appointments[0]?.id);
      }
      form.setFieldValue('name', dataDetail?.name);
      form.setFieldValue('phoneNumber', formatPhoneNumber(dataDetail?.phoneNumber));
      dataDetail?.birthDay
        ? form.setFieldValue('birthDay', dayjs(dataDetail?.birthDay))
        : form.setFieldValue('birthDay', '');
    } else {
      form.setFieldValue('name', '');
      form.setFieldValue('birthDay', '');
      setSelectedTechnician(undefined);
      setListSelectedService([]);
      setListMerchandise([]);
      setCheckedTechnician(true);
    }
  }, [dataDetail]);
  useEffect(() => {
    form.setFieldsValue(dataCustomer);
  }, [dataCustomer]);
  useEffect(() => {
    const check = phoneChecker(dataPhone);
    if (dataPhone && check) {
      createCustomerCheckinMutation.mutate(dataPhone);
    }
  }, [debouncedValue]);

  const onNext = () => {
    const data = form.getFieldsValue();
    form.validateFields();
    if (
      data?.phoneNumber === undefined ||
      dataPhone?.toString()?.length < 10 ||
      data?.name === undefined ||
      /^\s*$/.test(data?.name.trim())
    ) {
      NotificationError({ contentNoti: intl.formatMessage({ id: 'common.errorRequiredFields' }) });
    } else if (dataDetail?.appointments && dataDetail.appointments.length > 0) {
      setDataCustomer(data);
      setStep((prev) => prev + 2);
    } else {
      setDataCustomer(data);
      setStep((prev) => prev + 1);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs();
  };

  return (
    <section className="salon__step-one">
      <div className="salon__online-appointment-container">
        <h2 className="salon__step-one-title">{intl.formatMessage({ id: 'bookingOnline.step1' })}</h2>
        <div className="salon__form">
          <div className="salon__form-wrap">
            <div className="salon__form-item">
              <FormWrap
                name="customer"
                layout="vertical"
                initialValues={{ remember: true }}
                autoComplete="off"
                requiredMark={false}
                form={form}
              >
                <FormInput
                  name={'phoneNumber'}
                  inputProps={{
                    id: 'phoneNumber',
                    className: 'font-weight-500 ',
                    inputMode: showKeyboard ? 'none' : 'tel',
                    placeholder: intl.formatMessage({ id: 'bookingOnline.service.placeholder.phone' }),
                    onFocus: () => {
                      setInputName('phoneNumber');
                    },
                    maxLength: 16,
                    // onBlur: () => {
                    //   setInputName('output');
                    // },
                    onChange: (e) => {
                      const v = e?.target?.value?.replace(/[-\s]/g, '');
                      if (v) form.setFieldValue('phoneNumber', formatPhoneNumber(v));
                    },
                    onKeyDown: (event) => {
                      if (showKeyboard) return event.preventDefault();
                    },
                  }}
                  formItemProps={{
                    rules: [
                      {
                        validator: (_, value) => {
                          const v = value;
                          if (!v) {
                            return Promise.reject();
                          }
                          if (v && v.replace(/[-\s]/g, '').length < 10) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                    ],
                  }}
                />
                <FormInput
                  name={'name'}
                  inputProps={{
                    maxLength: 255,
                    inputMode: showKeyboard ? 'none' : 'text',
                    onFocus: () => setInputName('name'),
                    // onBlur: () => {
                    //   setInputName('output');
                    // },
                    onKeyDown: (event) => {
                      if (showKeyboard) return event.preventDefault();
                    },
                    placeholder: intl.formatMessage({ id: 'bookingOnline.service.placeholder.name' }),
                    disabled: !!(dataPhone ? dataPhone?.toString()?.length < 10 : true),
                  }}
                  formItemProps={{
                    rules: [
                      {
                        validator: (_, value) => {
                          const v = value?.toString().trim();
                          if (!v) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                    ],
                  }}
                />
                <FormDatePicker
                  name={'birthDay'}
                  datePickerProps={{
                    placeholder: intl.formatMessage({ id: 'bookingOnline.service.placeholder.birthday' }),
                    disabled: !!(dataPhone ? dataPhone?.toString()?.length < 10 : true),
                    format: DATE_FORMAT,
                    disabledDate: disabledDate,
                    allowClear: false,
                    className: `salon__custom-date-booking-online ${
                      !(dataPhone ? dataPhone?.toString()?.length < 10 : true) ? 'salon__border-color' : ''
                    }`,
                  }}
                />
              </FormWrap>
            </div>
          </div>

          {showKeyboard && (
            <div className="salon__form-keyboard">
              <KeyboardWrapper
                form={form}
                onNext={onNext}
                maxLength={inputName === 'phoneNumber' ? 10 : 255}
                inputName={inputName ?? 'phoneNumber'}
                keyboardRef={keyboard}
                keyboardProps={{
                  inputPattern: inputName === 'phoneNumber' ? /^\d+$/ : undefined,
                }}
              />
            </div>
          )}

          {id && (
            <div className="salon__custom-m-t d-flex justify-content-end m-t-200 p-t-7">
              <ButtonStyled
                content={intl.formatMessage({ id: 'common.next' })}
                buttonProps={{
                  className:
                    'width-184 height-64 border-radius-12px font-weight-700 font-size-24 line-height-29 text-transform-capitalize',
                  onClick: onNext,
                }}
                isPrimary
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default StepOne;
