import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import FormWrap from '../../../../../../components/FormWrap';
import RowWrap from '../../../../../../components/RowWrap';
import ColWrap from '../../../../../../components/ColWrap';
import { formatNumberThousandWithDecimal } from '../../../../../../utils';
import { FormInputNumber } from '../../../../../../components/Form/FormInputNumber';
import { SvgDollarIcon } from '../../../../../../components/@svg/SvgDollarIcon';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import KeyboardWrapper from '../../../keyboard';
import { StyledModal } from '../../../../../../components/StyledModal';
import NotificationSuccess from '../../../../../../components/HandleShowNotiSuccess';
import { IListIdTotalPrice } from '../../..';

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  setIsOpenPayAndComplete: (state: boolean) => void;
  listTotal: IListIdTotalPrice;
}

interface FormData {
  customerCheck?: number;
  customerTipLeft?: number;
}

enum InputNameEnum {
  CustomerCheck = 'customerCheck',
  CustomerTipLeft = 'customerTipLeft',
}

export const CheckPayment: React.FC<IProps> = (props) => {
  const { open, setOpen, setIsOpenPayAndComplete, listTotal } = props;
  const [form] = Form.useForm();
  const intl = useIntl();
  const keyboardRef = useRef<any>();
  const n = (key: keyof FormData) => key;
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();
  const customerCheckRef = useRef<HTMLInputElement>(null);
  const customerTipLeftRef = useRef<HTMLInputElement>(null);
  const customerCheck = Form.useWatch(n('customerCheck'), form);
  const customerTipLeft = Form.useWatch(n('customerTipLeft'), form);

  useEffect(() => {
    if (customerCheck && customerCheckRef.current) {
      const [integer] = customerCheck.replace(/,/g, '').split('.');
      customerCheckRef.current.selectionStart = customerCheckRef.current.selectionEnd =
        customerCheck.length + Math.floor(integer / 3);
    }

    if (customerTipLeft && customerTipLeftRef.current) {
      const [integer] = customerTipLeft.replace(/,/g, '').split('.');
      customerTipLeftRef.current.selectionStart = customerTipLeftRef.current.selectionEnd =
        customerTipLeft.length + Math.floor(integer / 3);
    }
  }, [customerCheck, customerTipLeft]);

  const onClickOK = (value: FormData) => {
    console.log('value', value);
    setOpen(false);
    setIsOpenPayAndComplete(false);
    NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.noti.saleCompleted' }) });
  };

  const onClickQuickNumber = (value: number) => {
    console.log('value', value);
    setOpen(false);
    setIsOpenPayAndComplete(false);
    NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.noti.saleCompleted' }) });
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpen(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: `checkout.payAndComplete.title.checkPayment` })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal',
        width: 700,
        footer: null,
      }}
    >
      <FormWrap form={form} onFinish={onClickOK} className="salon__checkout__pay-n-complete-modal__cash-payment">
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <p className="font-size-16 font-weight-500 m-0">
              {`${intl.formatMessage({ id: 'checkout.payAndComplete.balanceToPay' })}: `}
              <span className="font-weight-700">
                ${formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0)}
              </span>
            </p>
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <FormInputNumber
              name={n('customerCheck')}
              prefix={<SvgDollarIcon />}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.checkPayment.customerCheck.label' })}
              numericFormatProps={{
                placeholder: '0.00',
                className: 'm-b-0',
                onFocus: () => setFocusedInputName(InputNameEnum.CustomerCheck),
                getInputRef: customerCheckRef,
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <FormInputNumber
              name={n('customerTipLeft')}
              prefix={<SvgDollarIcon />}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.customerTipLeft.label' })}
              numericFormatProps={{
                placeholder: '0.00',
                className: 'm-b-0',
                onFocus: () => setFocusedInputName(InputNameEnum.CustomerTipLeft),
                getInputRef: customerTipLeftRef,
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
              {/* no tip */}
              <ColWrap colProps={{ span: 24 }}>
                <CustomButton
                  type="primary"
                  buttonProps={{
                    className:
                      'height-76 w-full border-radius-12px font-size-24 font-weight-700 background-color-EE7E68',
                  }}
                  content={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.noTip' })}
                />
              </ColWrap>

              {/* numeric keyboard */}
              <ColWrap colProps={{ span: 18 }}>
                <KeyboardWrapper
                  inputName={focusedInputName}
                  form={form}
                  keyboardRef={keyboardRef}
                  maxLength={9}
                  keyboardProps={{
                    inputPattern: /^\d+$/,
                  }}
                />
              </ColWrap>

              {/* ok button */}
              <ColWrap colProps={{ span: 6 }}>
                <CustomButton
                  type="primary"
                  buttonProps={{
                    className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                    htmlType: 'submit',
                  }}
                  content={intl.formatMessage({ id: 'common.OK' })}
                />
              </ColWrap>
            </RowWrap>
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
};
