import antdVI from 'antd/locale/vi_VN';
import common from './common_vi.json';
import signin from './signin.json';
import sideBar from './sidebar_vi.json';
import generalInfo from './generalInfo_vi.json';
import employee from './employee_vi.json';
import setting from './setting_vi.json';
import appointment from './appointment.json';
import customer from './customer_vi.json';
import onlineAppointment from './onlineAppointment_vi.json';
import promotions from './promotions_vi.json';
import checkIn from './checkin_vi.json';
import timesheet from './timesheet_vi.json';
import compensation from './compensation_vi.json';

const viLang = {
  messages: {
    ...common,
    ...signin,
    ...sideBar,
    ...generalInfo,
    ...employee,
    ...setting,
    ...appointment,
    ...customer,
    ...onlineAppointment,
    ...promotions,
    ...timesheet,
    ...compensation,
  },
  antd: antdVI,
  locale: 'vi-VN',
};
export default viLang;
