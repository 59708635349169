import { Carousel } from 'antd';
import { Asset } from '../../../../apis/client-axios';
import '../bookingOnline.scss';

interface IScreenSaverProps {
  status: boolean;
  screenSaver?: Asset[] | undefined;
  setStatus(status: boolean): void;
}

const ScreenSaver: React.FC<IScreenSaverProps> = ({ ...props }) => {
  const { status, screenSaver, setStatus } = props;
  return (
    <section className="salon__home">
      {screenSaver?.length && screenSaver.length > 0 ? (
        <Carousel autoplay autoplaySpeed={3000}>
          {screenSaver?.map((item) => (
            <div onClick={() => setStatus(!status)} key={item.id}>
              <img className="w-100" src={`${process.env.REACT_APP_API_URL}/static/${item.source}`} alt="" />
            </div>
          ))}
        </Carousel>
      ) : (
        <Carousel autoplay autoplaySpeed={3000}>
          <div onClick={() => setStatus(!status)}>
            <img className="w-100" src="/assets/qr/slide1.png" alt="" />
          </div>
          <div onClick={() => setStatus(!status)}>
            <img className="w-100" src="/assets/qr/slide2.png" alt="" />
          </div>
          <div onClick={() => setStatus(!status)}>
            <img className="w-100" src="/assets/qr/slide3.png" alt="" />
          </div>
        </Carousel>
      )}
    </section>
  );
};

export default ScreenSaver;
