import React from 'react';

interface IProps {
  className?: string;
}

export const SvgArrowDownCollapse: React.FC<IProps> = (props) => {
  return (
    <span {...props}>
      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.40814 0.91107C0.733577 0.585633 1.26121 0.585633 1.58665 0.91107L5.9974 5.32181L10.4081 0.91107C10.7336 0.585633 11.2612 0.585633 11.5867 0.91107C11.9121 1.23651 11.9121 1.76414 11.5867 2.08958L6.58665 7.08958C6.26121 7.41502 5.73358 7.41502 5.40814 7.08958L0.40814 2.08958C0.0827033 1.76414 0.0827033 1.23651 0.40814 0.91107Z"
          fill="#292F33"
        />
      </svg>
    </span>
  );
};
