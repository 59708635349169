import { useIntl } from 'react-intl';
import { StyledModal } from '../../../../components/StyledModal';
import './transactionsStyle.scss';
import { useCallback, useState } from 'react';
import RowWrap from '../../../../components/RowWrap';
import ColWrap from '../../../../components/ColWrap';
import { StyledDatePicker } from '../../../../components/StyledDatePicker';
import { TransactionsCollapse } from './components/TransactionsCollapse';
import { DATE_FORMAT, formatNumberThousandWithDecimal, formatOnlyDate } from '../../../../utils';
import { useQuery } from '@tanstack/react-query';
import { ticketApi } from '../../../../apis';
import { QUERY_TICKET, QUERY_TRANSACTIONS_CHECKOUT } from '../../../../utils/constant';
import dayjs from 'dayjs';

interface Props {
  open: boolean;
  onCancelModal: () => void;
}

function Transactions<T extends object>(props: Props) {
  const { open, onCancelModal } = props;
  const intl = useIntl();
  const [dateSearch, setDateSearch] = useState<string>(dayjs().toString());

  const { data: dataTicket } = useQuery({
    queryKey: [QUERY_TRANSACTIONS_CHECKOUT, dateSearch],
    enabled: true,
    staleTime: 1000,
    queryFn: () => ticketApi.ticketControllerGetTicketCompleted(dateSearch, undefined, undefined),
  });
  console.log(dataTicket);

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            <p className="m-b-4">{intl.formatMessage({ id: 'checkout.transactions' })}</p>
            <p className="font-size-12 font-weight-400 color-78797A m-0">
              {intl.formatMessage({ id: 'checkout.transactions.subTitle' })}
            </p>
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal salon__checkout__transactions',
        width: 700,
        footer: null,
      }}
    >
      <RowWrap isAutoFillRow={false} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
        <ColWrap colProps={{ span: 24 }}>
          <StyledDatePicker
            label={intl.formatMessage({ id: 'checkout.transactions.calendar' })}
            datePickerProps={{
              format: DATE_FORMAT,
              onChange: (date, dateString) => {
                // console.log('dateeeee', date, date?.toISOString(), dateString);
                setDateSearch(date?.toISOString() ?? '');
              },
              value: dateSearch ? dayjs(dateSearch) : undefined,
              allowClear: false,
            }}
          />
        </ColWrap>

        <ColWrap colProps={{ span: 24 }}>
          <RowWrap isAutoFillRow={false} isGutter={true} gutter={[12, 12]} isWrap={true} styleFill="between">
            {dataTicket?.data?.content?.map((ticket, index) => (
              <ColWrap colProps={{ span: 24 }}>
                <TransactionsCollapse ticket={ticket} onCancelModal={onCancelModal} />
              </ColWrap>
            ))}
          </RowWrap>
        </ColWrap>
      </RowWrap>
    </StyledModal>
  );
}

export default Transactions;
