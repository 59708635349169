import { useQuery } from '@tanstack/react-query';
import dayjs, { ManipulateType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from 'react';
import { SlotInfo } from 'react-big-calendar';
import { useIntl } from 'react-intl';
import { appointmentApi, employeeApi } from '../../../apis';
import { Appointment as AppointmentType } from '../../../apis/client-axios';
import { AppointmentStatusEnum, BlockTimeRepeatTypeEnum } from '../../../apis/client-axios/api';
import AvatarDefault from '../../../assets/images/appointment/avatar-default.png';
import { SvgAppointmentBeingServedIcon } from '../../../components/@svg/SvgAppointmentBeingServedIcon';
import { SvgAppointmentCanceledIcon } from '../../../components/@svg/SvgAppointmentCanceledIcon';
import { SvgAppointmentCheckedInIcon } from '../../../components/@svg/SvgAppointmentCheckedInIcon';
import { SvgAppointmentCompletedIcon } from '../../../components/@svg/SvgAppointmentCompletedIcon';
import { SvgAppointmentConfirmedIcon } from '../../../components/@svg/SvgAppointmentConfirmedIcon';
import { APPOINTMENT_TIME_FORMAT } from '../../../utils';
import { QUERY_LIST_APPOINTMENTS, QUERY_LIST_TECHNICIAN } from '../../../utils/constant';
import AppointmentCalendar from './AppointmentCalendar';
import AppointmentFilter from './AppointmentFilter';
import AppointmentModified, { IinitAppointmentModal } from './AppointmentModal';
import AppointmentSidebar from './AppointmentSidebar';
import VirtualCallerID from './AppointmentSidebar/CallerID/virtual';
import {
  APPOINTMENT_SORT,
  AppointmentEvent,
  AppointmentResource,
  Filter,
  RESOURCE_UNASIGNED_KEY,
  RESOURCE_UNASIGNED_NAME,
  SampleCallerID,
  getTechnicianDetail,
} from './models';
import './style.scss';

export const STATUSES = [
  {
    title: 'appointment.status.confirmed',
    icon: <SvgAppointmentConfirmedIcon />,
    key: AppointmentStatusEnum.Confirmed,
    color: '#FFD8D8',
  },
  {
    title: 'appointment.status.checked_in',
    icon: <SvgAppointmentCheckedInIcon />,
    key: AppointmentStatusEnum.CheckedIn,
    color: '#FCEAAA',
  },
  {
    title: 'appointment.status.being_served',
    icon: <SvgAppointmentBeingServedIcon />,
    key: AppointmentStatusEnum.BeingServed,
    color: '#DCFFF9',
  },
  {
    title: 'appointment.status.completed',
    icon: <SvgAppointmentCompletedIcon />,
    key: AppointmentStatusEnum.Completed,
    color: '#DDFFB5',
  },
  {
    title: 'appointment.status.canceled',
    icon: <SvgAppointmentCanceledIcon />,
    key: AppointmentStatusEnum.Canceled,
    color: '#DBDDDF',
  },
  {
    title: 'appointment.status.waiting_confirm',
    icon: <SvgAppointmentCanceledIcon />,
    key: AppointmentStatusEnum.WaitingConfirm,
    color: '#F4D3FE',
  },
];

dayjs.extend(customParseFormat);

const Appointment = () => {
  const intl = useIntl();

  const [resources, setResources] = useState<AppointmentResource[]>([]);
  const [events, setEvents] = useState<AppointmentEvent[]>([]);
  const [blockTimeEvents, setBlockTimeEvents] = useState<AppointmentEvent[]>([]);
  const [filter, setFilter] = useState<Filter>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editEvent, setEditEvent] = useState<AppointmentType>();
  const [draggedCaller, setDraggedCaller] = useState<SampleCallerID>();

  const { data: listAppointments, refetch: onRefetchAppointment } = useQuery({
    queryKey: [QUERY_LIST_APPOINTMENTS, filter?.date],
    queryFn: () =>
      appointmentApi.appointmentControllerGet(
        1,
        filter?.date?.toString() || new Date().toString(),
        undefined,
        APPOINTMENT_SORT,
        undefined,
        undefined,
        true
      ),
  });

  const { data: listTechnicians, refetch: onRefetchTechnician } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN],
    queryFn: () => employeeApi.employeeControllerTechnician(),
    enabled: true,
    staleTime: 1000,
  });

  const setOpenModal = (state: boolean) => {
    setIsModalOpen(state);
    setTimeout(() => {
      onRefetchAppointment();
    }, 1000);
  };

  useEffect(() => {
    if (listAppointments?.data.content && listAppointments?.data.content?.length >= 0) {
      const events = listAppointments?.data.content.map((appointment) => {
        const end = dayjs(appointment.timeStart).add(appointment.estimate, 'minutes').toDate();
        const endOfDate = dayjs(appointment.timeStart).endOf('days').toDate();

        const event: AppointmentEvent = {
          id: appointment.id,
          resource: appointment,
          resourceId: appointment.technicianId ? appointment.technicianId : RESOURCE_UNASIGNED_KEY,
          start: dayjs(appointment.timeStart).toDate(),
          end: end > endOfDate ? endOfDate : end,
          title:
            appointment.services
              .map((service) => service.name)
              .filter((name) => name)
              .join(', ') || '',
          isBlockTime: false,
          status: appointment.status,
        };

        return event;
      });

      setEvents(events);
    }
  }, [listAppointments?.data.content]);

  useEffect(() => {
    const { revenue, tasks, hours } = getTechnicianDetail(RESOURCE_UNASIGNED_KEY, events);

    let resources: AppointmentResource[] = [
      {
        resourceId: RESOURCE_UNASIGNED_KEY,
        revenue,
        tasks,
        hours,
        resourceTitle: RESOURCE_UNASIGNED_NAME,
        name: RESOURCE_UNASIGNED_NAME,
        id: -1,
        phoneNumber: '',
      },
    ];

    if (listTechnicians?.data && listTechnicians.data.length >= 0) {
      listTechnicians.data.forEach((technician) => {
        const { revenue, tasks, hours } = getTechnicianDetail(technician.id, events);

        const resource: AppointmentResource = {
          resourceId: technician.id,
          revenue,
          tasks,
          hours,
          resourceTitle: technician.name || '',
          avatar:
            technician.defaultAvatar || technician.avatar?.preview || technician.avatar?.source
              ? technician.defaultAvatar ||
                `${process.env.REACT_APP_API_URL}/static/${technician.avatar?.preview || technician.avatar?.source}`
              : AvatarDefault,
          name: technician.name,
          id: technician.id,
          phoneNumber: technician.phoneNumber,
          resource: technician,
        };

        resources.push(resource);
      });
    }

    setResources(resources);
  }, [listTechnicians?.data, events]);

  useEffect(() => {
    if (listTechnicians?.data && listTechnicians.data.length >= 0) {
      const blockTimes = listTechnicians.data.flatMap((technician) => technician.blockTime);

      const blockTimeEvents = blockTimes
        .map((blockTime) => {
          const from = {
            hour: dayjs(blockTime?.from, APPOINTMENT_TIME_FORMAT).hour(),
            minute: dayjs(blockTime?.from, APPOINTMENT_TIME_FORMAT).minute(),
          };

          const to = {
            hour: dayjs(blockTime?.to, APPOINTMENT_TIME_FORMAT).hour(),
            minute: dayjs(blockTime?.to, APPOINTMENT_TIME_FORMAT).minute(),
          };

          const isBlockTimeDeleted =
            blockTime?.blockTimeDeleted &&
            blockTime?.blockTimeDeleted.findIndex(
              (item) =>
                dayjs(item.timeDelete).startOf('days').isSame(dayjs(blockTime?.time).startOf('days')) &&
                item?.blockTimeId === blockTime?.id
            ) > -1;

          if (blockTime?.repeat && blockTime.repeatType && blockTime.repeatValue) {
            let dayToAdd = blockTime.repeatValue;

            const distanceDays = dayjs(filter?.date || new Date())
              .startOf('days')
              .diff(
                dayjs(blockTime.time).startOf('days'),
                blockTime.repeatType.toLowerCase() as ManipulateType | undefined
              );

            if (distanceDays > 0 && distanceDays % blockTime.repeatValue === 0) {
              dayToAdd = distanceDays;
            }

            const nextRepeatDay = dayjs(blockTime.time)
              .startOf('days')
              .add(dayToAdd, blockTime.repeatType.toLowerCase() as ManipulateType | undefined)
              .toDate();

            const blockTimeDate = dayjs(blockTime.time).date();
            const nextRepeatDate = dayjs(nextRepeatDay).date();

            const isBlockTimeRepeatDeleted =
              blockTime.blockTimeDeleted.findIndex(
                (item) =>
                  dayjs(item.timeDelete).startOf('days').isSame(dayjs(nextRepeatDay)) &&
                  item.blockTimeId === blockTime.id
              ) === -1;

            const isBlockTimeGreaterThanNextRepeat =
              (blockTime.repeatType === BlockTimeRepeatTypeEnum.Months ||
                blockTime.repeatType === BlockTimeRepeatTypeEnum.Years) &&
              blockTimeDate > nextRepeatDate;

            const isCreateNextRepeat =
              isBlockTimeRepeatDeleted &&
              dayjs(nextRepeatDay).isSame(dayjs(filter?.date || new Date()).startOf('days')) &&
              (blockTime.timeEnd
                ? dayjs(nextRepeatDay).toDate() <= dayjs(blockTime.timeEnd).startOf('days').toDate()
                : true);

            if (isBlockTimeGreaterThanNextRepeat || !isCreateNextRepeat) {
              if (isBlockTimeDeleted) return undefined;

              const event: AppointmentEvent = {
                title: intl.formatMessage({ id: 'appointment.event.blocktime' }),
                start: dayjs(blockTime?.time).set('hour', from.hour).set('minute', from.minute).toDate(),
                end: dayjs(blockTime?.time).set('hour', to.hour).set('minute', to.minute).toDate(),
                id: `blockTime_${blockTime?.id}`,
                resourceId: blockTime?.technicianId || '',
                isBlockTime: true,
                blockTime: blockTime,
              };

              return event;
            }

            if (isCreateNextRepeat) {
              const event: AppointmentEvent = {
                title: intl.formatMessage({ id: 'appointment.event.blocktime' }),
                start: dayjs(filter?.date || new Date())
                  .set('hour', from.hour)
                  .set('minute', from.minute)
                  .toDate(),
                end: dayjs(filter?.date || new Date())
                  .set('hour', to.hour)
                  .set('minute', to.minute)
                  .toDate(),
                id: `blockTime_${blockTime?.id}`,
                resourceId: blockTime?.technicianId || '',
                isBlockTime: true,
                blockTime: blockTime,
              };

              return event;
            }
          }

          if (isBlockTimeDeleted) return undefined;

          const event: AppointmentEvent = {
            title: intl.formatMessage({ id: 'appointment.event.blocktime' }),
            start: dayjs(blockTime?.time).set('hour', from.hour).set('minute', from.minute).toDate(),
            end: dayjs(blockTime?.time).set('hour', to.hour).set('minute', to.minute).toDate(),
            id: `blockTime_${blockTime?.id}`,
            resourceId: blockTime?.technicianId || '',
            isBlockTime: true,
            blockTime: blockTime,
          };

          return event;
        })
        .filter((blockTime) => blockTime);

      setBlockTimeEvents(blockTimeEvents as AppointmentEvent[]);
    }
  }, [listTechnicians?.data, filter?.date]);

  const handleChangeFilter = (newFilter: Filter) => {
    setFilter((prev) => ({ ...prev, ...newFilter }));
  };

  const handleOpenCreateModal = () => {
    setOpenModal(true);
  };

  const handleRefetch = () => {
    onRefetchAppointment();
    onRefetchTechnician();
  };

  const [initTabData, setInitTabData] = useState<IinitAppointmentModal | undefined>();
  const openCreateModal = (slotInfo?: SlotInfo) => {
    if (!slotInfo) return;
    const time = slotInfo.start < new Date() ? new Date() : slotInfo.start;
    const findResource = resources.find((resource) => resource.resourceId === slotInfo.resourceId);
    if (findResource) {
      setInitTabData({ technicianId: findResource.id, timeStart: time });
      setOpenModal(true);
    }
  };

  const handleDnDCaller = (initTabData: IinitAppointmentModal) => {
    if (!draggedCaller) return;

    setOpenModal(true);

    setInitTabData({
      customerName: draggedCaller.name,
      customerNumber: draggedCaller.phone,
      technicianId: initTabData.technicianId,
      timeStart: initTabData.timeStart,
    });

    setDraggedCaller(undefined);
  };

  return (
    <div className="salon__appointment">
      <AppointmentSidebar
        openCreateModal={handleOpenCreateModal}
        onRefetch={handleRefetch}
        onChangeDraggedCaller={(caller) => setDraggedCaller(caller)}
        isOpenModalModified={isModalOpen}
        draggedCaller={draggedCaller}
      />

      <div className="salon__appointment-container">
        <AppointmentFilter filter={filter || { date: new Date() }} onChangeFilter={handleChangeFilter} />
        <AppointmentCalendar
          filter={filter || { date: new Date() }}
          onChangeFilter={handleChangeFilter}
          events={[...events, ...blockTimeEvents]}
          resources={resources}
          onChangeEvents={(events) => setEvents(events.filter((event) => !event.isBlockTime))}
          onRefetch={handleRefetch}
          onChangeEditEvent={(event) => {
            setEditEvent(event);
            if (event !== undefined) setOpenModal(true);
          }}
          setSlotInfor={openCreateModal}
          openModifiedModal={isModalOpen}
          onDnDCaller={handleDnDCaller}
        />
      </div>

      {isModalOpen && (
        <AppointmentModified
          open={isModalOpen}
          setOpenModal={setOpenModal}
          appointment={editEvent}
          initData={initTabData}
          setAppointment={setEditEvent}
          setInitData={setInitTabData}
        />
      )}

      {draggedCaller && <VirtualCallerID caller={draggedCaller} />}
    </div>
  );
};

export default Appointment;
