import { EmployeeProfileSettingGuaranteePeriodsEnum, Role } from '../../../apis/client-axios';
import { FormInput } from '../../../components/Form/FormInput';

export const enum RoleCode {
  SuperAdmin = 'superadmin',
  Owner = 'owner',
  Manager = 'manager',
  Technician = 'technician',
  CheckinStand = 'checkin-stand',
}

export const EMPLOYEE_ROUTES = {
  LIST: '/',
  CREATE: '/create',
  DETAIL: '/detail/',
  EDIT: '/edit/',
};

export const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female',
};

export const checkAndGetRoleName = (roleCode: string) => {
  switch (roleCode) {
    case RoleCode.Owner:
      return 'Business Owner';
    case RoleCode.CheckinStand:
      return 'Checkin Stand';
    case RoleCode.Manager:
      return 'Manager';
    case RoleCode.Technician:
      return 'Technician';
    case RoleCode.SuperAdmin:
      return 'Super Admin';
    default:
      return '';
  }
};

interface INameFC {
  id: EmployeeProfileSettingGuaranteePeriodsEnum;
  name: (value?: number, isEdit?: boolean) => string | React.ReactNode;
}

export interface DailyDeduction {
  monday: number;
  tuesday: number;
  webnesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
}

export interface WorkingHours {
  monday: FromTo;
  tuesday: FromTo;
  webnesday: FromTo;
  thursday: FromTo;
  friday: FromTo;
  saturday: FromTo;
  sunday: FromTo;
}

export interface FromTo {
  from: string;
  to: string;
}

export const listDefaultAvatars: { id: number; src: string; name: string }[] = [...Array(18)].map((_, index) => ({
  id: index + 1,
  name: `public-avatar-${index + 1}`,
  src: `/assets/avatars/public-avatar-${index + 1}.svg`,
}));

export const LIST_EMPLOYEE_TAB: string[] = ['employee.currentEmployees', 'employee.terminatedEmployees'];
