import { Checkbox, CheckboxProps, Form, FormItemProps, Tooltip } from 'antd';
import React, { ReactNode } from 'react';
import './formCheckbox.scss';
import { SvgToolTipIcon } from '../../@svg/SvgToolTipIcon';
import { NamePath } from 'antd/es/form/interface';

interface ITooltip {
  trigger: 'hover' | 'focus' | 'click' | 'contextMenu';
  title: ReactNode;
  className?: string;
}

type Props = {
  name: string | NamePath<any>;
  label?: string;
  formItemProps?: FormItemProps;
  checkboxProps?: CheckboxProps;
  content: ReactNode | string;
  labelClassName?: string;
  tooltip?: ITooltip;
};

export const FormCheckbox: React.FC<Props> = ({
  checkboxProps,
  formItemProps,
  name,
  label,
  content,
  labelClassName,
  tooltip,
}) => {
  if (tooltip) {
    return (
      <div className="salon__form-checkbox__has-tooltip">
        <Form.Item
          name={name}
          label={label}
          valuePropName="checked"
          {...formItemProps}
          className={`salon__form-checkbox ${formItemProps?.className ?? ''}`}
        >
          <Checkbox {...checkboxProps}>
            <span className={'font-size-16 salon__form-checkbox-title' + (labelClassName ? ' ' + labelClassName : '')}>
              {content ?? ''}
            </span>
          </Checkbox>
        </Form.Item>
        {tooltip ? (
          <Tooltip
            title={tooltip.title}
            trigger={tooltip.trigger}
            className={
              'salon__form-checkbox__has-tooltip__tooltip' + (tooltip.className ? ` ${tooltip.className}` : '')
            }
          >
            <SvgToolTipIcon />
            &nbsp;
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    );
  } else
    return (
      <Form.Item
        name={name}
        label={label}
        valuePropName="checked"
        {...formItemProps}
        className={`salon__form-checkbox ${formItemProps?.className ?? ''}`}
      >
        <Checkbox {...checkboxProps}>
          <span className={'font-size-16 salon__form-checkbox-title' + (labelClassName ? ' ' + labelClassName : '')}>
            {content ?? ''}
          </span>
        </Checkbox>
      </Form.Item>
    );
};
