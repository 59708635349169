import React from 'react';

export const SvgToolTipIcon = () => (
  <span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3622 0.75L15.2622 4.65C15.4122 4.8 15.5039 5.01667 15.4956 5.23333V10.7667C15.4956 10.9917 15.4122 11.2 15.2539 11.3583L11.3539 15.2583C11.1956 15.4083 10.9872 15.5 10.7622 15.5H5.23724C5.02057 15.5 4.80391 15.4083 4.64557 15.25L0.745573 11.35C0.595573 11.1917 0.503906 10.9833 0.503906 10.7583V5.23333C0.503906 5.01667 0.595573 4.8 0.745573 4.65L4.65391 0.741667C4.80391 0.591667 5.02057 0.5 5.23724 0.5H10.7706C10.9872 0.5 11.2039 0.591667 11.3622 0.75ZM10.4206 13.8333L13.8372 10.4167V5.58333L10.4206 2.16667H5.58724L2.17057 5.58333V10.4167L5.58724 13.8333H10.4206ZM8.00391 10.5C7.54367 10.5 7.17057 10.8731 7.17057 11.3333C7.17057 11.7936 7.54367 12.1667 8.00391 12.1667C8.46414 12.1667 8.83724 11.7936 8.83724 11.3333C8.83724 10.8731 8.46414 10.5 8.00391 10.5ZM7.17057 4.66667C7.17057 4.20833 7.54557 3.83333 8.00391 3.83333C8.46224 3.83333 8.83724 4.20833 8.83724 4.66667V8.83333C8.83724 9.29167 8.46224 9.66667 8.00391 9.66667C7.54557 9.66667 7.17057 9.29167 7.17057 8.83333V4.66667Z"
        fill="#56595C"
      />
    </svg>
  </span>
);
