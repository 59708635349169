import { DatePicker, DatePickerProps, Form, FormItemProps } from 'antd';
import React, { ReactNode } from 'react';
import { SvgDatePickerIcon } from '../../@svg/SvgDatePickerIcon';
import { SvgNextDayIcon } from '../../@svg/SvgNextDayIcon';
import { SvgPrevDayIcon } from '../../@svg/SvgPrevDayIcon';
import './formDatePicker.scss';
type Props = {
  name: string;
  label?: ReactNode | string;
  formItemProps?: FormItemProps;
  datePickerProps?: DatePickerProps;
  datePickerIcon?: ReactNode;
  isPickWeek?: boolean;
};

export const FormDatePicker: React.FC<Props> = ({
  datePickerProps,
  formItemProps,
  datePickerIcon,
  name,
  label,
  isPickWeek,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      {...formItemProps}
      className={`salon__form-date-picker w-100 ${formItemProps?.className ?? ''}`}
    >
      <DatePicker
        {...datePickerProps}
        popupClassName={`salon__form-date-picker-container ${
          isPickWeek ? 'salon__form-date-picker-container-pick-week' : ''
        }`}
        // nextIcon={<SvgNextDayIcon />}
        // prevIcon={<SvgPrevDayIcon />}
        suffixIcon={datePickerIcon ?? <SvgDatePickerIcon />}
      />
    </Form.Item>
  );
};
