import React from 'react';
import RowWrap from '../../../../../components/RowWrap';
import ColWrap from '../../../../../components/ColWrap';
import { useIntl } from 'react-intl';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { useState } from 'react';
import CustomRedeem from '../CustomRedeem';
import { Customer, Promotion } from '../../../../../apis/client-axios';
import { IOpenModalCheckOut } from '../../index';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../../checkoutConstants';
import { CustomButton } from '../../../../../components/buttons/CustomButton';
import { formatNumberThousand, formatNumberThousandWithDecimal } from '../../../../../utils';
import { RedeemOption } from '../../../promotions/LoyaltyReward';

interface IProps {
  promotion?: Promotion;
  setOpenModal: React.Dispatch<React.SetStateAction<IOpenModalCheckOut>>;
  customer?: Customer;
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
  confirmAddDiscountsAndRewards: (
    onOKButton?: () => void,
    onCancelButton?: () => void,
    isMerchandise?: boolean,
    isGeneralDiscount?: boolean
  ) => void;
  onCancelModal: () => void;
}

export const RewardPoints: React.FC<IProps> = (props) => {
  const {
    promotion,
    setOpenModal,
    customer,
    discountsAndRewardsApplied,
    setDiscountsAndRewardsApplied,

    onCancelModal,
  } = props;
  const intl = useIntl();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const onClickAddNewCustomer = () => {
    setOpenModal((prev) => ({
      ...prev,
      openListCustomer: true,
    }));
  };

  const onClickQuickRewardBalance = (redeem: number = 0, forOff: number = 0) => {
    const handleSetDiscountsAndRewards = () => {
      const reward = customer?.customerReward?.reduce((currentValue, item) => currentValue + item.reward, 0) ?? 0;
      if (customer && reward && reward > redeem) {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          rewardBalance: {
            amount: forOff,
            points: redeem,
            isCustomAmount: false,
            type: DiscountTypeEnum.Dollar,
          },
        }));
        onCancelModal();
      }
    };
    if (
      discountsAndRewardsApplied?.rewardBalance &&
      discountsAndRewardsApplied?.rewardBalance.amount === forOff &&
      !discountsAndRewardsApplied?.rewardBalance.isCustomAmount &&
      discountsAndRewardsApplied?.rewardBalance.points === redeem
    ) {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        rewardBalance: undefined,
      }));
      // } else if (discountsAndRewardsApplied?.rewardBalance) {
      //   handleSetDiscountsAndRewards();
      // } else {
      //   confirmAddDiscountsAndRewards(handleSetDiscountsAndRewards, undefined, undefined, true);
      // }
    } else {
      handleSetDiscountsAndRewards();
    }
  };

  const onOKModalCustom = (redeem: number, forOff: number, type: DiscountTypeEnum) => {
    const reward = customer?.customerReward?.reduce((currentValue, item) => currentValue + item.reward, 0) ?? 0;
    if (customer && reward && reward >= redeem) {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        rewardBalance: {
          amount: forOff,
          points: redeem,
          isCustomAmount: true,
          type: type,
        },
      }));
      setIsOpenModal(false);
      onCancelModal();
    }
  };

  const onClickCustomRedeem = () => {
    if (discountsAndRewardsApplied?.rewardBalance && discountsAndRewardsApplied.rewardBalance.isCustomAmount) {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        rewardBalance: undefined,
      }));
    } else if (discountsAndRewardsApplied?.rewardBalance) {
      setIsOpenModal(true);
      // } else {
      //   confirmAddDiscountsAndRewards(
      //     () => {
      //       setIsOpenModal(true);
      //     },
      //     undefined,
      //     undefined,
      //     true
      //   );
      // }
    } else {
      setIsOpenModal(true);
    }
  };

  return (
    <>
      <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
        <ColWrap colProps={{ span: 24 }}>
          {/* Loyalty Reward */}
          <RowWrap
            isAutoFillRow={true}
            isGutter={true}
            gutter={[20, 12]}
            isWrap={true}
            styleFill="between"
            className="border-bottom p-t-b-20 salon__checkout-discount"
          >
            <ColWrap colProps={{ span: 24 }}>
              <span className="salon__checkout-discount-title">
                {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.title.1' })}
                {!promotion?.loyaltyReward?.loyaltyReward && (
                  <span className="salon__checkout-discount-not-available m-l-17">
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.available' })}
                  </span>
                )}
              </span>
              {promotion?.loyaltyReward?.loyaltyReward && (
                <>
                  <br />
                  <span className="salon__checkout-discount-title-sub">
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.reward' })}
                    &nbsp;
                    {formatNumberThousand(Number(promotion?.loyaltyReward?.reward || 0))}
                    &nbsp;
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.pointsForEachDollarSpent' })}
                    <br />
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.onlyCheckoutsOf' })} $
                    {formatNumberThousandWithDecimal(promotion?.loyaltyReward?.saleTicket || 0)}
                    &nbsp;
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.orMoreAreRewarded' })}
                  </span>
                </>
              )}
            </ColWrap>
            {promotion?.loyaltyReward?.loyaltyReward ? (
              !customer ? (
                <>
                  <ColWrap colProps={{ span: 24 }}>
                    <div className="d-flex gap-20 align-items-center">
                      <CustomButton
                        type="link"
                        buttonProps={{
                          className: 'salon__checkout-discount-button p-0 m-0 height-unset border-none',
                          onClick: onClickAddNewCustomer,
                        }}
                        content={intl.formatMessage({ id: 'checkout.discountAndRewards.add.text' })}
                      />
                      <span className="salon__checkout-discount-des">
                        {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.des.1' })}
                      </span>
                    </div>
                  </ColWrap>
                </>
              ) : (
                <ColWrap colProps={{ span: 24 }}>
                  <p className="font-size-16 font-weight-400 line-height-22 color-37BF50 m-0">
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.alreadyAutomaticallyApplied' })}
                  </p>
                </ColWrap>
              )
            ) : (
              <ColWrap colProps={{ span: 24 }}>
                <ButtonStyled
                  content={intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.apply' })}
                  buttonProps={{
                    className: 'width-124 height-38 salon__checkout-discount-rewards-button-3',
                    disabled: true,
                  }}
                  isPrimary={true}
                />
              </ColWrap>
            )}
          </RowWrap>

          {/* Reward Balance */}
          <RowWrap
            isAutoFillRow={true}
            isGutter={true}
            gutter={[20, 12]}
            isWrap={true}
            styleFill="between"
            className="p-t-b-20"
          >
            <ColWrap colProps={{ span: 24 }}>
              <span className="salon__checkout-discount-title">
                {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.title.2' })}
                {!promotion?.loyaltyReward?.loyaltyReward && (
                  <span className="salon__checkout-discount-not-available m-l-17">
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.available' })}
                  </span>
                )}
              </span>
              <br />
              {!customer ? (
                <>
                  {/* <span className="salon__checkout-discount-title-sub">
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.title.sub.3' })}
                    <br />
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.title.sub.4' })}
                  </span> */}
                </>
              ) : (
                <>
                  <span className="salon__checkout-discount-title-sub">
                    {formatNumberThousand(
                      customer?.customerReward?.reduce((currentValue, item) => currentValue + item.reward, 0) ?? 0
                    )}
                    &nbsp;
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.points' })}
                    &nbsp;
                    {promotion?.loyaltyReward?.loyaltyReward &&
                    promotion?.loyaltyReward?.reward &&
                    !!customer?.customerReward?.length
                      ? `($${formatNumberThousandWithDecimal(
                          (customer?.customerReward?.reduce((currentValue, item) => currentValue + item.reward, 0) ??
                            0) / promotion?.loyaltyReward?.reward
                        )}) `
                      : ''}
                    {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.available' })}
                  </span>
                </>
              )}
            </ColWrap>

            {promotion?.loyaltyReward?.loyaltyReward ? (
              !customer ? (
                <>
                  <ColWrap colProps={{ span: 24 }}>
                    <div className="d-flex gap-20 align-items-center">
                      <CustomButton
                        type="link"
                        buttonProps={{
                          className: 'salon__checkout-discount-button p-0 m-0 height-unset border-none',
                          onClick: onClickAddNewCustomer,
                        }}
                        content={intl.formatMessage({ id: 'checkout.discountAndRewards.add.text' })}
                      />
                      <span className="salon__checkout-discount-des">
                        {intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.des.2' })}
                      </span>
                    </div>
                  </ColWrap>
                </>
              ) : (
                <ColWrap colProps={{ span: 24 }}>
                  <RowWrap
                    isAutoFillRow={true}
                    isGutter={true}
                    gutter={[20, 20]}
                    isWrap={true}
                    styleFill="between"
                    className=""
                  >
                    {promotion?.loyaltyReward?.redeemOptions && !!promotion?.loyaltyReward.redeemOptions.length
                      ? (promotion.loyaltyReward.redeemOptions as RedeemOption[]).map((option) => (
                          <ColWrap colProps={{ span: 24 }}>
                            <ButtonStyled
                              content={
                                <span className="salon__checkout-merchandise-modal-keyboard-button-text">
                                  {intl.formatMessage({ id: 'promotion.checkinReward.redeemOptions.redeem' })}
                                  &nbsp;
                                  {formatNumberThousand(option.redeem || 0)}
                                  &nbsp;
                                  {intl.formatMessage({ id: 'promotion.checkinReward.redeemOptions.pointsFor' })}
                                  &nbsp; ${formatNumberThousandWithDecimal(option.for || 0)}
                                  &nbsp;
                                  {intl.formatMessage({ id: 'promotion.checkinReward.redeemOptions.off' })}
                                </span>
                              }
                              buttonProps={{
                                className: `width-620 height-43 salon__checkout-discount-rewards-button-3${
                                  !discountsAndRewardsApplied?.rewardBalance?.isCustomAmount &&
                                  discountsAndRewardsApplied?.rewardBalance?.amount === option.for &&
                                  discountsAndRewardsApplied?.rewardBalance?.points === option.redeem
                                    ? ' salon__checkout-discount-rewards-applied-btn'
                                    : ''
                                }`,
                                onClick: () => onClickQuickRewardBalance(option.redeem, option.for),
                                disabled:
                                  // !discountsAndRewardsApplied?.rewardBalance?.isCustomAmount &&
                                  // discountsAndRewardsApplied?.rewardBalance?.amount === option.for &&
                                  // discountsAndRewardsApplied?.rewardBalance?.points === option.redeem,
                                  !customer?.customerReward?.length ||
                                  customer?.customerReward?.reduce(
                                    (currentValue, item) => currentValue + item.reward,
                                    0
                                  ) < option.redeem,
                              }}
                              isPrimary={true}
                            />
                          </ColWrap>
                        ))
                      : ''}
                    {promotion?.loyaltyReward?.allowCustomAmount && (
                      <ColWrap colProps={{ span: 24 }}>
                        <ButtonStyled
                          content={
                            <span className="salon__checkout-merchandise-modal-keyboard-button-text">
                              {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.redeem.5' })}
                            </span>
                          }
                          buttonProps={{
                            className: `width-620 height-43 salon__checkout-discount-rewards-button-3${
                              discountsAndRewardsApplied?.rewardBalance?.isCustomAmount
                                ? // &&
                                  // !!discountsAndRewardsApplied?.rewardBalance?.amount &&
                                  // !!discountsAndRewardsApplied?.rewardBalance?.points
                                  ' salon__checkout-discount-rewards-applied-btn'
                                : ''
                            }`,
                            onClick: onClickCustomRedeem,
                            disabled:
                              // discountsAndRewardsApplied?.rewardBalance?.isCustomAmount &&
                              // !!discountsAndRewardsApplied?.rewardBalance?.amount &&
                              // !!discountsAndRewardsApplied?.rewardBalance?.points,
                              !customer?.customerReward?.length,
                          }}
                          isPrimary={true}
                        />
                      </ColWrap>
                    )}
                  </RowWrap>
                </ColWrap>
              )
            ) : (
              <ColWrap colProps={{ span: 24 }}>
                <ButtonStyled
                  content={intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.apply' })}
                  buttonProps={{
                    className: 'width-124 height-38 salon__checkout-discount-rewards-button-3',
                    disabled: true,
                  }}
                  isPrimary={true}
                />
              </ColWrap>
            )}
          </RowWrap>
        </ColWrap>
      </RowWrap>
      <CustomRedeem
        onCancelModal={() => {
          setIsOpenModal(false);
        }}
        open={isOpenModal}
        onOK={onOKModalCustom}
        customer={customer}
      />
    </>
  );
};
