import { Carousel, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import { Ticket } from '../../../../../apis/client-axios';
import AvatarDefault from '../../../../../assets/images/appointment/avatar-default.png';
import { SvgNextCarouselIcon } from '../../../../../components/@svg/SvgNextCarouselIcon';
import { SvgPrevCarouselIcon } from '../../../../../components/@svg/SvgPrevCarouselIcon';
import ColWrap from '../../../../../components/ColWrap';
import RowWrap from '../../../../../components/RowWrap';
import { formatNumberThousandWithDecimal, formatPhoneNumber } from '../../../../../utils';
import { ITicketDTO } from '../index';
import './ticket.scss';
export interface ITicketCardProps<T extends object> {
  ticketData: Ticket | undefined;
  listCombine: ITicketDTO[];
  setListCombine: (listCombine: ITicketDTO[]) => void;
}

function TicketCard<T extends object>(props: ITicketCardProps<T>) {
  const { ticketData, listCombine, setListCombine } = props;
  const intl = useIntl();

  return (
    <div
      className={`salon__ticket-card-container ${
        listCombine[0]?.id === ticketData?.id
          ? 'salon__ticket-card-container-first'
          : listCombine.find((item) => item.id === ticketData?.id)
          ? 'salon__ticket-card-container-selected'
          : ''
      }`}
    >
      <RowWrap isAutoFillRow={true} isGutter={true} gutter={[0, 0]} isWrap={true} styleFill="between">
        <ColWrap colProps={{ span: 24 }}>
          <div className="d-flex align-items-center justify-content-between height-17">
            <p className="font-size-12 font-weight-700 m-b-0 overflow-hidden white-space-nowrap text-overflow-ellipsis width-15-ch">
              {intl.formatMessage({ id: 'checkout.total.sub' })}{' '}
              {intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price' })}
              {formatNumberThousandWithDecimal(ticketData?.total ?? 0)}
            </p>
            <Checkbox
              className={`salon__ticket-card-checkbox ${
                listCombine[0]?.id === ticketData?.id ? 'salon__ticket-card-checkbox-first' : ''
              }`}
              onChange={(e) => {
                const checked = e.target.checked;
                if (ticketData && ticketData.id) {
                  if (checked) {
                    setListCombine(listCombine.concat(ticketData));
                  } else {
                    const dataFilter = listCombine.filter((item: ITicketDTO) => item.id !== ticketData.id);
                    setListCombine(dataFilter);
                  }
                }
              }}
            />
          </div>
        </ColWrap>
        <ColWrap colProps={{ span: 24 }}>
          <span className="salon__ticket-card-detail-customer-name">{ticketData?.customer?.name}</span>
        </ColWrap>
        <ColWrap colProps={{ span: 24 }}>
          <span className="salon__ticket-card-detail-customer-phone">
            {formatPhoneNumber(ticketData?.customer?.phoneNumber)}
          </span>
        </ColWrap>
      </RowWrap>
      {!!ticketData?.technicianTurns?.length ? (
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[0, 0]}
          isWrap={true}
          styleFill="between"
          className="m-t-8"
        >
          <ColWrap colProps={{ span: 24 }}>
            <div className="salon__ticket-card-detail-container">
              <div className="d-flex justify-content-between height-14 p-l-r-8 p-t-4 line-height-14">
                <span className="salon__ticket-card-detail-quantity">
                  {intl.formatMessage({ id: 'checkout.technician' })} ({ticketData?.technicianTurns?.length})
                </span>
              </div>
              <Carousel
                dots={false}
                arrows={true}
                infinite={true}
                nextArrow={
                  <span className="salon__checkout-ticket-arrow salon__checkout-ticket-arrow-next">
                    <SvgNextCarouselIcon />
                  </span>
                }
                prevArrow={
                  <span className="salon__checkout-ticket-arrow salon__checkout-ticket-arrow-prev">
                    <SvgPrevCarouselIcon />
                  </span>
                }
              >
                {ticketData?.technicianTurns?.map((item, index) => {
                  return (
                    <div className="salon__ticket-card-container-technician">
                      <div className="d-flex align-items-center height-16">
                        <img
                          // src={`${item?.technician.avatar ?? 'assets/qr/avatar.png'}`}
                          src={
                            item?.technician?.defaultAvatar ||
                            item?.technician?.avatar?.preview ||
                            item?.technician?.avatar?.source
                              ? item.technician.defaultAvatar ||
                                `${process.env.REACT_APP_API_URL}/static/${
                                  item?.technician?.avatar?.preview || item?.technician?.avatar?.source
                                }`
                              : AvatarDefault
                          }
                          alt=""
                          className="salon__ticket-card-detail-technician-avatar"
                        />
                        <span className="salon__ticket-card-detail-technician-name">
                          {item?.technician?.name ?? intl.formatMessage({ id: 'checkout.unassigned' })}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </ColWrap>
        </RowWrap>
      ) : (
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[0, 0]}
          isWrap={true}
          styleFill="between"
          className="m-t-8"
        >
          <ColWrap colProps={{ span: 24 }}>
            <div className="salon__ticket-card-detail-container">
              <div className="d-flex justify-content-between height-14 p-l-r-8 p-t-4 line-height-14">
                <span className="salon__ticket-card-detail-quantity">
                  {intl.formatMessage({ id: 'checkout.technician' })}
                </span>
              </div>
              <div className="salon__ticket-card-container-technician">
                <div className="d-flex align-items-center height-16">
                  <img src={AvatarDefault} alt="" className="salon__ticket-card-detail-technician-avatar" />
                  <span className="salon__ticket-card-detail-technician-name">--</span>
                </div>
              </div>
            </div>
          </ColWrap>
        </RowWrap>
      )}
    </div>
  );
}
export default TicketCard;
