import { useQuery } from '@tanstack/react-query';
import dayjs, { ManipulateType } from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { SlotInfo } from 'react-big-calendar';
import { useIntl } from 'react-intl';
import { appointmentApi, employeeApi } from '../../../../../apis';
import { Appointment, AppointmentStatusEnum, BlockTimeRepeatTypeEnum } from '../../../../../apis/client-axios';
import AvatarDefault from '../../../../../assets/images/appointment/avatar-default.png';
import { StyledModal } from '../../../../../components/StyledModal';
import { APPOINTMENT_TIME_FORMAT } from '../../../../../utils';
import { QUERY_APPOINTMENTS, QUERY_LIST_TECHNICIAN } from '../../../../../utils/constant';
import AppointmentFilter from '../../AppointmentFilter';
import {
  APPOINTMENT_SORT,
  AppointmentEvent,
  AppointmentResource,
  FILTER_TECHNICIAN,
  Filter,
  RESOURCE_UNASIGNED_KEY,
  RESOURCE_UNASIGNED_NAME,
  getTechnicianDetail,
} from '../../models';
import { IFormData, IFromState, IFromStateInput } from '../index';
import AppointmentCalendar from './SubAppointmentCalendar';

export interface Props {
  open: boolean;
  setOpenModal: (state: boolean) => void;
  type: PicktimeModalTypeEnum;
  activeTab: number;
  tabData: IFromState[];
  setTabData: (data: IFromStateInput) => void;
  appointment?: Appointment | undefined;
}

export enum PicktimeModalTypeEnum {
  NEWTAB = 'newTab',
  PICKTIME = 'pickTime',
}

const PickTimeModal: FC<Props> = ({ open, setOpenModal, type, activeTab, tabData, setTabData, appointment }) => {
  const intl = useIntl();
  const n = (key: keyof IFormData) => key;
  const [resources, setResources] = useState<AppointmentResource[]>([]);
  const [events, setEvents] = useState<AppointmentEvent[]>([]);
  const [blockTimeEvents, setBlockTimeEvents] = useState<AppointmentEvent[]>([]);
  const [filter, setFilter] = useState<Filter>({
    date: new Date(),
    technician: '',
  });
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [editEvent, setEditEvent] = useState<Appointment>();
  // const [dndPosition, setDnDPosition] = useState<DnDTouchPosition>();
  // const [dndSelected, setDnDSelected] = useState<DnDTouchSelected>();

  const { data: listAppointments, refetch: onRefetchAppointment } = useQuery({
    queryKey: [QUERY_APPOINTMENTS, filter.date],
    queryFn: () =>
      appointmentApi.appointmentControllerGet(1, filter.date?.toString() || '', undefined, APPOINTMENT_SORT),
  });

  const { data: listTechnicians, refetch: onRefetchTechnician } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN],
    queryFn: () => employeeApi.employeeControllerTechnician(),
  });

  // const setOpenModal = (state: boolean) => {
  //   setIsModalOpen(state);
  //   setTimeout(() => {
  //     onRefetchAppointment();
  //   }, 1000);
  // };

  useEffect(() => {
    if (listAppointments?.data.content && listAppointments?.data.content?.length >= 0) {
      const events = listAppointments?.data.content.map((appointment) => {
        const event: AppointmentEvent = {
          id: appointment.id,
          resource: appointment,
          resourceId: appointment.technicianId ? appointment.technicianId : RESOURCE_UNASIGNED_KEY,
          start: dayjs(appointment.timeStart).toDate(),
          end: dayjs(appointment.timeStart).add(appointment.estimate, 'minutes').toDate(),
          title: appointment.services[0]?.name || '',
          isBlockTime: false,
        };

        return event;
      });

      const eventsTabdata = tabData.map((tab) => {
        const formTab = tab.form;
        const estimate = formTab.getFieldValue(n('estimate')) || 15;
        const technicianId = tab.technicianId;
        const start = tab.time;
        const event: AppointmentEvent = {
          id: `tab_appointment_${tab.tabId}`,
          resourceId: technicianId ? technicianId : RESOURCE_UNASIGNED_KEY,
          start: dayjs(start).toDate(),
          end: dayjs(start).add(estimate, 'minutes').toDate(),
          title: !!tab?.listSelectedService?.length ? tab?.listSelectedService?.[0]?.name : '',
          isBlockTime: false,
          status: tab.status,
        };
        return event;
      });

      setEvents([
        ...events.filter((event) => {
          if (appointment) {
            return event.resource?.status !== AppointmentStatusEnum.Canceled && appointment.id !== event.id;
          }
          return event.resource?.status !== AppointmentStatusEnum.Canceled;
        }),
        ...eventsTabdata,
      ]);
    }
  }, [listAppointments?.data.content, tabData, appointment]);

  useEffect(() => {
    const { revenue, tasks, hours } = getTechnicianDetail(RESOURCE_UNASIGNED_KEY, events);

    let resources: AppointmentResource[] = [
      {
        resourceId: RESOURCE_UNASIGNED_KEY,
        revenue,
        tasks,
        hours,
        resourceTitle: RESOURCE_UNASIGNED_NAME,
        name: RESOURCE_UNASIGNED_NAME,
        id: -1,
        phoneNumber: '',
      },
    ];

    if (listTechnicians?.data && listTechnicians.data.length >= 0) {
      listTechnicians.data.forEach((technician) => {
        const { revenue, tasks, hours } = getTechnicianDetail(technician.id, events);

        const resource: AppointmentResource = {
          resourceId: technician.id,
          revenue,
          tasks,
          hours,
          resourceTitle: technician.name || '',
          avatar:
            technician.defaultAvatar || technician.avatar?.preview || technician.avatar?.source
              ? technician.defaultAvatar ||
                `${process.env.REACT_APP_API_URL}/static/${technician.avatar?.preview || technician.avatar?.source}`
              : AvatarDefault,
          name: technician.name,
          id: technician.id,
          phoneNumber: technician.phoneNumber,
          resource: technician,
        };

        resources.push(resource);
      });
    }

    setResources(resources);
  }, [listTechnicians?.data, events]);

  useEffect(() => {
    if (listTechnicians?.data && listTechnicians.data.length >= 0) {
      const blockTimes = listTechnicians.data.flatMap((technician) => technician.blockTime);

      const blockTimeEvents = blockTimes
        .map((blockTime) => {
          const from = {
            hour: dayjs(blockTime?.from, APPOINTMENT_TIME_FORMAT).hour(),
            minute: dayjs(blockTime?.from, APPOINTMENT_TIME_FORMAT).minute(),
          };

          const to = {
            hour: dayjs(blockTime?.to, APPOINTMENT_TIME_FORMAT).hour(),
            minute: dayjs(blockTime?.to, APPOINTMENT_TIME_FORMAT).minute(),
          };

          const isBlockTimeDeleted =
            blockTime?.blockTimeDeleted &&
            blockTime?.blockTimeDeleted.findIndex(
              (item) =>
                dayjs(item.timeDelete).startOf('days').isSame(dayjs(blockTime?.time)) &&
                item?.blockTimeId === blockTime?.id
            ) > -1;

          if (blockTime?.repeat && blockTime.repeatType && blockTime.repeatValue) {
            let dayToAdd = blockTime.repeatValue;

            const distanceDays = dayjs(filter.date)
              .startOf('days')
              .diff(
                dayjs(blockTime.time).startOf('days'),
                blockTime.repeatType.toLowerCase() as ManipulateType | undefined
              );

            if (distanceDays > 0 && distanceDays % blockTime.repeatValue === 0) {
              dayToAdd = distanceDays;
            }

            const nextRepeatDay = dayjs(blockTime.time)
              .startOf('days')
              .add(dayToAdd, blockTime.repeatType.toLowerCase() as ManipulateType | undefined)
              .toDate();

            const blockTimeDate = dayjs(blockTime.time).date();
            const nextRepeatDate = dayjs(nextRepeatDay).date();

            const isBlockTimeRepeatDeleted =
              blockTime.blockTimeDeleted.findIndex(
                (item) =>
                  dayjs(item.timeDelete).startOf('days').isSame(dayjs(nextRepeatDay)) &&
                  item.blockTimeId === blockTime.id
              ) === -1;

            const isBlockTimeGreaterThanNextRepeat =
              (blockTime.repeatType === BlockTimeRepeatTypeEnum.Months ||
                blockTime.repeatType === BlockTimeRepeatTypeEnum.Years) &&
              blockTimeDate > nextRepeatDate;

            const isCreateNextRepeat =
              isBlockTimeRepeatDeleted &&
              dayjs(nextRepeatDay).isSame(dayjs(filter.date).startOf('days')) &&
              (blockTime.timeEnd
                ? dayjs(nextRepeatDay).toDate() <= dayjs(blockTime.timeEnd).startOf('days').toDate()
                : true);

            if (isBlockTimeGreaterThanNextRepeat || !isCreateNextRepeat) {
              if (isBlockTimeDeleted) return undefined;

              const event: AppointmentEvent = {
                title: intl.formatMessage({ id: 'appointment.event.blocktime' }),
                start: dayjs(blockTime?.time).set('hour', from.hour).set('minute', from.minute).toDate(),
                end: dayjs(blockTime?.time).set('hour', to.hour).set('minute', to.minute).toDate(),
                id: `blockTime_${blockTime?.id}`,
                resourceId: blockTime?.technicianId || '',
                isBlockTime: true,
                blockTime: blockTime,
              };

              return event;
            }

            if (isCreateNextRepeat) {
              const event: AppointmentEvent = {
                title: intl.formatMessage({ id: 'appointment.event.blocktime' }),
                start: dayjs(filter.date).set('hour', from.hour).set('minute', from.minute).toDate(),
                end: dayjs(filter.date).set('hour', to.hour).set('minute', to.minute).toDate(),
                id: `blockTime_${blockTime?.id}`,
                resourceId: blockTime?.technicianId || '',
                isBlockTime: true,
                blockTime: blockTime,
              };

              return event;
            }
          }

          if (isBlockTimeDeleted) return undefined;

          const event: AppointmentEvent = {
            title: intl.formatMessage({ id: 'appointment.event.blocktime' }),
            start: dayjs(blockTime?.time).set('hour', from.hour).set('minute', from.minute).toDate(),
            end: dayjs(blockTime?.time).set('hour', to.hour).set('minute', to.minute).toDate(),
            id: `blockTime_${blockTime?.id}`,
            resourceId: blockTime?.technicianId || '',
            isBlockTime: true,
            blockTime: blockTime,
          };

          return event;
        })
        .filter((blockTime) => blockTime);

      setBlockTimeEvents(blockTimeEvents as AppointmentEvent[]);
    }
  }, [listTechnicians?.data, filter.date]);

  const handleChangeFilter = (newFilter: Filter) => {
    setFilter((prev) => ({ ...prev, ...newFilter }));
  };

  const handleRefetch = () => {
    onRefetchAppointment();
    onRefetchTechnician();
  };

  const onSave = (slotInfo?: SlotInfo) => {
    if (!slotInfo) return;
    const time = slotInfo.start < new Date() ? new Date() : slotInfo.start;
    let tabData;
    if (type === 'pickTime') {
      resources.forEach((item) => {
        if (item.resourceId === slotInfo.resourceId) tabData = { time: time, technicianId: item.id };
      });
    }
    if (type === 'newTab') {
      resources.forEach((item) => {
        if (item.resourceId === slotInfo.resourceId) tabData = { time: time, technicianId: item.id, tabId: -1 };
      });
    }
    if (tabData) setTabData(tabData);
    setOpenModal(false);
  };

  useEffect(() => {
    const technicaanId = tabData.find((item) => item.tabId == activeTab)?.technicianId;
    if (technicaanId && technicaanId !== -1 && type !== 'newTab')
      setFilter((prev) => ({ ...prev, technician: tabData.find((item) => item.tabId == activeTab)?.technicianId }));
    else setFilter((prev) => ({ ...prev, technician: '' }));
  }, [tabData, open]);

  const isTechnician =
    tabData.find((item) => item.tabId == activeTab)?.technicianId &&
    tabData.find((item) => item.tabId == activeTab)?.technicianId !== -1 &&
    filter.technician !== FILTER_TECHNICIAN.ALL &&
    type !== 'newTab';
  const padding = isTechnician ? 'p-l-4' : 'p-l-r-40';
  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpenModal(false)}
      modalProps={{
        className: `salon__appointment-modal-create salon__appointment-modal-create-calendar ${
          isTechnician ? 'salon__appointment-modal-create-calendar-technician' : ''
        }`,
        width: isTechnician ? 454 : 927,
        footer: null,
      }}
    >
      <div className="salon__appointment m-t-0">
        <div className={'salon__appointment-container'}>
          <AppointmentFilter filter={filter} onChangeFilter={handleChangeFilter} />

          <AppointmentCalendar
            filter={filter}
            onChangeFilter={handleChangeFilter}
            events={[...events, ...blockTimeEvents]}
            resources={resources}
            activeTab={activeTab}
            tabData={tabData}
            setTabData={setTabData}
            setSlotInfor={onSave}
            onRefetch={handleRefetch}
          />
        </div>
      </div>
    </StyledModal>
  );
};

export default PickTimeModal;
