import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SuspenseWrapper } from '../../components/LoadingComponent';
import CheckInTenant from './checkIn';
import CheckOutTenant from './checkOut';
import { MAIN_ROUTES } from './routes';
import CustomersTenant from './customers';
import CompensationTenant from './compensation';
import PromotionsTenant from './promotions/promotionsRoutes';
import ReportTenant from './report';
import BookingOnline from './bookingOnline/routes';

import { useAppDispatch } from '../../store';
import { RoleCode } from './employee/employeeConstants';
import { Administrator, Customer, Employee } from '../../apis/client-axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_EMPLOYEE_ME, QUERY_SALONS, QUERY_SALONS_ALL, QUERY_SALONS_DETAIL } from '../../utils/constant';
import { authApi, axiosInstance, salonApi } from '../../apis';
import { saveListSalons } from '../../store/dataQuerySlice';
import { logout, updateMe, updateSalon } from '../../store/authSlice';
import { BOOKING_ONLINE_ROUTES } from './bookingOnline/bookingOnlineConstants';
import { AxiosResponse } from 'axios';
import SettingRoutes from './setting/settingRoutes';
const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

const Tenant = React.lazy(() => import('.'));
const Appointment = React.lazy(() => import('./appointment'));
const SignInTenant = React.lazy(() => import('./auth/SignInTenant'));
const EmployeeComponent = React.lazy(() => import('./employee/index'));
const CustomerComponent = React.lazy(() => import('./customers/index'));
const TimesheetComponent = React.lazy(() => import('./timesheet/index'));
const CompensationComponent = React.lazy(() => import('./compensation/index'));

const OnlineAppointment = React.lazy(() => import('./onlineAppointment/index'));
const Information = React.lazy(() => import('./bookingOnline/information'));
const CheckInConfirm = React.lazy(() => import('./bookingOnline/checkInConfirm'));
const ForgotPasswordTenant = React.lazy(() => import('./auth/ForgotPassword/index'));

export const TenantRoutes = () => {
  const accessToken = sessionStorage.getItem('token') || localStorage.getItem('token');
  const authUserString = sessionStorage.getItem('authUser') || localStorage.getItem('authUser');
  const authUser: Administrator | Customer | Employee = authUserString ? JSON.parse(authUserString as string) : null;
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  let timer: NodeJS.Timeout | undefined;

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const { data } = useQuery({
    queryKey: [QUERY_EMPLOYEE_ME],
    enabled: !!localStorage.getItem('token'),
    queryFn: () => authApi.authControllerEmployeeMe(),
    onSuccess: (data: AxiosResponse<Employee, any>) => {
      localStorage.setItem('authUser', JSON.stringify(data.data));
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      const path = window.location.pathname.split('/');
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        if (path[1] === 'admin') window.location.href = '/admin/signin';
        else window.location.href = '/signin';
      }
    },
  });
  const { data: salonDetail, refetch: refetchSalon } = useQuery({
    queryKey: [QUERY_SALONS_DETAIL],
    queryFn: () => salonApi.salonControllerGetCurrentSalon(),
    enabled: !!localStorage.getItem('salonId'),
  });

  const { data: salonData } = useQuery({
    queryKey: [QUERY_SALONS_ALL],
    queryFn: () => salonApi.salonControllerGet(1),
    enabled: !!data?.data?.user?.roles?.some((e) => e.code === RoleCode.Owner) && !localStorage.getItem('salonId'),
  });

  useEffect(() => {
    if (data) {
      dispatch(updateMe(data.data));
      if (!data.data.user?.roles.some((e) => e.code === RoleCode.Owner)) {
        localStorage.setItem('salonId', data.data?.salons[0]?.id?.toString());
        axiosInstance.defaults.headers.salonId = data.data?.salons[0]?.id?.toString();
        refetchSalon();
      }
    }
  }, [data, dispatch, queryClient]);

  useEffect(() => {
    if (salonDetail) {
      dispatch(updateSalon(salonDetail.data));
    }
  }, [salonDetail, dispatch, queryClient]);

  useEffect(() => {
    if (!localStorage.getItem('salonId') && !!salonData?.data?.content?.length) {
      // dispatch(saveListSalons(salonData?.data?.content ?? []));
      const salonId = salonData.data.content[Math.floor(Math.random() * salonData.data.content.length)].id.toString();
      localStorage.setItem('salonId', salonId);
      axiosInstance.defaults.headers.salonId = salonId;
      refetchSalon();
    }
  }, [salonData, dispatch]);

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });

      dispatch(logout());
    }, 1800000);
  };

  useEffect(() => {
    if (!localStorage.getItem('isRemember')) {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {accessToken ? (
        <>
          {authUser?.user?.roles?.some((e) => e.code === RoleCode.CheckinStand) ? (
            <>
              <Route
                path={`${MAIN_ROUTES.BOOKING_ONLINE}/*`}
                element={<SuspenseWrapper component={<BookingOnline />} />}
              />

              <Route path="/*" element={<Navigate to={`${MAIN_ROUTES.BOOKING_ONLINE}`} />} />
            </>
          ) : (
            <>
              <Route path="" element={<Tenant />}>
                <Route path="" element={<SuspenseWrapper component={<Appointment />} />} />
                <Route path={`${MAIN_ROUTES.CHECK_IN}`} element={<CheckInTenant />} />
                <Route path={`${MAIN_ROUTES.CHECK_OUT}`} element={<SuspenseWrapper component={<CheckOutTenant />} />} />
                <Route
                  path={`${MAIN_ROUTES.CUSTOMERS}/*`}
                  element={<SuspenseWrapper component={<CustomerComponent />} />}
                />
                <Route
                  path={`${MAIN_ROUTES.EMPLOYEES}/*`}
                  element={<SuspenseWrapper component={<EmployeeComponent />} />}
                />
                <Route
                  path={`${MAIN_ROUTES.COMPENSATION}`}
                  element={<SuspenseWrapper component={<CompensationComponent />} />}
                />
                <Route
                  path={`${MAIN_ROUTES.TIME_SHEET}/*`}
                  element={<SuspenseWrapper component={<TimesheetComponent />} />}
                />
                <Route
                  path={`${MAIN_ROUTES.PROMOTIONS}/*`}
                  element={<SuspenseWrapper component={<PromotionsTenant />} />}
                />
                <Route path={`${MAIN_ROUTES.REPORT}`} element={<SuspenseWrapper component={<ReportTenant />} />} />
                <Route path={`${MAIN_ROUTES.SETTING}/*`} element={<SuspenseWrapper component={<SettingRoutes />} />} />
              </Route>
              <Route path="/*" element={<Navigate to={`/`} />} />
            </>
          )}
        </>
      ) : (
        <>
          <Route path={`${MAIN_ROUTES.SIGN_IN}`} element={<SuspenseWrapper component={<SignInTenant />} />} />
          <Route
            path={MAIN_ROUTES.FORGOT_PASSWORD}
            element={<SuspenseWrapper component={<ForgotPasswordTenant />} />}
          />
          <Route path="/*" element={<Navigate to={`${MAIN_ROUTES.SIGN_IN}`} />} />
        </>
      )}
      <Route path={`${MAIN_ROUTES.ONLINE_APPOINTMENT}/:id`} element={<OnlineAppointment />} />
      <Route
        path={`${MAIN_ROUTES.BOOKING_ONLINE}${BOOKING_ONLINE_ROUTES.INFORMATION}/:id`}
        element={<Information />}
      ></Route>
      <Route
        path={`${MAIN_ROUTES.BOOKING_ONLINE}${BOOKING_ONLINE_ROUTES.CONFIRM}/:id`}
        element={<CheckInConfirm />}
      ></Route>
    </Routes>
  );
};
