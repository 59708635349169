import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { axiosInstance } from '../apis';
import { Administrator, Customer, Employee, LoginResponseDto, Salon } from '../apis/client-axios';

export interface AuthState {
  loading: boolean;
  error: string;
  userType?: 'administrator' | 'customer' | 'employees';
  authUser?: Administrator | Customer | Employee;
  salonActive?: Salon;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: (): AuthState => {
    let initState: AuthState = {
      loading: false,
      error: '',
    };

    const authUser = localStorage.getItem('authUser');
    if (authUser) {
      initState.authUser = JSON.parse(authUser);
    }

    return initState;
  },
  reducers: {
    login: (state, action: PayloadAction<LoginResponseDto>) => {
      axiosInstance.defaults.headers.Authorization = action.payload.token ? `Bearer ${action.payload.token}` : '';
    },
    updateMe: (state, action: PayloadAction<Customer | Administrator | Employee>) => {
      state.authUser = action.payload;
    },
    updateSalon: (state, action: PayloadAction<Salon>) => {
      state.salonActive = action.payload;
    },
    logout: (state) => {
      window.location.href = '/signin';
      state.authUser = undefined;
      localStorage.clear();
      sessionStorage.clear();
    },
  },
});

export const { logout, login, updateMe, updateSalon } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
