import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { ticketApi } from '../../../../../apis';
import { CashDrawerDTO, CashDrawerDTOActionEnum } from '../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../components/Form/FormInputNumber';
import { FormTextArea } from '../../../../../components/Form/FormTextArea';
import FormWrap from '../../../../../components/FormWrap';
import NotificationError from '../../../../../components/HandleShowNotiError';
import RowWrap from '../../../../../components/RowWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import '../style.scss';

export interface Props {
  openModal: boolean;
  onCancelModal: () => void;
  refetchCashDrawer: () => void;
}

interface FormData {
  cashAmount?: string;
  notes?: string;
}
function CashIn<T extends object>(props: Props) {
  const intl = useIntl();
  const { openModal, onCancelModal, refetchCashDrawer } = props;
  const n = (key: keyof FormData) => key;
  const [form] = Form.useForm();

  const createCashDrawer = useMutation((data: CashDrawerDTO) => ticketApi.ticketControllerCreateCashDrawer(data), {
    onSuccess: ({ data }) => {
      onCancelModal();
      form.resetFields();
      refetchCashDrawer();
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      NotificationError({ contentNoti: error.response.data.message });
    },
  });

  const onSubmit = () => {
    const { cashAmount, notes } = form.getFieldsValue();
    if (!!cashAmount) {
      const dataSubmit: CashDrawerDTO = {
        cashAmount: Number(cashAmount.replace(/[,\s]/g, '')),
        notes,
        action: CashDrawerDTOActionEnum.CashIn,
      };
      createCashDrawer.mutate(dataSubmit);
    }
  };

  return (
    <StyledModal
      isOpen={openModal}
      onCancel={onCancelModal}
      modalProps={{
        title: intl.formatMessage({ id: 'checkout.cashDrawer.cashIn' }),
        className: 'salon__checkout-cash-drawer-modal',
        width: 700,
        footer: (
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex gap-12 align-items-center">
              <ButtonStyled
                content={intl.formatMessage({ id: 'checkout.cashDrawer.ok' })}
                buttonProps={{
                  className: 'width-100 height-38',
                  onClick: onSubmit,
                }}
                isPrimary={true}
              />
            </div>
          </div>
        ),
      }}
    >
      <FormWrap form={form} name="appointmentModified" layout="vertical" className="w-100  p-t-20">
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[10, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <FormInputNumber
              name={n('cashAmount')}
              label={intl.formatMessage({ id: 'checkout.cashDrawer.cashInAndCashOut.title' })}
              prefix={<SvgDollarIcon />}
              numericFormatProps={{
                min: 0,
                max: 999_999_999,
                className: 'm-b-0',
                placeholder: intl.formatMessage({ id: 'checkout.cashDrawer.cashInAndCashOut.placeholder' }),
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
              }}
              classNameFormInputNumber="m-b-0"
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <FormTextArea
              name={'notes'}
              inputProps={{
                className: 'salon__checkout-cash-drawer-modal-custom-input',
                placeholder: intl.formatMessage({ id: 'checkout.cashDrawer.others.placeholder' }),
              }}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
}

export default CashIn;
