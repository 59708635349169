import React from 'react';

export const SvgBigAdd = () => (
  <span>
    <svg width="35" height="12" viewBox="0 0 35 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.87642 12H0.62642L4.72301 0.363636H7.32528L11.4276 12H9.17756L6.0696 2.75H5.97869L2.87642 12ZM2.95028 7.4375H9.08665V9.13068H2.95028V7.4375ZM16.9034 12H12.9602V0.363636H16.983C18.1383 0.363636 19.1307 0.596591 19.9602 1.0625C20.7936 1.52462 21.4337 2.18939 21.8807 3.05682C22.3277 3.92424 22.5511 4.96212 22.5511 6.17045C22.5511 7.38258 22.3258 8.42424 21.875 9.29545C21.428 10.1667 20.7822 10.8352 19.9375 11.3011C19.0966 11.767 18.0852 12 16.9034 12ZM15.0682 10.1761H16.8011C17.6117 10.1761 18.2879 10.0284 18.8295 9.73295C19.3712 9.43371 19.7784 8.98864 20.0511 8.39773C20.3239 7.80303 20.4602 7.06061 20.4602 6.17045C20.4602 5.2803 20.3239 4.54167 20.0511 3.95455C19.7784 3.36364 19.375 2.92235 18.8409 2.63068C18.3106 2.33523 17.6515 2.1875 16.8636 2.1875H15.0682V10.1761ZM28.4972 12H24.554V0.363636H28.5767C29.732 0.363636 30.7244 0.596591 31.554 1.0625C32.3873 1.52462 33.0275 2.18939 33.4744 3.05682C33.9214 3.92424 34.1449 4.96212 34.1449 6.17045C34.1449 7.38258 33.9195 8.42424 33.4688 9.29545C33.0218 10.1667 32.3759 10.8352 31.5312 11.3011C30.6903 11.767 29.679 12 28.4972 12ZM26.6619 10.1761H28.3949C29.2055 10.1761 29.8816 10.0284 30.4233 9.73295C30.965 9.43371 31.3722 8.98864 31.6449 8.39773C31.9176 7.80303 32.054 7.06061 32.054 6.17045C32.054 5.2803 31.9176 4.54167 31.6449 3.95455C31.3722 3.36364 30.9688 2.92235 30.4347 2.63068C29.9044 2.33523 29.2453 2.1875 28.4574 2.1875H26.6619V10.1761Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
