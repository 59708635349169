import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { StyledModal } from '../../../../../../components/StyledModal';
import { PaymentChargeCard } from './PaymentChargeCard';
import { PrintChargeCard } from './PrintChargeCard';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { TipsChargeCard } from './TipsChargeCard';
import { IListIdTotalPrice } from '../../..';

export interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  setIsOpenPayAndComplete: (state: boolean) => void;
  listTotal: IListIdTotalPrice;
}

export enum ChargeCardEnum {
  Payment = 'payment',
  Print = 'print',
  CreditCardPayment = 'creditCardPayment',
  CardPayment = 'cardPayment',
  Tips = 'tips',
}

function ChargeCard<T extends object>(props: Props) {
  const { open, setOpen, setIsOpenPayAndComplete, listTotal } = props;
  const intl = useIntl();
  const [currentScreen, setCurrentScreen] = useState<ChargeCardEnum>(ChargeCardEnum.Payment);

  const renderScreen = useCallback(() => {
    switch (currentScreen) {
      case ChargeCardEnum.Payment:
        return <PaymentChargeCard setCurrentScreen={setCurrentScreen} />;
      case ChargeCardEnum.Print:
        return <PrintChargeCard />;
    }
  }, [currentScreen]);

  const onClickButtonPrint = (isPrint: boolean) => {
    setCurrentScreen(ChargeCardEnum.Tips);
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpen(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: `checkout.payAndComplete.title.${currentScreen}` })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal',
        width: 700,
        closable: currentScreen === ChargeCardEnum.Payment ? false : undefined,
        footer:
          currentScreen === ChargeCardEnum.Print ? (
            <div className="d-flex justify-content-end p-b-20 p-l-r-40">
              <CustomButton
                buttonProps={{
                  className: 'p-b-t-8 p-l-r-24 height-38 font-weight-700 salon__checkout__primary-outlined-btn',
                  onClick: () => onClickButtonPrint(false),
                }}
                type="cancel"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.print.noReceipt' })}
              />
              <CustomButton
                buttonProps={{
                  className: 'p-b-t-8 p-l-r-24 height-38 font-weight-700',
                  onClick: () => onClickButtonPrint(true),
                }}
                type="primary"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.title.print' })}
              />
            </div>
          ) : (
            false
          ),
      }}
    >
      {renderScreen()}
    </StyledModal>
  );
}

export default ChargeCard;
