export const MAIN_ROUTES = {
  SIGN_IN: 'signin',
  FORGOT_PASSWORD: 'forgot-password',
  //
  APPOINTMENT: '',
  CHECK_IN: 'check-in',
  CHECK_OUT: 'check-out',
  CUSTOMERS: 'customers',
  EMPLOYEES: 'employees',
  COMPENSATION: 'compensation',
  PROMOTIONS: 'promotions',
  REPORT: 'report',
  SETTING: 'setting',
  ONLINE_APPOINTMENT: 'online-appointment',
  BOOKING_ONLINE: 'booking-online',
  TIME_SHEET: 'time-sheet',
};

export const SETTING_ROUTES = {
  EXPENSE: 'expense',
  BUSINESS_SETTING: 'business-setting',
  BILLING_PAYMENT: 'billing-payment',
  DATA_SETTING: 'data-setting',
  CUSTOMER_RATE_SETTING: 'customer-rate-setting',
  TIP_SETTING: 'tip-setting',
  CHECK_OUT_SETTING: 'check-out-setting',
  TERMINAL_DEVICE_SETTING: 'terminal-device-setting',
  PRINTER_SETTING: 'printer-setting',
  CHECK_IN_SETTING: 'check-in-setting',
  SERVICE: 'service',
  TEXT_MESSAGE_SETTING: 'text-message-setting',
};
