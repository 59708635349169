import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Tooltip } from 'antd';
import { CollapseProps } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { appointmentApi, employeeApi, promotionApi, servicesApi, ticketApi } from '../../../apis';
import {
  Appointment,
  AppointmentStatusEnum,
  ChangeStatusAppointments,
  Customer,
  ServiceChargeDTO,
  ServiceItems,
  ServiceTaxDTO,
  TechnicianTurnDTO,
  TicketDTO,
  TicketDTOStatusEnum,
  TicketDisCountDTO,
  TicketMerchandiseDTO,
  TicketTransactionDTO,
} from '../../../apis/client-axios';
import AvatarDefault from '../../../assets/images/appointment/avatar-default.png';
import { SvgAddMoreDataIconSmall } from '../../../components/@svg/SvgAddMoreDataIconSmall';
import { SvgDoubleCheckIcon } from '../../../components/@svg/SvgDoubleCheckIcon';
import SvgMultiUserIcon from '../../../components/@svg/SvgMultiUserIcon';
import { SvgNextIcon } from '../../../components/@svg/SvgNextIcon';
import { SvgPrevIcon } from '../../../components/@svg/SvgPrevIcon';
import { SvgToolTipIcon } from '../../../components/@svg/SvgToolTipIcon';
import CheckInCard from '../../../components/CheckinCard';
import { FormInputSearch } from '../../../components/Form/FormInputSearch';
import FormWrap from '../../../components/FormWrap';
import NotificationError from '../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../components/HandleShowNotiSuccess';
import { ServiceItemSmall } from '../../../components/ServiceItemSmall';
import StyledCollapse from '../../../components/StyledCollapse';
import StyledHeader from '../../../components/StyledHeader';
import TechnicianItem from '../../../components/TechnicianItem';
import {
  getSessionStorageByName,
  getStorageByName,
  handleTruncateToTwoDecimal,
  removeSessionStorageByName,
} from '../../../utils';
import {
  QUERY_APPOINTMENTS_CHECKOUT,
  QUERY_LIST_TECHNICIAN_CHECKOUT,
  QUERY_PROMOTION_CHECKOUT,
  QUERY_SERVICES_CHECKOUT,
  QUERY_TICKET_PENDING_CHECKOUT,
} from '../../../utils/constant';
import { APPOINTMENT_SORT } from '../appointment/models';
import CheckoutBill from './CheckoutBill';
import DeleteTicket from './DeleteTicket';
import DiscountsAndRewards from './DiscountsAndRewards';
import GeneralService from './GeneralService';
import GiftCard from './Giftcard';
import Merchandise from './Merchandise';
import ModalCheckIn from './OptionTechnicianAndSearch/checkIn';
import ModalCustomer from './OptionTechnicianAndSearch/customer';
import ModalTechnicianList from './OptionTechnicianAndSearch/technicianList';
import PayAndComplete, { PayAndCompleteEnum } from './PayAndComplete';
import PendingTicket from './PendingTicket';
import TaxAndDeleteTicket from './TaxAndDeleteTicket';
import './checkOut.scss';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from './checkoutConstants';

interface Destination {
  droppableId: AppointmentStatusEnum;
  index: number;
}
export interface ITechnicianItem {
  id: number | string;
  defaultAvatar: string | undefined;
  name: string | undefined;
  phoneNumber: string | number | undefined;
  title: string | undefined;
  services?: IServicesItem[];
  skills?: number[];
}
export interface IServicesItem {
  description: string;
  id: number;
  name: string;
  price: number;
  supply: number;
  isMerchandise: boolean;
  isGeneralService: boolean;
  discount?: TicketDisCountDTO;
  totalDiscount?: number;
  isTaxable: boolean;
}
// export interface ICustomerItem {
//   id: number;
//   name: string;
//   phoneNumber: string;
//   salonId: number;
// }
export interface IMerchandise extends TicketMerchandiseDTO {
  name?: string;
  idDelete?: number;
  isTaxable?: boolean;
}
// }
export interface IMerchandiseCombine extends IMerchandise {
  merchandise?: ServiceItems;
}
export interface IGiftCard {
  id?: number;
  giftCardValue: number;
  giftCardCode: number;
}

export interface IAppointmentBill {
  id?: string | number;
  customer?: Customer;
  technician: ITechnicianItem[];
  appointmentId: number[];
  customerId: number;
  giftCards?: IGiftCard[];
  serviceTax?: ServiceTaxDTO;
  serviceCharge?: ServiceChargeDTO;
  deposit?: number;
  ticketMerchandise: IMerchandise[];
  status: TicketDTOStatusEnum;
  combineId?: number[];
}

export interface IFilter {
  page: number;
  sort: string;
  fullTextSearch: string;
  salonId: number | string;
  date?: Date;
}
export interface IServiceCharge {
  name: string;
  chargeValue: number;
}
export interface IFilterTechnician {
  isAll?: boolean;
  filterBy: 'WORK_TODAY' | 'CLOCKED_IN' | undefined;
  orderBy: 'NAME' | 'CLOCK_TIME' | undefined;
}
export interface IOpenModalCheckOut {
  openListTechnician: boolean;
  openListCustomer: boolean;
  openGeneralService: boolean;
  openGiftCard: boolean;
  openMerchandise: boolean;
  openTaxAndDeleteTicket: boolean;
  openDiscountsAndRewards: boolean;
  openListCheckIn: boolean;
  openPendingTicket: boolean;
  openDeleteTicket: boolean;
}
export interface IListIdDisable {
  listIdServices: number[] | string[];
  listIdMerchandise: number[] | string[];
  listIdTechnician: number[] | string[];
}
export interface IListIdTotalPrice {
  total: number;
  totalTip: number;
  totalDiscount: number;
  totalDiscountGeneralTicket: number;
  totalDiscountGeneralMerchandise: number;
  totalPayments: number;
  totalBalanceTotalPay: number;
  saleTax: number;
  serviceCharge: number;
  giftCard: number;
  //
  totalDiscountBirthday: number;
  totalDiscountWeekly: number;
  totalDiscountOccasion: number;
  generalTicketDiscount: number;
  generalItemDiscount: number;
  loyaltyReward: number;
  referralDiscount: number;
  //
  totalDiscountMerchandise: number;
  totalDiscountService: number;

  totalRedeeming: number;
  totalDeposit: number;

  // user are paying
  totalCash: number;
}

export interface IHandleSubmitCheckoutProps {
  status?: TicketDTOStatusEnum;
  ticketTransaction?: TicketTransactionDTO[];
  totalTip?: number;
  balanceToPay?: number;
}

export interface IGiftCardPaymentInformation {
  giftCardNumber: number;
  giftCardValueUse: number;
}

export interface IGiftCardPayment {
  totalValue: number;
  giftCards?: IGiftCardPaymentInformation[];
}

export interface PaymentInformation {
  cashDiscount?: number;
  amount?: number;
  tip?: number;
  ticketTransaction?: TicketTransactionDTO[];
  giftCardPayment?: IGiftCardPayment;
}

export interface ITipInformation {
  technicians?: ITechnicianItem[];
  tip?: number;
}

export const initialBill: IAppointmentBill = {
  customer: undefined,
  technician: [],
  appointmentId: [0],
  customerId: 0,
  giftCards: [],
  serviceTax: undefined,
  serviceCharge: undefined,
  deposit: 0,
  status: TicketDTOStatusEnum.Completed,
  ticketMerchandise: [],
};

const initialListTotal: IListIdTotalPrice = {
  total: 0,
  totalTip: 0,
  totalBalanceTotalPay: 0,
  totalDiscount: 0,
  totalPayments: 0,
  saleTax: 0,
  serviceCharge: 0,
  giftCard: 0,
  totalDiscountGeneralMerchandise: 0,
  totalDiscountGeneralTicket: 0,
  totalDiscountBirthday: 0,
  totalDiscountOccasion: 0,
  totalDiscountWeekly: 0,
  generalItemDiscount: 0,
  generalTicketDiscount: 0,
  loyaltyReward: 0,
  referralDiscount: 0,
  totalDiscountMerchandise: 0,
  totalDiscountService: 0,
  totalRedeeming: 0,
  totalDeposit: 0,
  totalCash: 0,
};
export const initialPromotion: PaymentInformation = {
  cashDiscount: 0,
  amount: 0,
  tip: 0,
  ticketTransaction: undefined,
  giftCardPayment: undefined,
};

export const DesiredStatusesItem = {
  CheckIn: 'Checked_in',
  BeingServed: 'Being_served',
  CheckOutBIll: 'checkout_bill',
  ListTechnician: 'list_technician',
  ListServices: 'list_services',
  ListCheckin: 'list_checkin',
};
const ListServicesItem = {
  GeneralService: 'generalService',
  GiftCard: 'giftCard',
  Merchandise: 'merchandise',
  DiscountAndRewards: 'discountAndRewards',
  TaxAndServiceCharge: 'taxAndServiceCharge',
};
export const desiredStatuses = [DesiredStatusesItem.CheckIn, DesiredStatusesItem.BeingServed];
const listServices = [
  ListServicesItem.GeneralService,
  ListServicesItem.GiftCard,
  ListServicesItem.Merchandise,
  ListServicesItem.DiscountAndRewards,
  ListServicesItem.TaxAndServiceCharge,
];

const CheckOutTenant = () => {
  const intl = useIntl();
  const location = useLocation();
  const salonId = getStorageByName('salonId') as string;
  const dataInitialAppointmentBill: IAppointmentBill | null = getSessionStorageByName('appointmentBill');
  const listServicesIdDisable: number[] = [];
  dataInitialAppointmentBill?.technician.forEach((tech) => {
    tech?.services?.forEach((service) => {
      listServicesIdDisable.push(service.id);
    });
  });
  const [isCollapse, setIsCollapse] = useState(false);
  const [openModal, setOpenModal] = useState<IOpenModalCheckOut>({
    openListTechnician: false,
    openListCustomer: false,
    openGeneralService: false,
    openGiftCard: false,
    openMerchandise: false,
    openTaxAndDeleteTicket: false,
    openDiscountsAndRewards: false,
    openListCheckIn: false,
    openPendingTicket: false,
    openDeleteTicket: false,
  });
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [searchPending, setSearchPending] = useState<string>('');
  const [listTechnician, setListTechnician] = useState<ITechnicianItem[]>([]);
  const [isOpenPayAndComplete, setIsOpenPayAndComplete] = useState<boolean>(false);
  const [idTechnician, setIdTechnician] = useState<number | string>(
    dataInitialAppointmentBill && dataInitialAppointmentBill?.technician?.length > 0
      ? (dataInitialAppointmentBill?.technician[0].id as number)
      : 0
  );
  const [listSkill, setListSkill] = useState<number[]>([]);
  const [indexServiceEdit, setIndexServiceEdit] = useState<number>(0);
  const [serviceApply, setServiceApply] = useState<number[]>(listServicesIdDisable);
  const [idService, setIdService] = useState<number | string>(0);
  const [serviceItem, setServiceItem] = useState<IServicesItem>();
  const [taxPercent, setTaxPercent] = useState<number>(0);
  const [taxDiscount, setTaxDiscount] = useState<number>(0);
  const [filterTechnician, setFilterTechnician] = useState<IFilterTechnician>({
    isAll: true,
    filterBy: undefined,
    orderBy: 'NAME',
  });
  const [isEditServicePrice, setIsEditServicePrice] = useState<boolean>(false);
  const [serviceCharge, setServiceCharge] = useState<IServiceCharge>({
    chargeValue: 0,
    name: '',
  });
  const [idAppointment, setIdAppointment] = useState<number[]>(dataInitialAppointmentBill?.appointmentId ?? []);
  const [discountsAndRewardsApplied, setDiscountsAndRewardsApplied] = useState<IDiscountsAndRewardsApplied>();
  const [paymentInformation, setPaymentInformation] = useState<PaymentInformation>(initialPromotion);
  const [idTechnicianDeposit, setIdTechnicianDeposit] = useState<number>(0);

  const [listIdDisable, setListIdDisable] = useState<IListIdDisable>({
    listIdServices: listServicesIdDisable,
    listIdTechnician: (dataInitialAppointmentBill?.technician.map((item) => item.id) as number[]) ?? [],
    listIdMerchandise:
      (dataInitialAppointmentBill?.ticketMerchandise.map((item) => item.merchandiseId) as number[]) ?? [],
  });

  const [listTotal, setListTotal] = useState<IListIdTotalPrice>(initialListTotal);
  //for column bill
  const [appointmentBill, setAppointmentBill] = useState<IAppointmentBill>(dataInitialAppointmentBill ?? initialBill);

  // state for tips and technicians after pay and complete
  const [tipInformation, setTipInformation] = useState<ITipInformation>();

  const { data: listTechnicians } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN_CHECKOUT, filterTechnician],
    staleTime: 1000,
    enabled: true,
    queryFn: () =>
      employeeApi.employeeControllerTechnician(
        undefined,
        undefined,
        filterTechnician.filterBy,
        filterTechnician.orderBy
      ),
  });

  const { data: listPromotion } = useQuery({
    queryKey: [QUERY_PROMOTION_CHECKOUT],
    staleTime: 1000,
    enabled: true,
    queryFn: () => promotionApi.promotionControllerGet(salonId),
    onSuccess: () => {
      if (!dataInitialAppointmentBill) {
        setServiceCharge({
          chargeValue: 0,
          name: '',
        });
      }
    },
  });

  const { data: listAppointments, refetch: handleRefetchAppointment } = useQuery({
    queryKey: [QUERY_APPOINTMENTS_CHECKOUT],
    staleTime: 1000,
    enabled: true,
    queryFn: () => appointmentApi.appointmentControllerGet(1, '', 0, APPOINTMENT_SORT, ''),
  });

  const { data: listDataServices } = useQuery({
    queryKey: [QUERY_SERVICES_CHECKOUT],
    staleTime: 1000,
    enabled: true,
    queryFn: () =>
      servicesApi.serviceControllerGetBySalon(0, undefined, undefined, undefined, localStorage.getItem('salonId')),
  });

  const { data: listTicketPending, refetch: handleRefetchTicketPending } = useQuery({
    queryKey: [QUERY_TICKET_PENDING_CHECKOUT, searchPending],
    enabled: true,
    staleTime: 1000,
    queryFn: () => ticketApi.ticketControllerGetTicketPending(1, undefined, undefined, searchPending),
  });

  const handleUpdateShowModal = (item: string) => {
    if (item === ListServicesItem.GeneralService) {
      if (appointmentBill.technician.length === 0) {
        return NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.error.serviceDrag' }) });
      } else {
        setOpenModal({
          ...openModal,
          openGeneralService: true,
        });
        setIdService(0);
        setServiceItem(undefined);
      }
    } else if (item === ListServicesItem.GiftCard) {
      setOpenModal({
        ...openModal,
        openGiftCard: true,
      });
    } else if (item === ListServicesItem.Merchandise) {
      setOpenModal({
        ...openModal,
        openMerchandise: true,
      });
    } else if (item === ListServicesItem.DiscountAndRewards) {
      setOpenModal({
        ...openModal,
        openDiscountsAndRewards: true,
      });
    } else {
      setOpenModal({
        ...openModal,
        openTaxAndDeleteTicket: true,
      });
    }
  };

  const mutationChangeStatusAppoiment = useMutation(
    (input: { dropAppoiment: Appointment; destination: Destination }) =>
      appointmentApi.appointmentControllerChangeStatusAppointment(
        String(input.dropAppoiment.id),
        { status: input.destination.droppableId },
        input.dropAppoiment.salonId,
        undefined
      ),
    {
      onSuccess: ({}) => {
        // handleRefetchlistAppointments();
      },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    if (appointmentBill.customerId === 0 && appointmentBill.technician.length === 0) {
      setIdAppointment([]);
    }
  }, [appointmentBill]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination }: { source: DraggableLocation; destination?: DraggableLocation | null } = result;
    if (!destination) return;
    const sourceDroppableId: AppointmentStatusEnum = source.droppableId as AppointmentStatusEnum;
    const destinationDroppableId: AppointmentStatusEnum = destination.droppableId as AppointmentStatusEnum;
    if (sourceDroppableId !== destinationDroppableId) {
      const dropAppoiment = appointments.filter((appointment) => appointment.status === source.droppableId)[
        source.index
      ];
      let dropTechnician: ITechnicianItem[] = [];
      const dropServicesItems: IServicesItem[] = [];
      // filter list technician
      if (source.droppableId === DesiredStatusesItem.ListTechnician) {
        dropTechnician = listTechnician.filter((appointment) => appointment.id === source.index);
      }

      // filter list service item
      if (
        source.droppableId !== DesiredStatusesItem.CheckIn &&
        source.droppableId !== DesiredStatusesItem.BeingServed &&
        source.droppableId !== DesiredStatusesItem.ListTechnician
      ) {
        listDataServices?.data?.content?.some((item) => {
          const found = item.serviceItems.find((serviceItem) => serviceItem.id === source.index);
          if (found) {
            dropServicesItems.push({
              ...found,
              isGeneralService: false,
              occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(found.id)
                ? handleTruncateToTwoDecimal((found.price * discountsAndRewardsApplied.occasionDiscount?.amount) / 100)
                : undefined,
              discountBirthday: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(found.id)
                ? discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Percent
                  ? handleTruncateToTwoDecimal(
                      (found.price * discountsAndRewardsApplied.birthdayDiscount?.amount) / 100
                    )
                  : discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                  ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                  : undefined
                : undefined,
              discountWeekly: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(found.id)
                ? discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(found.id)
                  ? discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Percent
                    ? handleTruncateToTwoDecimal(
                        (found.price * discountsAndRewardsApplied.weeklyDiscount?.amount) / 100
                      )
                    : discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                    : undefined
                  : undefined
                : undefined,
            } as IServicesItem);
            return true;
          }
          return false;
        });
      }
      const correctDestination: Destination = {
        droppableId: destinationDroppableId,
        index: destination.index,
      };
      const copyAppointment = [...appointments];
      const findIndex = copyAppointment.findIndex(
        (appointment) => appointment.id === (dropAppoiment && dropAppoiment.id)
      );
      //check dropzone is being served in col right
      if (destination.droppableId === DesiredStatusesItem.BeingServed) {
        if (
          (source.droppableId === DesiredStatusesItem.CheckIn &&
            destination.droppableId !== DesiredStatusesItem.BeingServed) ||
          source.droppableId === DesiredStatusesItem.BeingServed ||
          (destination.droppableId === DesiredStatusesItem.BeingServed &&
            (!dropAppoiment.services.length || !dropAppoiment.technician))
        ) {
          return;
        }

        if (findIndex > -1) {
          copyAppointment[findIndex] = { ...dropAppoiment, status: destination.droppableId as AppointmentStatusEnum };
          setAppointments(copyAppointment);
          mutationChangeStatusAppoiment.mutate({ dropAppoiment, destination: correctDestination }); // Fixed typo here
        }
      }

      // check dropzone is col mid
      if (destination.droppableId === DesiredStatusesItem.CheckOutBIll) {
        handleCreateBill(dropAppoiment, dropServicesItems, dropTechnician);
      }
    }
  };
  const checkListServiceInTechnician = (idTechnician: number, isRemoveService?: boolean, idService?: number) => {
    const tempTechnician = appointmentBill.technician.find((item) => item.id === idTechnician);
    if (isRemoveService) {
      setServiceApply(
        (tempTechnician?.services?.map((item) => item.id).filter((item) => item !== idService) as number[]) ?? []
      );
    } else {
      setServiceApply((tempTechnician?.services?.map((item) => item.id) as number[]) ?? []);
    }
  };

  const handleCreateBill = (
    dropAppoiment: Appointment,
    dropServicesItems: IServicesItem[],
    dropTechnician: ITechnicianItem[]
  ) => {
    // appointment drop into bill
    if (dropAppoiment) {
      let totalDeposit: number = 0;
      const findTechnician = listTechnicians?.data?.find((item) => item.id === dropAppoiment.technicianId);
      if (dropAppoiment.appointmentDeposits) {
        setIdTechnicianDeposit(dropAppoiment.technicianId);
        dropAppoiment.appointmentDeposits.map((item) => (totalDeposit += +item.money));
      }
      const idTechnicianUnassign: number = parseFloat(Math.random().toFixed(3)) * 10;
      checkListServiceInTechnician(dropAppoiment.id as number);
      const dataBill: IAppointmentBill = {
        customerId: dropAppoiment.customer.id,
        status: TicketDTOStatusEnum.Completed,
        appointmentId: [dropAppoiment.id],
        customer: dropAppoiment.customer,
        technician: [
          {
            id: dropAppoiment.technicianId ?? idTechnicianUnassign,
            name: dropAppoiment.technician?.name,
            defaultAvatar: dropAppoiment.technician?.defaultAvatar,
            phoneNumber: dropAppoiment.technician?.phoneNumber,
            title: dropAppoiment.technician?.title,
            services: dropAppoiment.services
              .filter((item) => !item.isMerchandise)
              .map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  price: item.price,
                  description: item.description,
                  supply: item.supply,
                  discount: {
                    occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(item.id)
                      ? {
                          discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                          type: discountsAndRewardsApplied?.occasionDiscount?.type,
                          moneyDiscount:
                            discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                              ? discountsAndRewardsApplied?.occasionDiscount?.amount
                              : handleTruncateToTwoDecimal(
                                  (item.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 1)) / 100
                                ),
                        }
                      : undefined,
                    weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(item.id)
                      ? {
                          discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                          type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                          moneyDiscount:
                            discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                              ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                              : handleTruncateToTwoDecimal(
                                  (item.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 1)) / 100
                                ),
                        }
                      : undefined,
                    birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(item.id)
                      ? {
                          discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                          type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                          moneyDiscount:
                            discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                              ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                              : handleTruncateToTwoDecimal(
                                  (item.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 1)) / 100
                                ),
                        }
                      : undefined,
                  },
                };
              }) as IServicesItem[],
            skills: findTechnician?.skills.map((item) => item.id) as number[],
          },
        ],
        ticketMerchandise: dropAppoiment.services
          .filter((item) => item.isMerchandise)
          .map((item) => {
            return {
              id: item.id,
              idDelete: parseFloat(Math.random().toFixed(3)) * 10,
              name: item.name,
              merchandiseId: item.id,
              merchandisePrice: item.price,
              isTaxable: item.isTaxable,
              totalDiscount: 0,
              discount: {
                occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
                  item.id as number
                )
                  ? {
                      discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                      type: discountsAndRewardsApplied?.occasionDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.occasionDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (item.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 1)) / 100
                            ),
                    }
                  : undefined,
                weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(item.id as number)
                  ? {
                      discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                      type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (item.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 1)) / 100
                            ),
                    }
                  : undefined,
                birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
                  item.id as number
                )
                  ? {
                      discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                      type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (item.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 1)) / 100
                            ),
                    }
                  : undefined,
                rewardBalance: discountsAndRewardsApplied?.rewardBalance?.amount
                  ? {
                      discountApply: discountsAndRewardsApplied?.rewardBalance?.amount,
                      type: discountsAndRewardsApplied?.rewardBalance?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.rewardBalance?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.rewardBalance?.amount
                          : 0,
                      point: discountsAndRewardsApplied?.rewardBalance?.points,
                    }
                  : undefined,
              },
            };
          }),
        deposit: totalDeposit,
      };
      if (listPromotion?.data.feeDiscount?.autoOption) {
        setServiceCharge({
          chargeValue: +listPromotion?.data.feeDiscount?.autoOption,
          name: listPromotion?.data.feeDiscount?.feeName,
        });
      }
      setIdTechnician(dropAppoiment.technicianId ?? idTechnicianUnassign);
      setIdAppointment([dropAppoiment.id]);
      setListSkill((findTechnician?.skills.map((item) => item.id) as number[]) ?? []);
      setServiceApply(
        (dropAppoiment.services.filter((item) => !item.isMerchandise).map((item) => item.id) as number[]) ?? []
      );
      setListIdDisable({
        listIdServices: dropAppoiment.services.map((item) => item.id),
        listIdTechnician: [dropAppoiment.technicianId],
        listIdMerchandise: dropAppoiment.services.filter((item) => item.isMerchandise).map((itemMap) => itemMap.id),
      });
      handleGetTotal(dataBill);
      setAppointmentBill(dataBill);
    }
    // technician drop into bill
    if (!!dropTechnician.length && appointmentBill) {
      setIdTechnician(dropTechnician[0].id);
      setListSkill(dropTechnician[0].skills ?? []);
      setServiceApply([]);
      setListIdDisable({
        ...listIdDisable,
        listIdTechnician: [...(listIdDisable.listIdTechnician as number[]), dropTechnician[0].id as number],
      });
      const dataGrpDropTechnician = dropTechnician.concat(appointmentBill.technician);
      setAppointmentBill({
        ...appointmentBill,
        technician: dataGrpDropTechnician,
      });
      handleGetTotal({
        ...appointmentBill,
        technician: dataGrpDropTechnician,
      });
    }

    if (!!dropServicesItems.length) {
      if (idTechnician === 0 && !dropServicesItems[0].isMerchandise) {
        return NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.error.serviceDrag' }) });
      }
      setServiceApply((prev) => prev.concat(dropServicesItems.map((item) => item.id)));
      const dataMapDropServicesItems = dropServicesItems
        .filter((item) => item.isMerchandise)
        .map((item) => {
          return {
            name: item.name,
            id: item.id,
            idDelete: parseFloat(Math.random().toFixed(3)) * 10,
            merchandisePrice: item.price,
            merchandiseId: item.id,
            isTaxable: item.isTaxable,
            discount: {
              occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(item.id)
                ? {
                    discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                    type: discountsAndRewardsApplied?.occasionDiscount?.type,
                    moneyDiscount:
                      discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                        ? discountsAndRewardsApplied?.occasionDiscount?.amount
                        : handleTruncateToTwoDecimal(
                            (item.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 1)) / 100
                          ),
                  }
                : undefined,
              weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(item.id)
                ? {
                    discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                    type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                    moneyDiscount:
                      discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                        ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                        : handleTruncateToTwoDecimal(
                            (item.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 1)) / 100
                          ),
                  }
                : undefined,
              birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(item.id)
                ? {
                    discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                    type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                    moneyDiscount:
                      discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                        ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                        : handleTruncateToTwoDecimal(
                            (item.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 1)) / 100
                          ),
                  }
                : undefined,
            },
          };
        });
      const dataMerchandise = appointmentBill.ticketMerchandise
        ? appointmentBill.ticketMerchandise.concat(dataMapDropServicesItems)
        : dataMapDropServicesItems;
      if (appointmentBill && appointmentBill.technician.length !== 0) {
        setListIdDisable({
          ...listIdDisable,
          listIdMerchandise:
            dropServicesItems.filter((item) => item.isMerchandise).length > 0
              ? [...(listIdDisable.listIdMerchandise as number[]), dropServicesItems[0].id as number]
              : listIdDisable.listIdMerchandise,
          listIdServices:
            dropServicesItems.filter((item) => !item.isMerchandise).length > 0
              ? [...(listIdDisable.listIdServices as number[]), dropServicesItems[0].id as number]
              : listIdDisable.listIdServices,
        });
        const dataMapTechnician = appointmentBill.technician.map((item) => {
          const dataTechnicianWithDiscount = dropServicesItems
            .filter((item) => !item.isMerchandise)
            .map((itemServiceMap) => {
              return {
                ...itemServiceMap,
                discount: {
                  occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
                    itemServiceMap.id
                  )
                    ? {
                        discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                        type: discountsAndRewardsApplied?.occasionDiscount?.type,
                        moneyDiscount:
                          discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                            ? discountsAndRewardsApplied?.occasionDiscount?.amount
                            : handleTruncateToTwoDecimal(
                                (itemServiceMap.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 1)) /
                                  100
                              ),
                      }
                    : undefined,
                  weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(itemServiceMap.id)
                    ? {
                        discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                        type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                        moneyDiscount:
                          discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                            ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                            : handleTruncateToTwoDecimal(
                                (itemServiceMap.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 1)) / 100
                              ),
                      }
                    : undefined,
                  birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
                    itemServiceMap.id
                  )
                    ? {
                        discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                        type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                        moneyDiscount:
                          discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                            ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                            : handleTruncateToTwoDecimal(
                                (itemServiceMap.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 1)) /
                                  100
                              ),
                      }
                    : undefined,
                },
              };
            });
          return {
            ...item,
            services:
              idTechnician === item.id
                ? item.services && item.services.length > 0
                  ? item.services.concat(dataTechnicianWithDiscount)
                  : dataTechnicianWithDiscount
                : item.services,
          };
        });
        const dataUpdateAppointment = {
          ...appointmentBill,
          technician: dataMapTechnician,
          ticketMerchandise: dataMerchandise,
        };
        setAppointmentBill(dataUpdateAppointment);
        handleGetTotal(dataUpdateAppointment);
      } else {
        setListIdDisable({
          ...listIdDisable,
          listIdMerchandise:
            dropServicesItems.filter((item) => item.isMerchandise).length > 0
              ? [...(listIdDisable.listIdMerchandise as number[]), dropServicesItems[0].id as number]
              : listIdDisable.listIdMerchandise,
        });
        const dataUpdateAppointmentMerchandise = {
          ...appointmentBill,
          ticketMerchandise: dataMerchandise,
        };
        setAppointmentBill(dataUpdateAppointmentMerchandise);
        handleGetTotal(dataUpdateAppointmentMerchandise);
      }
    }
  };

  const handleGetTotal = (dataMap: IAppointmentBill) => {
    let totalService: number = 0;
    let totalServiceWithTax: number = 0;
    let giftCardValue: number = 0;
    let totalMerchandise: number = 0;
    let totalMerchandiseWithTax: number = 0;
    let totalBalanceTotalPay: number = 0;
    let totalDiscountGeneralMerchandise: number = 0;
    let totalDiscountGeneralTicket: number = 0;
    let totalDiscountBirthdayService: number = 0;
    let totalDiscountWeeklyService: number = 0;
    let totalDiscountOccasionService: number = 0;
    let totalDiscountBirthdayMerchandise: number = 0;
    let totalDiscountWeeklyMerchandise: number = 0;
    let totalDiscountOccasionMerchandise: number = 0;
    let totalDiscountReferral: number = 0;

    let totalDiscountMerchandise: number = 0;
    let totalDiscountServices: number = 0;

    let totalRedeeming: number = 0;
    let totalDeposit: number = 0;
    // services
    dataMap.technician.map((item) =>
      item.services?.forEach((itemService) => {
        totalService += +itemService.price;
      })
    );
    dataMap.technician.forEach((item) => {
      item.services?.forEach((itemService) => {
        if (itemService.isTaxable) {
          totalServiceWithTax += +itemService.price;
        }
      });
    });

    dataMap.technician.forEach((item) =>
      item.services?.forEach((itemService) => {
        if (itemService.discount?.birthdayDiscount?.moneyDiscount) {
          totalDiscountBirthdayService += +itemService.discount?.birthdayDiscount.moneyDiscount;
        }
      })
    );
    dataMap.technician.forEach((item) =>
      item.services?.forEach((itemService) => {
        if (itemService.discount?.weeklyDiscount?.moneyDiscount) {
          totalDiscountWeeklyService += +itemService.discount?.weeklyDiscount.moneyDiscount;
        }
      })
    );
    dataMap.technician.forEach((item) => {
      item.services?.forEach((itemService) => {
        if (itemService.discount?.occasionDiscount?.moneyDiscount) {
          totalDiscountOccasionService += +itemService.discount?.occasionDiscount.moneyDiscount;
        }
      });
    });

    // merchandise
    dataMap.ticketMerchandise?.forEach((item) => {
      totalMerchandise += +item.merchandisePrice;
    });
    dataMap.ticketMerchandise?.forEach((item) => {
      if (item.isTaxable) {
        totalMerchandiseWithTax += +item.merchandisePrice;
      }
    });

    dataMap.ticketMerchandise?.forEach((item) => {
      if (item.discount?.birthdayDiscount?.moneyDiscount) {
        totalDiscountBirthdayMerchandise += +item.discount?.birthdayDiscount?.moneyDiscount;
      }
    });
    dataMap.ticketMerchandise?.forEach((item) => {
      if (item.discount?.weeklyDiscount?.moneyDiscount) {
        totalDiscountWeeklyMerchandise += +item.discount?.weeklyDiscount?.moneyDiscount;
      }
    });
    dataMap.ticketMerchandise?.forEach((item) => {
      if (item.discount?.occasionDiscount?.moneyDiscount) {
        totalDiscountOccasionMerchandise += +item.discount?.occasionDiscount?.moneyDiscount;
      }
    });

    const taxDiscountValue =
      taxPercent > 0
        ? handleTruncateToTwoDecimal(totalMerchandiseWithTax > 0 ? (totalMerchandiseWithTax * taxPercent) / 100 : 0)
        : 0;

    const totalServiceCharge =
      serviceCharge.chargeValue > 0
        ? handleTruncateToTwoDecimal((totalServiceWithTax * serviceCharge.chargeValue) / 100)
        : 0;

    if (dataMap && dataMap.giftCards) {
      dataMap.giftCards.forEach((item) => {
        giftCardValue += item.giftCardValue;
      });
    }

    if (discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount) {
      totalDiscountGeneralMerchandise =
        discountsAndRewardsApplied?.generalMerchandiseDiscount.type === DiscountTypeEnum.Percent
          ? handleTruncateToTwoDecimal(
              (totalMerchandise * discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount) / 100
            )
          : discountsAndRewardsApplied?.generalMerchandiseDiscount.type === DiscountTypeEnum.Dollar
          ? discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount
          : 0;
    }
    if (discountsAndRewardsApplied?.generalTicketDiscount?.amount) {
      totalDiscountGeneralTicket =
        discountsAndRewardsApplied?.generalTicketDiscount.type === DiscountTypeEnum.Percent
          ? handleTruncateToTwoDecimal(
              ((totalService + totalMerchandise + giftCardValue) *
                discountsAndRewardsApplied?.generalTicketDiscount?.amount) /
                100
            )
          : discountsAndRewardsApplied?.generalTicketDiscount.type === DiscountTypeEnum.Dollar
          ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
          : 0;
    }
    if (discountsAndRewardsApplied?.referralDiscount?.amount) {
      totalDiscountReferral = +discountsAndRewardsApplied?.referralDiscount?.amount;
    }

    const totalDiscount: number =
      totalDiscountGeneralMerchandise +
        totalDiscountGeneralTicket +
        totalDiscountBirthdayService +
        totalDiscountWeeklyService +
        totalDiscountOccasionService +
        totalDiscountBirthdayMerchandise +
        totalDiscountWeeklyMerchandise +
        totalDiscountReferral +
        totalDiscountOccasionMerchandise ?? 0;

    const totalPayment =
      totalService + totalMerchandise + giftCardValue + taxDiscountValue + totalServiceCharge - totalDiscount > 0
        ? totalService + totalMerchandise + giftCardValue + taxDiscountValue + totalServiceCharge - totalDiscount
        : 0;
    if (dataMap.deposit) {
      totalDeposit = dataMap.deposit;
    }

    totalDiscountServices =
      totalDiscountBirthdayService + totalDiscountWeeklyService + totalDiscountOccasionService ?? 0;
    totalDiscountMerchandise =
      totalDiscountBirthdayMerchandise + totalDiscountWeeklyMerchandise + totalDiscountOccasionMerchandise ?? 0;

    if (discountsAndRewardsApplied?.rewardBalance?.amount) {
      totalRedeeming =
        discountsAndRewardsApplied?.rewardBalance.type === DiscountTypeEnum.Percent
          ? handleTruncateToTwoDecimal((totalPayment * discountsAndRewardsApplied?.rewardBalance?.amount) / 100)
          : discountsAndRewardsApplied?.rewardBalance.type === DiscountTypeEnum.Dollar
          ? discountsAndRewardsApplied?.rewardBalance?.amount
          : 0;
    }

    const giftCardPaymentValue: number = paymentInformation.giftCardPayment?.totalValue ?? 0;

    if (totalPayment) {
      const tempBalanceTotal: number =
        totalPayment - totalRedeeming - totalDeposit - listTotal.totalCash - giftCardPaymentValue;
      totalBalanceTotalPay = tempBalanceTotal > 0 ? tempBalanceTotal : 0;
    } else {
      totalBalanceTotalPay = 0;
    }

    setListTotal({
      ...listTotal,
      total: totalService + totalMerchandise + giftCardValue,
      totalPayments: totalPayment,
      totalBalanceTotalPay: totalBalanceTotalPay,
      saleTax: taxDiscountValue,
      serviceCharge: totalServiceCharge,
      totalDiscountGeneralMerchandise: totalDiscountGeneralMerchandise,
      totalDiscountGeneralTicket: totalDiscountGeneralTicket,
      totalDiscount: totalDiscount,
      totalDiscountBirthday: totalDiscountBirthdayMerchandise + totalDiscountBirthdayService,
      totalDiscountWeekly: totalDiscountWeeklyMerchandise + totalDiscountWeeklyService,
      totalDiscountOccasion: totalDiscountOccasionMerchandise + totalDiscountOccasionService,
      generalItemDiscount: totalDiscountGeneralMerchandise,
      generalTicketDiscount: totalDiscountGeneralTicket,
      referralDiscount: totalDiscountReferral,
      totalDiscountService: totalDiscountServices,
      totalDiscountMerchandise: totalDiscountMerchandise,
      totalRedeeming: totalRedeeming,
      totalDeposit: totalDeposit,
    });

    setTaxDiscount(taxDiscountValue);
  };

  const handleRemoveTechnician = (idDeleteTechnician: number) => {
    const technicianRemove = appointmentBill.technician.filter((item) => item.id !== idDeleteTechnician);
    if (technicianRemove) {
      appointmentBill.technician.some((item) => {
        if (item.id === idDeleteTechnician) {
          const updateTechnician = {
            ...appointmentBill,
            technician: technicianRemove,
          };
          setAppointmentBill({
            ...updateTechnician,
            deposit: idTechnicianDeposit !== idDeleteTechnician ? appointmentBill.deposit : 0,
          });
          handleGetTotal({
            ...updateTechnician,
            deposit: idTechnicianDeposit !== idDeleteTechnician ? appointmentBill.deposit : 0,
          });
          if (updateTechnician.technician.length > 0) {
            setIdTechnician(updateTechnician.technician[0].id);
            setListSkill(updateTechnician.technician[0].skills ?? []);
          } else {
            setIdTechnician(0);
            setListSkill([]);
          }

          setListIdDisable({
            ...listIdDisable,
            listIdServices: handleSortListServices(
              listIdDisable.listIdServices as number[],
              item.services?.map((item) => item.id) as number[]
            ),
            listIdTechnician: technicianRemove.map((item) => item.id) as number[],
          });
          setServiceApply([]);
        }
        return false;
      });
      appointments.filter((item) => {
        if (item.technicianId === idDeleteTechnician) {
          return setIdAppointment((preState) => preState.filter((itemApp: number) => itemApp !== item.id));
        }
        return item;
      });
      setIdTechnicianDeposit(0);
    }
  };

  const handleSortListServices = (arrBase: number[], arrDelete: number[]) => {
    const arrValue: boolean[] = [];
    const arrDiff: number[] = [];
    for (let i = 0; i < arrBase.length; i++) {
      arrValue[arrBase[i]] = true;
    }
    if (arrDelete && !!arrDelete.length) {
      for (let i = 0; i < arrDelete.length; i++) {
        if (arrValue[arrDelete[i]]) {
          delete arrValue[arrDelete[i]];
        } else {
          arrValue[arrDelete[i]] = true;
        }
      }
    }
    for (let value in arrValue) {
      arrDiff.push(+value);
    }
    return arrDiff;
  };
  const handleRemoveServices = (idDeleteServices: number, idTechnician: number) => {
    setIdTechnician(idTechnician);
    appointmentBill.technician.some((item) => {
      if (item.id === idTechnician) {
        const found = item.services?.filter((serviceItem) => serviceItem.id !== idDeleteServices);

        if (found) {
          const updateService = {
            ...appointmentBill,
            technician: appointmentBill.technician.map((item) => {
              return {
                ...item,
                services: idTechnician === item.id ? found : item.services,
              };
            }),
          };
          setListIdDisable({
            ...listIdDisable,
            listIdServices: found.map((item) => item.id),
          });
          checkListServiceInTechnician(idTechnician as number, true, idDeleteServices);
          setAppointmentBill(updateService);
          handleGetTotal(updateService);
          return true;
        }
      }
      return false;
    });
  };
  const handleRemoveServiceDiscount = (
    idDeleteServices: number,
    idTechnician: number,
    isRemoveWeekly: boolean,
    isRemoveBirthday: boolean,
    isRemoveOccasion: boolean
  ) => {
    appointmentBill.technician.some((item) => {
      if (item.id === idTechnician) {
        const updateService = {
          ...appointmentBill,
          technician: appointmentBill.technician.map((item) => {
            return {
              ...item,
              services:
                idTechnician === item.id
                  ? item.services?.map((itemFound) => {
                      return {
                        ...itemFound,
                        discount: {
                          ...itemFound.discount,
                          weeklyDiscount: isRemoveWeekly
                            ? idDeleteServices === itemFound.id
                              ? undefined
                              : itemFound.discount?.weeklyDiscount
                            : itemFound.discount?.weeklyDiscount,
                          birthdayDiscount: isRemoveBirthday
                            ? idDeleteServices === itemFound.id
                              ? undefined
                              : itemFound.discount?.birthdayDiscount
                            : itemFound.discount?.birthdayDiscount,
                          occasionDiscount: isRemoveOccasion
                            ? idDeleteServices === itemFound.id
                              ? undefined
                              : itemFound.discount?.occasionDiscount
                            : itemFound.discount?.occasionDiscount,
                        },
                      };
                    })
                  : item.services,
            };
          }),
        };
        setAppointmentBill(updateService);
        handleGetTotal(updateService);
        return true;
      }
      return false;
    });
  };

  const handleRemoveMerchandiseDiscount = (
    idDeleteMerchandise: number | undefined,
    isRemoveWeekly: boolean,
    isRemoveBirthday: boolean,
    isRemoveOccasion: boolean
  ) => {
    if (appointmentBill.ticketMerchandise && idDeleteMerchandise) {
      const ticketMerchandiseDelete = appointmentBill.ticketMerchandise.map((item) => {
        if (item.merchandiseId === idDeleteMerchandise) {
          return {
            ...item,
            discount: {
              ...item.discount,
              weeklyDiscount: isRemoveWeekly
                ? item.merchandiseId === idDeleteMerchandise
                  ? undefined
                  : item.discount?.weeklyDiscount
                : item.discount?.weeklyDiscount,
              birthdayDiscount: isRemoveBirthday
                ? item.merchandiseId === idDeleteMerchandise
                  ? undefined
                  : item.discount?.birthdayDiscount
                : item.discount?.birthdayDiscount,
              occasionDiscount: isRemoveOccasion
                ? item.merchandiseId === idDeleteMerchandise
                  ? undefined
                  : item.discount?.occasionDiscount
                : item.discount?.occasionDiscount,
            },
          };
        }
        return item;
      });
      const updateMerchandise = {
        ...appointmentBill,
        ticketMerchandise: ticketMerchandiseDelete,
      };
      setAppointmentBill(updateMerchandise);
      handleGetTotal(updateMerchandise);
    }
  };

  const handleRemoveMerchandise = (idDeleteMerchandise: number | undefined) => {
    if (appointmentBill.ticketMerchandise && idDeleteMerchandise) {
      const ticketMerchandiseDelete = appointmentBill.ticketMerchandise.filter(
        (merchandise) => merchandise.idDelete !== idDeleteMerchandise
      );
      if (ticketMerchandiseDelete) {
        setListIdDisable({
          ...listIdDisable,
          listIdMerchandise: ticketMerchandiseDelete.map((item) => item.merchandiseId) as number[],
        });
        const updateMerchandise = {
          ...appointmentBill,
          ticketMerchandise: ticketMerchandiseDelete,
        };
        setAppointmentBill(updateMerchandise);
        handleGetTotal(updateMerchandise);
      }
    }
  };
  const handleRemoveCustomer = () => {
    if (appointmentBill.customer) {
      const updateCustomer = {
        ...appointmentBill,
        customer: undefined,
        customerId: 0,
      };
      setAppointmentBill(updateCustomer);
      handleGetTotal(updateCustomer);
      setDiscountsAndRewardsApplied({
        ...discountsAndRewardsApplied,
        birthdayDiscount: undefined,
        referralDiscount: undefined,
        rewardBalance: undefined,
      });
    }
  };
  const handleRemoveGiftCard = (idGiftCard: number) => {
    if (!!appointmentBill.giftCards?.length) {
      const updateCustomer = {
        ...appointmentBill,
        giftCards: appointmentBill.giftCards.filter((item) => item.id !== idGiftCard) as IGiftCard[],
      };
      setAppointmentBill(updateCustomer);
      handleGetTotal(updateCustomer);
    }
  };

  const resetBill = () => {
    setListIdDisable({
      listIdServices: [],
      listIdTechnician: [],
      listIdMerchandise: [],
    });
    setAppointmentBill(initialBill);
    setDiscountsAndRewardsApplied(undefined);
    setIdTechnician(0);
    setListSkill([]);
    setIdAppointment([]);
    setIdTechnicianDeposit(0);
    setIsEditServicePrice(false);
    setTaxPercent(0);
    setTaxDiscount(0);
    setServiceCharge({
      chargeValue: 0,
      name: '',
    });
    setServiceApply([]);
    setListTotal(initialListTotal);
    setPaymentInformation(initialPromotion);
  };
  const createCheckoutMutation = useMutation((ticketDto: TicketDTO) => ticketApi.ticketControllerCreate(ticketDto), {
    onSuccess: ({ data }) => {
      // if (paymentInformation.ticketTransaction?.every((ticket) => !ticket.tipMoney)) {
      if (!tipInformation?.tip) {
        resetBill();
        if (data.status !== TicketDTOStatusEnum.Hold) {
          setIsOpenPayAndComplete(false);
        }
      }
      if (data.status === TicketDTOStatusEnum.Hold) {
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.noti.pending' }) });
      } else {
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.noti.saleCompleted' }) });
      }
      handleRefetchAppointment();
      handleRefetchTicketPending();
      removeSessionStorageByName('appointmentBill');
    },
    onError: (error) => {
      resetBill();
    },
  });

  const handleChangeOpenPayAndComplete = (type: PayAndCompleteEnum, isOpen: boolean) => {
    setIsOpenPayAndComplete(isOpen);

    if (type === PayAndCompleteEnum.Tips && !isOpen) {
      resetBill();
    }
  };

  const handleSubmitCheckout = (submitParams?: IHandleSubmitCheckoutProps) => {
    const data: TicketDTO = {
      appointmentId: appointmentBill.appointmentId,
      combineId: appointmentBill.combineId,
      customerId: appointmentBill.customerId,
      giftCards: appointmentBill.giftCards,
      serviceTax: {
        moneyDiscount: listTotal.saleTax,
        percentDiscount: taxPercent,
      },
      serviceCharge: {
        moneyDiscount: listTotal.serviceCharge,
        name: serviceCharge.name,
        percentDiscount: serviceCharge.chargeValue,
      },
      total: listTotal.total ?? 0,
      totalDiscount: listTotal.totalDiscount ?? 0,
      totalPayment: listTotal.totalPayments ?? 0,
      discount: {
        birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
              type: discountsAndRewardsApplied?.birthdayDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalDiscountBirthday),
            }
          : undefined,
        generalItemDiscount: discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount,
              type: discountsAndRewardsApplied?.generalMerchandiseDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.generalItemDiscount),
            }
          : undefined,
        generalTicketDiscount: discountsAndRewardsApplied?.generalTicketDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.generalTicketDiscount?.amount,
              type: discountsAndRewardsApplied?.generalTicketDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.generalTicketDiscount),
            }
          : undefined,
        occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
              type: discountsAndRewardsApplied?.occasionDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalDiscountOccasion),
            }
          : undefined,
        referralDiscount: discountsAndRewardsApplied?.referralDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.referralDiscount?.amount,
              type: discountsAndRewardsApplied?.referralDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.referralDiscount),
              referralCode: discountsAndRewardsApplied.referralDiscount.referralCode,
            }
          : undefined,
        weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
              type: discountsAndRewardsApplied?.weeklyDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalDiscountWeekly),
            }
          : undefined,
        loyaltyReward: undefined,
        rewardBalance: discountsAndRewardsApplied?.rewardBalance?.amount
          ? {
              discountApply: discountsAndRewardsApplied.rewardBalance.amount,
              type: discountsAndRewardsApplied?.rewardBalance?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalRedeeming),
              point: discountsAndRewardsApplied.rewardBalance.points,
              isCustomAmount: !!discountsAndRewardsApplied.rewardBalance.type,
            }
          : undefined,
        // rewardUse: discountsAndRewardsApplied?.rewardBalance?.points ?? 0,
      },
      deposit: appointmentBill.deposit ?? 0,
      balanceToPay: submitParams?.balanceToPay ?? listTotal.totalBalanceTotalPay ?? 0,
      status: submitParams?.status || appointmentBill.status,
      technicianTurns: appointmentBill.technician.map((item: ITechnicianItem) => {
        return {
          technicianId: item.id,
          serviceTurns:
            item.services &&
            item.services.map((itemService: IServicesItem) => {
              let totalDiscount: number = 0;
              if (itemService && itemService.discount) {
                if (itemService.discount.birthdayDiscount?.moneyDiscount) {
                  totalDiscount += +itemService.discount.birthdayDiscount?.moneyDiscount;
                }
                if (itemService.discount.weeklyDiscount?.moneyDiscount) {
                  totalDiscount += +itemService.discount.weeklyDiscount?.moneyDiscount;
                }
                if (itemService.discount.occasionDiscount?.moneyDiscount) {
                  totalDiscount += +itemService.discount.occasionDiscount?.moneyDiscount;
                }
              }
              return {
                isGeneralService: itemService.isGeneralService,
                price: itemService.price,
                supply: itemService.supply,
                serviceId: itemService.id,
                discount: itemService.discount,
                totalDiscount: totalDiscount,
              };
            }),
        };
      }) as TechnicianTurnDTO[],
      ticketMerchandise:
        appointmentBill.ticketMerchandise &&
        appointmentBill.ticketMerchandise.map((item) => {
          let totalDiscount: number = 0;
          if (item && item.discount) {
            if (item.discount.birthdayDiscount?.moneyDiscount) {
              totalDiscount += +item.discount.birthdayDiscount?.moneyDiscount;
            }
            if (item.discount.weeklyDiscount?.moneyDiscount) {
              totalDiscount += +item.discount.weeklyDiscount?.moneyDiscount;
            }
            if (item.discount.occasionDiscount?.moneyDiscount) {
              totalDiscount += +item.discount.occasionDiscount?.moneyDiscount;
            }
          }
          return {
            merchandisePrice: item.merchandisePrice,
            merchandiseId: item.merchandiseId,
            discount: item.discount,
            totalDiscount: totalDiscount,
          };
        }),
      ticketTransaction: submitParams?.ticketTransaction || paymentInformation.ticketTransaction || [],
      totalTip: submitParams?.totalTip || paymentInformation.tip || 0,
    };
    createCheckoutMutation.mutate(data);
  };

  const handleClickOption = (
    id: string | number,
    sourceDroppableId: typeof DesiredStatusesItem[keyof typeof DesiredStatusesItem] | string
  ) => {
    if (
      ((sourceDroppableId === DesiredStatusesItem.ListTechnician &&
        !!(listIdDisable.listIdTechnician as number[]).includes(+id)) ||
        (idAppointment.includes(+id) &&
          (sourceDroppableId === DesiredStatusesItem.CheckIn ||
            sourceDroppableId === DesiredStatusesItem.BeingServed)) ||
        !!(listIdDisable.listIdMerchandise as number[]).includes(+id)) &&
      !!(serviceApply as number[]).includes(+id)
    )
      return;
    onDragEnd({
      combine: null,
      destination: {
        droppableId: DesiredStatusesItem.CheckOutBIll,
        index: 0,
      },
      reason: 'DROP',
      mode: 'FLUID',
      draggableId: id.toString(),
      type: 'DEFAULT',
      source: {
        droppableId: sourceDroppableId,
        index: Number(id),
      },
    });
  };

  const checkDisableService = (item: ServiceItems) => {
    if (item.isMerchandise && !(listIdDisable.listIdMerchandise as number[]).includes(+item.id)) {
      return false;
    } else {
      const valueCheck: boolean =
        !!(serviceApply as number[]).includes(+item.id) ||
        !!(listIdDisable.listIdMerchandise as number[]).includes(+item.id);
      if (!Number.isInteger(idTechnician)) {
        return valueCheck;
      } else {
        return valueCheck || (!listSkill.includes(+item.id) && !!listIdDisable.listIdTechnician.length);
      }
    }
  };

  const technicianItems: CollapseProps['items'] = [
    {
      key: 1,
      label: (
        <span className="font-size-16 font-weight-600 color-292F33">
          {intl.formatMessage({ id: 'checkout.technician' })}
          {' (' + listTechnician.length + ')'}
        </span>
      ),
      children: (
        <Droppable
          droppableId={DesiredStatusesItem.ListTechnician}
          key={DesiredStatusesItem.ListTechnician}
          direction="horizontal"
          isDropDisabled={true}
        >
          {(providedDrop: DroppableProvided, snapshotDrop: DroppableStateSnapshot) => (
            <React.Fragment>
              <div ref={providedDrop.innerRef} {...providedDrop.droppableProps} {...snapshotDrop} className="d-none" />
              <div className="d-flex align-items-center flex-wrap gap-8">
                <div
                  className="salon__checkout-edit-technician"
                  onClick={() => {
                    setOpenModal({
                      ...openModal,
                      openListTechnician: true,
                    });
                  }}
                >
                  <SvgMultiUserIcon />
                  <span className="salon__checkout-edit-technician-title">
                    {intl.formatMessage({ id: 'checkout.options' })}
                  </span>
                </div>
                {listTechnician.map((item, index) => (
                  <Draggable
                    draggableId={item.id.toString()}
                    index={+item.id}
                    key={item.id}
                    isDragDisabled={!!(listIdDisable.listIdTechnician as number[]).includes(+item.id)}
                  >
                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                      <React.Fragment>
                        <div
                          ref={providedDrag?.innerRef}
                          {...providedDrag?.draggableProps}
                          {...providedDrag?.dragHandleProps}
                          {...snapshotDrag}
                          className={
                            !!(listIdDisable.listIdTechnician as number[]).includes(+item.id)
                              ? 'salon__checkout-disable-draggable'
                              : ''
                          }
                          onClick={() =>
                            !!(listIdDisable.listIdTechnician as number[]).includes(+item.id)
                              ? null
                              : handleClickOption(item.id, DesiredStatusesItem.ListTechnician)
                          }
                        >
                          {!snapshotDrag.isDropAnimating && (
                            <TechnicianItem
                              imgSrc={item.defaultAvatar}
                              imgSrcTitle={item.name}
                              technicianName={item.name}
                              onClick={() => {}}
                            />
                          )}
                        </div>
                        {snapshotDrag.isDragging && (
                          <TechnicianItem
                            imgSrc={item.defaultAvatar}
                            imgSrcTitle={item.name}
                            technicianName={item.name}
                            onClick={() => {}}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Draggable>
                ))}
              </div>
            </React.Fragment>
          )}
        </Droppable>
      ),
    },
  ];
  const serviceItems: CollapseProps['items'] = listDataServices?.data?.content?.map((service, index) => {
    return {
      key: index + 1,
      label: (
        <span className="font-size-16 font-weight-600 color-292F33">
          {service.name}
          {' (' + service.serviceItems.length + ')'}
        </span>
      ),
      children: (
        <Droppable droppableId={service.name} key={service.name} direction="horizontal" isDropDisabled={true}>
          {(providedDrop: DroppableProvided, snapshotDrop: DroppableStateSnapshot) => (
            <React.Fragment>
              <div ref={providedDrop.innerRef} {...providedDrop.droppableProps} {...snapshotDrop} />
              <div className="d-flex align-items-center flex-wrap gap-8">
                {service.serviceItems.map((item) => (
                  <Draggable
                    draggableId={item.id.toString()}
                    index={item.id}
                    key={item.id}
                    isDragDisabled={checkDisableService(item)}
                  >
                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                      <React.Fragment>
                        <div
                          ref={providedDrag?.innerRef}
                          {...providedDrag?.draggableProps}
                          {...providedDrag?.dragHandleProps}
                          {...snapshotDrag}
                          className={`${
                            item.isMerchandise && !(listIdDisable.listIdMerchandise as number[]).includes(+item.id)
                              ? false
                              : checkDisableService(item)
                              ? 'salon__checkout-disable-draggable'
                              : ''
                          }`}
                          onClick={() =>
                            item.isMerchandise && !(listIdDisable.listIdMerchandise as number[]).includes(+item.id)
                              ? handleClickOption(item.id, service.name)
                              : checkDisableService(item)
                              ? null
                              : handleClickOption(item.id, service.name)
                          }
                        >
                          {!snapshotDrag.isDropAnimating && (
                            <ServiceItemSmall
                              isMerchandise={item.isMerchandise}
                              name={item.name}
                              price={item?.price?.toString()}
                              time={item?.time?.toString()}
                              key={item.id}
                              backgroundColor={item.backgroundColor}
                              isServiceCreate={false}
                            />
                          )}
                        </div>
                        {snapshotDrag.isDragging && (
                          <ServiceItemSmall
                            isMerchandise={item.isMerchandise}
                            name={item.name}
                            price={item?.price?.toString()}
                            time={item?.time?.toString()}
                            key={item.id}
                            backgroundColor={item.backgroundColor}
                            isServiceCreate={false}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Draggable>
                ))}
              </div>
            </React.Fragment>
          )}
        </Droppable>
      ),
    };
  });

  const updateAppointmentMutation = useMutation(
    (changeStatusAppointments: ChangeStatusAppointments) =>
      appointmentApi.appointmentControllerChangeStatusAppointments(changeStatusAppointments, undefined, undefined),
    {
      onSuccess: ({ data }) => {
        handleRefetchAppointment();
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        handleRefetchAppointment();
      },
    }
  );

  const handleUpdateAppoint = () => {
    const filterAppointment = appointments.filter((appointment) => appointment.status === DesiredStatusesItem.CheckIn);
    updateAppointmentMutation.mutate({
      status: 'Being_served',
      appointmentIds: filterAppointment.map((item) => item.id),
    });
  };

  useEffect(() => {
    if (listAppointments?.data?.content && listAppointments?.data?.content?.length > 0) {
      setAppointments(listAppointments?.data?.content);
    }
  }, [listAppointments]);

  useEffect(() => {
    if (listTechnicians?.data && listTechnicians.data.length >= 0) {
      const dataTechnician: ITechnicianItem[] = [];
      listTechnicians.data.forEach((technician) => {
        const resource: ITechnicianItem = {
          defaultAvatar:
            technician.defaultAvatar || technician.avatar?.preview || technician.avatar?.source
              ? technician.defaultAvatar ||
                `${process.env.REACT_APP_API_URL}/static/${technician.avatar?.preview || technician.avatar?.source}`
              : AvatarDefault,
          name: technician.name,
          id: technician.id,
          phoneNumber: technician.phoneNumber,
          title: technician.title,
          skills:
            (technician.skills.map((item) => {
              if (!item.isMerchandise) {
                return item.id;
              }
            }) as number[]) ?? [],
        };
        dataTechnician.push(resource);
      });
      setListTechnician(!filterTechnician.isAll && !filterTechnician.filterBy ? [] : dataTechnician);
    }
  }, [listTechnicians?.data, filterTechnician]);

  useEffect(() => {
    if (taxPercent > 0 || taxPercent === -1) {
      handleGetTotal(appointmentBill);
    }
  }, [taxPercent]);

  useEffect(() => {
    if (listPromotion?.data.feeDiscount?.autoOption && serviceCharge.chargeValue !== -1) {
      handleGetTotal(appointmentBill);
      setServiceCharge({
        chargeValue: +listPromotion?.data.feeDiscount?.autoOption,
        name: listPromotion?.data.feeDiscount?.feeName,
      });
    }
  }, [listPromotion]);

  useEffect(() => {
    if (serviceCharge.chargeValue > 0 || serviceCharge.chargeValue === -1 || serviceCharge.chargeValue === 0) {
      handleGetTotal(appointmentBill);
    }
  }, [serviceCharge]);
  useEffect(() => {
    if (
      paymentInformation &&
      paymentInformation.giftCardPayment?.totalValue &&
      paymentInformation.giftCardPayment?.totalValue > 0
    ) {
      handleGetTotal(appointmentBill);
    }
  }, [paymentInformation]);

  useEffect(() => {
    if (listTotal.totalCash > 0) {
      handleGetTotal(appointmentBill);
    }
  }, [listTotal.totalCash]);

  useEffect(() => {
    if (
      discountsAndRewardsApplied?.occasionDiscount ||
      discountsAndRewardsApplied?.weeklyDiscount ||
      discountsAndRewardsApplied?.birthdayDiscount ||
      discountsAndRewardsApplied?.rewardBalance
    ) {
      const dataUpdateBill = {
        ...appointmentBill,
        technician: appointmentBill.technician.map((item: ITechnicianItem) => {
          return {
            ...item,
            services:
              item.services &&
              item.services.map((itemService: IServicesItem) => {
                return {
                  ...itemService,
                  discount: {
                    ...itemService.discount,
                    occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
                      itemService.id
                    )
                      ? {
                          discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                          type: discountsAndRewardsApplied?.occasionDiscount?.type,
                          moneyDiscount:
                            discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                              ? discountsAndRewardsApplied?.occasionDiscount?.amount
                              : handleTruncateToTwoDecimal(
                                  (itemService.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 1)) /
                                    100
                                ),
                        }
                      : undefined,
                    weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(itemService.id)
                      ? {
                          discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                          type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                          moneyDiscount:
                            discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                              ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                              : handleTruncateToTwoDecimal(
                                  (itemService.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 1)) / 100
                                ),
                        }
                      : undefined,
                    birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
                      itemService.id
                    )
                      ? {
                          discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                          type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                          moneyDiscount:
                            discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                              ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                              : handleTruncateToTwoDecimal(
                                  (itemService.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 1)) /
                                    100
                                ),
                        }
                      : undefined,
                  },
                };
              }),
          };
        }),
        ticketMerchandise: appointmentBill.ticketMerchandise.map((item) => {
          return {
            id: item.merchandiseId,
            idDelete: parseFloat(Math.random().toFixed(3)) * 10,
            name: item.name,
            merchandiseId: item.merchandiseId,
            merchandisePrice: item.merchandisePrice,
            isTaxable: item.isTaxable,
            totalDiscount: 0,
            discount: {
              ...item.discount,
              occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
                item.merchandiseId as number
              )
                ? {
                    discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                    type: discountsAndRewardsApplied?.occasionDiscount?.type,
                    moneyDiscount:
                      discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                        ? discountsAndRewardsApplied?.occasionDiscount?.amount
                        : handleTruncateToTwoDecimal(
                            (item.merchandisePrice * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 1)) / 100
                          ),
                  }
                : undefined,
              weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(
                item.merchandiseId as number
              )
                ? {
                    discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                    type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                    moneyDiscount:
                      discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                        ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                        : handleTruncateToTwoDecimal(
                            (item.merchandisePrice * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 1)) / 100
                          ),
                  }
                : undefined,
              birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
                item.merchandiseId as number
              )
                ? {
                    discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                    type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                    moneyDiscount:
                      discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                        ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                        : handleTruncateToTwoDecimal(
                            (item.merchandisePrice * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 1)) / 100
                          ),
                  }
                : undefined,
            },
          };
        }),
      };
      setAppointmentBill(dataUpdateBill);
      handleGetTotal(dataUpdateBill);
    } else {
      handleGetTotal(appointmentBill);
    }
  }, [discountsAndRewardsApplied]);

  const checkIsHaveServiceHold = (): boolean => {
    const merchandise = !!appointmentBill.ticketMerchandise.length;
    const giftCard = !!appointmentBill.giftCards;
    const technicianService = appointmentBill.technician.filter((item) => item.name);
    if (!!technicianService.every((item) => !!item?.services?.length) || merchandise || giftCard) {
      return true;
    } else {
      return false;
    }
  };

  const checkIsHaveServiceCreate = (): boolean => {
    const technicianNull = appointmentBill.technician.find((item) => !item.name);
    const technicianService = appointmentBill.technician.filter((item) => item.name);
    const merchandise = !!appointmentBill.ticketMerchandise.length;
    const giftCard = !!appointmentBill.giftCards;
    const isHaveService = !!technicianService.every((item) => !!item?.services?.length);

    if (technicianNull) {
      return false;
    } else if (!!technicianService.length && isHaveService) {
      return true;
    } else if (merchandise || giftCard) {
      if (technicianService) {
        return isHaveService;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="salon__checkout-container-page">
      <StyledHeader
        content={intl.formatMessage({ id: 'checkout.checkOut' })}
        extraButton={
          !!listTicketPending?.data?.total ? (
            <span
              className="cursor-pointer font-size-20 font-weight-600 color-0090FF line-height-45"
              onClick={() => {
                setOpenModal({
                  ...openModal,
                  openPendingTicket: true,
                });
              }}
            >
              {listTicketPending?.data.total} {intl.formatMessage({ id: 'checkout.pending.ticket' })}
            </span>
          ) : null
        }
      />
      <div className="salon__checkout-container">
        <DragDropContext onDragEnd={onDragEnd}>
          <div
            className={`salon__checkout-col-container salon__checkout-col-container-col-left ${
              isCollapse ? 'salon__checkout-expand-width-left' : ''
            }`}
          >
            <div id={DesiredStatusesItem.ListTechnician}>
              <StyledCollapse items={technicianItems} />
            </div>
            {/* list services */}

            <div className="salon__checkout-list-services">
              {listServices.map((item, index) => (
                <div
                  className="salon__checkout-service-item"
                  key={index}
                  onClick={() => {
                    handleUpdateShowModal(item);
                    setIsEditServicePrice(false);
                  }}
                >
                  <span className="salon__checkout-service-item-title">
                    {intl.formatMessage({ id: `checkout.${item}` })}
                  </span>
                </div>
              ))}
            </div>
            <div id={DesiredStatusesItem.ListServices}>
              <StyledCollapse
                items={serviceItems}
                collapseProps={{
                  defaultActiveKey: ['1'],
                }}
              />
            </div>
          </div>

          <div className="salon__checkout-col-container-col-mid">
            <CheckoutBill
              appointmentBill={appointmentBill}
              idTechnician={idTechnician}
              isOpenPayAndComplete={isOpenPayAndComplete}
              listTotal={listTotal}
              openModal={openModal}
              taxDiscount={taxDiscount}
              serviceCharge={serviceCharge}
              serviceChangePromotionAuto={
                listPromotion?.data.feeDiscount?.autoOption ? +listPromotion?.data.feeDiscount?.autoOption : 0
              }
              serviceChangePromotionManual={
                listPromotion?.data.feeDiscount?.manualOption ? +listPromotion?.data.feeDiscount?.manualOption : 0
              }
              serviceChangeNamePromotion={listPromotion?.data.feeDiscount?.feeName ?? ''}
              discountsAndRewardsApplied={discountsAndRewardsApplied}
              paymentInformation={paymentInformation}
              taxPercent={taxPercent}
              listPromotion={listPromotion}
              isLoading={createCheckoutMutation.isLoading}
              setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
              handleRemoveMerchandise={handleRemoveMerchandise}
              handleRemoveServices={handleRemoveServices}
              handleRemoveServiceDiscount={handleRemoveServiceDiscount}
              handleRemoveTechnician={handleRemoveTechnician}
              handleRemoveMerchandiseDiscount={handleRemoveMerchandiseDiscount}
              setIdTechnician={setIdTechnician}
              setListSkill={setListSkill}
              setIsOpenPayAndComplete={setIsOpenPayAndComplete}
              setOpenModal={setOpenModal}
              setTaxPercent={setTaxPercent}
              handleRemoveCustomer={handleRemoveCustomer}
              handleRemoveGiftCard={handleRemoveGiftCard}
              setServiceCharge={setServiceCharge}
              setIsEditServicePrice={setIsEditServicePrice}
              setIdService={setIdService}
              handleSubmitCheckout={handleSubmitCheckout}
              setServiceItem={setServiceItem}
              resetBill={resetBill}
              checkListServiceInTechnician={checkListServiceInTechnician}
              checkIsHaveServiceHold={checkIsHaveServiceHold}
              checkIsHaveServiceCreate={checkIsHaveServiceCreate}
              setIndexServiceEdit={setIndexServiceEdit}
            />
          </div>

          <div
            className={`d-flex flex-column salon__checkout-col-container-col-right salon__checkout-container-btn-collapse ${
              isCollapse ? 'salon__checkout-expand-width-right' : ''
            }`}
          >
            <Button
              className="salon__checkout-btn-collapse z-index-100"
              onClick={() => {
                setIsCollapse(!isCollapse);
              }}
            >
              {isCollapse ? <SvgNextIcon /> : <SvgPrevIcon />}
            </Button>
            <FormWrap
              name="formCreateServices"
              layout="vertical"
              preserve={false}
              autoComplete="off"
              className="salon__checkout-form-search "
            >
              <FormInputSearch
                name={'fullTextSearch'}
                inputProps={{
                  inputMode: 'none',
                  readOnly: true,
                  placeholder: 'Search',
                  onClick: () => {
                    setOpenModal({
                      ...openModal,
                      openListCheckIn: true,
                    });
                  },
                  onKeyDown: (e) => {
                    e.preventDefault();
                  },
                  className: 'salon__checkout-form-search-input',
                }}
                formItemProps={{
                  rules: [
                    {
                      transform: (value) => {
                        return value?.trim();
                      },
                    },
                  ],
                  className: 'm-0',
                }}
              />
              <div className="width-38 height-38">
                <Tooltip
                  trigger={'hover'}
                  placement="bottomRight"
                  title={
                    <span className="font-size-16 font-weight-500">
                      {intl.formatMessage({ id: 'checkout.tooltip.markAll' })}
                    </span>
                  }
                  overlayClassName="salon__checkout-tooltip "
                >
                  <Button
                    className="salon__checkout-btn-update-service-checkin"
                    onClick={() => handleUpdateAppoint()}
                    loading={updateAppointmentMutation.isLoading}
                    disabled={updateAppointmentMutation.isLoading}
                  >
                    <SvgDoubleCheckIcon />
                  </Button>
                </Tooltip>
              </div>
            </FormWrap>
            <div className="salon__checkout-col-container w-100" id={DesiredStatusesItem.ListCheckin}>
              {desiredStatuses.map((status, index) => (
                <Droppable droppableId={status} key={status} isDropDisabled={status === DesiredStatusesItem.CheckIn}>
                  {(providedDrop: DroppableProvided, snapshotDrop: DroppableStateSnapshot) => (
                    <React.Fragment>
                      {/* <div ref={providedDrop.innerRef} {...providedDrop.droppableProps} {...snapshotDrop} /> */}
                      <div
                        ref={providedDrop.innerRef}
                        {...providedDrop.droppableProps}
                        {...snapshotDrop}
                        className="salon__checkout-drop-zone"
                      >
                        <StyledCollapse
                          collapseProps={{
                            defaultActiveKey: ['1', '2'],
                            className: 'p-l-20',
                          }}
                          items={[
                            {
                              key: (index + 1).toString(),
                              label: (
                                <>
                                  <span className="font-size-14 font-weight-700 color-292F33 d-flex gap-4">
                                    {intl.formatMessage({ id: `checkout.${status}` })}
                                    {' (' +
                                      appointments.filter((appointment) => appointment.status === status).length +
                                      ')'}
                                    <Tooltip
                                      trigger="hover"
                                      overlayClassName="salon__checkout-tooltip"
                                      title={
                                        status === DesiredStatusesItem.CheckIn ? (
                                          <ul className="m-b-0 ">
                                            <li>
                                              <span className="font-size-16 font-weight-500">
                                                {intl.formatMessage(
                                                  { id: 'checkout.tooltip.checkin1' },
                                                  {
                                                    service: `"${intl.formatMessage({
                                                      id: `checkout.${DesiredStatusesItem.BeingServed}`,
                                                    })}"`,
                                                  }
                                                )}
                                              </span>
                                            </li>
                                            <li>
                                              <span className="font-size-16 font-weight-500">
                                                {intl.formatMessage({ id: 'checkout.tooltip.checkin2' })}
                                              </span>
                                            </li>
                                          </ul>
                                        ) : (
                                          <span className="font-size-16">
                                            {intl.formatMessage({ id: 'checkout.tooltip.beingServed' })}
                                          </span>
                                        )
                                      }
                                    >
                                      <p className="m-b-0 m-t-1-n">
                                        <SvgToolTipIcon />
                                      </p>
                                    </Tooltip>
                                  </span>
                                </>
                              ),
                              children: (
                                <div className="d-flex align-items-center flex-wrap gap-8 p-t-b-6">
                                  {appointments.filter((appointment) => appointment.status === status).length > 0 ? (
                                    appointments
                                      .filter((appointment) => appointment.status === status)
                                      .map((data: Appointment, indexAppointment: number) => (
                                        <Draggable
                                          draggableId={data.id.toString()}
                                          index={indexAppointment}
                                          key={data.id}
                                          isDragDisabled={idAppointment.includes(data.id)}
                                        >
                                          {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                                            <>
                                              <div
                                                ref={providedDrag?.innerRef}
                                                {...providedDrag?.draggableProps}
                                                {...providedDrag?.dragHandleProps}
                                                {...snapshotDrag}
                                                className={
                                                  idAppointment.includes(data.id)
                                                    ? 'salon__checkout-disable-draggable'
                                                    : ''
                                                }
                                                onClick={() =>
                                                  idAppointment.includes(data.id)
                                                    ? null
                                                    : handleClickOption(
                                                        indexAppointment,
                                                        data.status === AppointmentStatusEnum.CheckedIn
                                                          ? DesiredStatusesItem.CheckIn
                                                          : DesiredStatusesItem.BeingServed
                                                      )
                                                }
                                              >
                                                {!snapshotDrag.isDropAnimating && (
                                                  <CheckInCard
                                                    customerName={data.customer.name}
                                                    estimate={data.estimate}
                                                    rate={5}
                                                    serviceName={data.services.map((item) => item.name).join(', ')}
                                                    status={data.status}
                                                    timeStart={data?.timeStart}
                                                    technicianName={data.technician?.name}
                                                    containerClassName="salon__checkout-card"
                                                  />
                                                )}
                                              </div>
                                              {snapshotDrag.isDragging && (
                                                <CheckInCard
                                                  customerName={data.customer.name}
                                                  estimate={data.estimate}
                                                  rate={5}
                                                  serviceName={data.services.map((item) => item.name).join(', ')}
                                                  status={data.status}
                                                  timeStart={data?.timeStart}
                                                  technicianName={data.technician?.name}
                                                  containerClassName="salon__checkout-card"
                                                />
                                              )}
                                            </>
                                          )}
                                        </Draggable>
                                      ))
                                  ) : (
                                    <div className="d-flex align-items-center justify-content-center w-100">
                                      <SvgAddMoreDataIconSmall />
                                    </div>
                                  )}
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </Droppable>
              ))}
            </div>
          </div>
        </DragDropContext>
      </div>
      {openModal.openListTechnician && (
        <ModalTechnicianList
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openListTechnician: false,
            });
          }}
          open={openModal.openListTechnician}
          setFilterTechnician={setFilterTechnician}
          filterTechnician={filterTechnician}
        />
      )}
      {openModal.openListCustomer && (
        <ModalCustomer
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openListCustomer: false,
            });
          }}
          open={openModal.openListCustomer}
          setAppointmentBill={setAppointmentBill}
          appointmentBill={appointmentBill}
          listPromotion={listPromotion}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
        />
      )}
      {openModal.openGeneralService && (
        <GeneralService
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openGeneralService: false,
            });

            if (serviceItem) setServiceItem(undefined);
          }}
          setAppointmentBill={setAppointmentBill}
          appointmentBill={appointmentBill}
          idTechnician={idTechnician}
          setIdTechnician={setIdTechnician}
          setListSkill={setListSkill}
          handleGetTotal={handleGetTotal}
          setIsEditServicePrice={setIsEditServicePrice}
          listIdDisable={listIdDisable}
          setListIdDisable={setListIdDisable}
          open={openModal.openGeneralService}
          isEdit={isEditServicePrice}
          idService={idService}
          serviceItem={serviceItem}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          indexServiceEdit={indexServiceEdit}
        />
      )}
      {openModal.openGiftCard && (
        <GiftCard
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openGiftCard: false,
            });
          }}
          open={openModal.openGiftCard}
          appointmentBill={appointmentBill}
          setAppointmentBill={setAppointmentBill}
          handleGetTotal={handleGetTotal}
        />
      )}

      {openModal.openMerchandise && (
        <Merchandise
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openMerchandise: false,
            });
          }}
          open={openModal.openMerchandise}
          setAppointmentBill={setAppointmentBill}
          appointmentBill={appointmentBill}
          handleGetTotal={handleGetTotal}
          listIdDisable={listIdDisable}
          setListIdDisable={setListIdDisable}
        />
      )}

      {openModal.openDiscountsAndRewards && (
        <DiscountsAndRewards
          open={openModal}
          customer={appointmentBill.customer}
          setOpenModal={setOpenModal}
          listIdDisable={listIdDisable}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
          handleRemoveCustomer={handleRemoveCustomer}
          listDataServices={listDataServices}
          promotionData={listPromotion}
        />
      )}
      {openModal.openTaxAndDeleteTicket && (
        <TaxAndDeleteTicket
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openTaxAndDeleteTicket: false,
            });
          }}
          open={openModal.openTaxAndDeleteTicket}
          setTaxPercent={setTaxPercent}
          feeName={listPromotion?.data.feeDiscount?.feeName ?? ''}
          serviceCharge={serviceCharge}
          autoOption={listPromotion?.data.feeDiscount?.autoOption ?? 0}
          serviceChargePercent={listPromotion?.data.feeDiscount?.manualOption ?? 0}
          setServiceCharge={setServiceCharge}
        />
      )}
      {openModal.openDeleteTicket && (
        <DeleteTicket
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openDeleteTicket: false,
            });
          }}
          price={+(listTotal.totalCash + (paymentInformation.giftCardPayment?.totalValue ?? 0))}
          open={openModal.openDeleteTicket}
          listTotal={listTotal}
          onDeleteTicket={() => {
            resetBill();
          }}
        />
      )}
      {openModal.openPendingTicket && (
        <PendingTicket
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openPendingTicket: false,
            });
          }}
          open={openModal.openPendingTicket}
          listTicketPending={listTicketPending?.data.content}
          appointmentBill={appointmentBill}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          listPromotion={listPromotion?.data}
          listTotal={listTotal}
          setSearchPending={setSearchPending}
          handleGetTotal={handleGetTotal}
          setAppointmentBill={setAppointmentBill}
          setServiceCharge={setServiceCharge}
          setTaxPercent={setTaxPercent}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
          setListIdDisable={setListIdDisable}
          setIdTechnician={setIdTechnician}
          setListSkill={setListSkill}
          setPaymentInformation={setPaymentInformation}
          setListTotal={setListTotal}
          setServiceApply={setServiceApply}
        />
      )}
      {openModal.openListCheckIn && (
        <ModalCheckIn
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openListCheckIn: false,
            });
          }}
          open={openModal.openListCheckIn}
          listIdDisable={listIdDisable}
          appointmentBill={appointmentBill}
          setAppointmentBill={setAppointmentBill}
          setIdTechnician={setIdTechnician}
          setListSkill={setListSkill}
          setIdAppointment={setIdAppointment}
          setListIdDisable={setListIdDisable}
          setServiceApply={setServiceApply}
        />
      )}
      {isOpenPayAndComplete && (
        <PayAndComplete
          open={isOpenPayAndComplete}
          onChangeOpenPayAndComplete={handleChangeOpenPayAndComplete}
          handleSubmitCheckout={handleSubmitCheckout}
          listTotal={listTotal}
          paymentInformation={paymentInformation}
          onChangePaymentInformation={setPaymentInformation}
          technicians={listTechnician.filter(
            (technician) => appointmentBill.technician.findIndex((item) => item.id === technician.id) > -1
          )}
          setListTotal={setListTotal}
          listPromotion={listPromotion}
          resetBill={resetBill}
          tipInformation={tipInformation}
          setTipInformation={setTipInformation}
          isLoading={createCheckoutMutation.isLoading}
        />
      )}
    </div>
  );
};

export default CheckOutTenant;
