import { useIntl } from 'react-intl';
import ColWrap from '../../../../components/ColWrap';
import RowWrap from '../../../../components/RowWrap';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { DesiredStatusesItem } from '../index';
import ModalCashDrawer from '../CashDrawer';
import { useState } from 'react';
import Transactions from '../Transactions';
import Closeout from '../CashDrawer/Closeout';

export interface IOpenModalCheckOut {
  openModalCashDrawer: boolean;
  openTransactions: boolean;
  openCloseoutBatch: boolean;
}

interface IProps<T extends object> {}

function BillStart<T extends object>(props: IProps<T>) {
  const intl = useIntl();

  const [openModal, setOpenModal] = useState<IOpenModalCheckOut>({
    openModalCashDrawer: false,
    openTransactions: false,
    openCloseoutBatch: false,
  });
  const handleHighlight = (keyHighlight: string) => {
    const highLightElement = document.querySelector(`#${keyHighlight}`) as HTMLElement;
    if (highLightElement) {
      highLightElement.style.border = '1px solid red';
      setTimeout(() => {
        highLightElement.style.border = 'unset';
      }, 3000);
    }
  };

  const onClickTransactions = () => {
    setOpenModal((prev) => ({
      ...prev,
      openTransactions: true,
    }));
  };
  const onClickCloseoutBatch = () => {
    setOpenModal((prev) => ({
      ...prev,
      openCloseoutBatch: true,
    }));
  };
  return (
    <div className="salon__checkout-bill-start">
      <div className="salon__checkout-bill-start-tap-on-container">
        <span className="salon__checkout-bill-start-tap-on-title">
          {intl.formatMessage({ id: 'checkout.tapOn.title' })}{' '}
          <span
            className="salon__checkout-bill-start-tap-on-title-btn cursor-pointer"
            onClick={() => {
              handleHighlight(DesiredStatusesItem.ListTechnician);
            }}
          >
            {intl.formatMessage({ id: 'checkout.tapOn.technician' })}
          </span>
          ,
        </span>
        <br />
        <span className="salon__checkout-bill-start-tap-on-title">
          <span
            className="salon__checkout-bill-start-tap-on-title-btn cursor-pointer"
            onClick={() => {
              handleHighlight(DesiredStatusesItem.ListServices);
            }}
          >
            {intl.formatMessage({ id: 'checkout.tapOn.service' })}
          </span>{' '}
          {intl.formatMessage({ id: 'checkout.tapOn.or' })}{' '}
          <span
            className="salon__checkout-bill-start-tap-on-title-btn cursor-pointer"
            onClick={() => {
              handleHighlight(DesiredStatusesItem.ListCheckin);
            }}
          >
            {intl.formatMessage({ id: 'checkout.tapOn.checkin' })}
          </span>{' '}
          {intl.formatMessage({ id: 'checkout.tapOn.to' })}
        </span>
        <br />
        <span className="salon__checkout-bill-start-tap-on-title">
          {intl.formatMessage({ id: 'checkout.tapOn.createNew' })}
        </span>
        <br />
      </div>
      <div className="m-l-r-48">
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[12, 12]}
          isWrap={true}
          styleFill="between"
          rowClassName="m-b-52"
        >
          <ColWrap colProps={{ span: 12, className: 'text-center' }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.transaction.btn.title' })}
              buttonProps={{
                className: 'w-100 height-62',
                onClick: onClickTransactions,
              }}
              isPrimary={false}
              isButtonCancel={false}
              isCancelPopup={false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12, className: 'text-center' }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.printLastTicket.btn.title' })}
              buttonProps={{
                className: 'w-100 height-62',
                onClick: () => {},
              }}
              isPrimary={false}
              isButtonCancel={false}
              isCancelPopup={false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12, className: 'text-center' }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.cashDrawer.btn.title' })}
              buttonProps={{
                className: 'w-100 height-62',
                onClick: () => {
                  setOpenModal({
                    ...openModal,
                    openModalCashDrawer: true,
                  });
                },
              }}
              isPrimary={false}
              isButtonCancel={false}
              isCancelPopup={false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12, className: 'text-center' }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.closeoutBatch.btn.title' })}
              buttonProps={{
                className: 'w-100 height-62',
                onClick: onClickCloseoutBatch,
              }}
              isPrimary={false}
              isButtonCancel={false}
              isCancelPopup={false}
            />
          </ColWrap>
        </RowWrap>
      </div>
      <ModalCashDrawer
        onCancelModal={() => {
          setOpenModal({
            ...openModal,
            openModalCashDrawer: false,
          });
        }}
        open={openModal.openModalCashDrawer}
      />
      {openModal?.openTransactions && (
        <Transactions
          open={openModal.openTransactions}
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openTransactions: false,
            });
          }}
        />
      )}
      {openModal?.openCloseoutBatch && (
        <Closeout
          open={openModal.openCloseoutBatch}
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openCloseoutBatch: false,
            });
          }}
        />
      )}
    </div>
  );
}

export default BillStart;
