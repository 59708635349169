import { Form } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { SvgAppointmentArrowBackIcon } from '../../../../../components/@svg/SvgAppointmentArrowBackIcon';
import { SvgAppointmentArrowForwardIcon } from '../../../../../components/@svg/SvgAppointmentArrowForwardIcon';
import { SvgAppointmentDatePickerIcon } from '../../../../../components/@svg/SvgAppointmentDatePickerIcon';
import { FormDatePicker } from '../../../../../components/Form/FormDatePicker';
import FormWrap from '../../../../../components/FormWrap';
import { ButtonIcon } from '../../../../../components/buttons/ButtonIcon';
import { formatOnlyDate } from '../../../../../utils';
import { monthNamesId, weekDays } from '../../../../../utils/constant';
import { FilterProps, SLIDE_ACTION, SlideActionType } from '../../models';

interface IScrollCalendar {
  title: string;
  day: number;
  date: Date;
  isLastDayOfMonth: boolean;
}

interface ScrollCalendarProps extends FilterProps {}

const scrollCalendarItemWidth = 52;
const countSlide = 7;
const defaultTotalDaysToSlide = 30;

dayjs.extend(isBetween);

const ScrollCalendar: FC<ScrollCalendarProps> = ({ filter, onChangeFilter }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const [slide, setSlide] = useState<{ index: number; action: SlideActionType }>({
    index: 0,
    action: SLIDE_ACTION.NO_ACTION,
  });
  const [scrollCalendars, setScrollCalendars] = useState<IScrollCalendar[]>([]);
  const [totalDaysToSlide, setTotalDaysToSlide] = useState<number>(defaultTotalDaysToSlide);

  const scrollCalendarListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    form.setFieldsValue({ date: dayjs(filter?.date) });
  }, [filter?.date]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const scrollCalendarListWidth = entries?.[0]?.contentRect.width;
      const totalDays =
        Math.round(Number(scrollCalendarListWidth) / scrollCalendarItemWidth) || defaultTotalDaysToSlide;

      setTotalDaysToSlide(totalDays);
    });

    if (scrollCalendarListRef.current) {
      observer.observe(scrollCalendarListRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const calendars: IScrollCalendar[] = useMemo(() => {
    const calendarList: IScrollCalendar[] = [];

    const filterDate = dayjs(new Date()).get('date');
    const filterMonth = dayjs(new Date()).get('month') + 1;
    const filterYear = dayjs(new Date()).get('year');
    const totalDaysOfMonth = dayjs(new Date()).daysInMonth();
    const totalDaysOfLastMonth = dayjs(dayjs(new Date()).subtract(1, 'months')).daysInMonth();

    for (let i = filterDate - 2; i < filterDate - 2 + totalDaysToSlide; i++) {
      let day = i;
      let month = filterMonth;

      if (i <= 0) {
        if (i < 0) {
          day = totalDaysOfLastMonth - 1;
        } else if (i === 0) {
          day = totalDaysOfLastMonth;
        }

        if (month === 1) {
          month = 12;
        } else {
          month -= 1;
        }
      } else if (i > totalDaysOfMonth) {
        day = i - totalDaysOfMonth;
        month += 1;
      }

      const date = `${i === 0 && month === 12 ? filterYear - 1 : filterYear}-${month > 9 ? month : `0${month}`}-${day}`;

      const dateDependentSlideIndex = dayjs(date).add(slide.index, 'day').startOf('days');

      const weekDayIndex = dateDependentSlideIndex.get('day');

      const calendarItem: IScrollCalendar = {
        title: weekDays[weekDayIndex],
        day: dateDependentSlideIndex.get('date'),
        date: dateDependentSlideIndex.toDate(),
        isLastDayOfMonth: dateDependentSlideIndex.get('date') === totalDaysOfMonth,
      };

      calendarList.push(calendarItem);
    }

    const findIndexCurrentDate = calendarList.findIndex((calendar) =>
      dayjs(new Date()).startOf('days').isSame(dayjs(calendar.date))
    );

    if (findIndexCurrentDate > -1) {
      localStorage.setItem('lastScrollCurrentDate', JSON.stringify(calendarList[calendarList.length - 1].date));
    }

    return calendarList;
  }, [slide.index, totalDaysToSlide]);

  useEffect(() => {
    if (slide.action !== SLIDE_ACTION.CHOOSE) {
      setSlide((prev) => ({ ...prev, action: SLIDE_ACTION.NO_ACTION }));
      setScrollCalendars(calendars);
    }
  }, [slide.action]);

  const handleChangeSlideIndex = (index: number) => {
    setSlide((prev) => ({
      action: SLIDE_ACTION.SWIPE,
      index: prev.index + index,
    }));
  };

  const handleChooseDate = (date: Date) => {
    setSlide((prev) => ({
      action: SLIDE_ACTION.CHOOSE,
      index: prev.index,
    }));
    onChangeFilter({ date });
  };

  const onFieldsChange = () => {
    const { date } = form.getFieldsValue();

    const isBeforeDate = dayjs(date).startOf('days').isBefore(dayjs(new Date()).startOf('days'));
    const isSameNewDate = dayjs(date).startOf('days').isSame(dayjs(new Date()).startOf('days'));

    const lastScrollCurrentDate = JSON.parse(localStorage.getItem('lastScrollCurrentDate') as string);
    const firstScrollCurrentDate = dayjs(lastScrollCurrentDate).subtract(totalDaysToSlide, 'days').toDate();

    const isBetweenCurrentDate = dayjs(date).isBetween(
      dayjs(firstScrollCurrentDate),
      dayjs(lastScrollCurrentDate),
      'days'
    );

    let index = isBeforeDate ? -1 : 1;

    while (index) {
      if (isSameNewDate || isBetweenCurrentDate) break;

      const lastDate = dayjs(lastScrollCurrentDate)
        .add(index * countSlide, 'days')
        .startOf('days');

      if (isBeforeDate) {
        --index;
      } else {
        ++index;
      }

      if (
        dayjs(lastDate).isAfter(dayjs(date).startOf('days')) &&
        dayjs(lastDate).diff(dayjs(date).startOf('days'), 'days') <= totalDaysToSlide
      )
        break;
    }

    onChangeFilter({ date: dayjs(date).toDate() });
    setSlide({
      action: SLIDE_ACTION.PICK_CALENDAR,
      index: isSameNewDate || isBetweenCurrentDate ? 0 : index * countSlide,
    });
  };

  return (
    <div className="salon__appointment-filter-days">
      <ButtonIcon
        icon={<SvgAppointmentArrowBackIcon />}
        buttonProps={{
          onClick: () => handleChangeSlideIndex(-countSlide),
        }}
      />
      <div className="salon__appointment-filter-days-list" ref={scrollCalendarListRef}>
        {scrollCalendars.map((calendar, index) => {
          const isWeekend = calendar.title === weekDays[0] || calendar.title === weekDays[6];
          const isSelected = formatOnlyDate(filter?.date) === formatOnlyDate(calendar.date);
          const isToday = formatOnlyDate(new Date()) === formatOnlyDate(calendar.date);
          const calendarMonth = dayjs(calendar.date).get('month');

          return (
            <div
              className={`salon__appointment-filter-days-wrapper ${
                index === 0 || calendar.day === 1 ? 'salon__appointment-filter-days-wrapper-border' : ''
              }`}
              key={index}
            >
              {(index === 0 || calendar.day === 1) && (
                <p
                  className="font-weight-700 salon__appointment-filter-days-wrapper-month"
                  style={{ zIndex: index + 1 }}
                >
                  {intl.formatMessage({ id: monthNamesId[calendarMonth] })}
                </p>
              )}
              {isToday && (
                <p className="font-weight-700 salon__appointment-filter-days-item-today">
                  {intl.formatMessage({ id: 'appointment.calendar.today' })}
                </p>
              )}
              <div
                className={`cursor-pointer salon__appointment-filter-days-item ${isSelected ? 'selected' : ''}`}
                onClick={() => handleChooseDate(calendar.date)}
              >
                <p className={`font-weight-700 salon__appointment-filter-days-item-text ${isWeekend ? 'weekend' : ''}`}>
                  {calendar.title}
                </p>
                <p className={`font-weight-700 salon__appointment-filter-days-item-text ${isWeekend ? 'weekend' : ''}`}>
                  {calendar.day}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <ButtonIcon
        icon={<SvgAppointmentArrowForwardIcon />}
        buttonProps={{
          onClick: () => handleChangeSlideIndex(countSlide),
        }}
      />
      <FormWrap form={form} name="appointmentDate" onFieldsChange={onFieldsChange}>
        <FormDatePicker
          name="date"
          datePickerIcon={<SvgAppointmentDatePickerIcon />}
          datePickerProps={{
            className: 'salon__appointment-filter-days-picker',
            allowClear: false,
            inputReadOnly: true,
          }}
        />
      </FormWrap>
    </div>
  );
};

export default ScrollCalendar;
