import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { SvgPrintIcon } from '../../../../../../components/@svg/SvgPrintIcon';
import { useIntl } from 'react-intl';
import avatarDefaultSrc from '../../../../../../assets/images/appointment/avatar-default.png';
import {
  RewardBalanceDiscountDTO,
  TechnicianTurn,
  Ticket,
  TicketDisCountDTO,
  TicketMerchandise,
  TicketTransactionTypePaymentEnum,
} from '../../../../../../apis/client-axios';
import { formatNumberThousandWithDecimal } from '../../../../../../utils';
import RowWrap from '../../../../../../components/RowWrap';
import ColWrap from '../../../../../../components/ColWrap';

interface IProps {
  ticket: Ticket;
  className?: string;
  isHiddenCustomer?: boolean;
}

enum TicketDisCountDTOKeyEnum {
  ReferralDiscount = 'referralDiscount',
  BirthdayDiscount = 'birthdayDiscount',
  WeeklyDiscount = 'weeklyDiscount',
  OccasionDiscount = 'occasionDiscount',
  GeneralTicketDiscount = 'generalTicketDiscount',
  GeneralItemDiscount = 'generalItemDiscount',
  LoyaltyReward = 'loyaltyReward',
  RewardBalance = 'rewardBalance',
}

export const SaleTicket: React.FC<IProps> = (props) => {
  const { ticket, className, isHiddenCustomer } = props;
  const intl = useIntl();

  const [listTechnicianTurn, setListTechnicianTurn] = useState<TechnicianTurn[]>([]);
  const [listTicketMerchandise, setListTicketMerchandise] = useState<TicketMerchandise[]>([]);

  const getDiscountName = (value: keyof TicketDisCountDTO) => {
    switch (value) {
      case TicketDisCountDTOKeyEnum.ReferralDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.1' });
      case TicketDisCountDTOKeyEnum.BirthdayDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.2' });
      case TicketDisCountDTOKeyEnum.WeeklyDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.3' });
      case TicketDisCountDTOKeyEnum.OccasionDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.4' });
      case TicketDisCountDTOKeyEnum.GeneralTicketDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.5' });
      case TicketDisCountDTOKeyEnum.GeneralItemDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.6' });
      case TicketDisCountDTOKeyEnum.LoyaltyReward:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.title.1' });
      case TicketDisCountDTOKeyEnum.RewardBalance:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.customRedeem.title' });
      default:
        return '';
    }
  };

  const getTypePaymentName = (value: TicketTransactionTypePaymentEnum) => {
    switch (value) {
      case TicketTransactionTypePaymentEnum.CardNumber:
        return intl.formatMessage({ id: 'checkout.payment.cardnumber' });
      case TicketTransactionTypePaymentEnum.Cash:
        return intl.formatMessage({ id: 'checkout.payment.cash' });
      case TicketTransactionTypePaymentEnum.ChargeCard:
        return intl.formatMessage({ id: 'checkout.payment.chargecard' });
      case TicketTransactionTypePaymentEnum.Check:
        return intl.formatMessage({ id: 'checkout.payment.check' });
      case TicketTransactionTypePaymentEnum.GiftCard:
        return intl.formatMessage({ id: 'checkout.giftCard' });
      default:
        return '...';
    }
  };

  useEffect(() => {
    setListTechnicianTurn(ticket?.technicianTurns || []);
    setListTicketMerchandise(ticket?.ticketMerchandise || []);
  }, [ticket]);

  return (
    <div
      className={'salon__transactions-collapse__sale-ticket-collapse__container' + (className ? ' ' + className : '')}
    >
      {!isHiddenCustomer ? (
        <div className="salon__transactions-collapse__sale-ticket-collapse__header background-color-0090FF1A p-l-r-40 p-t-b-8">
          <div className="d-flex gap-8 align-items-center width-350">
            <Image
              src={
                ticket?.customer?.avatar?.preview || ticket?.customer?.avatar?.source
                  ? `${process.env.REACT_APP_API_URL}/static/${
                      ticket?.customer?.avatar?.preview || ticket?.customer?.avatar?.source
                    }`
                  : avatarDefaultSrc
              }
              preview={false}
              className="border-radius-8px width-32 height-32"
            />
            <p className="salon__transactions-collapse__sale-ticket-collapse__content-customer-name">
              {ticket?.customer?.name ?? ''}
            </p>
          </div>
          <CustomButton
            type="primary"
            buttonProps={{
              icon: <SvgPrintIcon />,
              className: 'm-l-auto m-y-auto border-radius-8px width-28 height-28',
            }}
          />
        </div>
      ) : (
        ''
      )}
      <div className="salon__transactions-collapse__sale-ticket-collapse__content">
        {/* Nano hairstroke */}
        <div className="p-l-r-20 p-t-b-8 d-flex flex-column gap-8">
          <div className="font-weight-500 p-l-r-20">
            {listTechnicianTurn?.map((techTurn) =>
              techTurn?.serviceTurns?.map((serTurn) => (
                <div className="salon__transactions-collapse__sale-ticket-collapse__content-row" key={serTurn.id}>
                  <div className="">
                    {serTurn?.isGeneralService
                      ? intl.formatMessage({ id: 'checkout.generalService' })
                      : serTurn?.service?.name ?? '...'}
                  </div>
                  <div className="text-align-right">
                    ${formatNumberThousandWithDecimal(serTurn.price ?? serTurn.service?.price ?? 0)}
                  </div>
                </div>
              ))
            )}

            {listTicketMerchandise?.map((merchandise) => (
              <div className="salon__transactions-collapse__sale-ticket-collapse__content-row" key={merchandise.id}>
                <div className="">
                  {merchandise?.merchandise?.name ?? intl.formatMessage({ id: 'checkout.merchandise' })}
                </div>
                <div className="text-align-right">
                  $
                  {formatNumberThousandWithDecimal(
                    merchandise?.merchandisePrice ?? merchandise?.merchandise?.price ?? 0
                  )}
                </div>
              </div>
            ))}

            {!!ticket?.ticketGiftCard?.length ? (
              <div className="salon__transactions-collapse__sale-ticket-collapse__content-row" key={'ticketGiftCard'}>
                <div className="">{intl.formatMessage({ id: 'checkout.giftCard' })}</div>
                <div className="text-align-right">
                  $
                  {formatNumberThousandWithDecimal(
                    ticket.ticketGiftCard
                      ?.map((giftCard) => giftCard.giftCardValue)
                      .reduce((prev, cur) => Number(prev) + Number(cur), 0)
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        {/* general discount */}
        <div className="p-20 d-flex flex-column gap-20">
          <div className="p-l-r-20 d-flex flex-column gap-8">
            {ticket?.discount && !!Object.keys(ticket?.discount).length ? (
              <div className="d-flex flex-column gap-8 border-bottom-1px-DBDDDF p-b-8">
                {Object.keys(ticket.discount).map((discount) => (
                  <div className="salon__transactions-collapse__sale-ticket-collapse__content-row" key={discount}>
                    <div className="">
                      {getDiscountName(discount as keyof TicketDisCountDTO)}&nbsp;
                      {ticket.discount[discount as keyof TicketDisCountDTO]?.type === '%'
                        ? `${Number(ticket.discount[discount as keyof TicketDisCountDTO]?.discountApply) ?? 0}%`
                        : ''}
                    </div>
                    <div className="text-align-right">
                      {(discount as keyof TicketDisCountDTO) === 'rewardBalance'
                        ? `${
                            (ticket.discount[discount as keyof TicketDisCountDTO] as RewardBalanceDiscountDTO).point
                          } ${intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.points' })} / `
                        : ''}
                      $
                      {formatNumberThousandWithDecimal(
                        ticket.discount[discount as keyof TicketDisCountDTO]?.moneyDiscount ?? 0
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}

            {Number(ticket.serviceTax?.moneyDiscount) || Number(ticket.serviceCharge?.moneyDiscount) ? (
              <div className="d-flex flex-column gap-8 border-bottom-1px-DBDDDF p-b-8">
                {Number(ticket.serviceTax?.moneyDiscount) ? (
                  <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
                    <div className="text-transform-capitalize">
                      {intl.formatMessage({ id: 'checkout.transactions.tax' })}&nbsp;
                      {ticket.serviceTax?.percentDiscount ? `(${Number(ticket.serviceTax?.percentDiscount)}%)` : ''}
                    </div>
                    <div className="text-align-right">${ticket.serviceTax?.moneyDiscount?.toFixed(2)}</div>
                  </div>
                ) : (
                  ''
                )}
                {Number(ticket.serviceCharge?.moneyDiscount) ? (
                  <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
                    <div className="text-transform-capitalize">
                      {ticket.serviceCharge?.name ?? intl.formatMessage({ id: 'checkout.transactions.charge' })}
                      &nbsp;
                      {ticket.serviceCharge?.percentDiscount
                        ? `(${Number(ticket.serviceCharge?.percentDiscount)}%)`
                        : ''}
                    </div>
                    <div className="text-align-right">${ticket.serviceCharge?.moneyDiscount?.toFixed(2)}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
              <div className="">{intl.formatMessage({ id: 'checkout.total' })}</div>
              <div className="text-align-right font-weight-600">
                ${formatNumberThousandWithDecimal(ticket.total ?? 0)}
              </div>
            </div>

            {/* total tip */}
            <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
              <div className="">
                {intl.formatMessage({ id: 'checkout.total' })}{' '}
                <span className="text-transform-lowercase">
                  {intl.formatMessage({ id: 'checkout.transactions.item.tip' })}
                </span>
              </div>
              <div className="text-align-right font-weight-600">
                ${formatNumberThousandWithDecimal(ticket.totalTip ?? 0)}
              </div>
            </div>

            {/* total discount */}
            <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
              <div className="">{intl.formatMessage({ id: 'checkout.totalDiscount' })} </div>
              <div className="text-align-right font-weight-600">
                ${formatNumberThousandWithDecimal(ticket.totalDiscount ?? 0)}
              </div>
            </div>

            {/* total payment */}
            <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
              <div className="">
                {intl.formatMessage({ id: 'checkout.total' })}{' '}
                <span className="text-transform-uppercase">
                  {intl.formatMessage({ id: 'checkout.transactions.payments' })}
                </span>
              </div>
              <div className="text-align-right font-weight-600">
                $
                {formatNumberThousandWithDecimal(
                  (ticket?.totalPayment ?? 0) - (ticket?.discount?.rewardBalance?.moneyDiscount ?? 0)
                )}
              </div>
            </div>
            {Number(ticket.deposit) ? (
              <>
                <div
                  className="salon__transactions-collapse__sale-ticket-collapse__content-row border-bottom-1px-DBDDDF p-b-8"
                  key={'deposit'}
                >
                  <div className="">{intl.formatMessage({ id: 'checkout.deposit' })}</div>
                  <div className="text-align-right font-weight-600">
                    ${formatNumberThousandWithDecimal(ticket.deposit)}
                  </div>
                </div>
                <div className="salon__transactions-collapse__sale-ticket-collapse__content-row" key={'deposit'}>
                  <div className=""></div>
                  <div className="text-align-right font-weight-600">
                    $
                    {formatNumberThousandWithDecimal(
                      (ticket?.totalPayment ?? 0) -
                        (ticket?.discount?.rewardBalance?.moneyDiscount ?? 0) -
                        (ticket.deposit ?? 0)
                    )}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="p-l-r-20 p-t-b-12 m-t-20 salon__transactions-collapse__sale-ticket-collapse__table">
            <RowWrap isAutoFillRow={true} isGutter={false} isWrap={true} styleFill="between">
              <ColWrap colProps={{ span: 6, className: 'font-weight-500 ' }}>
                <span>{intl.formatMessage({ id: 'checkout.transactions.payments' })}</span>
              </ColWrap>
              {/* <ColWrap colProps={{ span: 6, className: 'font-weight-500 text-center' }}>
                <span>{intl.formatMessage({ id: 'checkout.transactions.cashDiscount' })}</span>
              </ColWrap> */}
              <ColWrap colProps={{ span: 9, className: 'font-weight-500 text-align-right' }}>
                <span>{intl.formatMessage({ id: 'checkout.transactions.amount' })}</span>
              </ColWrap>
              <ColWrap colProps={{ span: 9, className: 'font-weight-500 text-align-right' }}>
                <span>{intl.formatMessage({ id: 'checkout.transactions.item.tip' })}</span>
              </ColWrap>
            </RowWrap>

            {ticket?.ticketTransaction
              ?.filter(
                (trans) =>
                  trans.typePayment &&
                  trans.typePayment !== TicketTransactionTypePaymentEnum.TipCash &&
                  trans.typePayment !== TicketTransactionTypePaymentEnum.Tip
              )
              ?.map((trans) => (
                <RowWrap isAutoFillRow={true} isGutter={false} isWrap={true} styleFill="between" key={trans.id}>
                  <ColWrap colProps={{ span: 6, className: 'font-weight-400 ' }}>
                    <span className="text-transform-capitalize">{getTypePaymentName(trans.typePayment)}</span>
                  </ColWrap>
                  {/* <ColWrap colProps={{ span: 6, className: 'font-weight-400 text-center' }}>
                    <span>{''}</span>
                  </ColWrap> */}
                  <ColWrap colProps={{ span: 9, className: 'font-weight-400 text-align-right' }}>
                    <span>${formatNumberThousandWithDecimal(trans.money ?? 0)}</span>
                  </ColWrap>
                  <ColWrap colProps={{ span: 9, className: 'font-weight-400 text-align-right' }}>
                    <span>
                      {Number(trans.tipMoney) ? `$${formatNumberThousandWithDecimal(trans.tipMoney ?? 0)}` : ''}
                    </span>
                  </ColWrap>
                </RowWrap>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
