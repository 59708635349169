import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { CardNumberEnum } from '..';
import NotificationSuccess from '../../../../../../../components/HandleShowNotiSuccess';
import FormWrap from '../../../../../../../components/FormWrap';
import RowWrap from '../../../../../../../components/RowWrap';
import ColWrap from '../../../../../../../components/ColWrap';
import { formatNumberThousandWithDecimal } from '../../../../../../../utils';
import { FormInputNumber } from '../../../../../../../components/Form/FormInputNumber';
import { SvgDollarIcon } from '../../../../../../../components/@svg/SvgDollarIcon';
import { CustomButton } from '../../../../../../../components/buttons/CustomButton';
import KeyboardWrapper, { KeyboardDirection } from '../../../../keyboard';
import { IListIdTotalPrice } from '../../../..';

interface IProps {
  setCurrentScreen: React.Dispatch<React.SetStateAction<CardNumberEnum>>;
  setOpenModal: (open: boolean) => void;
  setIsOpenPayAndComplete: (open: boolean) => void;
  listTotal: IListIdTotalPrice;
}

interface FormData {
  customerCard?: number;
  customerTipLeft?: number;
}

enum InputNameEnum {
  CustomerCard = 'customerCard',
  CustomerTipLeft = 'customerTipLeft',
}

export const CardPaymentCardNumber: React.FC<IProps> = (props) => {
  const { setCurrentScreen, setOpenModal, setIsOpenPayAndComplete, listTotal } = props;
  const [form] = Form.useForm();
  const intl = useIntl();
  const keyboardRef = useRef<any>();
  const n = (key: keyof FormData) => key;
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();
  const customerCardRef = useRef<HTMLInputElement>(null);
  const customerTipLeftRef = useRef<HTMLInputElement>(null);
  const customerCard = Form.useWatch(n('customerCard'), form);
  const customerTipLeft = Form.useWatch(n('customerTipLeft'), form);

  useEffect(() => {
    if (customerCard && customerCardRef.current) {
      const [integer] = customerCard.replace(/,/g, '').split('.');
      customerCardRef.current.selectionStart = customerCardRef.current.selectionEnd =
        customerCard.length + Math.floor(integer / 3);
    }

    if (customerTipLeft && customerTipLeftRef.current) {
      const [integer] = customerTipLeft.replace(/,/g, '').split('.');
      customerTipLeftRef.current.selectionStart = customerTipLeftRef.current.selectionEnd =
        customerTipLeft.length + Math.floor(integer / 3);
    }
  }, [customerCard, customerTipLeft]);

  const onClickOK = (value: FormData) => {
    console.log('value', value);
    setOpenModal(false);
    setIsOpenPayAndComplete(false);
    NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.noti.saleCompleted' }) });
  };

  const onClickQuickNumber = (value: number) => {
    console.log('value', value);
    setOpenModal(false);
    setIsOpenPayAndComplete(false);
    NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.noti.saleCompleted' }) });
  };

  return (
    <FormWrap form={form} onFinish={onClickOK} className="salon__checkout__pay-n-complete-modal__cash-payment">
      <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
        <ColWrap colProps={{ span: 24 }}>
          <p className="font-size-16 font-weight-500 m-0">
            {`${intl.formatMessage({ id: 'checkout.payAndComplete.balanceToPay' })}: `}
            <span className="font-weight-700">
              ${formatNumberThousandWithDecimal(listTotal.totalBalanceTotalPay || 0)}
            </span>
          </p>
        </ColWrap>
        <ColWrap colProps={{ span: 24 }}>
          <FormInputNumber
            name={n('customerCard')}
            prefix={<SvgDollarIcon />}
            label={intl.formatMessage({ id: 'checkout.payAndComplete.cardPayment.customerCard.label' })}
            numericFormatProps={{
              placeholder: '0.00',
              className: 'm-b-0',
              onFocus: () => setFocusedInputName(InputNameEnum.CustomerCard),
              getInputRef: customerCardRef,
            }}
            formItemProps={{
              className: 'm-b-0 w-full',
            }}
          />
        </ColWrap>
        <ColWrap colProps={{ span: 24 }}>
          <FormInputNumber
            name={n('customerTipLeft')}
            prefix={<SvgDollarIcon />}
            label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.customerTipLeft.label' })}
            numericFormatProps={{
              placeholder: '0.00',
              className: 'm-b-0',
              onFocus: () => setFocusedInputName(InputNameEnum.CustomerTipLeft),
              disabled: !customerCard || customerCard === '0.00',
              getInputRef: customerTipLeftRef,
            }}
            formItemProps={{
              className: 'm-b-0 w-full',
            }}
          />
        </ColWrap>
        <ColWrap colProps={{ span: 24 }}>
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
            {/* no tip */}
            <ColWrap colProps={{ span: 24 }}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className: 'height-76 w-full border-radius-12px font-size-24 font-weight-700 background-color-EE7E68',
                  onClick: () => onClickQuickNumber(0),
                }}
                content={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.noTip' })}
              />
            </ColWrap>

            {/* no tip */}
            <ColWrap colProps={{ span: 24 }}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className:
                    'height-76 w-full border-radius-12px font-size-24 font-weight-700 background-color-CCF4FF color-292F33',
                  onClick: () => onClickQuickNumber(listTotal?.totalBalanceTotalPay || 0),
                }}
                content={`$${formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0)}`}
              />
            </ColWrap>

            {/* numeric keyboard */}
            <ColWrap colProps={{ span: 18 }}>
              <KeyboardWrapper
                inputName={focusedInputName}
                form={form}
                keyboardRef={keyboardRef}
                maxLength={9}
                keyboardProps={{
                  inputPattern: /^\d+$/,
                }}
                direction={KeyboardDirection.rtl}
              />
            </ColWrap>

            {/* ok button */}
            <ColWrap colProps={{ span: 6 }}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                  htmlType: 'submit',
                }}
                content={intl.formatMessage({ id: 'common.OK' })}
              />
            </ColWrap>
          </RowWrap>
        </ColWrap>
      </RowWrap>
    </FormWrap>
  );
};
