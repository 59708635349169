import React from 'react';
import { Route, Routes } from 'react-router-dom';

const Sample = React.lazy(() => import('.'));
const SampleComponent = React.lazy(() => import('./SampleComponent'));

export const SampleRoutes = () => (
  <Routes>
    <Route path="" element={<Sample />}>
      <Route path="" element={<SampleComponent />} />
    </Route>
  </Routes>
);
