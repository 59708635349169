import React from 'react';

export const SvgCalenderIcon = () => (
  <span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5067_274802)">
        <path
          d="M13.9276 0.941406H12.5158V2.35317C12.5158 2.63552 12.2805 2.82376 12.0452 2.82376C11.8099 2.82376 11.5746 2.63552 11.5746 2.35317V0.941406H4.04522V2.35317C4.04522 2.63552 3.80993 2.82376 3.57463 2.82376C3.33934 2.82376 3.10404 2.63552 3.10404 2.35317V0.941406H1.69228C0.986397 0.941406 0.46875 1.55317 0.46875 2.35317V4.04729H15.5276V2.35317C15.5276 1.55317 14.6805 0.941406 13.9276 0.941406ZM0.46875 5.03552V13.6473C0.46875 14.4943 0.986397 15.0591 1.73934 15.0591H13.9746C14.7276 15.0591 15.5746 14.4473 15.5746 13.6473V5.03552H0.46875ZM4.65699 12.9414H3.52757C3.33934 12.9414 3.1511 12.8002 3.1511 12.5649V11.3885C3.1511 11.2002 3.29228 11.012 3.52757 11.012H4.70404C4.89228 11.012 5.08051 11.1532 5.08051 11.3885V12.5649C5.03346 12.8002 4.89228 12.9414 4.65699 12.9414ZM4.65699 8.70611H3.52757C3.33934 8.70611 3.1511 8.56494 3.1511 8.32964V7.15317C3.1511 6.96494 3.29228 6.7767 3.52757 6.7767H4.70404C4.89228 6.7767 5.08051 6.91788 5.08051 7.15317V8.32964C5.03346 8.56494 4.89228 8.70611 4.65699 8.70611ZM8.42169 12.9414H7.24522C7.05698 12.9414 6.86875 12.8002 6.86875 12.5649V11.3885C6.86875 11.2002 7.00993 11.012 7.24522 11.012H8.42169C8.60993 11.012 8.79816 11.1532 8.79816 11.3885V12.5649C8.79816 12.8002 8.65699 12.9414 8.42169 12.9414ZM8.42169 8.70611H7.24522C7.05698 8.70611 6.86875 8.56494 6.86875 8.32964V7.15317C6.86875 6.96494 7.00993 6.7767 7.24522 6.7767H8.42169C8.60993 6.7767 8.79816 6.91788 8.79816 7.15317V8.32964C8.79816 8.56494 8.65699 8.70611 8.42169 8.70611ZM12.1864 12.9414H11.0099C10.8217 12.9414 10.6335 12.8002 10.6335 12.5649V11.3885C10.6335 11.2002 10.7746 11.012 11.0099 11.012H12.1864C12.3746 11.012 12.5629 11.1532 12.5629 11.3885V12.5649C12.5629 12.8002 12.4217 12.9414 12.1864 12.9414ZM12.1864 8.70611H11.0099C10.8217 8.70611 10.6335 8.56494 10.6335 8.32964V7.15317C10.6335 6.96494 10.7746 6.7767 11.0099 6.7767H12.1864C12.3746 6.7767 12.5629 6.91788 12.5629 7.15317V8.32964C12.5629 8.56494 12.4217 8.70611 12.1864 8.70611Z"
          fill="#56595C"
        />
      </g>
      <defs>
        <clipPath id="clip0_5067_274802">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);
