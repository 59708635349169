import { Form } from 'antd';
import { useIntl } from 'react-intl';
import '../style.scss';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import FormWrap from '../../../../../components/FormWrap';
import RowWrap from '../../../../../components/RowWrap';
import ColWrap from '../../../../../components/ColWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { FormTextArea } from '../../../../../components/Form/FormTextArea';
import { useMutation } from '@tanstack/react-query';
import { CashDrawerDTO, CashDrawerDTOActionEnum } from '../../../../../apis/client-axios';
import { ticketApi } from '../../../../../apis';
import NotificationError from '../../../../../components/HandleShowNotiError';

export interface Props {
  openModal: boolean;
  onCancelModal: () => void;
  refetchCashDrawer: () => void;
}
interface FormData {
  notes?: string;
}

function Others<T extends object>(props: Props) {
  const { openModal, onCancelModal, refetchCashDrawer } = props;
  const intl = useIntl();
  const [form] = Form.useForm();
  const n = (key: keyof FormData) => key;

  const createCashDrawer = useMutation((data: CashDrawerDTO) => ticketApi.ticketControllerCreateCashDrawer(data), {
    onSuccess: ({ data }) => {
      onCancelModal();
      form.resetFields();
      refetchCashDrawer();
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      NotificationError({ contentNoti: error.response.data.message });
    },
  });

  const onSubmit = () => {
    const { notes } = form.getFieldsValue();

    const dataSubmit: CashDrawerDTO = {
      notes,
      action: CashDrawerDTOActionEnum.Others,
    };
    createCashDrawer.mutate(dataSubmit);
  };
  return (
    <StyledModal
      isOpen={openModal}
      onCancel={onCancelModal}
      modalProps={{
        title: intl.formatMessage({ id: 'checkout.cashDrawer.others' }),
        className: 'salon__checkout-cash-drawer-modal',
        width: 700,
        footer: (
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex gap-12 align-items-center">
              <ButtonStyled
                content={intl.formatMessage({ id: 'checkout.cashDrawer.ok' })}
                buttonProps={{
                  className: 'width-100 height-38',
                  onClick: onSubmit,
                }}
                isPrimary={true}
              />
            </div>
          </div>
        ),
      }}
    >
      <FormWrap form={form} name="appointmentModified" layout="vertical" className="w-100  p-t-20">
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[10, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <FormTextArea
              name={n('notes')}
              inputProps={{
                className: 'salon__checkout-cash-drawer-modal-custom-input',
                placeholder: intl.formatMessage({ id: 'checkout.cashDrawer.others.placeholder' }),
                maxLength: 1000,
              }}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
}

export default Others;
