import React from 'react';

export const SvgArrowBackIcon = () => (
  <span>
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99938 0.939785C8.75027 0.690115 8.41207 0.549805 8.05938 0.549805C7.70669 0.549805 7.36848 0.690115 7.11938 0.939785L0.999375 7.05979C0.479375 7.57979 0.479375 8.41979 0.999375 8.93979L7.11938 15.0598C7.63938 15.5798 8.47938 15.5798 8.99938 15.0598C9.51937 14.5398 9.51937 13.6998 8.99938 13.1798L3.82604 7.99312L8.99938 2.81979C9.51937 2.29979 9.50604 1.44645 8.99938 0.939785Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
