import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Spin, Steps } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance, customerApi, employeeApi, salonApi, servicesApi, settingApi } from '../../../../apis';
import { CheckInDTO, Customer, ServiceItems } from '../../../../apis/client-axios';
import { SvgArrowBackIcon } from '../../../../components/@svg/SvgArrowBackIcon';
import NotificationError from '../../../../components/HandleShowNotiError';
import { formatDateTimeUTC, getStorageByName } from '../../../../utils';
import {
  QUERY_LIST_TECHNICIAN,
  QUERY_SALONS_DETAIL,
  QUERY_SERVICE_ITEMS,
  QUERY_SETTING,
} from '../../../../utils/constant';
import { MAIN_ROUTES } from '../../routes';
import { BOOKING_ONLINE_ROUTES } from '../bookingOnlineConstants';
import ScreenSaver from '../screenSaver';
import './information.scss';
import StepOne from './stepOne';
import StepThree from './stepThree';
import StepTwo from './stepTwo';
import FormWrap from '../../../../components/FormWrap';
import { FormCheckbox } from '../../../../components/Form/FormCheckbox';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';

const Information = () => {
  const intl = useIntl();
  const [formTerms] = Form.useForm();
  const [step, setStep] = useState<number>(0);
  const [stepCheckIn, setStepCheckIn] = useState<any[]>([
    {
      title: (
        <span className="text-transform-first-letter-capitalize">
          {intl.formatMessage({ id: 'bookingOnline.step1' })}
        </span>
      ),
      icon: <div className="salon__information-step-item">{intl.formatMessage({ id: 'bookingOnline.one' })}</div>,
    },
    {
      title: (
        <span className="text-transform-first-letter-capitalize">
          {intl.formatMessage({ id: 'bookingOnline.step2' })}
        </span>
      ),
      icon: <div className="salon__information-step-item">{intl.formatMessage({ id: 'bookingOnline.two' })}</div>,
    },
  ]);
  const [listSelectedService, setListSelectedService] = useState<number[]>([]);
  const [selectedTechnician, setSelectedTechnician] = useState<number>();
  const [appointmentId, setAppointmentId] = useState<number | undefined>();
  const [checkedTechnician, setCheckedTechnician] = useState<boolean>(true);
  const [dataCustomer, setDataCustomer] = useState<Customer>();
  const [receivePromotionViaEmail, setReceivePromotionViaEmail] = useState<boolean>(true);
  const [receiveMessagesByPhone, setReceiveMessagesByPhone] = useState<boolean>(true);
  const [dataDetail, setDataDetail] = useState<Customer>();
  const [status, setStatus] = useState<boolean>(false);
  const [isTerms, setIsTerms] = useState<boolean>(false);
  const [statusSkip, setStatusSkip] = useState<boolean>(false);
  const [listMerchandise, setListMerchandise] = useState<number[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const termsChecked = Form.useWatch('terms', formTerms);

  const salonId = Number(id) || getStorageByName('salonId');

  const { data: dataSalon } = useQuery({
    queryKey: [QUERY_SALONS_DETAIL],
    queryFn: () => salonApi.salonControllerGetCurrentSalon(id),
    enabled: !!id,
    staleTime: 1000,
  });
  const { data: setting, isLoading: settingLoading } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
    staleTime: 1000,
  });

  const { data: dataTechnicians } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN],
    queryFn: () => employeeApi.employeeControllerTechnician(),
    staleTime: 1000,
  });
  const { data: dataServiceItems } = useQuery({
    queryKey: [QUERY_SERVICE_ITEMS],
    queryFn: () =>
      servicesApi.serviceControllerGetServiceItems(
        1,
        undefined,
        undefined,
        undefined,
        1,
        undefined,
        salonId as number,
        undefined
      ),
    staleTime: 1000,
  });

  const onBack = () => {
    if (step && step >= 0) {
      if (!selectedTechnician) {
        setCheckedTechnician(true);
        setReceiveMessagesByPhone(true);
        setReceivePromotionViaEmail(true);
      }
      if (selectedTechnician && listSelectedService) {
        setStatusSkip(true);
      }

      setStep((prev) => prev - 1);
    } else {
      navigate('/' + MAIN_ROUTES.BOOKING_ONLINE);
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (!status) setStatus(true);
    }, 120000);

    const onMouseDown = () => {
      clearInterval(myInterval);
      myInterval = setInterval(() => {
        if (!status) setStatus(true);
      }, 120000);
    };

    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  useEffect(() => {
    if (id) {
      axiosInstance.defaults.headers.salonId = id;
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const createCustomerCheckinMutation = useMutation(
    (checkIn: CheckInDTO) => customerApi.customerControllerCheckin(checkIn),
    {
      onSuccess: () => {
        navigate('/' + MAIN_ROUTES.BOOKING_ONLINE + BOOKING_ONLINE_ROUTES.CONFIRM + (id ? `/${id}` : ''));
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
    }
  );

  useEffect(() => {
    if (setting?.data.settingCheckin.asksOptionReceivingTextMessage) {
      console.log('333');
      const tempStep = stepCheckIn;
      tempStep.push({
        title: (
          <span className="text-transform-first-letter-capitalize">
            {intl.formatMessage({ id: 'bookingOnline.step3' })}
          </span>
        ),
        icon: <div className="salon__information-step-item">{intl.formatMessage({ id: 'bookingOnline.three' })}</div>,
      });
      setStepCheckIn(tempStep);
    }
  }, [setting]);

  const onFinish = () => {
    const dataPush: CheckInDTO = {
      useAnyTechnician: checkedTechnician,
      technicianId: selectedTechnician,
      serviceIds: [...listSelectedService, ...listMerchandise],
      totalMoney:
        dataServiceItems?.data.content
          ?.filter((serviceItem: ServiceItems) => listSelectedService.includes(serviceItem.id))
          .reduce((total, service) => Number(total) + Number(service.price), 0) ?? 0,
      estimate:
        listSelectedService.length > 0
          ? dataServiceItems?.data.content
              ?.filter((serviceItem: ServiceItems) => listSelectedService.includes(serviceItem.id))
              ?.map(({ time }) => time)
              .reduce((totalTime, time) => totalTime + time, 0) ?? 0
          : 15,
      customer: {
        customerName: dataCustomer?.name ?? '',
        customerPhone: dataCustomer?.phoneNumber.replace(/[-\s]/g, '') ?? '',
        birthDay: formatDateTimeUTC(dataCustomer?.birthDay),
        receivePromotionViaEmail: receivePromotionViaEmail,
        receiveMessagesByPhone: receiveMessagesByPhone,
      },
      appointmentId: appointmentId,
    };
    if (dataPush) {
      createCustomerCheckinMutation.mutate(dataPush);
    }
  };

  const onFinishTerms = () => {
    setIsTerms(true);
    console.log({ status });
  };

  console.log(setting);

  return !setting?.data.settingCheckin ? (
    <div className="min-height-600 d-flex justify-content-center align-items-center">
      <Spin />
    </div>
  ) : setting?.data.settingCheckin.askForConsent && !isTerms ? (
    <section className="salon__information">
      <FormWrap
        name=""
        form={formTerms}
        layout="vertical"
        className="salon__information-form-terms"
        onFinish={onFinishTerms}
      >
        <div className="salon__online-appointment-container w-100 p-l-r-40">
          <div className="d-flex justify-content-center align-items-center position-relative w-100">
            <div className="salon__information-back" onClick={onBack}>
              <SvgArrowBackIcon />
              <span className="salon__information-back-title">{intl.formatMessage({ id: 'bookingOnline.back' })}</span>
            </div>
          </div>
          <div className="salon__information-main m-t-38">
            <p className="salon__online-appointment-title">{setting?.data.settingCheckin.consentTitle}</p>
            <div className="salon__online-appointment-container-terms">
              <p className="salon__online-appointment-container-terms-content">
                {setting?.data.settingCheckin.consentContent}
              </p>
              {setting?.data.settingCheckin.requireCustomers ? (
                <FormCheckbox name={'terms'} content={setting?.data.settingCheckin.consentPrompt ?? ''} />
              ) : (
                <></>
              )}
            </div>
          </div>

          <ButtonStyled
            content={intl.formatMessage({
              id: 'common.agree',
            })}
            isLarge={true}
            buttonProps={{
              className: 'width-184 salon__information-agree-terms',
              htmlType: 'submit',
              disabled: !termsChecked,
            }}
            isPrimary={true}
          />
        </div>
      </FormWrap>
    </section>
  ) : !status && !settingLoading ? (
    <section className="salon__information">
      <div className="salon__online-appointment-container">
        <div className="d-flex justify-content-center align-items-center position-relative">
          {!id ? (
            <div className="salon__information-back" onClick={onBack}>
              <SvgArrowBackIcon />
              <span className="salon__information-back-title">{intl.formatMessage({ id: 'bookingOnline.back' })}</span>
            </div>
          ) : (
            step !== 0 && (
              <div className="salon__information-back" onClick={onBack}>
                <SvgArrowBackIcon />
                <span className="salon__information-back-title">
                  {intl.formatMessage({ id: 'bookingOnline.back' })}
                </span>
              </div>
            )
          )}
          <div className="salon__information-step ">
            <Steps current={step} responsive={false} labelPlacement="vertical" items={stepCheckIn} />
          </div>
        </div>
      </div>

      <div className="salon__information-main">
        <div className="salon__online-appointment-container">
          {step === 0 && (
            <StepOne
              dataCustomer={dataCustomer}
              dataDetail={dataDetail}
              setDataDetail={setDataDetail}
              setStep={setStep}
              setDataCustomer={setDataCustomer}
              setCheckedTechnician={setCheckedTechnician}
              setSelectedTechnician={setSelectedTechnician}
              setListSelectedService={setListSelectedService}
              setReceivePromotionViaEmail={setReceivePromotionViaEmail}
              setReceiveMessagesByPhone={setReceiveMessagesByPhone}
              showKeyboard={!id}
              setListMerchandise={setListMerchandise}
              setAppointmentId={setAppointmentId}
            />
          )}
          {step === 1 && (
            <StepTwo
              setReceivePromotionViaEmail={setReceivePromotionViaEmail}
              setReceiveMessagesByPhone={setReceiveMessagesByPhone}
              dataTechnicians={dataTechnicians?.data || []}
              salonInformation={dataSalon?.data}
              step={step}
              setStep={setStep}
              listService={dataServiceItems?.data.content ?? []}
              listSelectedService={listSelectedService}
              setListSelectedService={setListSelectedService}
              selectedTechnician={selectedTechnician}
              setSelectedTechnician={setSelectedTechnician}
              checkedTechnician={checkedTechnician}
              setCheckedTechnician={setCheckedTechnician}
              appointmentId={appointmentId}
              statusSkip={statusSkip}
              setStatusSkip={setStatusSkip}
              settingCheckin={setting?.data.settingCheckin}
              isLoading={createCustomerCheckinMutation.isLoading}
              onFinish={onFinish}
            />
          )}
          {step === 2 && setting?.data.settingCheckin.asksOptionReceivingTextMessage && (
            <StepThree
              receivePromotionViaEmail={receivePromotionViaEmail}
              receiveMessagesByPhone={receiveMessagesByPhone}
              setReceivePromotionViaEmail={setReceivePromotionViaEmail}
              setReceiveMessagesByPhone={setReceiveMessagesByPhone}
              isLoading={createCustomerCheckinMutation.isLoading}
              onFinish={onFinish}
            />
          )}
        </div>
      </div>
    </section>
  ) : (
    <ScreenSaver status={status} setStatus={setStatus} screenSaver={setting?.data.settingCheckin.screenSaver} />
  );
};

export default Information;
