import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SvgPercentIcon } from '../../../../components/@svg/SvgPercentIcon';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../components/FormWrap';
import { StyledModal } from '../../../../components/StyledModal';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { IServiceCharge } from '../index';
import './style.scss';

interface Props {
  open: boolean;
  feeName: string;
  serviceChargePercent: number;
  serviceCharge: IServiceCharge;
  autoOption: number;
  setTaxPercent: (taxPercent: number) => void;
  setServiceCharge: (serviceCharge: IServiceCharge) => void;
  onCancelModal: () => void;
}

function TaxAndDeleteTicket(props: Props) {
  const {
    open,
    feeName,
    serviceChargePercent,
    serviceCharge,
    autoOption,
    onCancelModal,
    setTaxPercent,
    setServiceCharge,
  } = props;
  const [saveServiceCharge, setSaveServiceCharge] = useState<IServiceCharge>({
    chargeValue: 0,
    name: '',
  });
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
  const [flagSaveValue, setFlagSaveValue] = useState<boolean>(false);
  const intl = useIntl();

  const [form] = Form.useForm();

  const onFinish = () => {
    if (!flagSaveValue) {
      const tax = form.getFieldValue('supply') ?? 0;
      setTaxPercent(tax);
      form.resetFields();
    } else {
      setServiceCharge({
        chargeValue: saveServiceCharge.chargeValue,
        name: saveServiceCharge.name,
      });
    }
    onCancelModal();
  };
  useEffect(() => {
    // console.log({ autoOption });
    // console.log({ serviceChargePercent });
    // console.log({ serviceCharge });
    if (
      (+serviceCharge.chargeValue === 0 || +serviceCharge.chargeValue > 0) &&
      +autoOption &&
      +serviceCharge.chargeValue !== +autoOption
    ) {
      setIsDisableButton(true);
    } else {
      if (+serviceCharge.chargeValue === +serviceChargePercent) {
        setIsDisableButton(true);
      } else {
        setIsDisableButton(false);
      }
    }
  }, [serviceCharge]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-tax-ticket-modal-heading">
            {intl.formatMessage({ id: 'checkout.tax.ticket' })}
          </div>
        ),
        className: 'salon__checkout-tax-ticket-modal',
        width: 700,
        footer: (
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex gap-12 align-items-center">
              <ButtonStyled
                content={intl.formatMessage({ id: 'checkout.tax.ticket.add' })}
                buttonProps={{
                  className: 'width-100 height-38',
                  onClick: onFinish,
                }}
                isPrimary={true}
              />
            </div>
          </div>
        ),
      }}
    >
      <FormWrap
        form={form}
        name="taxCheckout"
        layout="vertical"
        className="w-100 salon__checkout-tax-ticket-modal-form p-t-10"
      >
        <FormInputNumber
          name={'supply'}
          label={
            <span className="salon__checkout-tax-ticket-modal-title">
              {intl.formatMessage({ id: 'checkout.tax.ticket.title' })}
            </span>
          }
          suffix={<SvgPercentIcon />}
          numericFormatProps={{
            allowNegative: false,
            min: 0,
            max: 100,
            placeholder: intl.formatMessage({ id: 'checkout.tax.ticket.placeholder' }),
            className: 'salon__checkout-tax-ticket-modal-form-input',
            onChange: (e) => {
              const currentValue = Number(e.target.value.replace(/,/g, ''));
              if (currentValue <= 0) return;
              form.setFieldValue('supply', currentValue);
            },
            onBlur: () => {
              setFlagSaveValue(false);
            },
          }}
          formItemProps={{
            className: 'm-b-0',
            rules: [
              {
                transform(value) {
                  return value?.trim();
                },
              },
            ],
          }}
        />
        <div>
          <span className="salon__checkout-tax-ticket-modal-text">{feeName}</span>
        </div>
        <ButtonStyled
          content={<span className="salon__checkout-tax-ticket-modal-text">{+serviceChargePercent}%</span>}
          buttonProps={{
            className: `width-80 height-38 m-t-12 ${
              isDisableButton ? 'salon__checkout-tax-ticket-modal-disable-btn' : ''
            }`,
            onClick: () => {
              if (serviceCharge.chargeValue === serviceChargePercent) {
                setIsDisableButton(false);
                setServiceCharge({
                  chargeValue: -1,
                  name: '',
                  // chargeValue: autoOption as number, enable if want to return auto option value
                  // name: feeName,
                });
                onCancelModal();
              } else {
                setIsDisableButton(true);
                setSaveServiceCharge({
                  chargeValue: serviceChargePercent,
                  name: feeName,
                });
              }
              setFlagSaveValue(true);
            },
          }}
          isLarge={false}
          isPrimary={true}
          isButtonCancel={false}
        />
      </FormWrap>
    </StyledModal>
  );
}

export default TaxAndDeleteTicket;
