import { useQuery } from '@tanstack/react-query';
import { Form, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ticketApi } from '../../../../apis';
import { SvgDollarIcon } from '../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../components/ColWrap';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../components/FormWrap';
import NotificationError from '../../../../components/HandleShowNotiError';
import RowWrap from '../../../../components/RowWrap';
import { StyledModal } from '../../../../components/StyledModal';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { formatNumberThousandWithDecimal } from '../../../../utils';
import { QUERY_GIFT_CARD_CHECKOUT } from '../../../../utils/constant';
import { IAppointmentBill } from '../index';
import KeyboardWrapper, { KeyboardDirection } from '../keyboard';
import './style.scss';

export interface Props {
  open: boolean;
  appointmentBill: IAppointmentBill;
  onCancelModal: () => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  handleGetTotal: (updateBill: IAppointmentBill) => void;
}
enum InputNameEnum {
  cardNumber = 'cardNumber',
  moneyAmount = 'moneyAmount',
}
interface FormData {
  cardNumber?: number;
  moneyAmount?: number;
  switch?: boolean;
}

function GiftCard(props: Props) {
  const { open, appointmentBill, onCancelModal, setAppointmentBill, handleGetTotal } = props;
  const n = (key: keyof FormData) => key;
  const intl = useIntl();
  const [form] = Form.useForm();
  const keyboardRef = useRef<any>();
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();
  const [cardNumber, setCardNumber] = useState<number>(0);

  const moneyAmountRef = useRef<HTMLInputElement>(null);
  const moneyAmount = Form.useWatch(n('moneyAmount'), form);
  const cardNumberRef = useRef<HTMLInputElement>(null);
  const cardNumberWatch = Form.useWatch(n('cardNumber'), form);

  useEffect(() => {
    if (moneyAmount && moneyAmountRef.current) {
      const [integer] = moneyAmount.replace(/,/g, '').split('.');
      moneyAmountRef.current.selectionStart = moneyAmountRef.current.selectionEnd =
        moneyAmount.length + Math.floor(integer / 3);
    }

    if (cardNumberWatch && cardNumberRef.current) {
      const [integer] = cardNumberWatch.replace(/,/g, '').split('.');
      cardNumberRef.current.selectionStart = cardNumberRef.current.selectionEnd =
        cardNumberWatch.length + Math.floor(integer / 3);
    }
  }, [moneyAmount, cardNumberWatch]);

  const { data: dataGiftCard, isLoading: isLoadingDataGiftCard } = useQuery({
    queryKey: [QUERY_GIFT_CARD_CHECKOUT, cardNumber],
    queryFn: () => ticketApi.ticketControllerGetGiftCardByCustomer(cardNumber, appointmentBill?.customer?.id ?? 0),
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((value: FormData) => {
        console.log({ appointmentBill });
        if (!value?.moneyAmount || !value?.cardNumber) {
          return handleSetError();
        } else if (value?.cardNumber !== undefined && value?.moneyAmount !== undefined && dataGiftCard) {
          handleRemoveError();
          let giftCardValue: number = 0;
          if (value && value.moneyAmount) {
            giftCardValue = +value?.moneyAmount;
          }
          const dataGiftCardUpdate = {
            id: parseFloat(Math.random().toFixed(3)) * 10,
            giftCardValue: handleConvertPrice(giftCardValue.toString()),
            giftCardCode: value.cardNumber,
          };
          const checkSameCode: boolean = !!appointmentBill.giftCards
            ?.map((item) => item.giftCardCode)
            .includes(value.cardNumber);

          const dataUpdateGiftCardSameCode = {
            ...appointmentBill,
            giftCards: appointmentBill?.giftCards?.map((item) => {
              if (value?.moneyAmount !== undefined && value?.cardNumber !== undefined && dataGiftCard) {
                return {
                  id: item.id,
                  giftCardValue: handleConvertPrice(giftCardValue.toString()),
                  giftCardCode: item.giftCardCode,
                };
              }
              return item;
            }),
          };

          const dataUpdateGiftCard = {
            ...appointmentBill,
            giftCards: appointmentBill?.giftCards
              ? appointmentBill.giftCards.concat(dataGiftCardUpdate)
              : [dataGiftCardUpdate],
          };

          setAppointmentBill(checkSameCode ? dataUpdateGiftCardSameCode : dataUpdateGiftCard);
          handleGetTotal(checkSameCode ? dataUpdateGiftCardSameCode : dataUpdateGiftCard);
          onCancelModal();
        }
      })
      .catch(() => {
        NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.giftCard.validate' }) });
      });
  };

  const handleConvertPrice = (price: string) => {
    return price ? Number(price.toString().replace(/,/g, '')) : 0;
  };

  const handleSetError = () => {
    form.setFields([
      {
        name: n('cardNumber'),
        errors: [intl.formatMessage({ id: 'common.invalidCardNumber' })],
      },
    ]);
  };
  const handleRemoveError = () => {
    form.setFields([
      {
        name: n('cardNumber'),
        errors: [],
      },
    ]);
  };

  useEffect(() => {
    if (dataGiftCard && dataGiftCard.status !== 200) {
      handleSetError();
    } else {
      handleRemoveError();
    }
  }, [dataGiftCard, cardNumber]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-giftcard-modal-heading">
            {intl.formatMessage({ id: 'checkout.giftCard' })}
          </div>
        ),
        className: 'salon__checkout-giftcard-modal',
        width: 700,
        footer: null,
      }}
    >
      <FormWrap
        form={form}
        name="giftCard"
        layout="vertical"
        // className="w-100 salon__checkout-giftcard-modal-form"
      >
        <FormInputNumber
          name={n('cardNumber')}
          label={<span className="font-weight-400">{intl.formatMessage({ id: 'checkout.giftCard.title.2' })}</span>}
          numericFormatProps={{
            inputMode: 'none',
            placeholder: `${intl.formatMessage({ id: 'checkout.giftCard.placeholder.2' })}`,
            onFocus: () => setFocusedInputName(InputNameEnum.cardNumber),
            thousandsGroupStyle: 'none',
            thousandSeparator: '',
            onBlur: (e) => {
              setCardNumber(+e.target.value ?? 0);
              if (focusedInputName === n('cardNumber')) {
                setFocusedInputName(undefined);
              }
            },
            onKeyDown: (e) => {
              return e.preventDefault();
            },
            getInputRef: cardNumberRef,
          }}
          formItemProps={{
            className: 'm-b-0',
            required: true,
            rules: [
              {
                transform(value) {
                  return value?.trim();
                },
                validator: (_, value) => {
                  const v = value;
                  if (!v) {
                    return Promise.reject(intl.formatMessage({ id: 'checkout.giftCard.validate.cardNumber' }));
                  }
                  return Promise.resolve();
                },
              },
            ],
          }}
        />

        <div className="m-b-20 m-t-4-n">
          <span className="salon__checkout-giftcard-modal-title">
            {intl.formatMessage({ id: 'checkout.giftCardBalance' })}
          </span>
          <span className="salon__checkout-giftcard-modal-title-value">
            {isLoadingDataGiftCard ? <Spin /> : `$${formatNumberThousandWithDecimal(dataGiftCard?.data || 0)}`}
          </span>
        </div>
        <FormInputNumber
          name={n('moneyAmount')}
          label={<span className="font-weight-400">{intl.formatMessage({ id: 'checkout.giftCard.title.1' })}</span>}
          prefix={<SvgDollarIcon />}
          numericFormatProps={{
            inputMode: 'none',
            readOnly: false,
            placeholder: `${intl.formatMessage({ id: 'checkout.giftCard.placeholder.1' })}`,
            className: 'm-b-0',
            max: 999_999_999,
            onFocus: () => setFocusedInputName(InputNameEnum.moneyAmount),
            onChange: (e) => {
              const currentValue = Number.parseFloat(e.target.value.replace(/,/g, '')).toFixed(2);
              form.setFieldValue([n('moneyAmount')], currentValue);
            },
            onKeyDown: (e) => {
              return e.preventDefault();
            },
            getInputRef: moneyAmountRef,
          }}
          formItemProps={{
            className: 'm-b-0 w-full',
            rules: [
              {
                transform(value) {
                  return value?.trim();
                },
                validator: (_, value) => {
                  const v = value;
                  if (!v) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ],
          }}
        />
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 19 }}>
            <KeyboardWrapper
              inputName={focusedInputName}
              form={form}
              keyboardRef={keyboardRef}
              maxLength={focusedInputName === InputNameEnum.cardNumber ? 20 : 9}
              keyboardProps={{
                inputPattern: /^\d+$/,
              }}
              direction={KeyboardDirection.rtl}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 5 }}>
            <ButtonStyled
              content={
                <span className="salon__checkout-giftcard-modal-keyboard-button-text">
                  {intl.formatMessage({ id: 'checkout.ok' })}
                </span>
              }
              buttonProps={{
                className: 'width-122 height-328',
                onClick: () => {
                  onSubmit();
                },
              }}
              isPrimary={true}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
}

export default GiftCard;
