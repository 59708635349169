import React from 'react';

export const SvgMiniStarIconUnactive = () => (
  <span>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.001 13.2086L12.7343 15.1974C13.1676 15.5197 13.7565 15.0863 13.5898 14.5752L12.5454 11.3641L15.2454 9.44189C15.6898 9.13078 15.4676 8.43078 14.9232 8.43078H11.6121L10.5343 5.07523C10.3676 4.56412 9.6454 4.56412 9.47874 5.07523L8.38985 8.43078H5.07874C4.53429 8.43078 4.31207 9.13078 4.75652 9.44189L7.45652 11.3641L6.41207 14.5752C6.2454 15.0863 6.83429 15.5086 7.26763 15.1974L10.001 13.2086Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  </span>
);
