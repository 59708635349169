import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { Promotion, TicketDTOStatusEnum, TicketTransactionDTO } from '../../../../apis/client-axios';
import { SvgPointRewardIcon } from '../../../../components/@svg/SvgPointRewardIcon';
import { SvgPrintIcon } from '../../../../components/@svg/SvgPrintIcon';
import { SvgRecycleIcon } from '../../../../components/@svg/SvgRecycleIcon';
import { SvgRemoveTechnicianIcon } from '../../../../components/@svg/SvgRemoveTechnicianIcon';
import ColWrap from '../../../../components/ColWrap';
import RowWrap from '../../../../components/RowWrap';
import { ButtonIcon } from '../../../../components/buttons/ButtonIcon';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { ButtonCirclePrimary } from '../../../../components/buttons/buttonCircle/ButtonCirclePrimary';
import {
  CHECKOUT_FULL_TIME_FORMAT,
  formatNumberThousand,
  formatNumberThousandWithDecimal,
  formatPhoneNumber,
} from '../../../../utils';
import BillItem from '../BillItem';
import BillItemServices from '../BillItemServices';
import BillItemTechnician from '../BillItemTechnician';
import BillStart from '../BillStart';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../checkoutConstants';
import {
  DesiredStatusesItem,
  IAppointmentBill,
  IListIdTotalPrice,
  IOpenModalCheckOut,
  IServiceCharge,
  IServicesItem,
  ITechnicianItem,
  PaymentInformation,
} from '../index';

interface IProps<T extends object> {
  appointmentBill: IAppointmentBill;
  openModal: IOpenModalCheckOut;
  idTechnician: number | string;
  listTotal: IListIdTotalPrice;
  taxDiscount: number;
  serviceChangePromotionAuto: number;
  serviceChangePromotionManual: number;
  serviceChangeNamePromotion: string;
  isOpenPayAndComplete: boolean;
  serviceCharge: IServiceCharge;
  discountsAndRewardsApplied: IDiscountsAndRewardsApplied | undefined;
  paymentInformation: PaymentInformation;
  taxPercent: number;
  listPromotion: AxiosResponse<Promotion, any> | undefined;
  isLoading: boolean;
  setDiscountsAndRewardsApplied: (discountsAndRewardsApplied: IDiscountsAndRewardsApplied | undefined) => void;
  setIdTechnician: (idTechnician: number | string) => void;
  setListSkill: (listSkill: number[]) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<IOpenModalCheckOut>>;
  handleRemoveTechnician: (idDeleteTechnician: number) => void;
  handleRemoveMerchandiseDiscount: (
    idDeleteTechnician: number | undefined,
    isRemoveWeekly: boolean,
    isRemoveBirthday: boolean,
    isRemoveOccasion: boolean
  ) => void;

  handleRemoveServices: (idDeleteServices: number, idTechnician: number) => void;
  handleRemoveServiceDiscount: (
    idDeleteServices: number,
    idTechnician: number,
    isRemoveWeekly: boolean,
    isRemoveBirthday: boolean,
    isRemoveOccasion: boolean
  ) => void;
  handleRemoveMerchandise: (idDeleteMerchandise: number | undefined) => void;
  setTaxPercent: (taxPercent: number) => void;
  setIsOpenPayAndComplete: (isOpenPayAndComplete: boolean) => void;
  handleRemoveCustomer: () => void;
  handleRemoveGiftCard: (idGiftCard: number) => void;
  setServiceCharge: (serviceCharge: IServiceCharge) => void;
  setIsEditServicePrice: (isEditServicePrice: boolean) => void;
  setIdService: (idService: number | string) => void;
  handleSubmitCheckout: (submitParams?: {
    status?: TicketDTOStatusEnum;
    ticketTransaction?: TicketTransactionDTO[];
  }) => void;
  setServiceItem: (serviceItem: IServicesItem) => void;
  setIndexServiceEdit: (indexService: number) => void;
  resetBill: () => void;
  checkListServiceInTechnician: (idTechnician: number) => void;
  checkIsHaveServiceHold: () => boolean;
  checkIsHaveServiceCreate: () => boolean;
}

function CheckoutBill<T extends object>(props: IProps<T>) {
  const {
    appointmentBill,
    openModal,
    idTechnician,
    listTotal,
    taxDiscount,
    serviceCharge,
    serviceChangePromotionAuto,
    serviceChangePromotionManual,
    serviceChangeNamePromotion,
    discountsAndRewardsApplied,
    paymentInformation,
    taxPercent,
    listPromotion,
    isLoading,
    setDiscountsAndRewardsApplied,
    setOpenModal,
    setIdTechnician,
    setListSkill,
    handleRemoveTechnician,
    handleRemoveServices,
    handleRemoveServiceDiscount,
    handleRemoveMerchandise,
    handleRemoveMerchandiseDiscount,
    setTaxPercent,
    setIsOpenPayAndComplete,
    handleRemoveCustomer,
    handleRemoveGiftCard,
    setServiceCharge,
    setIsEditServicePrice,
    setIdService,
    handleSubmitCheckout,
    setServiceItem,
    resetBill,
    checkListServiceInTechnician,
    checkIsHaveServiceHold,
    checkIsHaveServiceCreate,
    setIndexServiceEdit,
  } = props;
  const intl = useIntl();
  const [currentDateTime, setCurrentDateTime] = useState(dayjs().format(CHECKOUT_FULL_TIME_FORMAT));

  const checkActiveBill: boolean =
    appointmentBill.ticketMerchandise.length === 0 &&
    appointmentBill.customerId === 0 &&
    appointmentBill.technician.length === 0 &&
    !appointmentBill.giftCards?.length;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(dayjs().format(CHECKOUT_FULL_TIME_FORMAT));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const convertValue = (value: number | string, type?: DiscountTypeEnum) => {
    return value ? (type === DiscountTypeEnum.Percent ? Number(value) : value) : 0;
  };

  const handleCheckTax = () => {
    if (!!serviceChangePromotionManual && +serviceCharge.chargeValue === serviceChangePromotionManual) {
      return true;
    } else if (!!serviceChangePromotionAuto && +serviceCharge.chargeValue === serviceChangePromotionAuto) {
      return true;
    } else if (+serviceCharge.chargeValue === 0 && serviceCharge.name !== '') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Droppable droppableId={DesiredStatusesItem.CheckOutBIll} key={DesiredStatusesItem.CheckOutBIll}>
      {(providedDropZone: DroppableProvided) => (
        <>
          {checkActiveBill ? (
            <></>
          ) : (
            <div className="salon__checkout-container-customer">
              <div className="w-100">
                <RowWrap
                  isAutoFillRow={false}
                  isGutter={true}
                  gutter={24}
                  isWrap={false}
                  styleFill="between"
                  rowClassName="align-item-center"
                >
                  {!!appointmentBill.customer ? (
                    <ColWrap
                      colProps={{
                        span: 1,
                        className: 'p-0',
                      }}
                    >
                      <div className="p-l-12 m-t-15">
                        <ButtonIcon
                          icon={<SvgRemoveTechnicianIcon />}
                          className="m-r-20"
                          buttonProps={{
                            onClick: () => {
                              handleRemoveCustomer();
                              // setDiscountsAndRewardsApplied(undefined);
                            },
                          }}
                        />
                      </div>
                    </ColWrap>
                  ) : null}
                  <ColWrap
                    colProps={{
                      span: 12,
                    }}
                  >
                    <div className={`salon__checkout-customer ${!!appointmentBill?.customer ? '' : 'gap-6'}`}>
                      <p
                        className={`salon__checkout-customer-title m-b-0 ${
                          !!appointmentBill?.customer ? 'font-size-14' : 'font-size-16'
                        }`}
                      >
                        {!!appointmentBill?.customer
                          ? appointmentBill?.customer?.name
                          : intl.formatMessage({ id: 'checkout.customerAccount' })}
                      </p>
                      {!!appointmentBill?.customer?.phoneNumber && (
                        <span className="salon__checkout-customer-phone">
                          {formatPhoneNumber(appointmentBill.customer?.phoneNumber, ' - ')}
                        </span>
                      )}

                      <span
                        className={`salon__checkout-customer-subtitle cursor-pointer ${
                          !!!appointmentBill?.customer && 'd-flex gap-4'
                        }`}
                        onClick={() => {
                          !!appointmentBill?.customer
                            ? setOpenModal({
                                ...openModal,
                                openDiscountsAndRewards: true,
                              })
                            : setOpenModal({
                                ...openModal,
                                openListCustomer: true,
                              });
                        }}
                      >
                        {!appointmentBill?.customer ? (
                          <span className="font-size-12">
                            {intl.formatMessage({ id: 'checkout.pickRecentCheckin' })}
                          </span>
                        ) : (
                          <>
                            {formatNumberThousand(
                              appointmentBill.customer?.customerReward?.reduce(
                                (currentValue, item) => currentValue + item.reward,
                                0
                              ) || 0
                            )}{' '}
                            pts <SvgPointRewardIcon />
                          </>
                        )}
                      </span>
                    </div>
                  </ColWrap>
                  <ColWrap
                    colProps={{
                      span: !!appointmentBill.customer ? 11 : 12,
                    }}
                  >
                    <div className="d-flex align-items-end justify-content-end flex-column gap-4">
                      <span className="font-size-12 font-weight-600 color-292F33">{currentDateTime}</span>
                      <div className="d-flex align-items-center justify-content-between gap-8">
                        <ButtonCirclePrimary
                          content={<SvgPrintIcon />}
                          buttonProps={{
                            className: 'width-28 height-28',
                            onClick: () => {},
                          }}
                        />
                        <ButtonCirclePrimary
                          content={<SvgRecycleIcon />}
                          buttonProps={{
                            className: 'width-28 height-28',
                            onClick: () => {
                              if (listTotal.totalCash > 0 || !!paymentInformation.giftCardPayment?.totalValue) {
                                setOpenModal({
                                  ...openModal,
                                  openDeleteTicket: true,
                                });
                              } else {
                                resetBill();
                              }
                            },
                          }}
                        />
                      </div>
                    </div>
                  </ColWrap>
                </RowWrap>
              </div>
            </div>
          )}
          <div
            className={`salon__checkout-col-container ${
              checkActiveBill ? '' : 'salon__checkout-col-container-mid'
            } w-100`}
            {...providedDropZone.droppableProps}
            ref={providedDropZone.innerRef}
          >
            {checkActiveBill ? (
              <BillStart />
            ) : (
              <>
                <div className="d-flex align-items-center flex-wrap p-t-12 gap-8 salon__checkout-technician-dropzone">
                  {appointmentBill?.technician.map((item: ITechnicianItem, index: number) => (
                    <React.Fragment key={index}>
                      <div className="w-100">
                        <BillItemTechnician
                          name={item.name}
                          defaultAvatar={item.defaultAvatar ?? ''}
                          active={item.id === idTechnician}
                          onClick={() => handleRemoveTechnician(+item.id)}
                          onClickActiveTechnician={() => {
                            setIdTechnician(item.id);
                            setListSkill(item.skills ?? []);
                            checkListServiceInTechnician(item.id as number);
                          }}
                        />
                      </div>
                      {item?.services?.map((itemService: IServicesItem, indexService: number) => (
                        <React.Fragment key={indexService}>
                          <BillItemServices
                            name={itemService.name}
                            price={itemService.price}
                            supply={itemService.supply ?? 0}
                            onClick={() => {
                              handleRemoveServices(itemService.id, +item.id);
                            }}
                            onClickEdit={() => {
                              setIsEditServicePrice(true);
                              setIdTechnician(item.id);
                              setListSkill(item.skills ?? []);
                              setIdService(itemService.id);
                              setOpenModal({
                                ...openModal,
                                openGeneralService: true,
                              });
                              setServiceItem(itemService);
                              setIndexServiceEdit(indexService);
                            }}
                          />

                          {itemService.discount?.weeklyDiscount &&
                          discountsAndRewardsApplied?.weeklyDiscount?.amount ? (
                            <div key={index} className="p-l-67 p-r-20 w-100 m-b-8">
                              <BillItem
                                name={`${intl.formatMessage(
                                  { id: 'checkout.discount.weekly' },
                                  {
                                    index: convertValue(
                                      discountsAndRewardsApplied?.weeklyDiscount?.amount,
                                      discountsAndRewardsApplied?.weeklyDiscount?.type
                                    ),
                                    symbol:
                                      discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Percent
                                        ? discountsAndRewardsApplied?.weeklyDiscount?.type
                                        : '',
                                    symbolDollar:
                                      discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                                        ? discountsAndRewardsApplied?.weeklyDiscount?.type
                                        : '',
                                  }
                                )}`}
                                price={itemService.discount.weeklyDiscount.moneyDiscount}
                                onClick={() => {
                                  handleRemoveServiceDiscount(itemService.id, +item.id, true, false, false);
                                  const checkItemIds = (
                                    discountsAndRewardsApplied.weeklyDiscount?.serviceItemIds.filter(
                                      (itemFil) => itemFil !== itemService.id
                                    ) as number[]
                                  ).length;
                                  if (!!checkItemIds) {
                                    setDiscountsAndRewardsApplied({
                                      ...discountsAndRewardsApplied,
                                      weeklyDiscount: {
                                        amount: discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0,
                                        serviceItemIds:
                                          discountsAndRewardsApplied.weeklyDiscount?.serviceItemIds.filter(
                                            (itemFil) => itemFil !== itemService.id
                                          ) as number[],
                                        day: discountsAndRewardsApplied.weeklyDiscount?.day ?? [],
                                        type:
                                          discountsAndRewardsApplied.weeklyDiscount?.type ?? DiscountTypeEnum.Dollar,
                                      },
                                    });
                                  } else {
                                    setDiscountsAndRewardsApplied({
                                      ...discountsAndRewardsApplied,
                                      weeklyDiscount: undefined,
                                    });
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {itemService.discount?.birthdayDiscount &&
                          discountsAndRewardsApplied?.birthdayDiscount?.amount ? (
                            <div className="p-l-67 p-r-20 w-100 m-b-8">
                              <BillItem
                                name={`${intl.formatMessage(
                                  { id: 'checkout.discount.birthday' },
                                  {
                                    index: convertValue(
                                      discountsAndRewardsApplied?.birthdayDiscount?.amount,
                                      discountsAndRewardsApplied?.birthdayDiscount?.type
                                    ),
                                    symbol:
                                      discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Percent
                                        ? discountsAndRewardsApplied?.birthdayDiscount?.type
                                        : '',
                                    symbolDollar:
                                      discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                                        ? discountsAndRewardsApplied?.birthdayDiscount?.type
                                        : '',
                                  }
                                )}`}
                                price={itemService.discount?.birthdayDiscount.moneyDiscount}
                                onClick={() => {
                                  handleRemoveServiceDiscount(itemService.id, +item.id, false, true, false);
                                  const checkItemIds = (
                                    discountsAndRewardsApplied.birthdayDiscount?.serviceItemIds.filter(
                                      (itemFil) => itemFil !== itemService.id
                                    ) as number[]
                                  ).length;
                                  if (!!checkItemIds) {
                                    setDiscountsAndRewardsApplied({
                                      ...discountsAndRewardsApplied,
                                      birthdayDiscount: {
                                        amount: discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0,
                                        serviceItemIds:
                                          discountsAndRewardsApplied.birthdayDiscount?.serviceItemIds.filter(
                                            (itemFil) => itemFil !== itemService.id
                                          ) as number[],
                                        type:
                                          discountsAndRewardsApplied.birthdayDiscount?.type ?? DiscountTypeEnum.Dollar,
                                      },
                                    });
                                  } else {
                                    setDiscountsAndRewardsApplied({
                                      ...discountsAndRewardsApplied,
                                      birthdayDiscount: undefined,
                                    });
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <></>
                          )}

                          {itemService.discount?.occasionDiscount &&
                          discountsAndRewardsApplied?.occasionDiscount?.amount ? (
                            <div className="p-l-67 p-r-20 w-100 m-b-8">
                              <BillItem
                                name={`${intl.formatMessage(
                                  { id: 'checkout.discount.occasion' },
                                  {
                                    index: convertValue(
                                      discountsAndRewardsApplied?.occasionDiscount?.amount,
                                      discountsAndRewardsApplied?.occasionDiscount?.type
                                    ),
                                    symbol:
                                      discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Percent
                                        ? discountsAndRewardsApplied?.occasionDiscount?.type
                                        : '',
                                    symbolDollar:
                                      discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                                        ? discountsAndRewardsApplied?.occasionDiscount?.type
                                        : '',
                                  }
                                )}`}
                                price={itemService.discount?.occasionDiscount.moneyDiscount}
                                onClick={() => {
                                  handleRemoveServiceDiscount(itemService.id, +item.id, false, false, true);
                                  const checkItemIds = (
                                    discountsAndRewardsApplied.occasionDiscount?.serviceItemIds.filter(
                                      (itemFil) => itemFil !== itemService.id
                                    ) as number[]
                                  ).length;
                                  const filterId = discountsAndRewardsApplied.occasionDiscount?.serviceItemIds.filter(
                                    (itemFil) => itemFil !== itemService.id
                                  ) as number[];

                                  if (!!checkItemIds) {
                                    setDiscountsAndRewardsApplied({
                                      ...discountsAndRewardsApplied,
                                      occasionDiscount: {
                                        amount: discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0,
                                        serviceItemIds: filterId,
                                        name: discountsAndRewardsApplied?.occasionDiscount?.name ?? '',
                                        type:
                                          discountsAndRewardsApplied.occasionDiscount?.type ?? DiscountTypeEnum.Dollar,
                                      },
                                    });
                                  } else {
                                    setDiscountsAndRewardsApplied({
                                      ...discountsAndRewardsApplied,
                                      occasionDiscount: undefined,
                                    });
                                  }
                                }}
                              />
                            </div>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </div>

                {!!appointmentBill.ticketMerchandise?.length || !!appointmentBill.giftCards?.length ? (
                  <div className="salon__checkout-block">
                    <div className="d-flex flex-column gap-8">
                      {!!appointmentBill.giftCards?.length ? (
                        appointmentBill.giftCards.map((item, index) => (
                          <BillItem
                            key={index}
                            name={intl.formatMessage({ id: 'checkout.giftCard' })}
                            price={item.giftCardValue}
                            onClick={() => {
                              handleRemoveGiftCard(item?.id ?? 0);
                            }}
                          />
                        ))
                      ) : (
                        <></>
                      )}
                      {!!appointmentBill.ticketMerchandise?.length &&
                        appointmentBill.ticketMerchandise.map((item, index) => (
                          <div key={index}>
                            <BillItem
                              name={item.name}
                              price={item.merchandisePrice}
                              onClick={() => {
                                handleRemoveMerchandise(item.idDelete);
                              }}
                            />
                            {item.discount?.occasionDiscount && discountsAndRewardsApplied?.occasionDiscount?.amount ? (
                              <div className="p-l-20 m-t-8">
                                <BillItem
                                  name={`${intl.formatMessage(
                                    { id: 'checkout.discount.occasion' },
                                    {
                                      index: convertValue(
                                        discountsAndRewardsApplied?.occasionDiscount?.amount,
                                        discountsAndRewardsApplied?.occasionDiscount?.type
                                      ),
                                      symbol:
                                        discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Percent
                                          ? DiscountTypeEnum.Percent
                                          : '',
                                      symbolDollar:
                                        discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                                          ? DiscountTypeEnum.Dollar
                                          : '',
                                    }
                                  )}`}
                                  price={item.discount?.occasionDiscount.moneyDiscount}
                                  onClick={() => {
                                    handleRemoveMerchandiseDiscount(item.merchandiseId, false, false, true);

                                    const checkItemIds = (
                                      discountsAndRewardsApplied.occasionDiscount?.serviceItemIds.filter(
                                        (itemFil) => itemFil !== item.merchandiseId
                                      ) as number[]
                                    ).length;
                                    if (!!checkItemIds) {
                                      setDiscountsAndRewardsApplied({
                                        ...discountsAndRewardsApplied,
                                        occasionDiscount: {
                                          name: discountsAndRewardsApplied?.occasionDiscount?.name ?? '',
                                          amount: discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0,
                                          serviceItemIds:
                                            discountsAndRewardsApplied.occasionDiscount?.serviceItemIds.filter(
                                              (itemFil) => itemFil !== item.merchandiseId
                                            ) as number[],
                                          type:
                                            discountsAndRewardsApplied.occasionDiscount?.type ??
                                            DiscountTypeEnum.Dollar,
                                        },
                                      });
                                    } else {
                                      setDiscountsAndRewardsApplied({
                                        ...discountsAndRewardsApplied,
                                        occasionDiscount: undefined,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            ) : null}
                            {item.discount?.birthdayDiscount && discountsAndRewardsApplied?.birthdayDiscount?.amount ? (
                              <div className="p-l-20 m-t-8">
                                <BillItem
                                  name={`${intl.formatMessage(
                                    { id: 'checkout.discount.birthday' },
                                    {
                                      index: convertValue(
                                        discountsAndRewardsApplied?.birthdayDiscount?.amount,
                                        discountsAndRewardsApplied?.birthdayDiscount?.type
                                      ),
                                      symbol:
                                        discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Percent
                                          ? discountsAndRewardsApplied?.birthdayDiscount?.type
                                          : '',
                                      symbolDollar:
                                        discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                                          ? discountsAndRewardsApplied?.birthdayDiscount?.type
                                          : '',
                                    }
                                  )}`}
                                  price={item.discount.birthdayDiscount.moneyDiscount}
                                  onClick={() => {
                                    handleRemoveMerchandiseDiscount(item.merchandiseId, false, true, false);
                                    const checkItemIds = (
                                      discountsAndRewardsApplied.birthdayDiscount?.serviceItemIds.filter(
                                        (itemFil) => itemFil !== item.merchandiseId
                                      ) as number[]
                                    ).length;
                                    if (!!checkItemIds) {
                                      setDiscountsAndRewardsApplied({
                                        ...discountsAndRewardsApplied,
                                        birthdayDiscount: {
                                          amount: discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0,
                                          serviceItemIds:
                                            discountsAndRewardsApplied.birthdayDiscount?.serviceItemIds.filter(
                                              (itemFil) => itemFil !== item.merchandiseId
                                            ) as number[],
                                          type:
                                            discountsAndRewardsApplied.birthdayDiscount?.type ??
                                            DiscountTypeEnum.Dollar,
                                        },
                                      });
                                    } else {
                                      setDiscountsAndRewardsApplied({
                                        ...discountsAndRewardsApplied,
                                        birthdayDiscount: undefined,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            {item.discount?.weeklyDiscount && discountsAndRewardsApplied?.weeklyDiscount?.amount ? (
                              <div className="p-l-20 m-t-8">
                                <BillItem
                                  name={`${intl.formatMessage(
                                    { id: 'checkout.discount.weekly' },
                                    {
                                      index: convertValue(
                                        discountsAndRewardsApplied?.weeklyDiscount?.amount,
                                        discountsAndRewardsApplied?.weeklyDiscount?.type
                                      ),
                                      symbol:
                                        discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Percent
                                          ? discountsAndRewardsApplied?.weeklyDiscount?.type
                                          : '',
                                      symbolDollar:
                                        discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                                          ? discountsAndRewardsApplied?.weeklyDiscount?.type
                                          : '',
                                    }
                                  )}`}
                                  price={item.discount?.weeklyDiscount.moneyDiscount}
                                  onClick={() => {
                                    handleRemoveMerchandiseDiscount(item.merchandiseId, true, false, false);
                                    const checkItemIds = (
                                      discountsAndRewardsApplied.weeklyDiscount?.serviceItemIds.filter(
                                        (itemFil) => itemFil !== item.merchandiseId
                                      ) as number[]
                                    ).length;
                                    if (!!checkItemIds) {
                                      setDiscountsAndRewardsApplied({
                                        ...discountsAndRewardsApplied,
                                        weeklyDiscount: {
                                          amount: discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0,
                                          serviceItemIds:
                                            discountsAndRewardsApplied.weeklyDiscount?.serviceItemIds.filter(
                                              (itemFil) => itemFil !== item.merchandiseId
                                            ) as number[],
                                          day: discountsAndRewardsApplied.weeklyDiscount?.day ?? [],
                                          type:
                                            discountsAndRewardsApplied.weeklyDiscount?.type ?? DiscountTypeEnum.Dollar,
                                        },
                                      });
                                    } else {
                                      setDiscountsAndRewardsApplied({
                                        ...discountsAndRewardsApplied,
                                        weeklyDiscount: undefined,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="salon__checkout-block">
                  {discountsAndRewardsApplied &&
                  (discountsAndRewardsApplied.generalTicketDiscount?.amount ||
                    discountsAndRewardsApplied.generalMerchandiseDiscount?.amount ||
                    discountsAndRewardsApplied.referralDiscount?.amount) ? (
                    <div
                      className={
                        listTotal.referralDiscount ||
                        listTotal.totalDiscountGeneralMerchandise ||
                        listTotal.totalDiscountGeneralTicket
                          ? 'salon__checkout-bill-general-discount'
                          : ''
                      }
                    >
                      {listTotal.referralDiscount > 0 ? (
                        <div className={discountsAndRewardsApplied.generalTicketDiscount?.amount ? 'm-b-8' : ''}>
                          <BillItem
                            name={intl.formatMessage({ id: 'checkout.referralCode' })}
                            price={listTotal.referralDiscount}
                            onClick={() => {
                              setDiscountsAndRewardsApplied({
                                ...discountsAndRewardsApplied,
                                // referralDiscount: {
                                //   amount: 0,
                                //   referralCode: '',
                                //   type: DiscountTypeEnum.Dollar,
                                // },
                                referralDiscount: undefined,
                              });
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {discountsAndRewardsApplied.generalTicketDiscount?.amount ? (
                        <BillItem
                          name={`${intl.formatMessage(
                            { id: 'checkout.general.discount.ticket' },
                            {
                              index: convertValue(discountsAndRewardsApplied.generalTicketDiscount?.amount),
                              symbol: discountsAndRewardsApplied.generalTicketDiscount?.type,
                            }
                          )}`}
                          price={listTotal.totalDiscountGeneralTicket}
                          onClick={() => {
                            setDiscountsAndRewardsApplied({
                              ...discountsAndRewardsApplied,
                              // generalTicketDiscount: {
                              //   amount: 0,
                              //   isCustomAmount: false,
                              //   type: DiscountTypeEnum.Percent,
                              // },
                              generalTicketDiscount: undefined,
                            });
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      {listTotal.totalDiscountGeneralMerchandise > 0 &&
                      discountsAndRewardsApplied.generalMerchandiseDiscount?.amount ? (
                        <div className={discountsAndRewardsApplied.generalTicketDiscount?.amount ? 'm-t-8' : ''}>
                          <BillItem
                            name={`${intl.formatMessage(
                              { id: 'checkout.general.discount.merchandise' },
                              {
                                index: convertValue(discountsAndRewardsApplied.generalMerchandiseDiscount?.amount),
                                symbol: discountsAndRewardsApplied.generalMerchandiseDiscount?.type,
                              }
                            )}`}
                            price={listTotal.totalDiscountGeneralMerchandise}
                            onClick={() => {
                              setDiscountsAndRewardsApplied({
                                ...discountsAndRewardsApplied,
                                // generalMerchandiseDiscount: {
                                //   amount: 0,
                                //   isCustomAmount: false,
                                //   type: DiscountTypeEnum.Percent,
                                // },
                                generalMerchandiseDiscount: undefined,
                              });
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="d-flex gap-8 flex-column">
                    {listTotal.total > 0 ? (
                      <BillItem name={intl.formatMessage({ id: 'checkout.total' })} price={listTotal.total} />
                    ) : (
                      <></>
                    )}
                    {listTotal.totalDiscount > 0 ? (
                      <BillItem
                        name={intl.formatMessage({ id: 'checkout.totalDiscount' })}
                        price={listTotal.totalDiscount}
                      />
                    ) : (
                      <></>
                    )}

                    {listTotal.saleTax > 0 ? (
                      <BillItem
                        name={`${intl.formatMessage(
                          { id: 'checkout.saleTex.title' },
                          { index: convertValue(taxPercent, DiscountTypeEnum.Percent) }
                        )}`}
                        price={taxDiscount}
                        onClick={() => {
                          setTaxPercent(-1);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {/* {console.log(serviceCharge) as any}
                    {console.log(serviceChangePromotionAuto) as any}
                    {console.log(serviceChangePromotionManual) as any} */}
                    {+serviceCharge.chargeValue !== -1 && handleCheckTax() ? (
                      <BillItem
                        name={`${serviceCharge.name ?? serviceChangeNamePromotion} (${convertValue(
                          serviceCharge.chargeValue ?? serviceChangePromotionAuto ?? serviceChangePromotionManual,
                          DiscountTypeEnum.Percent
                        )}%)`}
                        price={+listTotal.serviceCharge}
                        onClick={() => {
                          setServiceCharge({
                            chargeValue: -1,
                            name: '',
                          });
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <BillItem
                      name={intl.formatMessage({ id: 'checkout.totalPayment' })}
                      price={listTotal.totalPayments}
                      isBoldName={true}
                    />
                  </div>
                </div>
                <div className="salon__checkout-block">
                  <div className="d-flex gap-8 flex-column">
                    <BillItem
                      name={intl.formatMessage({ id: 'checkout.payment' })}
                      isBoldName={true}
                      isHidePrice={true}
                    />
                    {listTotal.totalDeposit ? (
                      <BillItem name={intl.formatMessage({ id: 'checkout.deposit' })} price={listTotal.totalDeposit} />
                    ) : (
                      <></>
                    )}
                    {listTotal.totalCash ? (
                      <BillItem name={intl.formatMessage({ id: 'checkout.cash' })} price={listTotal.totalCash} />
                    ) : (
                      <></>
                    )}
                    {paymentInformation.giftCardPayment?.totalValue ? (
                      <BillItem
                        name={intl.formatMessage({ id: 'checkout.giftCard' })}
                        price={paymentInformation.giftCardPayment?.totalValue}
                      />
                    ) : (
                      <></>
                    )}
                    {listTotal.totalRedeeming ? (
                      <BillItem
                        name={intl.formatMessage({ id: 'checkout.redeeming' })}
                        price={listTotal.totalRedeeming}
                        ptsReward={
                          <>
                            {`${discountsAndRewardsApplied?.rewardBalance?.points} ${intl.formatMessage({
                              id: 'checkout.discountAndRewards.rewards.points',
                            })} / $${formatNumberThousandWithDecimal(listTotal.totalRedeeming ?? 0)}`}
                          </>
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <BillItem
                      name={intl.formatMessage({ id: 'checkout.balanceToPay' })}
                      price={listTotal.totalBalanceTotalPay}
                      isWarning={true}
                    />
                  </div>
                </div>

                {listPromotion &&
                listPromotion.data &&
                listPromotion.data.loyaltyReward &&
                listPromotion.data.loyaltyReward.reward &&
                listPromotion.data.loyaltyReward.loyaltyReward &&
                listTotal.totalPayments > +listPromotion.data.loyaltyReward.saleTicket ? (
                  <div className="salon__checkout-block">
                    <div className="d-flex gap-8 flex-column">
                      <BillItem
                        name={intl.formatMessage({ id: 'checkout.rewarding' })}
                        price={listTotal.totalRedeeming}
                        ptsReward={
                          <>
                            {`${
                              listPromotion &&
                              listPromotion.data &&
                              listPromotion.data.loyaltyReward &&
                              listPromotion.data.loyaltyReward.reward &&
                              formatNumberThousand(
                                listPromotion.data.loyaltyReward.reward * Math.floor(listTotal.totalPayments)
                              )
                            } ${intl.formatMessage({
                              id: 'checkout.discountAndRewards.rewards.points',
                            })} / $${formatNumberThousandWithDecimal(listTotal.totalPayments ?? 0)}`}
                          </>
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="d-flex flex-column align-items-center justify-content-center gap-8 m-t-20 m-b-20">
                  <ButtonStyled
                    content={intl.formatMessage({ id: 'checkout.payAndComplete' })}
                    isPrimary={true}
                    buttonProps={{
                      className: 'width-266',
                      disabled:
                        isLoading ||
                        appointmentBill.customerId === 0 ||
                        listTotal.total === 0 ||
                        !checkIsHaveServiceCreate(),
                      onClick: () => {
                        setIsOpenPayAndComplete(true);
                      },
                      loading: isLoading,
                    }}
                  />
                  <ButtonStyled
                    content={intl.formatMessage({ id: 'checkout.holdThisTicket' })}
                    isPrimary={false}
                    buttonProps={{
                      className: 'width-266',
                      disabled:
                        isLoading ||
                        appointmentBill.customerId === 0 ||
                        listTotal.total === 0 ||
                        !checkIsHaveServiceHold(),
                      onClick: () => {
                        handleSubmitCheckout({
                          status: TicketDTOStatusEnum.Hold,
                        });
                      },
                      loading: isLoading,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </Droppable>
  );
}

export default CheckoutBill;
