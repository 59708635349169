import { Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { IHandleSubmitCheckoutProps, IListIdTotalPrice, ITipInformation, PaymentInformation } from '../../../index';
import {
  TicketDTOStatusEnum,
  TicketTransactionDTO,
  TicketTransactionDTOStatusEnum,
  TicketTransactionDTOTypePaymentEnum,
} from '../../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../../components/FormWrap';
import RowWrap from '../../../../../../components/RowWrap';
import { StyledModal } from '../../../../../../components/StyledModal';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import {
  convertFormatNumberToNumber,
  formatNumberThousandWithDecimal,
  handleTruncateToTwoDecimal,
} from '../../../../../../utils';
import KeyboardWrapper, { KeyboardDirection } from '../../../keyboard';

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  setIsOpenPayAndComplete: (state: boolean) => void;
  listTotal: IListIdTotalPrice;
  setListTotal: (listTotal: IListIdTotalPrice) => void;
  handleSubmitCheckout: (submitParams?: IHandleSubmitCheckoutProps) => void;
  onChangePaymentInformation: React.Dispatch<React.SetStateAction<PaymentInformation>>;
  paymentInformation?: PaymentInformation;
  leftBalanceToPay: number;
  saveTipAndTechnician: (totalTip: number) => void;
  tipInformation: ITipInformation | undefined;
  isLoading: boolean;
}

interface FormData {
  customerCash?: number;
  customerTipLeft?: number;
}

enum InputNameEnum {
  CustomerCash = 'customerCash',
  CustomerTipLeft = 'customerTipLeft',
}

const arrayDollarMoney = [100, 50, 20, 10, 5, 1];

const arrayCentMoney = [25, 10, 5, 1];

export const CashPayment: React.FC<IProps> = (props) => {
  const {
    open,
    setOpen,
    setIsOpenPayAndComplete,
    listTotal,
    setListTotal,
    handleSubmitCheckout,
    onChangePaymentInformation,
    paymentInformation,
    leftBalanceToPay,
    saveTipAndTechnician,
    tipInformation,
    isLoading,
  } = props;
  const [form] = Form.useForm();
  const intl = useIntl();
  const keyboardRef = useRef<any>();
  const n = (key: keyof FormData) => key;
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();
  const customerCashRef = useRef<HTMLInputElement>(null);
  const customerTipLeftRef = useRef<HTMLInputElement>(null);
  const customerCash = Form.useWatch(n('customerCash'), form);
  const customerTipLeft = Form.useWatch(n('customerTipLeft'), form);

  useEffect(() => {
    if (customerCash && customerCashRef.current) {
      const [integer] = customerCash.replace(/,/g, '').split('.');
      customerCashRef.current.selectionStart = customerCashRef.current.selectionEnd =
        customerCash.length + Math.floor(integer / 3);
    }

    if (customerTipLeft && customerTipLeftRef.current) {
      const [integer] = customerTipLeft.replace(/,/g, '').split('.');
      customerTipLeftRef.current.selectionStart = customerTipLeftRef.current.selectionEnd =
        customerTipLeft.length + Math.floor(integer / 3);
    }
  }, [customerCash, customerTipLeft]);

  const onClickOK = (value: FormData) => {
    const customerCash = convertFormatNumberToNumber(value.customerCash || 0);
    const customerTipLeft = convertFormatNumberToNumber(value.customerTipLeft || 0);

    const ticketTransaction = [...(paymentInformation?.ticketTransaction ?? [])];

    const customerHandYouTrans: TicketTransactionDTO = {
      // money: customerCash > leftBalanceToPay ? handleTruncateToTwoDecimal(leftBalanceToPay) : customerCash,
      money: customerCash,
      typePayment: TicketTransactionDTOTypePaymentEnum.Cash,
      status: TicketTransactionDTOStatusEnum.CustomerHandToYou,
      // tipMoney:
      //   customerTipLeft +
      //   (customerCash < handleTruncateToTwoDecimal(leftBalanceToPay)
      //     ? 0
      //     : customerCash - handleTruncateToTwoDecimal(leftBalanceToPay)),
      tipMoney: customerTipLeft,
    };
    ticketTransaction.push(customerHandYouTrans);

    // let totalTip = 0;

    // ticketTransaction?.forEach((tran) => {
    //   totalTip += tran.tipMoney ?? 0;
    // });
    let totalTip = tipInformation?.tip ?? 0;
    totalTip += customerTipLeft;
    if (customerCash > handleTruncateToTwoDecimal(leftBalanceToPay)) {
      totalTip += customerCash - handleTruncateToTwoDecimal(leftBalanceToPay);
    }
    // if (customerCash < listTotal.totalBalanceTotalPay - Number(paymentInformation?.amount || 0)) {
    if (customerCash < leftBalanceToPay) {
      onChangePaymentInformation((prev) => ({
        ...prev,
        amount: customerCash + Number(paymentInformation?.amount || 0),
        // tip: customerTipLeft + Number(paymentInformation?.tip || 0),
        tip: totalTip,
        cashDiscount: 0,
        ticketTransaction: ticketTransaction,
      }));
    } else {
      onChangePaymentInformation((prev) => ({
        amount: customerCash + Number(paymentInformation?.amount || 0),
        // tip: customerTipLeft + Number(paymentInformation?.tip || 0),
        tip: totalTip,
        cashDiscount: 0,
        ticketTransaction: ticketTransaction,
      }));

      handleSubmitCheckout({
        status: TicketDTOStatusEnum.Completed,
        ticketTransaction: ticketTransaction,
        totalTip: totalTip,
        balanceToPay: 0,
      });
    }
    saveTipAndTechnician(totalTip);
    setListTotal({
      ...listTotal,
      totalCash: customerCash + Number(paymentInformation?.amount || 0),
    });
    setOpen(false);
  };

  const onClickQuickNumber = (value: number) => {
    if (focusedInputName) {
      form.setFieldValue(focusedInputName, formatNumberThousandWithDecimal(value));
    }
  };

  const onClickNoTip = () => {
    form.setFieldValue(n('customerTipLeft'), formatNumberThousandWithDecimal(0));
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpen(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: `checkout.payAndComplete.title.cashPayment` })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal',
        width: 700,
        footer: null,
      }}
    >
      <FormWrap form={form} onFinish={onClickOK} className="salon__checkout__pay-n-complete-modal__cash-payment">
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <p className="font-size-16 font-weight-500 m-0">
              {`${intl.formatMessage({ id: 'checkout.payAndComplete.balanceToPay' })}: `}
              <span className="font-weight-700">
                $
                {formatNumberThousandWithDecimal(
                  // Number(listTotal?.totalBalanceTotalPay || 0) - Number(paymentInformation?.amount || 0)
                  leftBalanceToPay
                )}
              </span>
            </p>
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <FormInputNumber
              name={n('customerCash')}
              prefix={<SvgDollarIcon />}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.customerCash.label' })}
              numericFormatProps={{
                placeholder: '0.00',
                className: 'm-b-0',
                onFocus: () => setFocusedInputName(InputNameEnum.CustomerCash),
                getInputRef: customerCashRef,
                onKeyDown: (e) => {
                  return e.preventDefault();
                },
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
                // rules: [
                //   {
                //     required: true,
                //     message: '',
                //   },
                // ],
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <FormInputNumber
              name={n('customerTipLeft')}
              prefix={<SvgDollarIcon />}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.customerTipLeft.label' })}
              numericFormatProps={{
                placeholder: '0.00',
                className: 'm-b-0',
                onFocus: () => setFocusedInputName(InputNameEnum.CustomerTipLeft),
                onKeyDown: (e) => {
                  return e.preventDefault();
                },
                // disabled: !customerCash || customerCash === '0.00',
                getInputRef: customerTipLeftRef,
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
              {/* no tip */}
              <ColWrap colProps={{ span: 24 }}>
                <CustomButton
                  type="primary"
                  buttonProps={{
                    className:
                      'height-76 w-full border-radius-12px font-size-24 font-weight-700 background-color-EE7E68',
                    onClick: onClickNoTip,
                  }}
                  content={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.noTip' })}
                />
              </ColWrap>

              {/* numeric keyboard */}
              <ColWrap colProps={{ span: 18 }}>
                <KeyboardWrapper
                  inputName={focusedInputName}
                  form={form}
                  keyboardRef={keyboardRef}
                  maxLength={9}
                  keyboardProps={{
                    inputPattern: /^\d+$/,
                  }}
                  direction={KeyboardDirection.rtl}
                />
              </ColWrap>

              {/* ok button */}
              <ColWrap colProps={{ span: 6 }}>
                <CustomButton
                  type="primary"
                  buttonProps={{
                    className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                    htmlType: 'submit',
                    loading: isLoading,
                    disabled: isLoading,
                  }}
                  content={intl.formatMessage({ id: 'common.OK' })}
                />
              </ColWrap>

              {/* quick number */}
              {arrayDollarMoney.map((money, index) => (
                <ColWrap colProps={{ span: 8 }} key={`DoolarMoney_${index}`}>
                  <CustomButton
                    type="primary"
                    buttonProps={{
                      className:
                        'height-76 w-full border-radius-12px font-size-24 font-weight-700 background-color-CCF4FF color-292F33',
                      onClick: () => onClickQuickNumber(money),
                    }}
                    content={`$${formatNumberThousandWithDecimal(money)}`}
                  />
                </ColWrap>
              ))}

              {arrayCentMoney.map((money, index) => (
                <ColWrap colProps={{ span: 6 }} key={`CentMoney_${index}`}>
                  <CustomButton
                    type="primary"
                    buttonProps={{
                      className:
                        'height-76 w-full border-radius-12px font-size-24 font-weight-700 background-color-CCF4FF color-292F33',
                      onClick: () => onClickQuickNumber(money * 0.01),
                    }}
                    content={`${formatNumberThousandWithDecimal(money)}c`}
                  />
                </ColWrap>
              ))}
            </RowWrap>
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
};
