import { Button, ButtonProps } from 'antd';
import React, { ReactNode } from 'react';
import './buttonStyled.scss';
type Props = {
  label?: string;
  buttonProps?: ButtonProps;
  content: ReactNode | string;
  isPrimary: boolean;
  isButtonCancel?: boolean;
  isCancelPopup?: boolean;
  isLarge?: boolean;
  isWarning?: boolean;
};
export const ButtonStyled: React.FC<Props> = ({
  buttonProps,
  isPrimary,
  isButtonCancel,
  isCancelPopup,
  isLarge,
  isWarning,
  content,
}) => {
  return (
    <>
      {isButtonCancel ? (
        <>
          {isCancelPopup ? (
            <Button
              type="default"
              {...buttonProps}
              className={`salon__button-cancel-popup ${isLarge ? 'height-64 font-size-32' : 'height-38 font-size-16'} ${
                buttonProps?.className ?? ''
              }`}
            >
              {content}
            </Button>
          ) : (
            <Button
              type="default"
              {...buttonProps}
              className={`${isWarning ? 'salon__button-warning' : 'salon__button-cancel'} ${
                isLarge ? 'height-64 font-size-32' : 'height-38 font-size-16'
              } ${buttonProps?.className ?? ''}`}
            >
              {content}
            </Button>
          )}
        </>
      ) : (
        <>
          {isPrimary ? (
            <Button
              type="primary"
              {...buttonProps}
              className={`salon__button-primary ${isLarge ? 'height-64 font-size-32' : 'height-38 font-size-16'} ${
                buttonProps?.className ?? ''
              }`}
            >
              {content}
            </Button>
          ) : (
            <Button
              type="default"
              {...buttonProps}
              className={`salon__button-secondary ${isLarge ? 'height-64 font-size-32' : 'height-38 font-size-16'} ${
                buttonProps?.className ?? ''
              }`}
            >
              {content}
            </Button>
          )}
        </>
      )}
    </>
  );
};
