import { ReactNode } from 'react';
import './pageContainer.scss';

export interface IPageContainerProps {
  children: ReactNode;
  paddingPage?: number;
  marginTop?: number;
  className?: string;
  isHidePaddingPage?: boolean;
}

function PageContainer(props: IPageContainerProps) {
  const { children, paddingPage, marginTop, className, isHidePaddingPage } = props;
  return (
    <div
      className={
        `salon__page-container ${isHidePaddingPage ? '' : `p-${paddingPage ?? 40}`} m-t-${marginTop ?? 20}` +
        (className ? ' ' + className : '')
      }
    >
      {children}
    </div>
  );
}
export default PageContainer;
