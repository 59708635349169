import { Button, ButtonProps } from 'antd';
import './customButton.scss';
import { ButtonType } from 'antd/lib/button';
import { ReactNode } from 'react';
type Props = {
  label?: string;
  buttonProps?: ButtonProps;
  content?: ReactNode;
  type?: 'cancel' | 'delete' | ButtonType;
};

export const CustomButton: React.FC<Props> = ({ buttonProps, type, ...rest }) => {
  if (type === 'cancel') {
    return (
      <Button {...buttonProps} type="default" className={'custom-btn btn-cancel ' + (buttonProps?.className || '')}>
        {rest.content}
      </Button>
    );
  } else if (type === 'delete') {
    return (
      <Button {...buttonProps} type="default" className={'custom-btn btn-delete ' + (buttonProps?.className || '')}>
        {rest.content}
      </Button>
    );
  }
  return (
    <Button {...buttonProps} type={type} className={'custom-btn ' + (buttonProps?.className || '')}>
      {rest.content}
    </Button>
  );
};
