import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { customerApi } from '../../../../../../apis';
import { Customer, Promotion } from '../../../../../../apis/client-axios';
import ColWrap from '../../../../../../components/ColWrap';
import { FormInput } from '../../../../../../components/Form/FormInput';
import FormWrap from '../../../../../../components/FormWrap';
import NotificationError from '../../../../../../components/HandleShowNotiError';
import RowWrap from '../../../../../../components/RowWrap';
import { ButtonStyled } from '../../../../../../components/buttons/ButtonStyled';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { formatOnlyDate, formatTimeHHMMa } from '../../../../../../utils';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../../../checkoutConstants';

interface IProps {
  promotion?: Promotion;
  customer?: Customer;
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
  confirmAddDiscountsAndRewards: (
    onOKButton?: () => void,
    onCancelButton?: () => void,
    isMerchandise?: boolean,
    isGeneralDiscount?: boolean
  ) => void;
  onCancelModal: () => void;
  onClickAddNewCustomer: () => void;
}

interface FormData {
  referralCode?: string;
}

export const ReferralDiscount: React.FC<IProps> = (props) => {
  const {
    promotion,
    customer,
    discountsAndRewardsApplied,
    setDiscountsAndRewardsApplied,
    confirmAddDiscountsAndRewards,
    onCancelModal,
    onClickAddNewCustomer,
  } = props;

  const intl = useIntl();
  const [form] = Form.useForm();
  const nFormData = (key: keyof FormData) => key;

  const [isNotAllowedReferral, setIsNotAllowedReferral] = useState<boolean>(false);

  const checkReferralCode = useMutation((payload: { referralCode: string; customerId: number }) =>
    customerApi.customerControllerReferralCodeCheck(payload.referralCode, { customerId: payload.customerId })
  );

  const onFinishRefferalCode = (value: FormData) => {
    // const reward = customer?.customerReward?.reduce((currentValue, item) => currentValue + item.reward, 0) ?? 0;
    const reward = !!customer?.tickets?.length;
    if (reward) {
      setIsNotAllowedReferral(true);
    } else {
      if (discountsAndRewardsApplied?.referralDiscount) {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          referralDiscount: undefined,
        }));
        form.setFieldValue(nFormData('referralCode'), undefined);
      } else if (value.referralCode && customer && !reward && promotion?.referralReward?.referralReward) {
        const handleCheckReferralCode = () => {
          checkReferralCode
            .mutateAsync({ referralCode: value.referralCode || '', customerId: customer.id })
            .then(({ data }) => {
              if (data) {
                setDiscountsAndRewardsApplied((prev) => ({
                  ...prev,
                  referralDiscount: {
                    amount: promotion.referralReward.rewardAmount,
                    referralCode: value.referralCode || '',
                    type: DiscountTypeEnum.Dollar,
                  },
                }));
                onCancelModal();
              }
            })
            .catch(({ response }) => {
              NotificationError({ contentNoti: response?.data?.message });
            });
        };
        confirmAddDiscountsAndRewards(handleCheckReferralCode, undefined, undefined, true);
      }
    }
  };

  useEffect(() => {
    const reward = !!customer?.tickets?.length;
    if (reward) {
      setIsNotAllowedReferral(true);
    } else {
      setIsNotAllowedReferral(false);
    }
  }, [customer]);

  return (
    <RowWrap
      isAutoFillRow={true}
      isGutter={true}
      gutter={[20, 12]}
      isWrap={true}
      styleFill="between"
      className="border-bottom p-t-b-20 salon__checkout-discount"
    >
      <ColWrap colProps={{ span: 24 }}>
        <span className="salon__checkout-discount-title">
          {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.1' })}
          {!promotion?.referralReward?.referralReward && (
            <span className="salon__checkout-discount-not-available m-l-17">
              {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.available' })}
            </span>
          )}
        </span>
      </ColWrap>
      {promotion?.referralReward?.referralReward ? (
        !customer ? (
          <>
            <ColWrap colProps={{ span: 24 }}>
              <div className="d-flex gap-20 align-items-center">
                <CustomButton
                  type="link"
                  buttonProps={{
                    className: 'salon__checkout-discount-button p-0 m-0 height-unset border-none',
                    onClick: onClickAddNewCustomer,
                  }}
                  content={intl.formatMessage({ id: 'checkout.discountAndRewards.add.text' })}
                />
                <span className="salon__checkout-discount-des">
                  {' '}
                  {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.des.1' })}
                </span>
              </div>
            </ColWrap>
          </>
        ) : (
          <ColWrap colProps={{ span: 24 }}>
            {!isNotAllowedReferral ? (
              <FormWrap form={form} layout="vertical" onFinish={onFinishRefferalCode}>
                <div className="d-flex justify-content-between align-items-center gap-12">
                  <FormInput
                    name={nFormData('referralCode')}
                    formItemProps={{
                      className: 'm-0 flex-1',
                      initialValue: discountsAndRewardsApplied?.referralDiscount?.referralCode || undefined,
                    }}
                    inputProps={{
                      placeholder: intl.formatMessage({ id: 'checkout.discountAndRewards.enterCode' }),
                      disabled:
                        !promotion?.referralReward?.referralReward || !!discountsAndRewardsApplied?.referralDiscount,
                    }}
                  />
                  <ButtonStyled
                    content={
                      !discountsAndRewardsApplied?.referralDiscount
                        ? intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.apply' })
                        : intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.applied' })
                    }
                    buttonProps={{
                      className: `width-124 height-38 salon__checkout-discount-rewards-button-3${
                        discountsAndRewardsApplied?.referralDiscount
                          ? ' salon__checkout-discount-rewards-applied-btn'
                          : ''
                      }`,
                      disabled: !promotion?.referralReward?.referralReward,
                      // ||
                      // !!discountsAndRewardsApplied?.referralDiscount
                      loading: checkReferralCode.isLoading,
                      htmlType: 'submit',
                    }}
                    isPrimary={true}
                    isCancelPopup={false}
                    isButtonCancel={false}
                  />
                </div>
              </FormWrap>
            ) : (
              <p className="m-0 color-EB4C3B font-style-italic font-size-14 font-weight-400 line-height-20">
                {intl.formatMessage({ id: 'checkout.discountAndRewards.thisIsAnExistingCustomer' })}
                &nbsp;({intl.formatMessage({ id: 'checkout.discountAndRewards.joinedOn' })}{' '}
                {formatOnlyDate(customer.createdOnDate)}{' '}
                <span className="text-transform-uppercase">{formatTimeHHMMa(customer.createdOnDate)}</span>)&nbsp;
                {intl.formatMessage({ id: 'checkout.discountAndRewards.soHeOrSheIsNotAllowedtoUseReferralCode' })}
              </p>
            )}
          </ColWrap>
        )
      ) : (
        <ColWrap colProps={{ span: 24 }}>
          <ButtonStyled
            content={intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.apply' })}
            buttonProps={{
              className: 'width-124 height-38 salon__checkout-discount-rewards-button-3',
              disabled: true,
            }}
            isPrimary={true}
          />
        </ColWrap>
      )}
    </RowWrap>
  );
};
