import { FormInstance } from 'antd/lib';
import React, { FunctionComponent, useState, MutableRefObject } from 'react';
import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { formatPhoneNumber } from '../../utils';

interface IProps<T extends object> {
  form: FormInstance;
  keyboardRef: any;
  inputName?: string;
  onNext: () => void;
  maxLength: number;
  keyboardProps?: KeyboardReactInterface['options'];
}

function KeyboardWrapper<T extends object>(props: IProps<T>) {
  const { keyboardRef, inputName, maxLength, onNext, keyboardProps, form } = props;
  const [layoutName, setLayoutName] = useState('default');

  const onKeyPress = (button: string, e?: MouseEvent) => {
    if (button === '{shift}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    } else if (button === '{next}') {
      e?.preventDefault();
      onNext();
    } else if (button === '{backspace}') {
      const currentValue = form.getFieldValue(inputName);

      if (currentValue) {
        form.setFieldValue(inputName, currentValue.slice(0, -1));
      }
    } else if (button === '{space}') {
      const currentValue = form.getFieldValue(inputName) || '';
      const newValue = `${currentValue}${' '}`;
      form.setFieldValue(inputName, inputName === 'phoneNumber' ? currentValue : newValue);
    } else {
      const currentValue = form.getFieldValue(inputName) || '';
      const newValue = `${currentValue}${button}`;
      form.setFieldValue(inputName, inputName === 'phoneNumber' ? formatPhoneNumber(newValue) : newValue);
    }
  };

  const customLayout = {
    default: [
      '1 2 3 4 5 6 7 8 9 0 + {backspace}',
      'q w e r t y u i o p - {shift}',
      'a s d f g h j k l , . @',
      'z x c v b n m _ {space} {next}',
    ],
    shift: [
      '1 2 3 4 5 6 7 8 9 0 + {backspace}',
      'Q W E R T Y U I O P - {shift}',
      'A S D F G H J K L , . @',
      'Z X C V B N M _ {space} {next}',
    ],
  };
  const customTheme = [
    { class: 'salon__blue', buttons: '@' },
    { class: 'salon__blue salon__next', buttons: '{next}' },
  ];

  const customDisplay = {
    '{space}': 'Space',
    '{backspace}': ' ',
    '{shift}': 'Caps',
    '{next}': 'Next',
  };

  return (
    <Keyboard
      keyboardRef={(r) => (keyboardRef.current = r)}
      maxLength={maxLength}
      layoutName={layoutName}
      onKeyPress={onKeyPress}
      layout={customLayout}
      buttonTheme={customTheme}
      display={customDisplay}
      inputName={inputName}
      preventMouseDownDefault={true}
      {...keyboardProps}
    />
  );
}

export default KeyboardWrapper;
