import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { StyledModal } from '../../../../../../components/StyledModal';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { SecurityCodeCardNumber } from './SecurityCodeCardNumber';
import { CardPaymentCardNumber } from './CardPaymentCardNumber';
import { IListIdTotalPrice } from '../../..';

export interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  setIsOpenPayAndComplete: (state: boolean) => void;
  listTotal: IListIdTotalPrice;
}

export enum CardNumberEnum {
  SecurityCode = 'securityCode',
  CardPayment = 'cardPayment',
}

function CardNumber<T extends object>(props: Props) {
  const { open, setOpen, setIsOpenPayAndComplete, listTotal } = props;
  const intl = useIntl();
  const [currentScreen, setCurrentScreen] = useState<CardNumberEnum>(CardNumberEnum.SecurityCode);

  const renderScreen = useCallback(() => {
    switch (currentScreen) {
      case CardNumberEnum.SecurityCode:
        return (
          <SecurityCodeCardNumber
            setCurrentScreen={setCurrentScreen}
            setOpenModal={setOpen}
            setIsOpenPayAndComplete={setIsOpenPayAndComplete}
            listTotal={listTotal}
          />
        );
      case CardNumberEnum.CardPayment:
        return (
          <CardPaymentCardNumber
            setCurrentScreen={setCurrentScreen}
            setOpenModal={setOpen}
            setIsOpenPayAndComplete={setIsOpenPayAndComplete}
            listTotal={listTotal}
          />
        );
    }
  }, [currentScreen]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpen(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: `checkout.payAndComplete.title.${currentScreen}` })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal',
        width: 700,
        footer: null,
      }}
    >
      {renderScreen()}
    </StyledModal>
  );
}

export default CardNumber;
