import { useMutation } from '@tanstack/react-query';
import { Avatar, Card, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { customerApi } from '../../../../apis';
import { CheckBusyDTO, Employee, Salon, ServiceItems, SettingCheckin } from '../../../../apis/client-axios';
import avatarDefaultSrc from '../../../../assets/images/appointment/avatar-default.png';
import { SvgCloseIcon } from '../../../../components/@svg/SvgCloseIcon';
import { ServiceItem } from '../../../../components/ServiceItem';
import { StyledPopup } from '../../../../components/StyledPopup';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { CustomButton } from '../../../../components/buttons/CustomButton';
import { formatNumberThousandWithDecimal } from '../../../../utils';

interface ITabTwoProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  listService: ServiceItems[];
  listSelectedService: number[];
  setListSelectedService: (services: number[]) => void;
  selectedTechnician: number | undefined;
  setSelectedTechnician: (selectedTechnician: number | undefined) => void;
  setCheckedTechnician: (checkedTechnician: boolean) => void;
  checkedTechnician: boolean;
  salonInformation?: Salon;
  dataTechnicians: Employee[];
  setReceivePromotionViaEmail: (receivePromotionViaEmail: boolean) => void;
  setReceiveMessagesByPhone: (receiveMessagesByPhone: boolean) => void;
  appointmentId?: number;
  setStatusSkip: (statusSkip: boolean) => void;
  statusSkip: boolean;
  isLoading: boolean;
  onFinish: () => void;
  settingCheckin: SettingCheckin | undefined;
}

const StepTwo: React.FC<ITabTwoProps> = ({ ...props }) => {
  const {
    listService,
    step,
    setStep,
    listSelectedService,
    setListSelectedService,
    selectedTechnician,
    setSelectedTechnician,
    checkedTechnician,
    setCheckedTechnician,
    salonInformation,
    dataTechnicians,
    setReceiveMessagesByPhone,
    setReceivePromotionViaEmail,
    appointmentId,
    statusSkip,
    setStatusSkip,
    isLoading,
    onFinish,
    settingCheckin,
  } = props;
  const [listTechnicians, setListTechnicians] = useState<Employee[]>();
  const [isErrorTechnician, setIsErrorTechnician] = useState(false);

  const checkCustomerBusy = useMutation(
    (dataCheck: CheckBusyDTO) => customerApi.customerControllerCheckBusy(dataCheck),
    {
      onSuccess: () => {
        if (!selectedTechnician) {
          setCheckedTechnician(true);
          setReceiveMessagesByPhone(true);
          setReceivePromotionViaEmail(true);
        }

        if (step === 1 && !settingCheckin?.asksOptionReceivingTextMessage) {
          onFinish();
        } else {
          setStep((prev) => prev + 1);
        }
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        setIsErrorTechnician(true);
      },
    }
  );
  const [loading, setLoading] = useState<boolean>(true);

  const intl = useIntl();
  const nextStep = () => {
    const dataPush = {
      estimate:
        listSelectedService.length > 0
          ? listService
              .filter((serviceItem: ServiceItems) => listSelectedService.includes(serviceItem.id))
              ?.map(({ time }) => time)
              .reduce((totalTime, time) => totalTime + time, 0)
          : 15,
      technicianId: selectedTechnician,
      appointmentId: appointmentId,
    };
    checkCustomerBusy.mutate(dataPush);
    setStatusSkip(true);
    if (loading) return false;
  };
  const skipStep = () => {
    if (!statusSkip) {
      setListSelectedService([]);
      setSelectedTechnician(undefined);
    }
    setCheckedTechnician(true);
    setReceiveMessagesByPhone(true);
    setReceivePromotionViaEmail(true);
    setStep((prev) => prev + 1);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (listSelectedService.length > 0) {
      const temp = [...dataTechnicians].filter(
        (technician) =>
          technician.skills &&
          listSelectedService.every((serviceItemId) =>
            technician.skills.map((skill) => skill.id).includes(serviceItemId)
          )
      );
      setListTechnicians(temp);

      if (selectedTechnician && (!temp.length || !temp.map((x) => x.id).includes(selectedTechnician))) {
        setSelectedTechnician(undefined);
      } else {
        setSelectedTechnician(selectedTechnician);
      }
    } else {
      setListTechnicians([]);
    }
  }, [listSelectedService, salonInformation]);

  return (
    <div className="salon__step-two">
      <div className="salon__step-two-top">
        <div
          className={`${
            settingCheckin?.allowSelectingServices ? 'salon__step-two-left' : 'salon__step-two-left-unselect'
          }`}
        >
          <div className="salon__step-two-left-top">
            <span className="salon__step-two-title">
              {intl.formatMessage({ id: 'bookingOnline.service' })} (
              {`${listService?.filter((e) => e.isCheckinStands).length ?? 0}`})
            </span>
            <div className="salon__step-two-left-wrap">
              <div className="salon__online-appointment__service-container">
                {listService
                  ?.filter((e) => e.isCheckinStands)
                  .map((serviceItem) => (
                    <ServiceItem
                      key={serviceItem.id}
                      isMerchandise={false}
                      name={serviceItem.name}
                      price={
                        settingCheckin?.showServicePriceInCheckin
                          ? formatNumberThousandWithDecimal(serviceItem.price)
                          : ''
                      }
                      isCheckinOnline={true}
                      time={serviceItem.time?.toString()}
                      backgroundColor={serviceItem.backgroundColor}
                      className={listSelectedService.find((x) => x === serviceItem.id) ? 'active' : ''}
                      onClick={() => {
                        if (!listSelectedService.find((x) => x === serviceItem.id)) {
                          const temp = [...listSelectedService];
                          temp.push(serviceItem.id);
                          setListSelectedService(temp);
                        }
                      }}
                    />
                  ))}
              </div>
            </div>
          </div>
          {settingCheckin?.allowSelectingTechnician ? (
            <div className="salon__step-two-left-bottom">
              <span className="salon__step-two-title">
                {intl.formatMessage({ id: 'bookingOnline.technician' })} ({`${listTechnicians?.length ?? 0}`})
              </span>
              <div className="salon__step-two-left-bottom-checkbox">
                <Checkbox
                  checked={checkedTechnician}
                  onChange={(e) => {
                    setCheckedTechnician(e.target.checked);
                    if (e.target.checked && selectedTechnician) {
                      setSelectedTechnician(undefined);
                    }
                  }}
                >
                  <span className="salon__step-two-left-bottom-checkbox-text">
                    {intl.formatMessage({ id: 'bookingOnline.checkbox' })}
                  </span>
                </Checkbox>
              </div>
              <div className="salon__step-two-left-bottom-wrap">
                <div className="salon__step-two-left-bottom-card">
                  {listTechnicians?.map((technician) => (
                    <Card
                      key={technician.id}
                      className={
                        'salon__custom-card salon__technician-item-preview' +
                        (selectedTechnician === technician.id ? ' active' : '')
                      }
                      extra={
                        selectedTechnician === technician.id ? (
                          <CustomButton
                            buttonProps={{
                              icon: <SvgCloseIcon />,
                              className: 'salon__technician-item-preview__deactive-button',
                              onClick: () => {
                                if (selectedTechnician === technician.id) {
                                  setSelectedTechnician(undefined);
                                  setCheckedTechnician(true);
                                }
                              },
                            }}
                            type="primary"
                          />
                        ) : null
                      }
                      onClick={() => {
                        if (selectedTechnician !== technician.id) {
                          setSelectedTechnician(technician.id);
                          setCheckedTechnician(false);
                        }
                      }}
                    >
                      <Avatar
                        src={
                          technician &&
                          (technician.defaultAvatar ||
                            (technician.avatar?.preview || technician.avatar?.source
                              ? `${process.env.REACT_APP_API_URL}/static/${
                                  technician.avatar?.preview || technician.avatar?.source
                                }`
                              : avatarDefaultSrc))
                        }
                        className=""
                      />
                      <p className="salon__custom-card-des text-max-1-line">{technician.name || '--'}</p>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {settingCheckin?.allowSelectingServices ? (
          <div className="salon__step-two-right">
            <span className="salon__step-two-title">
              {intl.formatMessage({ id: 'bookingOnline.service.selected' })}{' '}
              {`${
                listSelectedService && listSelectedService?.length > 0 ? '(' + listSelectedService?.length + ')' : ''
              }`}
            </span>

            <div className="salon__step-two-right-main">
              {!!listSelectedService.length ? (
                <div className=" d-flex flex-column gap-24 salon__step-two-right-main-item p-t-b-24">
                  {listService
                    .filter((serviceItem) => listSelectedService.includes(serviceItem.id))
                    .map((serviceItem) => (
                      <div className="position-relative width-min-content" key={serviceItem.id}>
                        <ServiceItem
                          key={serviceItem.id}
                          isMerchandise={false}
                          name={serviceItem.name}
                          price={
                            settingCheckin?.showServicePriceInCheckin
                              ? formatNumberThousandWithDecimal(serviceItem.price)
                              : ''
                          }
                          isCheckinOnline={true}
                          time={serviceItem.time?.toString()}
                          backgroundColor={serviceItem.backgroundColor}
                          onClick={() => {
                            if (!listSelectedService.find((x) => x === serviceItem.id)) {
                              const temp = [...listSelectedService];
                              temp.push(serviceItem.id);
                              setListSelectedService(temp);
                            }
                          }}
                        />
                        <CustomButton
                          buttonProps={{
                            icon: <SvgCloseIcon />,
                            className:
                              'salon__technician-item-preview__deactive-button salon__service-item-preview__deactive-button',
                            onClick: () => {
                              if (listSelectedService.find((x) => x === serviceItem.id)) {
                                const temp = [...listSelectedService].filter((x) => x !== serviceItem.id);
                                setListSelectedService(temp);
                              }
                            },
                          }}
                          type="primary"
                        />
                      </div>
                    ))}
                </div>
              ) : (
                <div className="salon__step-two-right-main-no-data">
                  <img src="/assets/qr/create.svg" alt="" />
                  <p className="salon__step-two-right-main-des">
                    {intl.formatMessage({ id: 'bookingOnline.service.no.selected' })}
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="salon__step-two-bottom">
        {settingCheckin?.asksOptionReceivingTextMessage ? (
          <ButtonStyled
            content={
              <span className="salon__step-two-bottom-left-text">
                {intl.formatMessage({ id: 'bookingOnline.skip' })}
              </span>
            }
            isLarge={true}
            buttonProps={{
              className: 'width-184 salon__step-two-bottom-left',
              onClick: skipStep,
            }}
            isPrimary={false}
            isButtonCancel={true}
          />
        ) : (
          <></>
        )}
        <ButtonStyled
          content={
            <span className="salon__step-two-bottom-right-text">
              {intl.formatMessage({
                id: settingCheckin?.asksOptionReceivingTextMessage ? 'bookingOnline.next' : 'bookingOnline.complete',
              })}
            </span>
          }
          isLarge={true}
          buttonProps={{
            className: 'width-184',
            onClick: nextStep,
            loading: isLoading,
            disabled: isLoading,
          }}
          isPrimary={true}
          isButtonCancel={false}
        />
      </div>
      <StyledPopup
        isOpen={isErrorTechnician}
        content={intl.formatMessage({ id: 'bookingOnline.step3.content' })}
        content2={intl.formatMessage({ id: 'bookingOnline.step3.content2' })}
        contentStrong={intl.formatMessage({ id: 'bookingOnline.step3.content.strong' })}
        onCancel={() => {}}
        onOk={() => {
          setIsErrorTechnician(false);
        }}
        customFooter={
          <div
            className="salon__step-three-custom-button"
            onClick={() => {
              setIsErrorTechnician(false);
            }}
          >
            <span className="font-size-16 font-weight-700 ">{intl.formatMessage({ id: 'bookingOnline.ok' })}</span>
          </div>
        }
        isHiddenCancel={true}
      />
    </div>
  );
};

export default StepTwo;
