import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Customer, Promotion, ServiceControllerGet200Response, ServiceItems } from '../../../../../apis/client-axios';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import dayjs from 'dayjs';
import { customerApi } from '../../../../../apis';
import ColWrap from '../../../../../components/ColWrap';
import { FormInput } from '../../../../../components/Form/FormInput';
import FormWrap from '../../../../../components/FormWrap';
import NotificationError from '../../../../../components/HandleShowNotiError';
import RowWrap from '../../../../../components/RowWrap';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../../checkoutConstants';
import { IOpenModalCheckOut } from '../../index';
import CustomDiscount from '../CustomDiscount';
import { BirthdayDiscount } from './BirthdayDiscount';
import { GeneralMerchandise } from './GeneralMerchandise';
import { GeneralTicket } from './GeneralTicket';
import { OccasionDiscount } from './OccasionDiscount';
import { ReferralDiscount } from './ReferraDiscount';
import { WeeklyDiscount } from './WeeklyDiscount';
import { AxiosResponse } from 'axios';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { CustomButton } from '../../../../../components/buttons/CustomButton';
import { formatOnlyDate, formatTimeHHMMa } from '../../../../../utils';

interface IProps {
  promotion?: Promotion;
  setOpenModal: React.Dispatch<React.SetStateAction<IOpenModalCheckOut>>;
  customer?: Customer;
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
  confirmAddDiscountsAndRewards: (
    onOKButton?: () => void,
    onCancelButton?: () => void,
    isMerchandise?: boolean,
    isGeneralDiscount?: boolean
  ) => void;
  onCancelModal: () => void;
  listDataServices: AxiosResponse<ServiceControllerGet200Response, any> | undefined;
}

export enum ListCustomDiscountStatusEnum {
  TicketDiscount = 'ticketDiscount',
  ItemDiscount = 'itemDiscount',
  TicketDiscountPercentage = 'ticketDiscountPercentage',
  ItemDiscountPercentage = 'itemDiscountPercentage',
}

export enum GeneralDiscountEnum {
  generalTicketDiscount = 'generalTicketDiscount',
  generalMerchandiseDiscount = 'generalMerchandiseDiscount',
}

export const Discounts: React.FC<IProps> = (props) => {
  const {
    promotion,
    setOpenModal,
    customer,
    discountsAndRewardsApplied,
    setDiscountsAndRewardsApplied,
    confirmAddDiscountsAndRewards,
    onCancelModal,
    listDataServices,
  } = props;
  const intl = useIntl();

  const [openCustomDiscount, setOpenCustomDiscount] = useState<boolean>(false);
  const [statusCustomDiscount, setStatusCustomDiscount] = useState<ListCustomDiscountStatusEnum>();
  const [listServiceItems, setListServiceItems] = useState<ServiceItems[]>([]);

  const onClickAddNewCustomer = () => {
    setOpenModal((prev) => ({
      ...prev,
      openListCustomer: true,
    }));
  };

  const onOKModalCustom = (value: number) => {
    if (openCustomDiscount && statusCustomDiscount) {
      if (
        [ListCustomDiscountStatusEnum.TicketDiscount, ListCustomDiscountStatusEnum.TicketDiscountPercentage].includes(
          statusCustomDiscount
        )
      ) {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          generalTicketDiscount: {
            amount: value,
            isCustomAmount: true,
            type:
              statusCustomDiscount === ListCustomDiscountStatusEnum.TicketDiscount
                ? DiscountTypeEnum.Dollar
                : DiscountTypeEnum.Percent,
          },
        }));
      } else {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          generalMerchandiseDiscount: {
            amount: value,
            isCustomAmount: true,
            type:
              statusCustomDiscount === ListCustomDiscountStatusEnum.ItemDiscount
                ? DiscountTypeEnum.Dollar
                : DiscountTypeEnum.Percent,
          },
        }));
      }
      setOpenCustomDiscount(false);
      setStatusCustomDiscount(undefined);
      onCancelModal();
    }
  };

  const onClickQuickValue = (
    value: number,
    type: ListCustomDiscountStatusEnum,
    discountsAndRewardsAppliedKey: GeneralDiscountEnum,
    isMerchandise?: boolean
  ) => {
    if (
      discountsAndRewardsApplied &&
      discountsAndRewardsApplied[discountsAndRewardsAppliedKey] &&
      discountsAndRewardsApplied[discountsAndRewardsAppliedKey]?.amount === value
    ) {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        [discountsAndRewardsAppliedKey]: undefined,
      }));
    } else if (discountsAndRewardsApplied && discountsAndRewardsApplied[discountsAndRewardsAppliedKey]) {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        [discountsAndRewardsAppliedKey]: {
          amount: value,
          isCustomAmount: false,
          type:
            type === ListCustomDiscountStatusEnum.TicketDiscount ? DiscountTypeEnum.Dollar : DiscountTypeEnum.Percent,
        },
      }));
      onCancelModal();
    } else {
      const handleSetDiscountsAndRewards = () => {
        if (
          [ListCustomDiscountStatusEnum.TicketDiscount, ListCustomDiscountStatusEnum.TicketDiscountPercentage].includes(
            type
          )
        ) {
          setDiscountsAndRewardsApplied((prev) => ({
            ...prev,
            generalTicketDiscount: {
              amount: value,
              isCustomAmount: false,
              type:
                type === ListCustomDiscountStatusEnum.TicketDiscount
                  ? DiscountTypeEnum.Dollar
                  : DiscountTypeEnum.Percent,
            },
          }));
        } else {
          setDiscountsAndRewardsApplied((prev) => ({
            ...prev,
            generalMerchandiseDiscount: {
              amount: value,
              isCustomAmount: false,
              type:
                type === ListCustomDiscountStatusEnum.TicketDiscount
                  ? DiscountTypeEnum.Dollar
                  : DiscountTypeEnum.Percent,
            },
          }));
        }
        onCancelModal();
      };
      confirmAddDiscountsAndRewards(handleSetDiscountsAndRewards, undefined, isMerchandise, true);
    }
  };

  const onOpenCustomDiscount = (
    status: ListCustomDiscountStatusEnum,
    discountsAndRewardsAppliedKey: GeneralDiscountEnum,
    type: DiscountTypeEnum,
    isMerchandise?: boolean
  ) => {
    if (
      discountsAndRewardsApplied &&
      discountsAndRewardsApplied[discountsAndRewardsAppliedKey] &&
      discountsAndRewardsApplied[discountsAndRewardsAppliedKey]?.type === type &&
      discountsAndRewardsApplied[discountsAndRewardsAppliedKey]?.isCustomAmount
    ) {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        [discountsAndRewardsAppliedKey]: undefined,
      }));
    } else {
      const handleOpenCustomDiscount = () => {
        setOpenCustomDiscount(true);
        setStatusCustomDiscount(status);
      };
      if (discountsAndRewardsApplied && discountsAndRewardsApplied[discountsAndRewardsAppliedKey]) {
        handleOpenCustomDiscount();
      } else {
        confirmAddDiscountsAndRewards(handleOpenCustomDiscount, undefined, isMerchandise, true);
      }
    }
  };

  useEffect(() => {
    if (!!listDataServices?.data?.content?.length) {
      let tempListServiceItems: ServiceItems[] = [];
      listDataServices.data.content.forEach((service) => {
        tempListServiceItems = [...tempListServiceItems, ...service.serviceItems];
      });
      setListServiceItems(tempListServiceItems);
    }
  }, [listDataServices]);

  return (
    <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
      <ColWrap colProps={{ span: 24 }}>
        {/* referral reward */}
        <ReferralDiscount
          confirmAddDiscountsAndRewards={confirmAddDiscountsAndRewards}
          onCancelModal={onCancelModal}
          onClickAddNewCustomer={onClickAddNewCustomer}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
          customer={customer}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          promotion={promotion}
        />

        {/* birthday */}
        <BirthdayDiscount
          confirmAddDiscountsAndRewards={confirmAddDiscountsAndRewards}
          onCancelModal={onCancelModal}
          onClickAddNewCustomer={onClickAddNewCustomer}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
          customer={customer}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          promotion={promotion}
          listServiceItems={listServiceItems}
        />

        {/* weekly */}
        <WeeklyDiscount
          confirmAddDiscountsAndRewards={confirmAddDiscountsAndRewards}
          onCancelModal={onCancelModal}
          onClickAddNewCustomer={onClickAddNewCustomer}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          promotion={promotion}
          listServiceItems={listServiceItems}
        />

        {/* occasion */}
        <OccasionDiscount
          confirmAddDiscountsAndRewards={confirmAddDiscountsAndRewards}
          onCancelModal={onCancelModal}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          promotion={promotion}
          listServiceItems={listServiceItems}
        />

        {/* general ticket */}
        <GeneralTicket
          onClickQuickValue={onClickQuickValue}
          onOpenCustomDiscount={onOpenCustomDiscount}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
        />

        {/* general merchandise */}
        <GeneralMerchandise
          onClickQuickValue={onClickQuickValue}
          onOpenCustomDiscount={onOpenCustomDiscount}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
        />
      </ColWrap>
      {openCustomDiscount && statusCustomDiscount && (
        <CustomDiscount
          onCancelModal={() => setOpenCustomDiscount(false)}
          open={openCustomDiscount}
          statusCustomDiscount={statusCustomDiscount}
          onOK={onOKModalCustom}
        />
      )}
    </RowWrap>
  );
};
