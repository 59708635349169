import { ColumnType } from 'antd/es/table';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IHandleSubmitCheckoutProps, IListIdTotalPrice, ITipInformation, PaymentInformation } from '../../index';
import { Promotion, TicketDTOStatusEnum, TicketTransactionDTO } from '../../../../../apis/client-axios';
import ColWrap from '../../../../../components/ColWrap';
import RowWrap from '../../../../../components/RowWrap';
import TableWrap from '../../../../../components/TableWrap';
import { CustomButton } from '../../../../../components/buttons/CustomButton';
import { formatNumberThousandWithDecimal } from '../../../../../utils';
import CardNumber from './CardNumber';
import { CashPayment } from './CashPayment';
import ChargeCard from './ChargeCard';
import { CheckPayment } from './CheckPayment';
import { GiftCard } from './GiftCard';
import { PayAndCompleteEnum } from '../index';
import { AxiosResponse } from 'axios';

interface IProps {
  setOpenModal: (state: boolean) => void;
  setListTotal: (listTotal: IListIdTotalPrice) => void;
  handleSubmitCheckout: (submitParams?: IHandleSubmitCheckoutProps) => void;
  listTotal: IListIdTotalPrice;
  onChangePaymentInformation: React.Dispatch<React.SetStateAction<PaymentInformation>>;
  paymentInformation?: PaymentInformation;
  onChangeCurrentScreen: (screen: PayAndCompleteEnum) => void;
  listPromotion: AxiosResponse<Promotion, any> | undefined;
  saveTipAndTechnician: (totalTip: number) => void;
  tipInformation: ITipInformation | undefined;
  resetBill: () => void;
  isLoading: boolean;
}

export const Payment: React.FC<IProps> = (props) => {
  const {
    setOpenModal,
    setListTotal,
    handleSubmitCheckout,
    listTotal,
    paymentInformation,
    onChangePaymentInformation,
    onChangeCurrentScreen,
    listPromotion,
    saveTipAndTechnician,
    tipInformation,
    resetBill,
    isLoading,
  } = props;

  const intl = useIntl();

  const [isOpenCashPayment, setIsOpenCashPayment] = useState<boolean>(false);
  const [isOpenCheckPayment, setIsOpenCheckPayment] = useState<boolean>(false);
  const [isOpenChargeCard, setIsOpenChargeCard] = useState<boolean>(false);
  const [isOpenCardNumber, setIsOpenCardNumber] = useState<boolean>(false);
  const [isOpenGiftCard, setIsOpenGiftCard] = useState<boolean>(false);

  const leftBalanceToPay = () => {
    return Number(listTotal?.totalBalanceTotalPay?.toFixed(2) ?? 0);
  };

  const columns: ColumnType<any>[] = [
    {
      align: 'left',
      dataIndex: 'key',
      rowScope: 'row',
      key: 0,
      width: '40%',
      title: (
        <span className="font-size-14 font-weight-600">
          {intl.formatMessage({ id: 'checkout.payAndComplete.title.payment' })}
        </span>
      ),
    },
    // {
    //   align: 'right',
    //   dataIndex: 'cashDiscount',
    //   key: 1,
    //   title: (
    //     <span className="font-size-14 font-weight-600">
    //       {intl.formatMessage({ id: 'checkout.payAndComplete.title.cashDiscount' })}
    //     </span>
    //   ),
    //   render: (value) => <span className="font-size-14 font-weight-600">{value && `$${value}`}</span>,
    // },
    {
      align: 'right',
      dataIndex: 'amount',
      key: 1,
      title: (
        <span className="font-size-14 font-weight-600">
          {intl.formatMessage({ id: 'checkout.payAndComplete.title.amount' })}
        </span>
      ),
      render: (value) => <span className="font-size-14 font-weight-600">{value && `$${value}`}</span>,
    },
    {
      align: 'right',
      dataIndex: 'tip',
      key: 1,
      title: (
        <span className="font-size-14 font-weight-600">
          {intl.formatMessage({ id: 'checkout.payAndComplete.title.tip' })}
        </span>
      ),
      render: (value) => <span className="font-size-14 font-weight-600">{value && `$${value}`}</span>,
    },
  ];

  const dataSource = [
    // cash payment
    paymentInformation?.amount || paymentInformation?.tip
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.payAndComplete.title.cash' })}
            </span>
          ),
          cashDiscount: formatNumberThousandWithDecimal(0),
          tip: formatNumberThousandWithDecimal(paymentInformation?.tip || 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.amount || 0),
        }
      : undefined,
    // gift card payment
    paymentInformation?.giftCardPayment
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.payAndComplete.title.giftCard' })}
            </span>
          ),
          cashDiscount: '',
          tip: '',
          amount: formatNumberThousandWithDecimal(paymentInformation?.giftCardPayment?.totalValue ?? 0),
        }
      : undefined,
    {
      key: (
        <span className="font-size-14 font-weight-600">
          {intl.formatMessage({ id: 'checkout.payAndComplete.title.balanceToPay' })}
        </span>
      ),
      cashDiscount: '',
      tip: '',
      amount: formatNumberThousandWithDecimal(leftBalanceToPay()),
    },
  ];

  console.log('dataSource', dataSource);

  const onClickCash = () => {
    setIsOpenCashPayment(true);
  };
  const onClickCheck = () => {
    setIsOpenCheckPayment(true);
  };
  const onClickChargeCard = () => {
    setIsOpenChargeCard(true);
  };
  const onClickCardNumber = () => {
    setIsOpenCardNumber(true);
  };
  const onClickGiftCard = () => {
    setIsOpenGiftCard(true);
  };

  const handleSubmit = (submitParams?: {
    status?: TicketDTOStatusEnum;
    ticketTransaction?: TicketTransactionDTO[];
    totalTip?: number;
  }) => {
    onChangeCurrentScreen(PayAndCompleteEnum.Tips);
    handleSubmitCheckout({
      ...submitParams,
      totalTip: submitParams?.totalTip ?? tipInformation?.tip,
    });
    resetBill();
  };

  return (
    <>
      <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
        <ColWrap
          colProps={{
            span: 24,
          }}
        >
          {!paymentInformation ? (
            <div className="background-color-F6F9FB d-flex justify-content-between p-l-r-20 p-t-b-4 font-weight-600 font-size-14">
              <span>{intl.formatMessage({ id: 'checkout.balanceToPay' })}</span>
              <span className="p-l-r-80">${formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0)}</span>
            </div>
          ) : (
            <TableWrap
              isHidePagination
              setSize={() => {}}
              columns={columns}
              data={dataSource?.filter((data) => data)}
              rowKey="key"
              rootClassName="salon__checkout__pay-n-complete-modal__table"
            />
          )}
        </ColWrap>
        <ColWrap
          colProps={{
            span: 12,
          }}
        >
          <CustomButton
            type="primary"
            content={
              <>
                <p className="font-size-20 font-weight-600 color-292F33 m-b-4">
                  {intl.formatMessage({ id: 'checkout.payAndComplete.chargeCard' })}
                </p>
                <p className="font-size-14 font-weight-400 color-78797A m-0">
                  {intl.formatMessage({ id: 'checkout.payAndComplete.chargeCard.subTitle' })}
                </p>
              </>
            }
            buttonProps={{
              className: 'height-132 w-full background-color-0090FF1A',
              onClick: onClickChargeCard,
            }}
          ></CustomButton>
        </ColWrap>
        <ColWrap
          colProps={{
            span: 12,
          }}
        >
          <CustomButton
            type="primary"
            content={
              <>
                <p className="font-size-20 font-weight-600 color-292F33 m-b-4">
                  {intl.formatMessage({ id: 'checkout.payAndComplete.cardNumber' })}
                </p>
                <p className="font-size-14 font-weight-400 color-78797A m-0">
                  {intl.formatMessage({ id: 'checkout.payAndComplete.cardNumber.subTitle' })}
                </p>
              </>
            }
            buttonProps={{
              className: 'height-132 w-full background-color-0090FF1A',
              onClick: onClickCardNumber,
            }}
          ></CustomButton>
        </ColWrap>
        <ColWrap
          colProps={{
            span: 24,
          }}
        >
          <p className="font-size-16 font-weight-500 text-center m-0">
            {intl.formatMessage({ id: 'checkout.payAndComplete.customer' })}{' '}
            <span className="font-weight-700">{intl.formatMessage({ id: 'checkout.payAndComplete.paid' })}</span>{' '}
            {intl.formatMessage({ id: 'checkout.payAndComplete.theAboveAmountWith' })}
          </p>
        </ColWrap>
        <ColWrap
          colProps={{
            span: 24,
          }}
        >
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[12, 12]} isWrap={true} styleFill="between">
            <ColWrap
              colProps={{
                span: 12,
              }}
            >
              <CustomButton
                type="primary"
                content={`${intl.formatMessage({
                  id: 'checkout.payAndComplete.giftCard',
                })}`}
                buttonProps={{
                  className: 'height-62 w-full font-weight-700 font-size-16',
                  onClick: onClickGiftCard,
                }}
              ></CustomButton>
            </ColWrap>
            <ColWrap
              colProps={{
                span: 12,
              }}
            >
              <CustomButton
                type="primary"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.check' })}
                buttonProps={{
                  className: 'height-62 w-full font-weight-700 font-size-16',
                  onClick: onClickCheck,
                }}
              ></CustomButton>
            </ColWrap>
            <ColWrap
              colProps={{
                span: 24,
              }}
            >
              <CustomButton
                type="primary"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.cash' })}
                buttonProps={{
                  className: 'height-62 w-full font-weight-700 font-size-16',
                  onClick: onClickCash,
                }}
              ></CustomButton>
            </ColWrap>
          </RowWrap>
        </ColWrap>
      </RowWrap>

      {/* Inner modals */}
      {isOpenChargeCard && (
        <ChargeCard
          listTotal={listTotal}
          open={isOpenChargeCard}
          setOpen={setIsOpenChargeCard}
          setIsOpenPayAndComplete={setOpenModal}
        />
      )}
      {isOpenCardNumber && (
        <CardNumber
          listTotal={listTotal}
          open={isOpenCardNumber}
          setOpen={setIsOpenCardNumber}
          setIsOpenPayAndComplete={setOpenModal}
        />
      )}
      {isOpenCashPayment && (
        <CashPayment
          open={isOpenCashPayment}
          setOpen={setIsOpenCashPayment}
          setIsOpenPayAndComplete={setOpenModal}
          listTotal={listTotal}
          handleSubmitCheckout={handleSubmit}
          onChangePaymentInformation={onChangePaymentInformation}
          paymentInformation={paymentInformation}
          leftBalanceToPay={leftBalanceToPay()}
          setListTotal={setListTotal}
          saveTipAndTechnician={saveTipAndTechnician}
          tipInformation={tipInformation}
          isLoading={isLoading}
        />
      )}
      {isOpenCheckPayment && (
        <CheckPayment
          open={isOpenCheckPayment}
          setOpen={setIsOpenCheckPayment}
          setIsOpenPayAndComplete={setOpenModal}
          listTotal={listTotal}
        />
      )}
      {isOpenGiftCard && (
        <GiftCard
          open={isOpenGiftCard}
          setOpen={setIsOpenGiftCard}
          setIsOpenPayAndComplete={setOpenModal}
          listTotal={listTotal}
          handleSubmitCheckout={handleSubmit}
          onChangePaymentInformation={onChangePaymentInformation}
          paymentInformation={paymentInformation}
          leftBalanceToPay={leftBalanceToPay()}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
