import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { CompletedList } from './ListCheckin/CheckInManager';
import { EventProps } from 'react-big-calendar';
import AppointmentModified from '../appointment/AppointmentModal';
import { Appointment as AppointmentType } from '../../../apis/client-axios';
const CheckInTenant = () => {
  return (
    <Layout>
      <CompletedList />
    </Layout>
  );
};

export default CheckInTenant;
