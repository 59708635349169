import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ticketApi } from '../../../../../apis';
import ColWrap from '../../../../../components/ColWrap';
import { FormTextArea } from '../../../../../components/Form/FormTextArea';
import FormWrap from '../../../../../components/FormWrap';
import NotificationError from '../../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../../components/HandleShowNotiSuccess';
import RowWrap from '../../../../../components/RowWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { formatNumberThousandWithDecimal } from '../../../../../utils';
import { QUERY_TICKET } from '../../../../../utils/constant';
import '../style.scss';
import './closeoutStyle.scss';
import ConfirmCloseOut from '../../ConfirmCloseOut';
import { CloseoutResponse } from '../../../../../apis/client-axios';

export interface Props {
  open: boolean;
  onCancelModal: () => void;
}
interface FormData {
  notes?: string;
}

const { confirm } = Modal;

function Closeout<T extends object>(props: Props) {
  const { open, onCancelModal } = props;
  const n = (key: keyof FormData) => key;
  // const listCloseout = ['Credit', 'Cash', 'Check', 'Giftcard', 'Deposit', 'Reward Redeem', 'Others'];
  const listCloseout = ['Credit', 'Cash', 'Check', 'Giftcard', 'Deposit', 'Reward Redeem'];
  const intl = useIntl();
  const [form] = Form.useForm();
  const [ticketCloseout, setTicketCloseout] = useState<CloseoutResponse | undefined>();
  const [openConfirmCloseOut, setOpenConfirmCloseOut] = useState<boolean>(false);
  const { data: dataTicketCloseout } = useQuery({
    queryKey: [QUERY_TICKET],
    enabled: true,
    staleTime: 1000,
    queryFn: () => ticketApi.ticketControllerGetCloseout(),
  });
  const postTicketCloseout = useMutation(() => ticketApi.ticketControllerCloseout(), {
    onSuccess: () => {
      onCancelModal();
      NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'checkout.cashDrawer.closeout.success' }) });
    },
    onError: ({ response }) => {
      confirm({
        width: 480,
        centered: true,
        className: 'salon__modal-styled salon__modal__confirmation salon__modal__confirmation_closebatch',
        content: (
          <div className="text-center font-size-20 font-weight-400 line-height-28 white-space-pre-line">
            {/* {'You have 1 unpaid ticket. Please check out the ticket to end the shift!'} */}
            {response.data.message}
          </div>
        ),
        onOk() {},
        cancelButtonProps: {
          className: 'd-none',
        },
        okButtonProps: {
          className: 'm-0',
        },
        okText: intl.formatMessage({ id: 'common.OK' }),
      });
    },
  });
  useEffect(() => {
    setTicketCloseout(dataTicketCloseout?.data);
  }, [dataTicketCloseout]);
  const onSubmit = () => {
    const data = form.getFieldsValue();
    postTicketCloseout.mutate();
  };
  return (
    <>
      <StyledModal
        isOpen={open}
        onCancel={onCancelModal}
        modalProps={{
          title: intl.formatMessage({ id: 'checkout.cashDrawer.closeout' }),
          className: 'salon__checkout-cash-drawer-modal',
          width: 700,
          footer: (
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex gap-12 align-items-center">
                <ButtonStyled
                  content={intl.formatMessage({ id: 'checkout.cashDrawer.closeout.button' })}
                  buttonProps={{
                    className: 'width-100 height-38',
                    onClick: () => {
                      // onSubmit();
                      setOpenConfirmCloseOut(true);
                    },
                  }}
                  isPrimary={true}
                />
              </div>
            </div>
          ),
        }}
      >
        <FormWrap form={form} name="appointmentModified" layout="vertical" className="w-100  p-t-20">
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[12, 12]} isWrap={true} styleFill="between">
            <ColWrap colProps={{ span: 24 }}>
              <span>{intl.formatMessage({ id: 'checkout.cashDrawer.closeout.title' })}</span>
            </ColWrap>
            <ColWrap colProps={{ span: 12, className: 'salon__checkout-cash-drawer-modal-closeout-table' }}>
              <RowWrap
                isAutoFillRow={true}
                isGutter={true}
                gutter={[0, 8]}
                isWrap={true}
                styleFill="between"
                className="salon__checkout-cash-drawer-modal-closeout-table-top"
              >
                <ColWrap colProps={{ span: 24 }}>
                  {listCloseout?.map((item, index) => {
                    return (
                      <div key={index} className="salon__checkout-cash-drawer-modal-closeout-table-top-col">
                        <h6 className="salon__checkout-cash-drawer-modal-closeout-table-title">{item}</h6>
                        <span className="salon__checkout-cash-drawer-modal-closeout-table-des">
                          {intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price' })}
                          {item === 'Cash'
                            ? formatNumberThousandWithDecimal(
                                ticketCloseout?.cash ??
                                  intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })
                              )
                            : item === 'Check'
                            ? formatNumberThousandWithDecimal(
                                ticketCloseout?.check ??
                                  intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })
                              )
                            : item === 'Credit'
                            ? formatNumberThousandWithDecimal(
                                ticketCloseout?.credit ??
                                  intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })
                              )
                            : item === 'Giftcard'
                            ? formatNumberThousandWithDecimal(
                                ticketCloseout?.giftCard ??
                                  intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })
                              )
                            : item === 'Deposit'
                            ? formatNumberThousandWithDecimal(
                                ticketCloseout?.deposit ??
                                  intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })
                              )
                            : item === 'Reward Redeem'
                            ? formatNumberThousandWithDecimal(
                                ticketCloseout?.rewardRedeem ??
                                  intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })
                              )
                            : item === 'Others'
                            ? formatNumberThousandWithDecimal(
                                ticketCloseout?.others ??
                                  intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })
                              )
                            : intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })}
                        </span>
                      </div>
                    );
                  })}
                </ColWrap>
              </RowWrap>
              <RowWrap
                isAutoFillRow={true}
                isGutter={true}
                gutter={[0, 0]}
                isWrap={true}
                styleFill="between"
                className="salon__checkout-cash-drawer-modal-closeout-table-bottom"
              >
                <ColWrap colProps={{ span: 24 }}>
                  <div className="salon__checkout-cash-drawer-modal-closeout-table-bottom-col">
                    <span className="salon__checkout-cash-drawer-modal-closeout-table-title font-weight-600">
                      {intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.total' })}
                    </span>
                    <span className="salon__checkout-cash-drawer-modal-closeout-table-des">
                      {ticketCloseout?.totalPayments
                        ? '$' +
                          formatNumberThousandWithDecimal(
                            ticketCloseout?.totalPayments ??
                              intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })
                          )
                        : intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price' }) +
                          intl.formatMessage({ id: 'checkout.cashDrawer.closeout.price.0' })}
                    </span>
                  </div>
                </ColWrap>
              </RowWrap>
            </ColWrap>
            <ColWrap colProps={{ span: 12 }}>
              <FormTextArea
                name={n('notes')}
                inputProps={{
                  className: 'salon__checkout-cash-drawer-modal-custom-input-close-out',
                  placeholder: intl.formatMessage({ id: 'checkout.cashDrawer.closeout.placeholder' }),
                }}
              />
            </ColWrap>
          </RowWrap>
        </FormWrap>
      </StyledModal>
      <ConfirmCloseOut
        open={openConfirmCloseOut}
        onCancelModal={() => setOpenConfirmCloseOut(false)}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default Closeout;
