import './bookingOnline.scss';
import CheckInQR from './checkInQR';
import { useEffect, useState } from 'react';
import ScreenSaver from './screenSaver';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { QUERY_SETTING } from '../../../utils/constant';
import { settingApi } from '../../../apis';

const BookingOnline = () => {
  const [status, setStatus] = useState<boolean>(false);
  const { id } = useParams();
  const { data: setting } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
    staleTime: 1000,
  });

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (!status) setStatus(true);
    }, 120000);

    const onMouseDown = () => {
      clearInterval(myInterval);
      myInterval = setInterval(() => {
        if (!status) setStatus(true);
      }, 120000);
    };

    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  return (
    <section className="salon__home">
      {!status ? (
        <CheckInQR settingCheckin={setting?.data.settingCheckin} />
      ) : (
        <ScreenSaver status={status} setStatus={setStatus} screenSaver={setting?.data.settingCheckin.screenSaver} />
      )}
    </section>
  );
};

export default BookingOnline;
