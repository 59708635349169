import { FC, Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SvgAppointmentCallerIcon } from '../../../../components/@svg/SvgAppointmentCallerIcon';
import { SvgAppointmentPlusIcon } from '../../../../components/@svg/SvgAppointmentPlusIcon';
import { SvgAppointmentSettingIcon } from '../../../../components/@svg/SvgAppointmentSettingIcon';
import { SvgCheckInQrCodeIcon } from '../../../../components/@svg/SvgCheckInQrCodeIcon';
import NotificationSuccess from '../../../../components/HandleShowNotiSuccess';
import StyledPopover from '../../../../components/StyledPopover';
import { ButtonIcon } from '../../../../components/buttons/ButtonIcon';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { MAIN_ROUTES } from '../../routes';
import {
  OPEN_POPOVER,
  OPEN_SETTING_POPOVER,
  OpenPopoverType,
  OpenSettingPopoverType,
  SampleCallerID,
  SidebarRenderButtonIcon,
} from '../models';
import CallerIdContent from './CallerID';
import DisplaySettingsModal from './Settings/Display';
import CheckInQRModal from './CheckIn';

interface AppointmentSidebarProps {
  openCreateModal: () => void;
  onRefetch: () => void;
  onChangeDraggedCaller: (caller: SampleCallerID) => void;
  isOpenModalModified: boolean;
  draggedCaller?: SampleCallerID;
}

const AppointmentSidebar: FC<AppointmentSidebarProps> = (props) => {
  const { openCreateModal, onRefetch, onChangeDraggedCaller, isOpenModalModified, draggedCaller } = props;

  const intl = useIntl();
  const [open, setOpen] = useState<OpenPopoverType>();
  const [openSetting, setOpenSetting] = useState<OpenSettingPopoverType>();

  useEffect(() => {
    if (isOpenModalModified) {
      setOpen(undefined);
      setOpenSetting(undefined);
    }
  }, [isOpenModalModified]);

  const handleClickOnlineAppointment = () => {
    const salonId = localStorage.getItem('salonId');
    const url = `${process.env.REACT_APP_URL}/${MAIN_ROUTES.ONLINE_APPOINTMENT}/${salonId}`;

    navigator.clipboard.writeText(url);
    NotificationSuccess({
      contentNoti: intl.formatMessage({ id: 'appointment.event.onlineAppointment.copy.success' }),
    });

    setOpen(undefined);
  };

  const handleOpenSetting = (type: OpenSettingPopoverType) => {
    setOpenSetting(type);
    setOpen(undefined);
  };

  const renderSettingContentPopover = () => {
    const settings = [
      {
        label: intl.formatMessage({ id: 'appointment.setting.heading' }, { title: 'Display' }),
        onClick: () => handleOpenSetting(OPEN_SETTING_POPOVER.SETTING_DISPLAY),
      },
      {
        label: intl.formatMessage({ id: 'appointment.setting.heading' }, { title: 'Turn' }),
        onClick: () => handleOpenSetting(OPEN_SETTING_POPOVER.SETTING_TURN),
      },
      {
        label: intl.formatMessage({ id: 'appointment.button.onlineAppointment' }),
        onClick: handleClickOnlineAppointment,
      },
    ];

    return (
      <>
        {settings.map((setting, index) => (
          <div key={index} className="salon__appointment-setting-item cursor-pointer" onClick={setting.onClick}>
            <p className="m-b-0 font-size-14 font-weight-500 salon__appointment-setting-item-label">{setting.label}</p>
          </div>
        ))}
      </>
    );
  };

  const renderButtonIcon: SidebarRenderButtonIcon<OpenPopoverType>[] = [
    {
      icon: (
        <>
          <SvgCheckInQrCodeIcon />
          <span className="font-size-16 font-weight-700 salon__appointment-sidebar-button-text">
            {intl.formatMessage({ id: 'appointment.button.checkIn' })}
          </span>
        </>
      ),
      type: OPEN_POPOVER.CHECKIN,
      content: '',
      title: '',
      onClick: () => setOpen(OPEN_POPOVER.CHECKIN),
      overlayClassName: '',
    },
    {
      icon: (
        <>
          <SvgAppointmentCallerIcon />
          <span className="font-size-14 font-weight-700 salon__appointment-sidebar-button-text">
            {intl.formatMessage({ id: 'appointment.button.callerId' })}
          </span>
        </>
      ),
      type: OPEN_POPOVER.CALLER_ID,
      content: (
        <CallerIdContent
          onClosePopover={() => setOpen(undefined)}
          onChangeDraggedCaller={onChangeDraggedCaller}
          draggedCaller={draggedCaller}
        />
      ),
      title: (
        <span className="font-size-20 font-weight-700 salon__appointment-caller-heading">
          {intl.formatMessage({ id: 'appointment.modal.caller.heading' })}
        </span>
      ),
      onClick: () => setOpen(OPEN_POPOVER.CALLER_ID),
      overlayClassName: 'salon__appointment-caller',
    },
    {
      icon: (
        <>
          <SvgAppointmentSettingIcon />
        </>
      ),
      type: OPEN_POPOVER.SETTING,
      content: renderSettingContentPopover(),
      title: '',
      onClick: () => setOpen((prev) => (prev && prev === OPEN_POPOVER.SETTING ? undefined : OPEN_POPOVER.SETTING)),
      overlayClassName: 'salon__appointment-setting',
    },
  ];

  return (
    <div className="salon__appointment-sidebar">
      <div className="salon__appointment-sidebar-left">
        <h2 className="font-weight-700 salon__appointment-sidebar-left-heading">
          {intl.formatMessage({ id: 'appointment.heading' })}
        </h2>
      </div>
      <div className="salon__appointment-sidebar-right">
        {renderButtonIcon.map((item, index) => (
          <Fragment key={index}>
            <StyledPopover
              open={item.content ? open === item.type : false}
              popoverProps={{
                title: item.title,
                content: item.content,
                placement: 'bottomRight',
                trigger: 'click',
                arrow: false,
                overlayClassName: item.overlayClassName,
              }}
            >
              <ButtonIcon
                icon={item.icon}
                buttonProps={{
                  onClick: item.onClick,
                }}
              />
            </StyledPopover>
          </Fragment>
        ))}

        <ButtonStyled
          isPrimary
          content={
            <div className="d-flex gap-6 align-items-center">
              <SvgAppointmentPlusIcon wrapperClassName="salon__appointment-sidebar-right-button" />
              {intl.formatMessage({ id: 'appointment.button.create' })}
            </div>
          }
          buttonProps={{
            onClick: openCreateModal,
          }}
        />
      </div>

      {openSetting === OPEN_SETTING_POPOVER.SETTING_DISPLAY && (
        <DisplaySettingsModal
          open={openSetting === OPEN_SETTING_POPOVER.SETTING_DISPLAY}
          onCancel={() => setOpenSetting(undefined)}
          onRefetch={onRefetch}
        />
      )}

      {open === OPEN_POPOVER.CHECKIN && (
        <CheckInQRModal open={open === OPEN_POPOVER.CHECKIN} onCancel={() => setOpen(undefined)} />
      )}
    </div>
  );
};

export default AppointmentSidebar;
