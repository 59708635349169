import React, { useEffect } from 'react';
import { ConfigProvider, theme } from 'antd';
import { useSelector } from 'react-redux';
import AppLocale from './lngProvider';
import { IntlProvider } from 'react-intl';
import { RootState } from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RoutesApp from './routes';

import { ToastContainer } from 'react-toastify';
import { customTheme } from './utils/ant-theme';

const App: React.FC = () => {
  const { locale, isDarkMode } = useSelector((state: RootState) => state.setting);
  const currentAppLocale = (AppLocale as any)[locale.locale];
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    window.addEventListener('resize', () => {
      // dispatch(updateWindowWidth(window.innerWidth));
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          ...customTheme,
        }}
        locale={currentAppLocale.antd}
      >
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <RoutesApp />
        </IntlProvider>
      </ConfigProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
};

export default App;
