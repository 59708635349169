import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { StyledModal } from '../../../../../../../components/StyledModal';
import FormWrap from '../../../../../../../components/FormWrap';
import RowWrap from '../../../../../../../components/RowWrap';
import ColWrap from '../../../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../../../components/Form/FormInputNumber';
import { SvgDollarIcon } from '../../../../../../../components/@svg/SvgDollarIcon';
import KeyboardWrapper, { KeyboardDirection } from '../../../../keyboard';
import { CustomButton } from '../../../../../../../components/buttons/CustomButton';
import { StyledPopup } from '../../../../../../../components/StyledPopup';
import { formatNumberThousandWithDecimal } from '../../../../../../../utils';
import { useMutation } from '@tanstack/react-query';
import { ticketApi } from '../../../../../../../apis';
import { RefundTransactionDTO, RefundTransactionDTOTypePaymentEnum } from '../../../../../../../apis/client-axios';
import NotificationError from '../../../../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../../../../components/HandleShowNotiSuccess';

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  refundType: RefundTransactionDTOTypePaymentEnum;
  ticketId: number;
  totalPayment: number;
  setOpenRefundMethod: (state: boolean) => void;
  onCancelModal: () => void;
}

interface FormData {
  refundAmount?: number;
}

enum InputNameEnum {
  RefundAmount = 'refundAmount',
}

export const Refund: React.FC<IProps> = (props) => {
  const { open, setOpen, ticketId, totalPayment, setOpenRefundMethod, onCancelModal, refundType } = props;
  const [form] = Form.useForm();
  const intl = useIntl();
  const keyboardRef = useRef<any>();
  const n = (key: keyof FormData) => key;
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();
  const refundAmountRef = useRef<HTMLInputElement>(null);
  const refundAmount = Form.useWatch(n('refundAmount'), form);
  const [isConfirmDialog, setIsConfirmDialog] = useState<boolean>(false);

  useEffect(() => {
    if (refundAmount && refundAmountRef.current) {
      const [integer] = refundAmount.replace(/,/g, '').split('.');
      refundAmountRef.current.selectionStart = refundAmountRef.current.selectionEnd =
        refundAmount.length + Math.floor(integer / 3);
    }
  }, [refundAmount]);

  const refundMutation = useMutation(
    (refundTransactionDTO: RefundTransactionDTO) => ticketApi.ticketControllerRefundTransaction(refundTransactionDTO),
    {
      onSuccess: ({ data }) => {
        NotificationSuccess({ contentNoti: 'Refund success!' });
        setIsConfirmDialog(false);
        setOpen(false);
        setOpenRefundMethod(false);
        onCancelModal();
      },
      onError: (error) => {},
    }
  );

  const onClickOK = (value: FormData) => {
    if (value?.refundAmount && Number(value?.refundAmount) > Number(totalPayment))
      return NotificationError({ contentNoti: "The refund amount can't be more than the total payment!" });
    setIsConfirmDialog(true);
  };

  return (
    <>
      <StyledModal
        isOpen={open}
        onCancel={() => setOpen(false)}
        modalProps={{
          title: (
            <div className="salon__checkout-merchandise-modal-heading">
              {intl.formatMessage({ id: 'checkout.transactions.item.refund' })}
            </div>
          ),
          className: 'salon__checkout__pay-n-complete-modal',
          width: 700,
          footer: null,
        }}
      >
        <FormWrap form={form} onFinish={onClickOK} className="salon__checkout__pay-n-complete-modal__cash-payment">
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
            <ColWrap colProps={{ span: 24 }}>
              <FormInputNumber
                name={n('refundAmount')}
                prefix={<SvgDollarIcon />}
                label={intl.formatMessage({ id: 'checkout.transactions.refundMethod.refundAmount' })}
                numericFormatProps={{
                  placeholder: '0.00',
                  className: 'm-b-0',
                  onFocus: () => setFocusedInputName(InputNameEnum.RefundAmount),
                  getInputRef: refundAmountRef,
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                }}
              />
            </ColWrap>

            <ColWrap colProps={{ span: 24 }}>
              <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
                {/* numeric keyboard */}
                <ColWrap colProps={{ span: 18 }}>
                  <KeyboardWrapper
                    inputName={focusedInputName}
                    form={form}
                    keyboardRef={keyboardRef}
                    maxLength={9}
                    keyboardProps={{
                      inputPattern: /^\d+$/,
                    }}
                    direction={KeyboardDirection.rtl}
                  />
                </ColWrap>

                {/* ok button */}
                <ColWrap colProps={{ span: 6 }}>
                  <CustomButton
                    type="primary"
                    buttonProps={{
                      className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                      htmlType: 'submit',
                    }}
                    content={intl.formatMessage({ id: 'common.OK' })}
                  />
                </ColWrap>
              </RowWrap>
            </ColWrap>
          </RowWrap>
        </FormWrap>
      </StyledModal>

      {/* confirm dialog */}
      <StyledPopup
        isOpen={isConfirmDialog}
        content={
          <div>
            {intl.formatMessage({ id: 'checkout.transactions.refundMethod.confirm.areYouSureYouWantTo' })}
            &nbsp;
            <span className="font-weight-700 text-transform-lowercase">
              {intl.formatMessage({ id: 'checkout.transactions.refundMethod.confirm.refund' })}
            </span>
            &nbsp;
            {intl.formatMessage({ id: 'checkout.transactions.refundMethod.confirm.this' })}
            &nbsp;
            <span className="font-weight-700 text-transform-lowercase">
              ${formatNumberThousandWithDecimal(refundAmount)}
            </span>
            &nbsp;
            {intl.formatMessage({ id: 'checkout.transactions.refundMethod.confirm.toTheCustomer' })}?
          </div>
        }
        customFooter={
          <div className="d-flex justify-content-center gap-12">
            <CustomButton
              type="primary"
              content={intl.formatMessage({ id: 'common.no' })}
              buttonProps={{
                className: 'font-size-16 font-weight-700 height-38 width-100',
                onClick: () => setIsConfirmDialog(false),
              }}
            />

            <CustomButton
              type="cancel"
              content={intl.formatMessage({ id: 'common.yes' })}
              buttonProps={{
                className: 'font-size-16 font-weight-700 height-38 width-100',
                onClick: () => {
                  refundMutation.mutate({
                    money: Number(refundAmount) || 0,
                    ticketId: ticketId,
                    typePayment: refundType,
                  });
                },
              }}
            />
          </div>
        }
        onCancel={() => {
          setIsConfirmDialog(false);
        }}
        onOk={() => {}}
      />
    </>
  );
};
