import { useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { customerApi } from '../../../../../apis';
import { Customer, Promotion } from '../../../../../apis/client-axios';
import { FormInputSearch } from '../../../../../components/Form/FormInputSearch';
import FormWrap from '../../../../../components/FormWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import TableWrap from '../../../../../components/TableWrap';
import { formatPhoneNumber } from '../../../../../utils';
import { QUERY_CUSTOMER } from '../../../../../utils/constant';
import { IAppointmentBill } from '../../index';
import '../style.scss';
import { AxiosResponse } from 'axios';
import { RedeemOption } from '../../../promotions/LoyaltyReward';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../../checkoutConstants';

export interface Props<T extends object> {
  open: boolean;
  appointmentBill: IAppointmentBill;
  onCancelModal: () => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  listPromotion: AxiosResponse<Promotion, any> | undefined;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
}

interface IFilter {
  fullTextSearch: string;
}

const initialFilters: IFilter = {
  fullTextSearch: '',
};
function ModalCustomer<T extends object>(props: Props<T>) {
  const { open, appointmentBill, onCancelModal, setAppointmentBill, listPromotion, setDiscountsAndRewardsApplied } =
    props;
  const [form] = Form.useForm();
  const intl = useIntl();
  const [filters, setFilters] = useState(initialFilters);

  const { data: listDataCustomer } = useQuery({
    queryKey: [QUERY_CUSTOMER, filters],
    queryFn: () => customerApi.customerControllerGetRecentCheckins(1, undefined, '', filters.fullTextSearch),
    enabled: true,
    staleTime: 1000,
  });
  const onFinishSearch = ({ fullTextSearch }: { fullTextSearch: string }) => {
    setFilters({ ...filters, fullTextSearch: fullTextSearch });
  };
  const handleSaveValue = (record: Customer) => {
    console.log(record);
    setAppointmentBill({
      ...appointmentBill,
      customer: record,
      customerId: record.id,
    });
    // auto select the highest redeem option in rewards
    if (
      !!listPromotion?.data?.loyaltyReward?.redeemOptions?.length &&
      listPromotion?.data?.loyaltyReward?.autoRewardPoint &&
      !!record?.customerReward?.length
    ) {
      const redeemOptions = listPromotion?.data?.loyaltyReward?.redeemOptions as RedeemOption[];
      const customerRewardPoints =
        record?.customerReward?.reduce((currentValue, item) => currentValue + item.reward, 0) || 0;
      const maxRedeemOption: RedeemOption | undefined = !customerRewardPoints
        ? undefined
        : redeemOptions.reduce((prev, cur) => {
            console.log('prevvv', prev, 'current', cur);
            return cur
              ? customerRewardPoints >= (cur.redeem || 0) && cur.redeem > (prev?.redeem ?? 0)
                ? cur
                : prev
              : prev;
          }, undefined as RedeemOption | undefined);
      console.log('loggg', {
        redeemOptions: listPromotion?.data?.loyaltyReward?.redeemOptions,
        autoRewardPoint: listPromotion?.data?.loyaltyReward?.autoRewardPoint,
        recordCustomerReward: record?.customerReward,
        maxRedeemOption,
      });
      if (maxRedeemOption) {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          rewardBalance: {
            amount: maxRedeemOption.for,
            points: maxRedeemOption.redeem,
            isCustomAmount: false,
            type: DiscountTypeEnum.Dollar,
          },
          referralDiscount: undefined,
        }));
      } else {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          referralDiscount: undefined,
        }));
      }
    } else {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        referralDiscount: undefined,
      }));
    }
    onCancelModal();
  };

  const columnsCustomer: ColumnType<Customer>[] = [
    {
      key: 0,
      title: intl.formatMessage({ id: 'checkout.option.phoneNumber' }),
      width: 170,
      align: 'left',
      render: (text, item: Customer) => (
        <span className="font-size-16 font-weight-400">
          {item.phoneNumber ? formatPhoneNumber(item.phoneNumber, ' - ') : '--'}
        </span>
      ),
    },
    {
      key: 1,
      title: intl.formatMessage({ id: 'checkout.option.name' }),
      width: 272,
      align: 'left',
      render: (text, item: Customer) => <span className="font-size-16 font-weight-400">{item.name}</span>,
    },
    {
      key: 1,
      title: intl.formatMessage({ id: 'checkout.option.paymentCard' }),
      width: 142,
      align: 'left',
      render: (text, item: Customer) => <span>**** **** {8990}</span>,
    },
    {
      key: 1,
      title: intl.formatMessage({ id: 'checkout.option.referralcode' }),
      width: 136,
      align: 'left',
      render: (text, item: Customer) => <span>{item.referralCode ?? '--'}</span>,
    },
  ];

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-option-modal-heading">
            {intl.formatMessage({ id: 'checkout.option.customer' })}
          </div>
        ),
        className: 'salon__checkout-option-modal salon__checkout-option-modal-list-customer',
        width: 860,
        footer: null,
      }}
    >
      <FormWrap
        form={form}
        onFinish={onFinishSearch}
        name="searchTechnicianCustomerCheckout"
        layout="vertical"
        className="w-100 salon__checkout-option-modal-form"
      >
        <FormInputSearch
          name={'fullTextSearch'}
          inputProps={{
            placeholder: intl.formatMessage({ id: 'customer.placeholder.search' }),
            className: 'width-780 salon__checkout-option-modal-form-search',
          }}
          formItemProps={{
            rules: [
              {
                transform: (value) => {
                  return value?.trim();
                },
              },
            ],
            className: 'm-0',
          }}
        />
        <div className="salon__checkout-table-customer m-t-12">
          <TableWrap
            data={listDataCustomer?.data.content ?? []}
            columns={columnsCustomer}
            total={0}
            size={0}
            page={1}
            setPage={() => {}}
            setSize={() => {}}
            onRowClick={(record) => {
              handleSaveValue(record);
            }}
            showSizeChanger
            isHidePagination={true}
            isScroll={true}
            scrollValue={{ y: 216 }}
            isPointer={true}
          />
        </div>
      </FormWrap>
    </StyledModal>
  );
}

export default ModalCustomer;
