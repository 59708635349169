import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Form, FormInstance, Image, Space, Spin, Tabs } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import TabPane from 'antd/es/tabs/TabPane';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { appointmentApi, customerApi, employeeApi, servicesApi } from '../../../../apis';
import {
  Appointment,
  AppointmentStatusEnum,
  ChangeStatusAppointment,
  CreateAppointmentDTO,
  CreateAppointmentsDTO,
  DepositTotalDTO,
  ServiceItems,
  TicketDTOStatusEnum,
  UpdateAppointmentDTO,
} from '../../../../apis/client-axios';
import AvatarDefault from '../../../../assets/images/appointment/avatar-default.png';
import SvgAdd from '../../../../components/@svg/SvgAdd';
import { SvgAppointmentGroup } from '../../../../components/@svg/SvgAppointmentGroup';
import { SvgCalenderIcon } from '../../../../components/@svg/SvgCalenderIcon';
import { SvgCopyIcon } from '../../../../components/@svg/SvgCopyIcon';
import { SvgDollarIcon } from '../../../../components/@svg/SvgDollarIcon';
import { SvgMinMinutesIcon } from '../../../../components/@svg/SvgMinMinutesIcon';
import { SvgMiniStarIconUnactive } from '../../../../components/@svg/SvgMiniStarIconUnactive';
import { SvgRemoveServiceItemIcon } from '../../../../components/@svg/SvgRemoveServiceItemIcon';
import SvgRingVolume from '../../../../components/@svg/SvgRingVolume';
import ColWrap from '../../../../components/ColWrap';
import { FormCheckbox } from '../../../../components/Form/FormCheckbox';
import { FormInput } from '../../../../components/Form/FormInput';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import { FormInputNumberFormat } from '../../../../components/Form/FormInputNumberFormat';
import { FormSelect } from '../../../../components/Form/FormSelect';
import { FormTextArea } from '../../../../components/Form/FormTextArea';
import FormWrap from '../../../../components/FormWrap';
import NotificationError from '../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../components/HandleShowNotiSuccess';
import RowWrap from '../../../../components/RowWrap';
import { StyledModal } from '../../../../components/StyledModal';
import { StyledPopup } from '../../../../components/StyledPopup';
import { ButtonIcon } from '../../../../components/buttons/ButtonIcon';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { formatOnlyDate, formatTimeHHMMa, formatTimeHHMMaFulltime } from '../../../../utils';
import { QUERY_EMPLOYEES, QUERY_SERVICES } from '../../../../utils/constant';
import { STATUSES } from '../index';
import '../style.scss';
import CustomerModal from './CustomerModal';
import DepositModal, { DepositModalType, DepositModalTypeEnum } from './DepositModal';
import { FormGroupButton } from './FormGroupButton';
import PickTimeModal, { PicktimeModalTypeEnum } from './PickTimeModal';
import SelectServiceModal from './ServiceModal';
import './style.scss';
import { SvgBigAdd } from '../../../../components/@svg/SvgBigAdd';
import { useNavigate } from 'react-router-dom';
import { ITechnicianItem, IMerchandise, IServicesItem, IAppointmentBill } from '../../checkOut';
import { MAIN_ROUTES } from '../../routes';

interface Props {
  open: boolean;
  setOpenModal: (state: boolean) => void;
  appointment?: Appointment | undefined;
  setAppointment?: (appointment: Appointment | undefined) => void;
  initData?: IinitAppointmentModal;
  setInitData?: (initData: IinitAppointmentModal | undefined) => void;
}

export interface IinitAppointmentModal {
  technicianId?: number;
  timeStart?: Date;
  customerName?: string;
  customerNumber?: string;
}

export interface IFormData {
  time: string;
  phone: string;
  phoneCheckbox: boolean;
  estimate: number;
  name: string;
  serviceIds: number[];
  technicianId: number;
  technicianCheckbox: boolean;
  notes?: string;
  status: string;
  total: number;
  deposit: number;
}
export interface IFromState {
  listSelectedService: ServiceItems[];
  deposit: DepositTotalDTO[] | undefined;
  status: AppointmentStatusEnum;
  time: Date | undefined;
  technicianId: number | undefined;
  phone: string | undefined;
  tabId: number;
  form: FormInstance;
  name: string | undefined;
}
export interface IFromStateInput {
  listSelectedService?: ServiceItems[];
  deposit?: DepositTotalDTO[];
  status?: AppointmentStatusEnum;
  time?: Date | undefined;
  technicianId?: number | undefined;
  phone?: string | undefined;
  tabId?: number;
  form?: FormInstance;
  name?: string | undefined;
}

const AppointmentModified: FC<Props> = (props) => {
  const { open, setOpenModal, appointment, initData, setAppointment, setInitData } = props;
  const intl = useIntl();
  const salonId = Number(localStorage.getItem('salonId'));
  const n = (key: keyof IFormData) => key;
  const [activeTab, setActiveTab] = useState(0);
  const [isServiceModalOpen, setServiceModalOpen] = useState(false);
  const [isDepositModalOpen, setDepositModalOpen] = useState(false);
  const [depositModalType, setDepositModalType] = useState<DepositModalType>({ type: DepositModalTypeEnum.CREATE });
  const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [estimateTime, setEstimateTime] = useState(dayjs(new Date()).add(15, 'minutes').format('h:mmA'));
  const [isTimeModalOpen, setTimeModalOpen] = useState(false);
  const [timeModalType, setTimeModalType] = useState<PicktimeModalTypeEnum>(PicktimeModalTypeEnum.PICKTIME);
  const [tabData, setTabData] = useState<IFromState[]>([]);
  const [isAutoFillEstimate, setAutoFillEstimate] = useState(false);
  const [isAutoFillTotal, setAutoFillTotal] = useState(false);
  const navigate = useNavigate();

  const formArray = [
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
    Form.useForm()[0],
  ];

  const { data: listTechnicians, isLoading: isLoadingListTechnicians } = useQuery([QUERY_EMPLOYEES], () =>
    employeeApi.employeeControllerGet(
      initialFilters.page,
      initialFilters.size,
      initialFilters.sort,
      initialFilters.fullTextSearch,
      initialFilters.salons
    )
  );

  useEffect(() => {
    let initTabData: IFromState;

    if (appointment) {
      const skills = listTechnicians?.data?.content
        ?.find((item) => item.id === appointment.technicianId)
        ?.skills?.map((item) => item.id);

      formArray[0].setFieldsValue({
        [n('estimate')]: appointment.estimate,
        [n('phoneCheckbox')]: appointment.useMessageReminder,
        [n('technicianCheckbox')]: appointment.useAnyTechnician,
        [n('notes')]: appointment.note,
        [n('total')]: appointment.totalMoney,
        [n('serviceIds')]: appointment.services
          ?.filter((item) => item.isMerchandise === true || skills?.includes(item.id) || !appointment.technicianId)
          ?.map((item) => item.id),
        [n('deposit')]: appointment?.appointmentDeposits?.[0]?.money,
        [n('time')]: formatTimeHHMMaFulltime(appointment.timeStart),
        [n('technicianId')]: appointment.technicianId ?? -1,
        [n('phone')]: appointment.customer.phoneNumber,
        [n('name')]: appointment.customer.name,
      });

      initTabData = {
        listSelectedService: appointment.services?.filter(
          (item) => item.isMerchandise === true || skills?.includes(item.id) || !appointment.technicianId
        ),
        deposit: appointment.appointmentDeposits,
        status: appointment.status,
        time: new Date(appointment.timeStart),
        technicianId: appointment.technicianId ?? -1,
        phone: appointment.customer?.phoneNumber,
        tabId: 0,
        form: formArray[0],
        name: appointment.customer?.name,
      };
    } else if (initData) {
      formArray[0].setFieldsValue({
        [n('total')]: undefined,
        [n('estimate')]: undefined,
      });

      initTabData = {
        listSelectedService: [],
        deposit: undefined,
        status: AppointmentStatusEnum.Confirmed,
        time: initData.timeStart,
        technicianId: initData.technicianId,
        phone: initData.customerNumber,
        tabId: 0,
        form: formArray[0],
        name: initData.customerName,
      };
    } else {
      formArray[0].setFieldsValue({
        [n('technicianCheckbox')]: true,
        [n('total')]: undefined,
        [n('estimate')]: undefined,
      });

      initTabData = {
        listSelectedService: [],
        deposit: undefined,
        status: AppointmentStatusEnum.Confirmed,
        time: new Date(),
        technicianId: -1,
        phone: undefined,
        tabId: 0,
        form: formArray[0],
        name: undefined,
      };
    }
    setActiveTab(0);
    setTabData([initTabData]);
  }, [appointment, open, initData, listTechnicians]);

  const updateTabData = (tab: IFromStateInput) => {
    if (appointment && tab.status) {
      const methodIndex = [
        AppointmentStatusEnum.WaitingConfirm,
        AppointmentStatusEnum.Confirmed,
        AppointmentStatusEnum.CheckedIn,
        AppointmentStatusEnum.BeingServed,
        AppointmentStatusEnum.Completed,
        '',
      ];

      if (
        methodIndex.indexOf(tab.status) < methodIndex.indexOf(appointment.status) &&
        appointment.status !== AppointmentStatusEnum.Confirmed
      ) {
        return;
      }
    }
    if (tab.technicianId) {
      const active = tabData.find((item) => item.tabId === activeTab);
      const technician = listDataTechnicians?.find((item) => item.id === tab.technicianId);
      if (technician)
        tab.listSelectedService = active?.listSelectedService?.filter(
          (item) => technician.skills?.some((skill) => skill.id === item.id) || item.isMerchandise
        );
    }
    if (tab.listSelectedService) {
      setAutoFillEstimate(true);
      setAutoFillTotal(true);
    }
    if (tab.tabId === -1) {
      const tabId = tabData[tabData.length - 1].tabId + 1;

      setActiveTab(tabId);
      formArray[tabId]?.resetFields();
      setTabData([
        ...tabData,
        {
          listSelectedService: [],
          deposit: undefined,
          status: AppointmentStatusEnum.Confirmed,
          time: tab?.time,
          technicianId: tab?.technicianId,
          phone: tab?.phone,
          tabId: tabId,
          form: formArray[tabId],
          name: tab.name,
        },
      ]);
    } else {
      setTabData((prevState) =>
        prevState.map((item) => {
          if (item.tabId === activeTab) {
            return {
              ...item,
              ...tab,
            };
          }
          return item;
        })
      );
    }
  };

  const {
    data: listDataServices,
    refetch: handleRefetchServices,
    isLoading: isLoadingListDataServices,
  } = useQuery([QUERY_SERVICES], () => servicesApi.serviceControllerGet(0));

  const initialFilters = {
    page: 0,
    size: undefined,
    sort: undefined,
    fullTextSearch: undefined,
    salons: [salonId],
  };

  const findCustomerByPhonNumberMutation = useMutation(
    (phoneNumber: string) => customerApi.customerControllerGetCustomerByPhoneNumber(phoneNumber, salonId),
    {
      onSuccess: ({ data }) => {
        if (data) {
          tabData.find((item) => item.tabId === activeTab)?.form.setFieldValue([n('name')], data.name);
          updateTabData({ name: data.name, phone: data.phoneNumber });
        }
      },
    }
  );

  useEffect(() => {
    setAutoFillTotal(false);
    setAutoFillEstimate(false);
  }, [activeTab]);

  useEffect(() => {
    tabData.forEach((item) => {
      item.form.setFieldsValue({
        [n('serviceIds')]: item.listSelectedService?.map((item) => item.id),
        [n('deposit')]: item.deposit?.reduce((total, deposit) => total + Number(deposit.time), 0) || '',
        [n('time')]: item.time ? formatTimeHHMMaFulltime(item.time) : undefined,
        [n('technicianId')]: item.technicianId === -1 ? -1 : item.technicianId ? item.technicianId : undefined,
        [n('technicianCheckbox')]:
          (item.technicianId && item.technicianId !== -1) || item.technicianId === undefined ? false : true,
        [n('phone')]: item.phone ? item.phone : item.form.getFieldValue([n('phone')]),
        [n('name')]: item.name ? item.name : item.form.getFieldValue([n('name')]),
        [n('total')]:
          !isAutoFillTotal || item.tabId !== activeTab
            ? item.form.getFieldValue([n('total')])
            : item.listSelectedService?.length === 0
            ? undefined
            : item.listSelectedService?.reduce((total, service) => total + Number(service.price), 0),
        [n('estimate')]:
          !isAutoFillEstimate || item.tabId !== activeTab
            ? item.form.getFieldValue([n('estimate')])
            : item.listSelectedService?.length === 0
            ? undefined
            : item.listSelectedService?.reduce((total, service) => total + Number(service.time), 0),
      });
      if (!item.form.getFieldValue([n('estimate')])) item.form.resetFields([n('estimate')]);

      if (!item.form.getFieldValue([n('total')])) item.form.resetFields([n('total')]);

      const estimate =
        item.form.getFieldValue([n('estimate')]) !== undefined ? item.form.getFieldValue([n('estimate')]) : 15;
      const estimateEnd = dayjs(item.time).add(estimate, 'minutes');
      const endOfDay = dayjs(item.time).endOf('day').set('hour', 23).set('minute', 59);
      const untilEstimate = estimateEnd > endOfDay ? endOfDay : estimateEnd;
      if (item.tabId === activeTab) setEstimateTime(formatTimeHHMMa(untilEstimate));
    });
  }, [tabData, activeTab]);

  const createAppointmentMutation = useMutation(
    (createAppointmentDTO: CreateAppointmentsDTO) =>
      appointmentApi.appointmentControllerCreateGroup(createAppointmentDTO),
    {
      onSuccess: ({ data }) => {
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.modal.notification.saved' }) });
        handleRefetchServices();
        clearDataAndCloseModal();
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
    }
  );

  const cloneAppointmentMutation = useMutation(
    (createAppointmentDTO: CreateAppointmentDTO) => appointmentApi.appointmentControllerCreate(createAppointmentDTO),
    {
      onSuccess: ({ data }) => {
        handleRefetchServices();
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.modal.clone.success' }) });
        if (setAppointment) setAppointment(data);
        setOpenModal(false);
        setTimeout(() => {
          setOpenModal(true);
        }, 200);
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
    }
  );

  const updateAppointmentMutation = useMutation(
    (payload: { appointmentId: string; updateAppointmentDTO: UpdateAppointmentDTO }) =>
      appointmentApi.appointmentControllerUpdate(payload.appointmentId, payload.updateAppointmentDTO),
    {
      onSuccess: ({ data }) => {
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.modal.notification.saved' }) });
        handleRefetchServices();
        clearDataAndCloseModal();
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
    }
  );

  const cancelAppointmentMutation = useMutation(
    (payload: { appointmentId: string; status: ChangeStatusAppointment }) =>
      appointmentApi.appointmentControllerChangeStatusAppointment(payload.appointmentId, payload.status),
    {
      onSuccess: ({ data }) => {
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.modal.notification.cancel' }) });
        handleRefetchServices();
        clearDataAndCloseModal();
      },
      onError: (error) => {
        NotificationError({ contentNoti: intl.formatMessage({ id: 'common.error' }) });
      },
    }
  );

  const formatTime = (timestamp: Date | undefined) => {
    const date = timestamp ? timestamp : new Date();
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  };

  const onFinishCreate = async () => {
    const appointmentsCreate = [];
    for (let index = 0; index < tabData.length; index++) {
      const tab = tabData[index];
      try {
        const values = await tab.form.validateFields();
        const appointmentCreate: CreateAppointmentDTO = {
          timeStart: formatTime(tab.time),
          estimate: values.estimate || 15,
          useAnyTechnician: values.technicianCheckbox,
          useMessageReminder: values.phoneCheckbox,
          status: tab.status,
          totalMoney: values.total,
          note: values.notes,
          color: STATUSES.find((item) => item.key === tab.status)?.color || '#FFD8D8',
          technicianId: tab.technicianId && tab.technicianId !== -1 ? tab.technicianId : undefined,
          customer: {
            customerName: values.name,
            customerPhone: values.phone,
          },
          serviceIds: values.serviceIds,
          depositTotal: tab.deposit ? tab.deposit : [],
        };
        appointmentsCreate.push(appointmentCreate);
      } catch (error) {
        return NotificationError({ contentNoti: intl.formatMessage({ id: 'common.errorRequiredFields' }) });
      }
    }
    if (!!appointmentsCreate.length) createAppointmentMutation.mutate({ appointments: appointmentsCreate });
  };

  const onFinishUpdate = () => {
    if (appointment)
      tabData[0].form
        .validateFields()
        .then((values: IFormData) => {
          const appointmentUpdate: UpdateAppointmentDTO = {
            timeStart: formatTime(tabData[0].time),
            estimate: values.estimate || 15,
            useAnyTechnician: values.technicianCheckbox,
            useMessageReminder: values.phoneCheckbox,
            status: tabData[0].status,
            totalMoney: values.total,
            note: values.notes,
            color: STATUSES.find((item) => item.key === tabData[0].status)?.color || '#FFD8D8',
            technicianId:
              tabData[0].technicianId && tabData[0].technicianId !== -1 ? tabData[0].technicianId : undefined,
            customer: {
              customerName: values.name,
              customerPhone: values.phone,
            },
            customerId: appointment?.customerId,
            serviceIds: values.serviceIds,
            depositTotal: tabData[0].deposit ? tabData[0].deposit : [],
          };

          updateAppointmentMutation.mutate({
            appointmentId: appointment.id.toString(),
            updateAppointmentDTO: appointmentUpdate,
          });
        })
        .catch((errorInfo) => {
          NotificationError({ contentNoti: intl.formatMessage({ id: 'common.errorRequiredFields' }) });
        });
  };

  const onFinishCancel = () => {
    if (appointment)
      cancelAppointmentMutation.mutate({
        appointmentId: appointment.id.toString(),
        status: { status: AppointmentStatusEnum.Canceled },
      });
  };

  const onFinishClone = () => {
    if (appointment) {
      const appointmentCreate: CreateAppointmentDTO = {
        timeStart: formatTime(new Date()),
        estimate: appointment.estimate || 15,
        useAnyTechnician: appointment.useAnyTechnician,
        useMessageReminder: appointment.useMessageReminder,
        status: AppointmentStatusEnum.WaitingConfirm,
        totalMoney: appointment.totalMoney,
        note: appointment.note,
        color: appointment.color,
        technicianId: appointment?.technicianId,
        customer: {
          customerName: appointment.customer.name,
          customerPhone: appointment.customer.phoneNumber,
        },
        serviceIds: appointment.services?.map((item) => item.id),
        depositTotal: appointment.appointmentDeposits,
      };
      cloneAppointmentMutation.mutate(appointmentCreate);
    }
  };

  const onCheckout = () => {
    if (appointment) {
      const technician: ITechnicianItem[] = [];
      const ticketMerchandise: IMerchandise[] = [];
      const services: IServicesItem[] = [];

      const checkoutServices = appointment?.services || [];

      checkoutServices.forEach((service) => {
        const ticketMerchandiseItem: IMerchandise = {
          name: service?.name,
          merchandisePrice: service?.price,
          merchandiseId: service?.id,
          isTaxable: service.isTaxable,
          idDelete: parseFloat(Math.random().toFixed(3)) * 10,
        } as IMerchandise;

        const serviceItem: IServicesItem = {
          description: service?.description,
          id: service?.id,
          name: service?.name,
          price: service?.price,
          supply: service?.supply,
          isMerchandise: service?.isMerchandise,
          isGeneralService: !service?.isMerchandise,
          isTaxable: service.isTaxable,
        } as IServicesItem;

        const findIndexService = services.findIndex((service) => service.id === serviceItem.id);

        if (findIndexService === -1) {
          if (serviceItem.isMerchandise) {
            ticketMerchandise.push(ticketMerchandiseItem);
          } else {
            services.push(serviceItem);
          }
        }
      });

      if (appointment?.technician) {
        const technicianService: IServicesItem[] = [];
        appointment.services.forEach((item) => {
          if (!item.isMerchandise) {
            technicianService.push({
              description: item?.description ?? '',
              id: item?.id,
              name: item?.name,
              price: item?.price,
              supply: item?.supply ?? 0,
              isMerchandise: item?.isMerchandise,
              isGeneralService: false,
              isTaxable: item.isTaxable,
            });
          }
        });
        const technicianItem: ITechnicianItem = {
          id: appointment?.technician.id ? +appointment?.technician.id : parseFloat(Math.random().toFixed(3)) * 10,
          defaultAvatar: appointment?.technician?.defaultAvatar,
          name: appointment?.technician?.name,
          phoneNumber: appointment?.technician?.phoneNumber,
          title: appointment?.technician?.title,
          services: technicianService,
          skills: appointment?.technician?.skills.map((e) => e.id),
        };

        technician.push(technicianItem);
      }
      let totalDeposit: number = 0;
      appointment.appointmentDeposits.map((item) => (totalDeposit += +item.money));
      const appointmentBill: IAppointmentBill = {
        technician,
        appointmentId: [appointment?.id],
        customerId: appointment?.customerId,
        ticketMerchandise,
        status: TicketDTOStatusEnum.Hold,
        giftCards: [],
        deposit: totalDeposit,
        customer: appointment?.customer,
      };

      sessionStorage.setItem('appointmentBill', JSON.stringify(appointmentBill));
      navigate('/' + MAIN_ROUTES.CHECK_OUT);
    }
  };

  const statusCanBeEdit = [
    AppointmentStatusEnum.WaitingConfirm,
    AppointmentStatusEnum.Confirmed,
    AppointmentStatusEnum.CheckedIn,
    '',
  ];

  const isDisableInput = (name: keyof IFormData) => {
    if (!appointment) return false;
    switch (appointment.status) {
      case AppointmentStatusEnum.WaitingConfirm:
        return false;
      case AppointmentStatusEnum.Confirmed:
        return false;
      case AppointmentStatusEnum.CheckedIn:
        if ([n('time'), n('serviceIds'), n('technicianId'), n('technicianCheckbox')].includes(name)) return false;
        return true;
      case AppointmentStatusEnum.BeingServed:
        return true;
      case AppointmentStatusEnum.Confirmed:
        return true;
    }
  };

  const listDataTechnicians = listTechnicians?.data?.content?.map((technician) => {
    return {
      ...technician,
      avatar:
        technician.defaultAvatar || technician.avatar?.preview || technician.avatar?.source
          ? technician.defaultAvatar ||
            `${process.env.REACT_APP_API_URL}/static/${technician.avatar?.preview || technician.avatar?.source}`
          : AvatarDefault,
    };
  });

  let technicianOption = listDataTechnicians?.map((employee) => ({
    label: employee.name,
    value: employee.id,
  }));

  technicianOption?.push({ label: 'Unassigned', value: -1 });

  const clearDataAndCloseModal = () => {
    if (setAppointment) setAppointment(undefined);
    if (setInitData) setInitData(undefined);
    tabData?.[0]?.form.resetFields();
    setOpenModal(false);
  };

  const deleteTab = (tab: IFromState) => {
    const newTabData = tabData.filter((item) => item.tabId !== tab.tabId);
    setTabData(newTabData);
    setTimeout(() => {
      if (tab.tabId === activeTab) setActiveTab(tabData[tabData.findIndex((item) => item == tab) - 1].tabId);
      else setActiveTab(tabData.find((item) => item.tabId === activeTab)?.tabId || 0);
    }, 50);
  };

  return (
    <React.Fragment key={`modalAppointmentModified`}>
      <StyledModal
        isOpen={open}
        onCancel={() => clearDataAndCloseModal()}
        modalProps={{
          title: (
            <div className="salon__appointment-modal-heading">
              <span className="line-height-32">
                {appointment &&
                (appointment.status === AppointmentStatusEnum.Completed ||
                  appointment.status === AppointmentStatusEnum.Canceled)
                  ? intl.formatMessage({ id: 'appointment.modal.heading.detail' })
                  : intl.formatMessage({ id: 'appointment.modal.heading' }, { title: appointment ? 'Edit' : 'Create' })}
              </span>

              <Space direction="horizontal" size={13}>
                {!appointment && tabData[tabData.length - 1]?.tabId < 9 && (
                  <Button
                    className="border-none shadow-none"
                    type="default"
                    onClick={() => {
                      setTimeModalType(PicktimeModalTypeEnum.NEWTAB);
                      setTimeModalOpen(true);
                    }}
                  >
                    <SvgAppointmentGroup />
                    <span className="p-l-4">{intl.formatMessage({ id: 'appointment.button.group' })}</span>
                  </Button>
                )}
              </Space>
            </div>
          ),
          className: 'salon__appointment-modal-create',
          width: 927,
          footer: (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                {appointment && statusCanBeEdit.includes(appointment.status) && (
                  <Button
                    className="salon__appointment-modal-cancel-button width-240"
                    onClick={() => setCancelModalOpen(true)}
                  >
                    {intl.formatMessage({ id: 'appointment.modal.button.cancel' })}{' '}
                  </Button>
                )}
              </div>

              <div className="d-flex">
                {appointment && (
                  <Button className="salon__appointment-modal-cancel-button width-38" onClick={() => onFinishClone()}>
                    <SvgCopyIcon />
                  </Button>
                )}

                {appointment && !statusCanBeEdit.includes(appointment.status) ? (
                  appointment.status === AppointmentStatusEnum.BeingServed && (
                    <>
                      <ButtonStyled
                        content={intl.formatMessage({ id: 'checkout.checkOut' })}
                        buttonProps={{
                          className: 'width-100 height-38',
                          onClick: () => onCheckout(),
                        }}
                        isPrimary={true}
                      />
                    </>
                  )
                ) : (
                  <ButtonStyled
                    content={intl.formatMessage({ id: 'common.save' })}
                    buttonProps={{
                      className: 'width-100 height-38',
                      onClick: () => {
                        appointment ? onFinishUpdate() : onFinishCreate();
                      },
                    }}
                    isPrimary={true}
                  />
                )}
              </div>
            </div>
          ),
        }}
      >
        {isLoadingListDataServices || isLoadingListTechnicians ? (
          <div className="min-height-600 d-flex justify-content-center align-items-center">
            <Spin />
          </div>
        ) : (
          <Tabs onChange={(key) => setActiveTab(Number(key))} activeKey={activeTab.toString()}>
            {!!tabData?.length &&
              tabData.map((tab) => (
                <TabPane
                  tab={
                    <div className="salon__appointment-modal-tab-content">
                      {listDataTechnicians?.find((item) => item.id === tab.technicianId)?.avatar && (
                        <Image
                          src={listDataTechnicians?.find((item) => item.id === tab.technicianId)?.avatar}
                          preview={false}
                        />
                      )}

                      <span>
                        {listDataTechnicians?.find((item) => item.id === tab.technicianId)?.name ?? 'Unassigned'}
                      </span>
                      {tab.tabId !== 0 ? (
                        <ButtonIcon
                          icon={<SvgRemoveServiceItemIcon />}
                          className="salon__appointment-remove-technician-button"
                          buttonProps={{
                            onClick: () => deleteTab(tab),
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  }
                  key={tab.tabId.toString()}
                  closable
                >
                  <FormWrap
                    form={tab.form}
                    name={`appointmentModified_${tab.tabId}`}
                    key={`appointmentModified_${tab.tabId}`}
                    layout="vertical"
                    className="w-100 salon__appointment-modal"
                  >
                    <RowWrap isAutoFillRow={true} isGutter={true} gutter={31} isWrap={false} styleFill="between">
                      <ColWrap colProps={{ span: 12 }}>
                        <FormInput
                          name={n('time')}
                          label={intl.formatMessage({ id: 'appointment.modal.form.title.time' })}
                          inputProps={{
                            placeholder: intl.formatMessage({ id: 'appointment.modal.placeholder.enterTime' }),
                            onClick: () => {
                              if (!isDisableInput(n('time'))) {
                                setTimeModalType(PicktimeModalTypeEnum.PICKTIME);
                                setTimeModalOpen(true);
                              }
                            },
                            disabled: isDisableInput(n('time')),
                            onKeyDown: (e) => {
                              e.preventDefault();
                            },
                          }}
                        />
                        <div
                          className="salon__appointment-modal-input-suffix cursor-pointer"
                          onClick={() => {
                            if (!isDisableInput(n('time'))) {
                              setTimeModalType(PicktimeModalTypeEnum.PICKTIME);
                              setTimeModalOpen(true);
                            }
                          }}
                        >
                          <SvgCalenderIcon />
                        </div>
                      </ColWrap>
                      <ColWrap colProps={{ span: 12 }}>
                        <FormInputNumberFormat
                          name={n('phone')}
                          label={intl.formatMessage({ id: 'appointment.modal.form.title.phone' })}
                          formItemProps={{
                            required: true,
                            rules: [
                              {
                                validator: (_, value) => {
                                  const v = value;

                                  if (!v) {
                                    return Promise.reject();
                                  }
                                  if (v && v.replace(/[-\s]/g, '').length < 10) {
                                    return Promise.reject();
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          }}
                          patternFormat={{
                            format: '### - ### - ####',
                            className: 'font-weight-500',
                            placeholder: intl.formatMessage({ id: 'appointment.modal.placeholder.enterPhoneNumber' }),
                            onChange: (e) => {
                              const v = e?.target?.value?.replace(/[-\s]/g, '');

                              tab.form.setFieldValue(n('phone'), v);
                            },
                            onBlur: (e) => {
                              const v = e?.target?.value?.replace(/[-\s]/g, '');

                              if (v?.length === 10) findCustomerByPhonNumberMutation.mutate(v);
                            },
                            disabled: isDisableInput(n('phone')),
                          }}
                        />
                        <div
                          className="salon__appointment-modal-input-suffix cursor-pointer"
                          onClick={() => {
                            if (!isDisableInput(n('phone'))) setCustomerModalOpen(true);
                          }}
                        >
                          <SvgRingVolume />
                        </div>

                        <div className="d-flex justify-content-between">
                          <FormCheckbox
                            name={'phoneCheckbox'}
                            content={intl.formatMessage({ id: 'appointment.modal.form.title.phone.checkbox' })}
                            checkboxProps={{
                              onChange: (e) => {},
                              disabled: isDisableInput(n('phoneCheckbox')),
                            }}
                            formItemProps={{
                              className: 'salon__appointment-modal-checkbox m-b-0',
                            }}
                          />
                          <div>
                            {Array.from({ length: 5 }).map((_, index) => (
                              <SvgMiniStarIconUnactive key={`miniStar_${index}_${tab.tabId}`} />
                            ))}
                          </div>
                        </div>
                      </ColWrap>
                    </RowWrap>

                    <RowWrap isAutoFillRow={true} isGutter={true} gutter={31} isWrap={false} styleFill="between">
                      <ColWrap colProps={{ span: 12 }}>
                        <RowWrap isAutoFillRow={true} isGutter={true} gutter={24} isWrap={false} styleFill="between">
                          <ColWrap colProps={{ span: 16 }}>
                            <div className="salon__appointment-modal-estimate ">
                              <FormInputNumber
                                name={n('estimate')}
                                label={intl.formatMessage({ id: 'appointment.modal.form.title.estimate' })}
                                suffix={<SvgMinMinutesIcon />}
                                numericFormatProps={{
                                  allowNegative: false,
                                  min: 1,
                                  max: 999_999_999,
                                  decimalScale: 0,
                                  className: 'height-34',
                                  onChange: (e) => {
                                    const v = Number(e.target.value.replace(/[,\s]/g, ''));
                                    if (v <= 0) return;
                                    const estimateEnd = dayjs(tab.time).add(v || 15, 'minutes');
                                    const endOfDay = dayjs(tab.time).endOf('day').set('hour', 23).set('minute', 59);
                                    const estimate = estimateEnd > endOfDay ? endOfDay : estimateEnd;
                                    setEstimateTime(formatTimeHHMMa(estimate));
                                    setAutoFillEstimate(false);
                                    tab.form.setFieldValue(n('estimate'), v);
                                  },
                                  disabled: isDisableInput(n('estimate')),
                                }}
                                formItemProps={{
                                  className: 'm-b-0',
                                  rules: [
                                    {
                                      transform(value) {
                                        return value?.trim();
                                      },
                                      validator: (_, value) => {
                                        return Promise.resolve();
                                      },
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </ColWrap>
                          <ColWrap colProps={{ span: 8 }}>
                            <span className="salon__appointment-modal-phone-input-until">
                              {intl.formatMessage({ id: 'appointment.modal.form.estimate.until' })} {estimateTime}
                            </span>
                          </ColWrap>
                        </RowWrap>
                      </ColWrap>
                      <ColWrap colProps={{ span: 12 }}>
                        <FormInput
                          name={n('name')}
                          label={intl.formatMessage({ id: 'appointment.modal.form.title.name' })}
                          inputProps={{
                            placeholder: intl.formatMessage({ id: 'appointment.modal.placeholder.enterCustomerName' }),
                            required: true,
                            disabled: isDisableInput(n('name')),
                          }}
                          formItemProps={{
                            required: true,
                            rules: [
                              {
                                transform(value) {
                                  return value?.trim();
                                },
                                validator: (_, value) => {
                                  const v = value;

                                  if (!v) {
                                    return Promise.reject();
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ],
                          }}
                        />
                      </ColWrap>
                    </RowWrap>
                    <RowWrap isAutoFillRow={true} isGutter={true} gutter={31} isWrap={false} styleFill="between">
                      <ColWrap colProps={{ span: 12 }}>
                        <FormSelect
                          name={n('serviceIds')}
                          label={intl.formatMessage({ id: 'appointment.modal.form.title.services' })}
                          selectProps={{
                            onClick: () => {
                              if (!isDisableInput(n('serviceIds'))) setServiceModalOpen(true);
                            },
                            options: listDataServices?.data?.content
                              ?.flatMap((item) => item.serviceItems)
                              .map((service) => ({
                                label: service.name,
                                value: service.id,
                              })),
                            mode: 'multiple',
                            maxTagCount: 'responsive',
                            open: false,
                            placeholder: intl.formatMessage({ id: 'appointment.modal.placeholder.enterService' }),
                            disabled: isDisableInput(n('serviceIds')),
                            onChange: (value) =>
                              updateTabData({
                                listSelectedService: listDataServices?.data?.content
                                  ?.flatMap((item) => item.serviceItems)
                                  .filter((service) => value.includes(service.id)),
                              }),
                            suffixIcon: <></>,
                          }}
                          formItemProps={{
                            rules: [
                              {
                                validator: (_, value) => {
                                  if (tab.status === AppointmentStatusEnum.BeingServed && !value?.length) {
                                    return Promise.reject();
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          }}
                        />
                      </ColWrap>
                      <ColWrap colProps={{ span: 12 }}>
                        <FormSelect
                          name={n('technicianId')}
                          label={intl.formatMessage({ id: 'appointment.modal.form.title.technician' })}
                          selectProps={{
                            allowClear: false,
                            open: false,
                            options: technicianOption,
                            maxTagCount: 'responsive',
                            onChange: (e) => updateTabData({ technicianId: e }),
                            placeholder: intl.formatMessage({ id: 'appointment.modal.placeholder.enterTechnician' }),
                            onClick: () => {
                              if (!isDisableInput(n('technicianId'))) {
                                setTimeModalType(PicktimeModalTypeEnum.PICKTIME);
                                setTimeModalOpen(true);
                              }
                            },
                            disabled: isDisableInput(n('technicianId')),
                            mode: 'multiple',
                            removeIcon: true,
                          }}
                          formItemProps={{
                            className: 'm-b-4',
                            rules: [
                              {
                                validator: (_, value) => {
                                  if (tab.status === AppointmentStatusEnum.BeingServed && (value === -1 || !value)) {
                                    return Promise.reject();
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          }}
                        />

                        <FormCheckbox
                          name={'technicianCheckbox'}
                          content={intl.formatMessage({ id: 'appointment.modal.form.title.technician.checkbox' })}
                          checkboxProps={{
                            onChange: (e: CheckboxChangeEvent) => {
                              if (e.target.checked) updateTabData({ technicianId: -1 });
                              else updateTabData({ technicianId: undefined });
                            },
                            disabled: isDisableInput(n('technicianCheckbox')),
                          }}
                          formItemProps={{
                            className: 'salon__appointment-modal-checkbox m-b-0',
                          }}
                        />
                      </ColWrap>
                    </RowWrap>

                    <RowWrap isAutoFillRow={true} isGutter={true} gutter={31} isWrap={false} styleFill="between">
                      <ColWrap colProps={{ span: 12 }}>
                        <FormTextArea
                          name={n('notes')}
                          label={intl.formatMessage({ id: 'appointment.modal.form.title.notes' })}
                          inputProps={{
                            className: 'border-12px min-height-143 resize-none flex-grow',
                            placeholder: intl.formatMessage({ id: 'appointment.modal.placeholder.enterNote' }),
                            disabled: isDisableInput(n('notes')),
                            maxLength: 1000,
                          }}
                        />
                      </ColWrap>

                      <ColWrap colProps={{ span: 12 }}>
                        <RowWrap isAutoFillRow={true} isGutter={true} gutter={24} isWrap={true} styleFill="between">
                          <ColWrap colProps={{ span: 24 }}>
                            <FormGroupButton
                              name={n('status')}
                              label={intl.formatMessage({ id: 'appointment.modal.form.title.status' })}
                              activeTab={activeTab}
                              tabData={tabData}
                              setTabData={updateTabData}
                              formItemProps={{
                                className: 'm-b-22',
                              }}
                              inputProps={{
                                disabled: isDisableInput(n('status')),
                              }}
                            />
                          </ColWrap>
                          <ColWrap colProps={{ span: 12 }}>
                            <FormInputNumber
                              name={n('total')}
                              label={intl.formatMessage({ id: 'appointment.modal.form.title.total' })}
                              prefix={<SvgDollarIcon />}
                              numericFormatProps={{
                                allowNegative: false,

                                min: 0,
                                max: 999_999_999,
                                placeholder: intl.formatMessage({ id: 'appointment.modal.form.placeholder.total' }),
                                style: { textAlign: 'left' },
                                onChange: (e) => {
                                  setAutoFillTotal(false);
                                  const v = Number(e.target.value.replace(/[,\s]/g, ''));
                                  tab.form.setFieldValue(n('total'), v);
                                },
                                disabled: isDisableInput(n('total')),
                              }}
                              formItemProps={{
                                className: 'm-b-0',
                                rules: [
                                  {
                                    transform(value) {
                                      return value?.trim();
                                    },
                                    validator: (_, value) => {
                                      return Promise.resolve();
                                    },
                                  },
                                ],
                              }}
                            />
                          </ColWrap>

                          <ColWrap colProps={{ span: 12, className: 'p-l-0' }}>
                            <div
                              className="salon__appointment-modal-deposit-container position-relative cursor-pointer"
                              onClick={() => {
                                if (!isDisableInput(n('deposit'))) {
                                  setDepositModalType({ type: DepositModalTypeEnum.CREATE });
                                  setDepositModalOpen(true);
                                }
                              }}
                            >
                              <label htmlFor={n('deposit')} className="salon__appointment-modal-deposit-label">
                                {intl.formatMessage({ id: 'appointment.modal.form.title.deposit' })}
                              </label>
                              <div className="salon__appointment-modal-deposit-input">
                                <SvgAdd />
                                <div className="salon__appointment-modal-deposit-value">
                                  <SvgDollarIcon />
                                  {tab.deposit ? (
                                    <p className="color-292F33 font-size-16 font-weight-400 m-b-0">
                                      {tab.deposit
                                        ?.reduce((total, deposit) => total + Number(deposit.money), 0)
                                        .toLocaleString('en-US', {
                                          style: 'decimal',
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                    </p>
                                  ) : (
                                    <p className="color-B4B6B8 font-size-16 font-weight-400 m-b-0">
                                      {intl.formatMessage({ id: 'appointment.modal.form.placeholder.deposit' })}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </ColWrap>

                          {tab.deposit && tab.deposit?.length > 0 && (
                            <ColWrap colProps={{ span: 24 }}>
                              <div className="salon__appointment-modal-deposit-history">
                                {tab?.deposit?.map((depositItem, index) => (
                                  <div
                                    key={`depositItem_${index}_${tab.tabId}`}
                                    className="salon__appointment-modal-deposit-history-item  cursor-pointer"
                                    onClick={() => {
                                      if (!isDisableInput(n('deposit'))) {
                                        setDepositModalType({ type: DepositModalTypeEnum.EDIT, index: index });
                                        setDepositModalOpen(true);
                                      }
                                    }}
                                  >
                                    <div className="salon__appointment-modal-deposit-date d-flex">
                                      <p className="color-292F33 font-size-16 font-weight-400 m-b-0">
                                        {formatOnlyDate(depositItem.time)}
                                      </p>
                                      <p className="color-292F33 font-size-16 font-weight-400 m-b-0 p-l-80">
                                        {depositItem.action}
                                      </p>
                                    </div>
                                    <div className="salon__appointment-modal-deposit-type"></div>
                                    <div className="salon__appointment-modal-deposit-value">
                                      <SvgDollarIcon />
                                      <p className="color-292F33 font-size-16 font-weight-400 m-b-0">
                                        {Number(depositItem.money).toLocaleString('en-US', {
                                          style: 'decimal',
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                                <div className="salon__appointment-modal-deposit-history-item justify-content-end">
                                  <div className="salon__appointment-modal-deposit-value cursor-pointer">
                                    <SvgBigAdd />
                                  </div>
                                </div>
                              </div>
                            </ColWrap>
                          )}
                        </RowWrap>
                      </ColWrap>
                    </RowWrap>
                  </FormWrap>
                </TabPane>
              ))}
          </Tabs>
        )}
      </StyledModal>

      {/* Model for select service */}
      <SelectServiceModal
        activeTab={activeTab}
        tabData={tabData}
        setTabData={updateTabData}
        open={isServiceModalOpen}
        setOpenModal={setServiceModalOpen}
      />

      {/* Model for set deposit */}
      <DepositModal
        activeTab={activeTab}
        tabData={tabData}
        setTabData={updateTabData}
        open={isDepositModalOpen}
        setOpenModal={setDepositModalOpen}
        type={depositModalType}
      />

      {/* Model for set time */}
      <PickTimeModal
        activeTab={activeTab}
        tabData={tabData}
        setTabData={updateTabData}
        open={isTimeModalOpen}
        setOpenModal={setTimeModalOpen}
        type={timeModalType}
        appointment={appointment}
      />

      <CustomerModal open={isCustomerModalOpen} setOpenModal={setCustomerModalOpen} setTabData={updateTabData} />

      {/* Cancel dialog */}
      <StyledPopup
        isOpen={isCancelModalOpen}
        content={
          <div>
            {intl.formatMessage({ id: 'appointment.modal.doYouWantTo' })}
            &nbsp;
            <span className="font-weight-700 text-transform-lowercase">
              {intl.formatMessage({ id: 'common.cancel' })}
            </span>
            &nbsp;
            {intl.formatMessage({ id: 'appointment.modal.thisAppointment' })}?
          </div>
        }
        onCancel={() => {
          setCancelModalOpen(false);
        }}
        onOk={() => {
          onFinishCancel();
          setCancelModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default AppointmentModified;
