import { DatePicker, DatePickerProps, Form, FormItemProps } from 'antd';
import React, { ReactNode } from 'react';
import './styledDatePickerStyle.scss';
import { SvgDatePickerIcon } from '../@svg/SvgDatePickerIcon';
type Props = {
  label?: ReactNode | string;
  datePickerProps?: DatePickerProps;
  datePickerIcon?: ReactNode;
  className?: string;
};

export const StyledDatePicker: React.FC<Props> = ({ datePickerProps, datePickerIcon, label, className }) => {
  return (
    <div className={`salon__form-date-picker salon__styled-date-picker w-100 ${className ?? ''}`}>
      {label && <label className="font-size-16 font-weight-500 m-b-8">{label}</label>}
      <DatePicker
        {...datePickerProps}
        popupClassName={'salon__form-date-picker-container'}
        // nextIcon={<SvgNextDayIcon />}
        // prevIcon={<SvgPrevDayIcon />}
        suffixIcon={datePickerIcon ?? <SvgDatePickerIcon />}
      />
    </div>
  );
};
