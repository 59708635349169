import { Form, FormProps } from 'antd';
import { ReactNode } from 'react';

function FormWrap(props: FormProps & { children: ReactNode }) {
  const formItemLayout =
    props.layout === 'horizontal' || !props.layout ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;

  return (
    <Form
      {...formItemLayout}
      layout={props.layout || 'horizontal'}
      form={props.form}
      // initialValues={{ layout: formLayout }}
      // style={{ maxWidth: formLayout === 'inline' ? 'none' : 600 }}
      {...props}
    >
      {/* <Form.Item label="Form Layout" name="layout">
        <Radio.Group value={formLayout}>
          <Radio.Button value="horizontal">Horizontal</Radio.Button>
          <Radio.Button value="vertical">Vertical</Radio.Button>
          <Radio.Button value="inline">Inline</Radio.Button>
        </Radio.Group>
      </Form.Item> */}
      {props.children}
    </Form>
  );
}

export default FormWrap;
