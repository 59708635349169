import React from 'react';

export const SvgAppointmentBlockTimeDatePickerIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.9276 0.941162H12.5158V2.35293C12.5158 2.63528 12.2805 2.82352 12.0452 2.82352C11.8099 2.82352 11.5746 2.63528 11.5746 2.35293V0.941162H4.04522V2.35293C4.04522 2.63528 3.80993 2.82352 3.57463 2.82352C3.33934 2.82352 3.10404 2.63528 3.10404 2.35293V0.941162H1.69228C0.986397 0.941162 0.46875 1.55293 0.46875 2.35293V4.04704H15.5276V2.35293C15.5276 1.55293 14.6805 0.941162 13.9276 0.941162ZM0.46875 5.03528V13.647C0.46875 14.4941 0.986397 15.0588 1.73934 15.0588H13.9746C14.7276 15.0588 15.5746 14.447 15.5746 13.647V5.03528H0.46875ZM4.65699 12.9412H3.52757C3.33934 12.9412 3.1511 12.8 3.1511 12.5647V11.3882C3.1511 11.2 3.29228 11.0118 3.52757 11.0118H4.70404C4.89228 11.0118 5.08051 11.1529 5.08051 11.3882V12.5647C5.03346 12.8 4.89228 12.9412 4.65699 12.9412ZM4.65699 8.70587H3.52757C3.33934 8.70587 3.1511 8.56469 3.1511 8.3294V7.15293C3.1511 6.96469 3.29228 6.77646 3.52757 6.77646H4.70404C4.89228 6.77646 5.08051 6.91763 5.08051 7.15293V8.3294C5.03346 8.56469 4.89228 8.70587 4.65699 8.70587ZM8.42169 12.9412H7.24522C7.05698 12.9412 6.86875 12.8 6.86875 12.5647V11.3882C6.86875 11.2 7.00993 11.0118 7.24522 11.0118H8.42169C8.60993 11.0118 8.79816 11.1529 8.79816 11.3882V12.5647C8.79816 12.8 8.65699 12.9412 8.42169 12.9412ZM8.42169 8.70587H7.24522C7.05698 8.70587 6.86875 8.56469 6.86875 8.3294V7.15293C6.86875 6.96469 7.00993 6.77646 7.24522 6.77646H8.42169C8.60993 6.77646 8.79816 6.91763 8.79816 7.15293V8.3294C8.79816 8.56469 8.65699 8.70587 8.42169 8.70587ZM12.1864 12.9412H11.0099C10.8217 12.9412 10.6335 12.8 10.6335 12.5647V11.3882C10.6335 11.2 10.7746 11.0118 11.0099 11.0118H12.1864C12.3746 11.0118 12.5629 11.1529 12.5629 11.3882V12.5647C12.5629 12.8 12.4217 12.9412 12.1864 12.9412ZM12.1864 8.70587H11.0099C10.8217 8.70587 10.6335 8.56469 10.6335 8.3294V7.15293C10.6335 6.96469 10.7746 6.77646 11.0099 6.77646H12.1864C12.3746 6.77646 12.5629 6.91763 12.5629 7.15293V8.3294C12.5629 8.56469 12.4217 8.70587 12.1864 8.70587Z"
        fill="#56595C"
      />
    </svg>
  </span>
);
