import { useQuery } from '@tanstack/react-query';
import { Form, Image } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { employeeApi } from '../../../../../apis';
import AvatarDefault from '../../../../../assets/images/appointment/avatar-default.png';
import { FormInputSearch } from '../../../../../components/Form/FormInputSearch';
import FormWrap from '../../../../../components/FormWrap';
import { QUERY_LIST_TECHNICIAN_FILTER } from '../../../../../utils/constant';
import {
  AppointmentEvent,
  AppointmentResource,
  FILTER_TECHNICIAN,
  FilterProps,
  FilterTechnician,
  RESOURCE_UNASIGNED_KEY,
  RESOURCE_UNASIGNED_NAME,
  getTechnicianDetail,
} from '../../models';

interface TechniciansFilterProps extends FilterProps {
  events: AppointmentEvent[];
  onCloseFilter: () => void;
}

const TechniciansFilter: FC<TechniciansFilterProps> = ({ filter, onChangeFilter, events, onCloseFilter }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [technicians, setTechnicians] = useState<AppointmentResource[]>([]);
  const [searchText, setSearchText] = useState<string>();

  const { data: listTechnicians } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN_FILTER, searchText],
    queryFn: () => employeeApi.employeeControllerTechnician(searchText),
  });

  useEffect(() => {
    const { revenue, tasks, hours } = getTechnicianDetail(RESOURCE_UNASIGNED_KEY, events);

    let resources: AppointmentResource[] = [
      {
        resourceId: RESOURCE_UNASIGNED_KEY,
        revenue,
        tasks,
        hours,
        resourceTitle: RESOURCE_UNASIGNED_NAME,
        name: RESOURCE_UNASIGNED_NAME,
        id: -1,
        phoneNumber: '',
      },
    ];

    if (listTechnicians?.data && listTechnicians.data.length > 0) {
      listTechnicians.data.forEach((technician) => {
        const { revenue, tasks, hours } = getTechnicianDetail(technician.id, events);

        const resource: AppointmentResource = {
          resourceId: technician.id,
          revenue,
          tasks,
          hours,
          resourceTitle: technician.name || '',
          avatar:
            technician.defaultAvatar || technician.avatar?.preview || technician.avatar?.source
              ? technician.defaultAvatar ||
                `${process.env.REACT_APP_API_URL}/static/${technician.avatar?.preview || technician.avatar?.source}`
              : AvatarDefault,
          name: technician.name,
          id: technician.id,
          phoneNumber: technician.phoneNumber,
          resource: technician,
        };

        resources.push(resource);
      });
    }

    setTechnicians(resources);
  }, [listTechnicians?.data, events]);

  const handleChangeFilterTechnicians = (technician: FilterTechnician) => {
    onChangeFilter({ technician });
    onCloseFilter();
  };

  const onFinish = (formValues: { fullTextSearch?: string }) => {
    setSearchText(formValues.fullTextSearch);
  };

  return (
    <FormWrap name="techniciansFilter" form={form} onFinish={onFinish}>
      <FormInputSearch
        name="fullTextSearch"
        inputProps={{
          placeholder: intl.formatMessage({ id: 'appointment.popover.technician.search.placeholder' }),
        }}
      />
      <div
        className={`salon__appointment-calendar-filter-popover-box ${
          filter?.technician === FILTER_TECHNICIAN.ALL || !filter?.technician
            ? 'salon__appointment-calendar-filter-popover-active'
            : ''
        }`}
        onClick={() => handleChangeFilterTechnicians(FILTER_TECHNICIAN.ALL)}
      >
        <p className="font-size-12 font-weight-600 salon__appointment-calendar-filter-popover-box-text">
          {intl.formatMessage({ id: 'appointment.popover.technician.all' })}
        </p>
      </div>
      <div
        className={`salon__appointment-calendar-filter-popover-box ${
          filter?.technician === FILTER_TECHNICIAN.AT_LEAST_ONE
            ? 'salon__appointment-calendar-filter-popover-active'
            : ''
        }`}
        onClick={() => handleChangeFilterTechnicians(FILTER_TECHNICIAN.AT_LEAST_ONE)}
      >
        <p className="font-size-12 font-weight-600 salon__appointment-calendar-filter-popover-box-text">
          {intl.formatMessage({ id: 'appointment.popover.technician.title' })}
        </p>
        <p className="font-size-10 font-weight-500 salon__appointment-calendar-filter-popover-box-text salon__appointment-calendar-filter-popover-box-subtitle">
          {intl.formatMessage({ id: 'appointment.popover.technician.subtitle' })}
        </p>
      </div>
      <div className="salon__appointment-calendar-filter-popover-list">
        {technicians.map((technician) => (
          <div
            className={`salon__appointment-calendar-filter-popover-item-wrapper ${
              filter?.technician === technician.resourceId ? 'salon__appointment-calendar-filter-popover-active' : ''
            }`}
            onClick={() => handleChangeFilterTechnicians(technician.resourceId)}
            key={technician.id}
          >
            <div className="salon__appointment-calendar-filter-popover-item">
              {technician.resourceId !== RESOURCE_UNASIGNED_KEY && (
                <div className="salon__appointment-calendar-filter-popover-item-avatar">
                  <Image alt={technician.resourceTitle} src={technician.avatar} preview={false} />
                </div>
              )}
              <span className="font-size-14 font-weight-500 salon__appointment-calendar-filter-popover-item-name truncate">
                {technician.resourceTitle}
              </span>
            </div>
          </div>
        ))}
      </div>
    </FormWrap>
  );
};

export default TechniciansFilter;
