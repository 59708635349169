import { FormInstance } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { formatNumberThousandWithDecimal } from '../../../../utils';
import './numericKeyboardStyle.scss';

export const KeyboardDirection = {
  rtl: 'rtl',
  ltr: 'ltr',
};

interface IProps<T extends object> {
  form: FormInstance;
  keyboardRef: any;
  inputName?: string | NamePath<any>;
  maxLength?: number;
  keyboardProps?: KeyboardReactInterface['options'];
  formatFunction?: (value: string | number) => string;
  direction?: typeof KeyboardDirection[keyof typeof KeyboardDirection];
  isNotFormat?: boolean;
  disabledKeyboard?: boolean;
}

interface TechnicianTip {
  id: number;
  tip?: string;
}

function KeyboardWrapper<T extends object>(props: IProps<T>) {
  const {
    keyboardRef,
    inputName,
    maxLength,
    keyboardProps,
    form,
    formatFunction,
    direction = 'ltr',
    isNotFormat,
    disabledKeyboard,
  } = props;

  const customLayout = {
    default: ['1 2 3', '4 5 6', '7 8 9', '00 0 {backspace}'],
  };

  const customDisplay = {
    '{backspace}': ' ',
  };

  const customTheme = [{ class: 'salon__numeric-keyboard__back-space', buttons: '{backspace}' }];

  const formatValueRtl = (currentValue?: string, button?: string, maxLength?: number) => {
    let value = '0.00';
    const firstRegex = /^0\.0[0-9]$/;
    const secondRegex = /^0\.[0-9]{2}$/;

    if (currentValue === undefined || currentValue === null) return value;

    if (maxLength) {
      const [integer] = currentValue.split('.');

      if (integer.length === maxLength) return currentValue;
    }

    const replaceCurrentValue = currentValue.replace('.', '');

    if (currentValue.length === 0) {
      if (button === '0' || button === '00') {
        value = '';
      } else {
        value = `0.0${button}`;
      }
    } else {
      if (currentValue.match(firstRegex)) {
        value = `0.${currentValue[currentValue.length - 1]}${button}`;

        if (button === '00') {
          value = `${currentValue[currentValue.length - 1]}.${button}`;
        }
      } else if (currentValue.match(secondRegex)) {
        value = `${replaceCurrentValue[1]}.${replaceCurrentValue[2]}${button}`;
      } else {
        value = `${replaceCurrentValue.slice(0, replaceCurrentValue.length - 1)}.${
          replaceCurrentValue[replaceCurrentValue.length - 1]
        }${button}`;

        if (button === '00') {
          value = `${replaceCurrentValue.slice(0, replaceCurrentValue.length)}.${button}`;
        }
      }
    }

    return value;
  };

  const formatValueRtlWhenDelete = (currentValue?: string, button?: string) => {
    let value = '0.00';

    if (currentValue === undefined || currentValue === null) return '';

    let [integer, decimal] = currentValue.replace(/,/g, '').split('.');
    integer = integer ? integer : '';
    decimal = decimal ? decimal : '';

    if (button === '{backspace}') {
      const newValueDecimal = `${integer.length > 0 ? integer[integer.length - 1] : 0}${
        decimal.length > 0 ? decimal.slice(0, decimal.length - 1) : 0
      }`;

      let newValueInteger = `${integer.length > 0 ? integer.slice(0, integer.length - 1) : 0}`;

      if (integer.length === 1) {
        newValueInteger = `0${newValueInteger}`;
      }

      value = `${newValueInteger}.${newValueDecimal}`;

      if (value === '0.00') value = '';

      return value;
    } else return value;
  };

  const onKeyPress = (button: string, e?: MouseEvent) => {
    if (disabledKeyboard) return;
    if (inputName && Array.isArray(inputName) && inputName[1] === 'tip') {
      const [index] = inputName;
      const technicianTips: TechnicianTip[] = form.getFieldValue('technicianTips');
      const currentValue = technicianTips[index];
      const tip = currentValue.tip?.split('.')[0].replace(/,/g, '').replace(/ /g, '') || '';
      if (button === '{backspace}') {
        if (currentValue.tip) {
          const newValue = formatValueRtlWhenDelete(currentValue.tip, button);
          technicianTips[index] = {
            ...currentValue,
            tip: newValue === '' ? newValue : formatNumberThousandWithDecimal(newValue),
          };
          const copyTechnicianTips = [...technicianTips].map((technician, idx) => {
            if (idx === index) return { ...technician };
            else return { ...technician, tip: '' };
          });
          form.setFieldValue('technicianTips', copyTechnicianTips);
        }
      } else {
        if (maxLength) {
          const newValue = tip.length < maxLength ? `${tip || ''}${button}` : tip;
          const value = formatValueRtl(currentValue.tip?.replace(/,/g, '') || '', button, maxLength);
          technicianTips[index] = {
            ...currentValue,
            tip: formatNumberThousandWithDecimal(direction === KeyboardDirection.rtl ? value : newValue),
          };
          const copyTechnicianTips = [...technicianTips].map((technician, idx) => {
            if (idx === index) return { ...technician };
            else if (technicianTips.every((technician) => technician?.tip)) return { ...technician, tip: '' };
            else return { ...technician };
          });
          form.setFieldValue('technicianTips', copyTechnicianTips);
        } else {
          const newValue = `${tip || ''}${button}`;
          const value = formatValueRtl(currentValue.tip?.replace(/,/g, '') || '', button, maxLength);
          technicianTips[index] = {
            ...currentValue,
            tip: formatNumberThousandWithDecimal(direction === KeyboardDirection.rtl ? value : newValue),
          };
          const copyTechnicianTips = [...technicianTips].map((technician, idx) => {
            if (idx === index) return { ...technician };
            else if (technicianTips.every((technician) => technician?.tip)) return { ...technician, tip: '' };
            else return { ...technician };
          });
          form.setFieldValue('technicianTips', copyTechnicianTips);
        }
      }
    } else if (inputName === 'cardNumber') {
      const currentValue = ((form.getFieldValue(inputName) as string)?.split('.')[0] || '')
        ?.replace(/,/g, '')
        .replace(/ /g, '');

      if (button === '{backspace}') {
        if (currentValue) {
          const newValue = currentValue.slice(0, -1);
          form.setFieldValue(inputName, newValue);
        }
      } else {
        if (maxLength) {
          const newValue = currentValue.length < maxLength ? `${currentValue}${button}` : currentValue;
          form.setFieldValue(inputName, newValue);
        } else {
          const newValue = `${currentValue}${button}`;
          form.setFieldValue(inputName, newValue);
        }
      }
    } else if (inputName) {
      //remove all space or splitter, get float number (e.g: 100.00; 10.5; ...)
      // const currentValue = form.getFieldValue(inputName)
      //   ? convertFormatNumberToNumber(form.getFieldValue(inputName)).toString()
      //   : '';

      const currentValue: string = form.getFieldValue(inputName) || '';

      //divide into integer and decimal part, like [10, 5] = 10.5.split('.')
      let [integer, decimal] = currentValue.replace(/,/g, '').split('.');
      integer = integer ? integer : '';
      decimal = decimal ? decimal : '';
      if (button === '{backspace}') {
        const newValue = formatValueRtlWhenDelete(currentValue, button);

        form.setFieldValue(
          inputName,
          newValue === ''
            ? newValue
            : formatFunction
            ? formatFunction(newValue)
            : formatNumberThousandWithDecimal(newValue)
        );
      } else {
        if (maxLength) {
          //add value to integer part
          const newValue =
            integer.length < maxLength ? `${integer}${button}`.concat('.', decimal) : integer.concat('.', decimal);

          const value = formatValueRtl(currentValue, button, maxLength);

          form.setFieldValue(
            inputName,
            formatFunction
              ? direction === KeyboardDirection.rtl
                ? value
                : formatFunction(newValue)
              : direction === KeyboardDirection.rtl
              ? value
              : formatNumberThousandWithDecimal(newValue)
          );
        } else {
          const newValue = `${integer}${button}`.concat('.', decimal);
          form.setFieldValue(
            inputName,
            formatFunction ? formatFunction(newValue) : formatNumberThousandWithDecimal(newValue)
          );
        }
      }
    }
  };

  const onKeyPressNoFormat = (button: string, e?: MouseEvent) => {
    if (disabledKeyboard) return;
    const currentValue = form.getFieldValue(inputName) ?? '';
    if (button === '{backspace}') {
      if (currentValue) {
        const newValue = currentValue.slice(0, -1);
        form.setFieldValue(inputName, newValue);
      }
    } else {
      const newValue = `${currentValue}${button}`;
      form.setFieldValue(inputName, newValue.length > 9 ? currentValue : newValue);
    }
  };

  return (
    <div className="salon__numeric-keyboard">
      <Keyboard
        keyboardRef={(r) => (keyboardRef.current = r)}
        maxLength={maxLength}
        onKeyPress={!isNotFormat ? onKeyPress : onKeyPressNoFormat}
        layout={customLayout}
        buttonTheme={customTheme}
        display={customDisplay}
        inputName={inputName}
        preventMouseDownDefault={true}
        {...keyboardProps}
      />
    </div>
  );
}

export default KeyboardWrapper;
