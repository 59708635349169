import { FormItemProps, SelectProps } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FC, ReactNode, useMemo } from 'react';
import { Salon } from '../../../../../apis/client-axios';
import { SvgAppointmentTimePickerIcon } from '../../../../../components/@svg/SvgAppointmentTimePickerIcon';
import { FormSelect } from '../../../../../components/Form/FormSelect';
import { APPOINTMENT_TIME_FORMAT, TIME_FORMAT, formatTimeHHMM, formatTimeHHMMa } from '../../../../../utils';
import { WorkingHours } from '../../../employee/employeeConstants';

interface TimeBlockingPickerProps {
  name: 'from' | 'to';
  time: Date;
  label?: ReactNode;
  salonInformation?: Salon;
  fromValue?: string;
  toValue?: string;
  placeholder?: string;
  formItemProps?: FormItemProps;
  selectProps?: SelectProps;
}

dayjs.extend(customParseFormat);

const TimeBlockingPicker: FC<TimeBlockingPickerProps> = (props) => {
  const { name, time, label, salonInformation, fromValue, toValue, placeholder, formItemProps, selectProps } = props;

  const options = useMemo(() => {
    const day = dayjs(time).day();
    const dayIndex = day > 0 ? day - 1 : 6;

    const timePicker = [];

    const workingTime: WorkingHours | undefined = salonInformation?.salonSetting?.workingTime as
      | WorkingHours
      | undefined;

    const findWorkingTime = workingTime?.[Object.keys(workingTime)?.[dayIndex] as keyof WorkingHours];

    const distance =
      dayjs(formatTimeHHMM(findWorkingTime?.to), TIME_FORMAT).diff(
        dayjs(formatTimeHHMM(findWorkingTime?.from), TIME_FORMAT),
        'minutes'
      ) / 15;

    const from = name === 'from' ? 0 : 1;
    const to = name === 'from' ? distance : distance + 1;

    for (let i = from; i < to; i++) {
      const newTime = formatTimeHHMMa(dayjs(findWorkingTime?.from).add(15 * i, 'minutes'));
      timePicker.push({
        label: newTime,
        value: newTime,
      });
    }

    if (name === 'from' && toValue) {
      return timePicker.filter(
        (time) =>
          dayjs(time.value, APPOINTMENT_TIME_FORMAT).format(TIME_FORMAT) <
          dayjs(toValue, APPOINTMENT_TIME_FORMAT).format(TIME_FORMAT)
      );
    }

    if (name === 'to' && fromValue) {
      return timePicker.filter(
        (time) =>
          dayjs(time.value, APPOINTMENT_TIME_FORMAT).format(TIME_FORMAT) >
          dayjs(fromValue, APPOINTMENT_TIME_FORMAT).format(TIME_FORMAT)
      );
    }

    return timePicker;
  }, [salonInformation, time, toValue, fromValue, name]);

  return (
    <FormSelect
      name={name}
      label={label}
      selectProps={{
        options,
        suffixIcon: <SvgAppointmentTimePickerIcon />,
        allowClear: false,
        placeholder,
        ...selectProps,
      }}
      formItemProps={{
        rules: [{ required: true, message: '' }],
        ...formItemProps,
      }}
    />
  );
};

export default TimeBlockingPicker;
