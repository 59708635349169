import React from 'react';

export const SvgAppointmentDatePickerIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22H5C3.89 22 3 21.1 3 20V6C3 4.9 3.89 4 5 4H6V3C6 2.45 6.45 2 7 2C7.55 2 8 2.45 8 3V4H16V3C16 2.45 16.45 2 17 2C17.55 2 18 2.45 18 3V4ZM8 11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11ZM18 20H6C5.45 20 5 19.55 5 19V9H19V19C19 19.55 18.55 20 18 20ZM8 15H13C13.55 15 14 15.45 14 16C14 16.55 13.55 17 13 17H8C7.45 17 7 16.55 7 16C7 15.45 7.45 15 8 15Z"
        fill="#00297A"
      />
    </svg>
  </span>
);
